import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  Box,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Grid,
  Paper,
} from "@mui/material";
import { Warning } from "@mui/icons-material";
import axios from "axios";
import { API_URI } from "../../../Globals";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import WarningIcon from "@mui/icons-material/Warning";
import { Home as HomeIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { claimJob } from "./totallossv2.service";
import BaseComponentTotalLoss from "./totallossv2baseapp";
import "@fontsource/orbitron";
import { styled } from "@mui/system";
import "@fontsource/lato";
import { useAuth } from "../../../AuthContext";

const AppraiserUsage = () => {
  const { isAuthenticated, user } = useAuth();
  var source = axios.CancelToken.source();
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState("0,1,2,3,4,5");
  const [showWarning, setShowWarning] = useState(false);
  const [pendingJobsWarning, setPendingJobsWarning] = useState(true);
  const [tableData, setTableData] = useState({
    columns: [],
    data: [],
    startIndex: 0,
    resultCount: 10,
    totalCount: 0,
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [columns, setColumns] = useState([
    { title: "Index", field: "index" },
    { title: "CustomerId", filed: "customer_id" },
    { title: "ZipCode", field: "customer_zip" },
    { title: "Status", field: "status" },
    { title: "VIN", field: "vin" },
    { title: "Claimed Date", field: "submit_date", type: "date" },
    { title: "Time Elapsed", field: "startTime", type: "date" },
  ]);

  const columnsBase = [
    { title: "Index", field: "index" },
    { title: "CustomerId", field: "customer_id" },
    { title: "ZipCode", field: "customer_zip" },
    { title: "Status", field: "status" },
    { title: "VIN", field: "vin" },
    { title: "Claimed Date", field: "submit_date", type: "date" },
    { title: "Time Elapsed", field: "startTime", type: "date" },
  ];

  const columnsAdmin = [
    { title: "Index", field: "index" },
    { title: "CustomerId", field: "index" },
    { title: "ZipCode", field: "customer_zip" },
    { title: "Status", field: "status" },
    { title: "VIN", field: "vin" },
    { title: "Claimed Date", field: "submit_date", type: "date" },
    { title: "Claimed By", field: "appraiser_name" },
    { title: "Time Elapsed", field: "startTime", type: "date" },
    { title: "Actions", field: "actions" },
  ];

  const statusMap = {
    0: "Unclaimed",
    1: "Claimed - Working",
    2: "Paused - Waiting for Customer Response",
    3: "Completed - Pending Customer Review",
    4: "Issue Raised - Waiting for Appraiser’s Response",
    5: "Issue Raised - Working",
    6: "Final - Locked",
    7: "Canceled - Locked",
  };

  const [userList, setUserlist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jobId, setJobId] = useState(null);

  const switchAppraiser = async (jobId, newAppraiserId, newAppraiserName) => {
    try {
      const response = await axios.patch(
        API_URI() + "/totalloss/switchAppraiserId",
        {
          jobId: jobId,
          newAppraiserId: newAppraiserId,
          newAppraiserName: newAppraiserName,
        }
      );

      console.log("Appraiser switched successfully", response.data);
      // Handle the success scenario (like updating UI or state)
    } catch (error) {
      console.error(
        "Error switching appraiser",
        error.response ? error.response.data : error
      );
      // Handle the error scenario (like showing an error message to the user)
    }
  };

  const openSelectClaimedByModal = (row) => {
    console.log("Row Data:", row);
    setJobId(row.jobId);
    setIsModalOpen(true);
  };

  const switchJobToUser = async (user) => {
    // ... your logic to switch job to this user
    console.log("Switching job to user:", user);
    await switchAppraiser(jobId, user.Serial, user.Name);
    handleCloseModal(); // Close the modal after switching
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    } else {
      const apiUrl = API_URI() + `/adminShop/users?serial=${user.Serial}`;

      axios
        .get(apiUrl)
        .then((response) => {
          console.log("User List:", response.data);
          setUserlist(response.data);
        })
        .catch((err) => {
          navigate("/");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isAuthenticated, user]);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await handleChange(
      null,
      parseInt(localStorage.getItem("tab")),
      newPage,
      rowsPerPage
    );
  };

  const handleChangeRowsPerPage = async (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    await handleChange(
      null,
      parseInt(localStorage.getItem("tab")),
      0,
      newRowsPerPage
    );
  };

  const fetchData = async (startIndex, resultCount) => {
    setPage(startIndex);
    setRowsPerPage(resultCount);
    handleChange(
      null,
      parseInt(localStorage.getItem("tab")),
      startIndex,
      resultCount
    );
  };

  useEffect(() => {
    localStorage.setItem("tab", "0");
    console.log("User Serial:", user);
    let statusFilter = "1,4,5";
    const requestUri =
      API_URI() +
      `/totalloss/getJobsAppraiser?status=${statusFilter}&customer_id=${user.Serial}`;

    setRequestUri(requestUri);
    fetchData(0, 10);
  }, [page, rowsPerPage, isAuthenticated, user]);

  const [lrequestUri, setRequestUri] = useState("");
  const handleChange = async (event, newValue, page, rowsPerPage) => {
    try {
      source.cancel("Operation canceled by the user.");
    } catch (err) {}

    cancelRefresh();

    if (parseInt(localStorage.getItem("tab")) !== newValue) {
      setPage(0);
      setRowsPerPage(10);

      page = 0;
      rowsPerPage = 10;
    }

    console.log(
      "Current Tab Value:",
      parseInt(localStorage.getItem("tab")),
      "New Tab Value:",
      newValue
    );

    localStorage.setItem("tab", newValue.toString());

    var statusFilter = "";
    var requestUri = "";

    if (newValue === 0) {
      user.role === "shopAdmin"
        ? setColumns(columnsAdmin)
        : setColumns(columnsBase);
      statusFilter = "1,4,5";
      requestUri =
        API_URI() +
        `/totalloss/getJobsAppraiser?status=${statusFilter}&startIndex=${page}&resultCount=${rowsPerPage}&customer_id=${user.Serial}`;
    } else if (newValue === 1) {
      setColumns(columnsBase);
      statusFilter = "0";
      requestUri =
        API_URI() +
        `/totalloss/getJobsByStatus?status=${statusFilter}&startIndex=${page}&resultCount=${rowsPerPage}
      )}`;
    } else if (newValue === 2) {
      user.role === "shopAdmin"
        ? setColumns(columnsAdmin)
        : setColumns(columnsBase);
      statusFilter = "2,3";
      requestUri =
        API_URI() +
        `/totalloss/getJobsAppraiser?status=${statusFilter}&startIndex=${page}&resultCount=${rowsPerPage}&customer_id=${user.Serial}`;
    } else if (newValue === 4) {
      setColumns(columnsBase);
      statusFilter = "6,7";
      requestUri =
        API_URI() +
        `/totalloss/getJobsAppraiser?status=${statusFilter}&startIndex=${page}&resultCount=${rowsPerPage}&customer_id=${user.Serial}`;
    }
    source = axios.CancelToken.source();
    setIsActiveRequest(true);
    try {
      const response = await axios.get(requestUri, {
        cancelToken: source.token,
      });

      setTableData(response.data);
      console.log("Data:", response.data);
      if (newValue === 0) {
        var arrData = Array.from(response.data.data);
        console.log("Data:", arrData);
        const hasUnclaimedJob = arrData.some((job) => job.status === "4");
        console.log("HasIssue:", hasUnclaimedJob);
        setShowWarning(hasUnclaimedJob);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching data:", error);
      }
    } finally {
      setIsActiveRequest(false);
      setupAutoRefresh();
    }
  };

  const handleRowClick = (rowData) => {
    navigate("/dashboard/totallossv2/appraiser/job/details", {
      state: { rowData },
    });
  };

  const refreshInterval = useRef(null);
  const [isActiveRequest, setIsActiveRequest] = useState(false);

  const cancelRefresh = () => {
    if (refreshInterval.current) {
      clearInterval(refreshInterval.current);
    }
  };

  const setupAutoRefresh = () => {
    cancelRefresh();

    refreshInterval.current = setInterval(() => {
      if (!isActiveRequest) {
        console.log("Fetching data...");
        setIsActiveRequest(true);
        fetchData(page, rowsPerPage).finally(() => {
          setIsActiveRequest(false);
        });
      }
    }, 3000);
  };

  useEffect(() => {
    setupAutoRefresh();

    return () => {
      cancelRefresh();
    };
  }, []);
  const StyledTableContainer = styled(TableContainer)`
    border-bottom: 4px solid black;
    margin-left: 16px;
    width: 98.5%;
  `;

  const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    backgroundColor: "white",
    color: "#00457c",
    borderBottom: "4px solid black",
    fontSize: "1.6em", // Adjust the font size as needed
    textAlign: "center",
  }));

  const StyledTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: white;
    }

    &:nth-of-type(even) {
      background-color: #e6e7e8;
    }

    &:hover {
      background-color: #5d4479;
      cursor: pointer;
    }
  `;

  const StyledTableCell = styled(TableCell)`
    font-weight: bold;
    font-family: "Lato", sans-serif;
    text-align: center;
  `;

  const StyledTablePagination = styled(TablePagination)`
    font-weight: bold;
    font-size: 1.2em;
  `;

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto", // Adjust the width as needed
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxHeight: "90vh", // Optional: limit height of the modal
    overflow: "auto", // Allow scrolling within the modal
  };

  const StyledTab = ({ label, ...otherProps }) => {
    const selected = otherProps.selected || false;

    return (
      <>
        <Tab
          label={
            <Typography
              style={{
                color: selected ? "#e6e7e8" : "hsl(0, 0%, 0%)",
                fontWeight: 900,
                fontFamily: "Orbitron",
                fontSize: "1.4em",
                opacity: 1,
              }}
            >
              {label.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          }
          style={{
            backgroundColor: selected ? "#00457c" : "#e6e7e8",
          }}
          {...otherProps}
        />
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Select a User to Switch Job
            </Typography>
            <Box
              id="modal-modal-description"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              {userList.map((user, index) => (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  onClick={() => switchJobToUser(user)}
                  sx={{ my: 1 }} // Margin top & bottom for spacing
                >
                  {user.Name /* or however you want to display the user */}
                </Button>
              ))}
            </Box>
          </Box>
        </Modal>
      </>
    );
  };

  function extractDateDifference(inputStr) {
    console.log("InputStr:", inputStr);
    // Parse the date string
    const givenDate = new Date(inputStr);

    // Get the current date and its timezone offset in minutes
    const currentDate = new Date();
    const currentOffset = currentDate.getTimezoneOffset();

    // Get the timezone offset of the given date in minutes
    const givenOffset = givenDate.getTimezoneOffset();

    // Adjust for the timezone difference
    const adjustedCurrentDate = new Date(
      currentDate.getTime() - (givenOffset - currentOffset) * 60000
    );

    // Calculate the difference in milliseconds
    let difference = adjustedCurrentDate - givenDate;

    // Calculate the days, hours, minutes, and seconds from the difference
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    difference -= days * (1000 * 60 * 60 * 24);

    const hours = Math.floor(difference / (1000 * 60 * 60));
    difference -= hours * (1000 * 60 * 60);

    const minutes = Math.floor(difference / (1000 * 60));
    difference -= minutes * (1000 * 60);

    const seconds = Math.floor(difference / 1000);

    return `${days}D:${hours}H:${minutes}M:${seconds}S`;
  }

  return (
    <BaseComponentTotalLoss imageUrl="../../../..\img\totalloss_header.png">
      {showWarning && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="#f8d7da"
          borderRadius={1}
          p={1}
          mb={2}
        >
          <Warning color="error" />
          <Typography
            variant="body2"
            color="error.main"
            style={{ fontWeight: "bold" }}
          >
            WARNING!!! You have job(s) that have have reported issues.You will
            not be able to claim another job until the issue(s) are handled
          </Typography>
          <Warning color="error" />
        </Box>
      )}

      <Box sx={{ width: "90%", marginLeft: "5%" }}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ backgroundColor: "white", padding: "0 16px" }}
          >
            <Grid item>
              <Tabs
                value={parseInt(localStorage.getItem("tab"))}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="standard"
              >
                <StyledTab label={"Claimed \n Jobs"} />
                <StyledTab label={"Available \n Jobs"} />
                <StyledTab label={"Waiting on \n Customer"} />
              </Tabs>
            </Grid>

            <Grid item xs>
              <Typography
                variant="h2"
                align="center"
                gutterBottom
                style={{
                  color: "#00457c",
                  fontWeight: 900,
                  fontFamily: "Orbitron",
                  fontSize: "3em",
                  opacity: 1,
                }}
              >
                Independent Appraiser Dashboard
              </Typography>
            </Grid>

            <Grid item>
              <Tabs
                value={parseInt(localStorage.getItem("tab"))}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="standard"
              >
                <Tab value={5} label="" disabled /> {/* Invisible tab */}
                <Tab value={5} label="" disabled /> {/* Invisible tab */}
                <StyledTab value={4} label={"Completed \n Jobs"} />
              </Tabs>
            </Grid>
          </Grid>
        </AppBar>
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableHeadCell key={column.field}>
                    {column.title.toUpperCase()}
                  </StyledTableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.data.map((row, index) => (
                <StyledTableRow
                  key={row.jobId}
                  hover
                  onClick={() => handleRowClick(row)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: row.status === "4" ? "#5d4479" : "inherit",
                  }}
                >
                  <StyledTableCell>
                    {row.status === "4" && <WarningIcon color="warning" />}
                    {tableData.startIndex + index + 1}
                  </StyledTableCell>
                  {columns.slice(1).map((column) => {
                    if (column.field === "status") {
                      return (
                        <StyledTableCell key={`${row.jobId}_${column.field}`}>
                          {statusMap[row[column.field]]}
                        </StyledTableCell>
                      );
                    } else if (column.field === "startTime") {
                      return (
                        <StyledTableCell key={`${row.jobId}_${column.field}`}>
                          {extractDateDifference(row[column.field])}
                        </StyledTableCell>
                      );
                    } else if (column.field === "actions") {
                      return (
                        <StyledTableCell key={`${row.jobId}_actions`}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(event) => {
                              event.stopPropagation(); // This will prevent the event from propagating to the row click
                              openSelectClaimedByModal(row);
                            }}
                          >
                            Switch Claimed User
                          </Button>
                        </StyledTableCell>
                      );
                    } else {
                      return (
                        <StyledTableCell key={`${row.jobId}_${column.field}`}>
                          {row[column.field]}
                        </StyledTableCell>
                      );
                    }
                  })}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <StyledTablePagination
          component="div"
          count={tableData.totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </BaseComponentTotalLoss>
  );
};

export default AppraiserUsage;
