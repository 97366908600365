import React, { useState, useEffect, useRef } from "react";
import CryptoJS from "crypto-js";
import { makeStyles } from "@material-ui/core/styles";
import { createJobRequest } from "./totallossv2.service";
import CustomInfoModal from "./totallossv2.custominfomodal";
import { updateJobRequest, updateRequestMessage } from "./totallossv2.service";
import SeeMoreInfoModal from "./totallossv2.job.moreinfo.modal";
import BaseComponentTotalLoss from "./totallossv2baseapp";
import "@fontsource/orbitron";
import { styled } from "@mui/system";
import "@fontsource/lato";
import { useLocation, useNavigate } from "react-router-dom";
import {
  UploadPdf,
  SubmitJob,
  ValidatePDF,
} from "../../../services/autoestimaterekey.service";
import { useAuth } from "../../../AuthContext";
import {
  claimJob,
  uploadPdf,
  abandonJob,
  submitJob,
} from "./totallossv2.service";
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  OutlinedInput,
  Modal,
  ButtonGroup,
  Dialog,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import PieMenu, { Slice } from "react-pie-menu";
import { ThemeProvider, css } from "styled-components";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "initial",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "initial",
    },
  },
});

const TotalLossJobDetailsAppraiser = () => {
  const { isAuthenticated, user } = useAuth();
  const { state } = useLocation();
  const [platform, setPlatform] = useState("");
  const [vin, setVin] = useState("");
  const [images, setImages] = useState({});
  const [open, setOpen] = useState(false);
  const [appraiserMsg, setAppraiserMsg] = useState([]);
  const [customerMsg, setCustomerMsg] = useState([]);
  const [optionalNotes, setOptionalNotes] = useState("");
  const [modalHeader, setModalHeader] = useState("");
  const [pdfOk, setPdfOk] = useState(null);
  const [additionalImages, setAdditionalImages] = useState(
    new Array(5).fill(null)
  );
  const [specialInstructions, setSpecialInstructions] = useState("");
  //Modal data
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [canEditFields, seCanEditFields] = useState(true);
  const [timer, setTimer] = useState("");
  const [counter, setCounter] = useState(0);
  const [showErrModal, setshowErrModal] = useState("");
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const handleAdditionalImageChange = (event, index) => {
    const updatedAdditionalImages = [...additionalImages];
    updatedAdditionalImages[index] = event.target.files[0];
    setAdditionalImages(updatedAdditionalImages);
  };

  const [body, setBody] = useState("");
  const [bdyS, setBdyS] = useState("");
  const [refinish, setRefinish] = useState("");
  const [glass, setGlass] = useState("");
  const [frame, setFrame] = useState("");
  const [mechanical, setMechanical] = useState("");
  const [checkingPdf, setIsCheckingPdf] = useState(false);
  const [checkingPdfMessage, setCheckingPdfMessage] = useState("");
  //
  const [paintMaterialsRate, setPaintMaterialsRate] = useState("");
  const [taxRateForParts, setTaxRateForParts] = useState("");
  const [taxRateForLabor, setTaxRateForLabor] = useState("");
  const [useAlternativeParts, setUseAlternativeParts] = useState(false);

  // State for each button
  const [condition, setCondition] = useState("");
  const [buttonColors, setButtonColors] = useState({
    14: "white",
    16: "white",
    19: "white",
    21: "white",
    12: "white",
    1: "white",
    3: "white",
    4: "white",
    5: "white",
    6: "white",
    7: "white",
    8: "white",
    9: "white",
    10: "white",
    11: "white",
    13: "white",
  });

  const handleButtonClick = (id) => {};

  const handleDialogOption = (color) => {};

  const theme = {
    pieMenu: {
      container: css`
        border: 4px solid white;
        background: radial-gradient(
          transparent
            ${({ centerRadius }) =>
              `${centerRadius}, rgba(255, 255, 255, 0.85) ${centerRadius}`}
        );
        overflow: visible;
      `,
    },
    slice: {
      container: css`
        cursor: pointer;
        border: 1px solid lightgrey;

        /* Dynamically adjust background based on slice kind */
        background: ${(props) => buttonColors[props.kind] || "white"};

        // ... other slice styles ...
      `,
      content: css`
        padding-left: 60px;
        padding-right: 60px;
      `,
    },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => {
        const newCounter = prevCounter;
        getTimerStr(newCounter);
        return newCounter;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    console.log("UseEffect:", state.rowData);
    const rawData = state.rowData;

    setMessages(rawData.infoLog === null ? [] : rawData.infoLog);
    setVin(rawData.vin);
    setPlatform(rawData.platform);
    setImagesFromUrls(rawData.imagePaths);
    setImagesExtraFromUrls(rawData.imagePathsExtras);
    setOptionalNotes(rawData.optional_notes);
    setBody(rawData.body);
    setBdyS(rawData.bdyS);
    setRefinish(rawData.refinish);
    setGlass(rawData.glass);
    setFrame(rawData.frame);
    setMechanical(rawData.mechanical);
    setCondition(rawData.condition);
    setButtonColors(JSON.parse(rawData.buttonColors));
    setshowErrModal(rawData.msg_error);
    setSpecialInstructions(rawData.specialInstructions);
    setPaintMaterialsRate(rawData.paintrate);
    setTaxRateForParts(rawData.taxparts);
    setTaxRateForLabor(rawData.taxlabor);
    setUseAlternativeParts(rawData.usealternativeparts);
    if (
      !(
        rawData.status === "1" ||
        rawData.status === "4" ||
        rawData.status === "5"
      )
    ) {
      seCanEditFields(false);
      console.log("We can't edit this");
    }
  }, [state.rowData]);

  const getTimerStr = (diff) => {
    const rawData = state.rowData;
    var dateToUse = rawData.startDate;
    var currentDate = new Date();
    if (
      rawData.status === "1" ||
      rawData.status === "4" ||
      rawData.status === "5"
    ) {
      dateToUse = rawData.startTime;
    } else if (rawData.status === "2") {
      dateToUse = rawData.updateTime;
    } else return;

    var differenceInMilliseconds =
      new Date(dateToUse) - currentDate + 1000 * 60 * 60 * 12;
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    const differenceInDays = Math.floor(differenceInHours / 24);

    const seconds = differenceInSeconds % 60;
    const minutes = differenceInMinutes % 60;
    const hours = differenceInHours % 24;

    const result = `${differenceInDays.toString().padStart(2, "0")} ${hours
      .toString()
      .padStart(2, "0")} ${minutes.toString().padStart(2, "0")} ${seconds
      .toString()
      .padStart(2, "0")}`;

    setTimer(result);
  };

  const handleOpenIssue = () => {
    setMessages(state.rowData.issueLog);
    setshowErrModal(state.rowData.msg_error);
    setOpen(true);

    console.log("AppMsg:", appraiserMsg, "/CustomeMsg:", customerMsg);
  };

  const handleClickClaimJob = async () => {
    handleOpenModal("Claiming Job", "", true, false);
    claimJob(
      state.rowData.jobId,
      user.Serial,
      (response) => {
        console.log("JobClaimResponse:", response);
        navigate("/dashboard/totallossv2/appraiser/usage");
      },
      (error) => {
        console.log("JobClaimResponseErr:", error);
        handleOpenModal(
          "Claiming Job Failed",
          "Failed to claim job. Please try again later",
          false,
          true
        );
      }
    );
  };

  const statusMap = {
    0: "Unclaimed",
    1: "Claimed - Working",
    2: "Paused - Waiting for Customer Response",
    3: "Completed - Pending Customer Review",
    4: "Issue Raised - Waiting for Appraiser’s Response",
    5: "Issue Raised - Working",
    6: "Final - Locked",
    7: "Canceled - Locked",
  };

  const validateFields = () => {
    if (vin == null || vin.length < 4) return "VIN is required field";

    if (platform == null || platform.length < 4)
      return "You have not selected platform";

    return "";
  };

  const inputRef = useRef();

  const [openImg, setOpenImg] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const addAdditionalDamageImage = () => {
    if (additionalImages.length < 25) {
      setAdditionalImages([...additionalImages, null]);
    }
  };

  const handleAbandonJob = () => {
    handleOpenModal("Updating Job", "", true, false);
    abandonJob(
      state.rowData.jobId,
      user.Serial,
      () => {
        navigate("/dashboard/totalloss__appraiser");
      },
      () => {
        handleOpenModal(
          "Abandoning Job Failed",
          "We were unable to abandon your job. Please contanct support",
          true,
          false
        );
      }
    );
  };

  const handleSubmitJob = () => {
    handleOpenModal("Submitting Job", "", true, false);
    submitJob(
      state.rowData.jobId,
      () => {
        navigate("/dashboard/totalloss__appraiser");
      },
      () => {
        handleOpenModal(
          "Submitting Job Failed",
          "We were unable to submit your job. Pleease try again later",
          true,
          false
        );
      }
    );
  };

  const handleOpenImg = (image) => {
    setSelectedImage(image);
    setOpenImg(true);
  };

  const handleCloseImg = () => {
    setOpenImg(false);
  };

  const updateJob = (desiredStatus) => {
    let newState = state.rowData.status === "2" ? "1" : "0";
    newState = desiredStatus === "7" ? "7" : newState;

    handleOpenModal("Updating Job", "", true, false);
    updateJobRequest(
      state.rowData.jobId,
      vin,
      platform,
      getFilesFromImages(),
      getFilesFromAditionalImages(),
      optionalNotes,
      specialInstructions,
      appraiserMsg,
      customerMsg,
      newState,
      () => {
        navigate("/dashboard/totallossv2/customer/usage");
      },
      () => {
        handleOpenModal(
          "Updating Job Failed",
          "We encountered a issue while updating your Job. Please try again later",
          false,
          true
        );
      }
    );
    console.log({ platform, vin, images });
  };

  const handleOpenModal = (modalTitle, modalBody, showSpinner, showClose) => {
    setModalBody(modalBody);
    setModalTitle(modalTitle);
    setShowSpinner(showSpinner);
    setShowClose(showClose);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const createJob = () => {
    let validateResponse = validateFields();

    if (validateResponse != "") {
      handleOpenModal(
        "Please fill all required fields",
        validateResponse,
        false,
        true
      );
      return;
    }

    const customer_id = user.Serial;
    handleOpenModal("Submitting Job", "", true, false);
    // Handle form submission logic here
    createJobRequest(
      customer_id,
      vin,
      platform,
      getFilesFromImages(),
      getFilesFromAditionalImages(),
      optionalNotes,
      specialInstructions,
      () => {
        window.location.href = "/dashboard/totallossv2/customer/usage";
      },
      () => {
        handleOpenModal(
          "Submit Job Error",
          "We encountered submitting your job. Please try again later.",
          false,
          true
        );
      }
    );
    console.log({ platform, vin, images });
  };

  const [config, setConfig] = React.useState({
    platform: "DV",
    entry: "Select Entry Method",
    username: "",
    password: "",
    RO: "NULL",
    pdf: null,
    totalloss: false,
  });
  const onCompleteUpload = (data, onComplete) => {
    let _config = config;
    _config.pdf = data.filename;

    _config.Application = "ReKey";
    _config.Args = {};
    _config.Args.Pdf = data.filename;
    console.log("Uploaded pdf name:", data.filename);

    if (data.filename != null && data.filename.length > 5) {
      ValidatePDF(_config, (validationResponse) => {
        console.log("Validation Response:", validationResponse);
        console.log(validationResponse.success);

        let message = JSON.parse(validationResponse.message); // console.log(validationResponse.message);
        console.log(message);

        const documentFields = message.EstimateDocument.DocumentFields;
        const vinField = documentFields.find(
          (field) => field.FieldName === "VIN"
        );

        /*if (vinField.FieldValue !== vin) {
          handleOpenModal(
            "VIN Missmatch",
            "Pdf VIN->" +
              vinField.FieldValue +
              " does not match with job VIN ->" +
              vin,
            false,
            true
          );
          console.log("NO VIN MATCH");
          onComplete(false);
          return;
        }*/

        //  setPdfValidationMsg(validationResponse.message);
        if (validationResponse.success === true) {
          onComplete(true);
        } else {
          onComplete(false);
          console.log("Not valid");
        }
      });
    }

    console.log(_config);
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    setIsCheckingPdf(true);
    if (file) {
      UploadPdf(file, (data) => {
        onCompleteUpload(data, (success) => {
          if (success) {
            const jobId = state.rowData.jobId; // Replace this with the actual jobId

            if (!file) {
              return;
            }

            if (file.type !== "application/pdf") {
              alert("Please select a PDF file");
              return;
            }

            handleOpenModal("Uploading Pdf", "", true, false);

            uploadPdf(
              jobId,
              file,
              () => {
                setPdfOk(true);
                handleCloseModal();
              },
              () => {
                handleOpenModal(
                  "Uploading Pdf Failed",
                  "Your pdf upload has failed. Please try again later",
                  false,
                  true
                );
              }
            );
          } else setPdfOk(false);

          setIsCheckingPdf(false);
        });
      });
    }
  };

  const handleClickUploadPdf = () => {
    inputRef.current.click();
  };

  const getImagePositionFromUrl = (url) => {
    const imageName = url.substring(url.lastIndexOf("/") + 1);
    let { position } = decodeImageName(imageName);
    position = position.replaceAll("%20", " ");
    position = position.replaceAll("TTT", "'");
    position = position.replaceAll("XXX", "/");
    position = position.replaceAll("WWW", ")");
    position = position.replaceAll("ZZZ", "(");
    position = position.replaceAll("QQQ", ",");
    console.log("Decoded Image Position:", position);
    return position;
  };

  const setImagesFromUrls = async (imageUrls) => {
    const imageFilesPromises = imageUrls.map((url) => {
      const position = getImagePositionFromUrl(url);
      return fetchImageAsFile(url, position);
    });

    const imageFilesArray = await Promise.all(imageFilesPromises);
    const newImages = imageFilesArray.reduce((acc, item) => {
      const position = Object.keys(item)[0];
      return { ...acc, [position]: item[position] };
    }, {});

    setImages(newImages);
  };

  const setImagesExtraFromUrls = async (imageUrls) => {
    if (imageUrls != null) {
      const imageFilesPromises = imageUrls.map((url) => {
        return fetchImageExtraAsFile(url);
      });

      console.log("ExtrasImg:", imageFilesPromises.length);
      const imageFilesArray = await Promise.all(imageFilesPromises);

      while (imageFilesArray.length < 5) {
        imageFilesArray.push(null);
      }

      setAdditionalImages(imageFilesArray);
    }
  };

  const getImageSrc = (item) => {
    if (item.type === "file") {
      return URL.createObjectURL(item.data);
    } else if (item.type === "url") {
      return item.data;
    }
    return null;
  };

  const decodeImageName = (imageName) => {
    const [position, hashWithExt] = imageName.split("_");
    const hash = hashWithExt.replace(".jpg", "");
    return { position, hash };
  };

  const getFilesFromAditionalImages = () => {
    console.log("Total Additional Images:", additionalImages.length);
    return additionalImages;
  };

  const generateImageHash = (imageData) => {
    const hash = CryptoJS.SHA256(imageData).toString(CryptoJS.enc.Hex);
    return hash;
  };

  const encodeImageName = (position, imageData) => {
    position = position.replaceAll("%20", " ");

    position = position.replaceAll("/", "XXX");
    position = position.replaceAll(")", "WWW");
    position = position.replaceAll("(", "ZZZ");
    position = position.replaceAll(",", "QQQ");

    const hash = generateImageHash(imageData);
    return `${position}_${hash}.jpg`;
  };

  const getFilesFromImages = () => {
    const files = Object.keys(images)
      .map((position) => images[position])
      .filter((item) => item.type === "file")
      .map((item) => item.data);
    return files;
  };

  const fetchImageAsFile = async (url, position) => {
    const response = await fetch(url);
    const blob = await response.blob();

    // Extract the original file name from the URL
    let originalFileName = url.substring(url.lastIndexOf("/") + 1);

    // Create a File object using the original file name
    const file = new File([blob], originalFileName.replaceAll("%20", " "), {
      type: blob.type,
    });
    position = position.replaceAll("%20", " ");
    position = position.replaceAll("TTT", "'");
    position = position.replaceAll("XXX", "/");
    position = position.replaceAll("WWW", ")");
    position = position.replaceAll("ZZZ", "(");
    position = position.replaceAll("QQQ", ",");

    return { [position]: { type: "file", data: file } };
  }; //fetchImageExtraAsFile

  const fetchImageExtraAsFile = async (url, position) => {
    const response = await fetch(url);
    const blob = await response.blob();

    // Extract the original file name from the URL
    let originalFileName = url.substring(url.lastIndexOf("/") + 1);
    originalFileName = originalFileName.replaceAll("%20", " ");

    // Create a File object using the original file name
    const file = new File([blob], originalFileName, { type: blob.type });

    return file;
  }; //

  const OFFSET_ANGLE = "15.2deg"; // You can adjust this value to move the conic-gradient around.

  const StyledSlice = styled(Slice)`
    background: ${(props) =>
      buttonColors[props.kind] && buttonColors[props.kind] !== "white"
        ? buttonColors[props.kind]
        : `conic-gradient(
             from ${OFFSET_ANGLE},
             grey 0% 0.1%, 
             white 0.1% 99.9%, 
             grey 99.9% 100%
           )`};
  `;

  const handleImageChange = (event, position) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const imageData = reader.result;
      position = position.replaceAll("%20", " ");
      position = position.replaceAll("TTT", "'");
      position = position.replaceAll("XXX", "/");
      position = position.replaceAll("WWW", ")");
      position = position.replaceAll("ZZZ", "(");
      position = position.replaceAll("QQQ", ",");

      const encodedImageName = encodeImageName(position, imageData);
      // Create a new File object with the encoded image name
      const fileWithEncodedName = new File([file], encodedImageName, {
        type: file.type,
      });

      // Store the new File object in the state with the position as the key
      setImages({
        ...images,
        [position]: { type: "file", data: fileWithEncodedName },
      });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleSubmitMsgResponse = (message) => {
    handleOpenModal("", "", true, false);
    const chatId = showErrModal === "appraiser" ? "Information" : "Issue";
    setAppraiserMsg(message);

    updateRequestMessage(
      state.rowData.jobId,
      "",
      message,
      () => {
        if (chatId == "Information") {
          navigate("/dashboard/totallossv2/appraiser/usage");
        } else {
          handleClose();
          handleCloseModal();
        }
      },
      () => {
        handleOpenModal(
          "Error",
          "We encountered a error while handling your request",
          false,
          true
        );
      },
      chatId
    );
  };

  const handleOpen = () => {
    setshowErrModal("appraiser");
    setMessages(state.rowData.infoLog);
    setOpen(true);

    console.log("AppMsg:", appraiserMsg, "/CustomeMsg:", customerMsg);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    outline: "none", // This is to remove the default outline that Material-UI might set
  };

  const modalContentStyle = {
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
    maxWidth: "80%",
    maxHeight: "80%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontFamily: "Orbitron",
    padding: "1em",
  };

  const imageStyle = {
    maxWidth: "100%",
    maxHeight: "100%",
  };

  const downloadURL = (url, filename) => {
    let a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const downloadAllImages = async () => {
    const JSZip = require("jszip");
    const zip = new JSZip();

    const addToZipFromObject = (imageSet) => {
      Object.entries(imageSet).forEach(([description, imageObject], index) => {
        if (!imageObject || !imageObject.data) return; // Skip if the entry or data is null

        if (
          imageObject.data instanceof Blob ||
          imageObject.data instanceof File
        ) {
          const reader = new FileReader();
          reader.readAsArrayBuffer(imageObject.data);
          reader.onload = function () {
            zip.file(
              `${description.replace(/\s+/g, "_")}_${index}.png`,
              reader.result
            );
          };
        }
      });
    };

    const addToZipFromArray = (imageArray) => {
      imageArray.forEach((file, index) => {
        if (!(file instanceof Blob) && !(file instanceof File)) return; // Skip if the entry isn't a file or blob

        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function () {
          zip.file(`additionalImage_${index}.png`, reader.result);
        };
      });
    };

    // Add images from both datasets to the zip
    addToZipFromObject(images);
    addToZipFromArray(additionalImages);

    // Generate zip after adding all images
    setTimeout(async function () {
      const content = await zip.generateAsync({ type: "blob" });
      const url = URL.createObjectURL(content);
      const a = document.createElement("a");
      a.href = url;
      a.download = "images.zip";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }, 2000); // Use promises for a more robust solution
  };

  const classes = useStyles();
  return (
    <BaseComponentTotalLoss imageUrl="../../../..\img\totalloss_header.png">
      <Container>
        <CustomInfoModal
          open={openModal}
          handleClose={handleCloseModal}
          title={modalTitle}
          showSpinner={showSpinner}
          showCloseButton={showClose}
          bodyText={modalBody}
        />
        <Modal
          open={openImg}
          onClose={handleCloseImg}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={modalStyle}
        >
          <div style={modalContentStyle}>
            <Typography variant="h3" component="div">
              {modalHeader}
            </Typography>
            {selectedImage && (
              <img src={selectedImage} alt="Selected" style={imageStyle} />
            )}
          </div>
        </Modal>

        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontFamily: "Orbitron, sans-serif",
            paddingTop: 1,
            paddingBottom: 0,
            color: "black",
          }}
        >
          Appraiser Dashboard Home
        </Typography>

        <Box
          sx={{
            fontFamily: "Orbitron, sans-serif",
            backgroundColor: "#fff",
            borderRadius: "2em",
            padding: "2em",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              left: 8,
              right: 8,
            }}
          >
            <Box
              align="left"
              sx={{ fontFamily: "Orbitron, sans-serif", alignItems: "left" }}
            ></Box>
            <Box
              sx={{
                fontFamily: "Orbitron, sans-serif",
                alignItems: "right",
                gap: "20px",
              }}
            >
              {(state.rowData.status === "1" ||
                state.rowData.status === "4") && (
                <Box
                  sx={{ fontFamily: "Orbitron, sans-serif", top: 0, right: 8 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpen}
                  >
                    Request More Information from Customer
                  </Button>
                  <SeeMoreInfoModal
                    open={open}
                    handleClose={handleClose}
                    onSubmit={handleSubmitMsgResponse}
                    messages={messages}
                    isError={showErrModal}
                  />
                </Box>
              )}
              {(state.rowData.status === "4" ||
                state.rowData.status === "5") && (
                <Box
                  align="right"
                  sx={{
                    fontFamily: "Orbitron, sans-serif",
                    top: 0,
                    right: 8,
                    paddingTop: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    align="right"
                    onClick={handleOpenIssue}
                  >
                    See Estimate Issue Reported
                  </Button>
                </Box>
              )}
            </Box>
          </div>{" "}
          <Box
            marginLeft={"10%"}
            sx={{ fontFamily: "Orbitron, sans-serif", marginTop: 0 }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={6} sm={1.8}></Grid>
              <Grid item xs={12} sm={8}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    fontFamily: "Orbitron, sans-serif",
                    fontSize: "2em",
                    fontWeight: "bold",
                    color: "center",
                  }}
                >
                  {statusMap[state.rowData.status]}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {canEditFields && (
            <Box
              marginLeft={"10%"}
              sx={{ fontFamily: "Orbitron, sans-serif", marginTop: 4 }}
            >
              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Grid item xs={6} sm={1.8}>
                  <Typography
                    align="left"
                    variant="subtitle1"
                    sx={{ fontFamily: "Orbitron, sans-serif", color: "black" }}
                  >
                    Time Left
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Box
                    component="div"
                    sx={{
                      fontFamily: "Orbitron, sans-serif",
                      backgroundColor: "white",
                      width: "50%",
                      height: "60%",
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      borderRadius: 1,
                      padding: "18.5px 14px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {timer}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box
            marginLeft={"10%"}
            sx={{ fontFamily: "Orbitron, sans-serif", marginTop: 4 }}
          >
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Grid item xs={6} sm={1.8}>
                <Typography
                  align="left"
                  variant="subtitle1"
                  sx={{ fontFamily: "Orbitron, sans-serif", color: "black" }}
                >
                  Select Platform Going Into
                </Typography>
              </Grid>
              <Grid
                sx={{ fontFamily: "Orbitron, sans-serif", marginRight: 0 }}
                item
                xs={12}
                sm={8}
              >
                <Box
                  component="div"
                  sx={{
                    fontFamily: "Orbitron, sans-serif",
                    backgroundColor: "white",
                    width: "50%",
                    height: "60%",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: 1,
                    padding: "18.5px 14px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {platform}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            marginLeft={"10%"}
            sx={{ fontFamily: "Orbitron, sans-serif", marginTop: 4 }}
          >
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Grid item xs={6} sm={1.8}>
                <Typography
                  align="left"
                  variant="subtitle1"
                  sx={{ fontFamily: "Orbitron, sans-serif", color: "black" }}
                >
                  Enter VIN
                </Typography>
              </Grid>
              <Grid
                sx={{ fontFamily: "Orbitron, sans-serif", marginRight: 0 }}
                item
                xs={12}
                sm={8}
              >
                <Box
                  component="div"
                  sx={{
                    fontFamily: "Orbitron, sans-serif",
                    backgroundColor: "white",
                    width: "50%",
                    height: "60%",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: 1,
                    padding: "18.5px 14px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {vin}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box marginLeft="10%" sx={{ marginTop: 2, marginBottom: 2 }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                <Typography
                  align="left"
                  variant="subtitle1"
                  sx={{ color: "black", fontFamily: "Orbitron, sans-serif" }}
                >
                  Select Impact Locations
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  zIndex: 4,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={() => handleButtonClick(14)}
                  variant="contained"
                  style={{
                    backgroundColor: buttonColors[14],
                    color: "black",
                    fontSize: "larger",
                    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
                  }}
                >
                  Unknown
                </Button>

                <Button
                  onClick={() => handleButtonClick(16)}
                  variant="contained"
                  style={{
                    backgroundColor: buttonColors[16],
                    color: "black",
                    fontSize: "larger",
                    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
                  }}
                >
                  Non-Collision
                </Button>

                <Button
                  onClick={() => handleButtonClick(19)}
                  variant="contained"
                  style={{
                    backgroundColor: buttonColors[19],
                    color: "black",
                    fontSize: "larger",
                    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
                  }}
                >
                  All-Over
                </Button>

                <Button
                  onClick={() => handleButtonClick(21)}
                  variant="contained"
                  style={{
                    backgroundColor: buttonColors[21],
                    color: "black",
                    fontSize: "larger",
                    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
                  }}
                >
                  Undercarriage
                </Button>
              </Grid>

              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <div
                  style={{
                    position: "relative",
                    width: "700px",
                    height: "700px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    pointerEvents: "none",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <PieMenu
                      radius="300px"
                      centerRadius="120px"
                      centerX="350px"
                      centerY="350px"
                      style={{ zIndex: -1, pointerEvents: "none" }}
                    >
                      <StyledSlice
                        kind="12"
                        onSelect={() => handleButtonClick(12)}
                      >
                        Front
                      </StyledSlice>
                      <StyledSlice
                        kind="1"
                        onSelect={() => handleButtonClick(1)}
                      >
                        R Front Corner
                      </StyledSlice>
                      <StyledSlice
                        kind="2"
                        onSelect={() => handleButtonClick(1)}
                      >
                        R Front Side
                      </StyledSlice>
                      <StyledSlice
                        kind="3"
                        onSelect={() => handleButtonClick(3)}
                      >
                        R Side
                      </StyledSlice>
                      <StyledSlice
                        kind="4"
                        onSelect={() => handleButtonClick(4)}
                      >
                        R Rear Side
                      </StyledSlice>
                      <StyledSlice
                        kind="5"
                        onSelect={() => handleButtonClick(5)}
                      >
                        R Rear Corner
                      </StyledSlice>
                      <StyledSlice
                        kind="6"
                        onSelect={() => handleButtonClick(6)}
                      >
                        Rear
                      </StyledSlice>
                      <StyledSlice
                        kind="7"
                        onSelect={() => handleButtonClick(7)}
                      >
                        L Rear Corner
                      </StyledSlice>
                      <StyledSlice
                        kind="8"
                        onSelect={() => handleButtonClick(8)}
                      >
                        L Rear Side
                      </StyledSlice>
                      <StyledSlice
                        kind="9"
                        onSelect={() => handleButtonClick(9)}
                      >
                        L Side
                      </StyledSlice>
                      <StyledSlice
                        kind="10"
                        onSelect={() => handleButtonClick(10)}
                      >
                        L Front Side
                      </StyledSlice>
                      <StyledSlice
                        kind="11"
                        onSelect={() => handleButtonClick(11)}
                      >
                        L Front Corner
                      </StyledSlice>
                    </PieMenu>
                  </ThemeProvider>

                  {/* Image in the center */}
                  <img
                    src="../../../..\img\vehicle-outline.png"
                    alt="Vehicle Outline"
                    style={{
                      position: "absolute",
                      width: "160px",
                      height: "290px",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 1,
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={() => handleButtonClick(13)} // Assuming 13 is the ID for the Rollover button
                    style={{
                      position: "absolute",
                      width: "60px", // Adjust as needed for desired button size
                      height: "60px",
                      borderRadius: "60px", // Half of width/height to make it circular
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -20%)",
                      zIndex: 2, // Ensure the button is on top
                      backgroundColor: buttonColors[13] || "white",
                      color: "black",
                    }}
                  >
                    Rollover
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Box marginLeft={"10%"} sx={{ marginTop: 4 }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={6} sm={1.8}>
                <Typography
                  align="left"
                  variant="subtitle1"
                  sx={{ color: "black", fontFamily: "Orbitron, sans-serif" }}
                >
                  Overall Condition
                </Typography>
              </Grid>
              <Grid
                sx={{ fontFamily: "Orbitron, sans-serif", marginRight: 0 }}
                item
                xs={12}
                sm={8}
              >
                <Box
                  component="div"
                  sx={{
                    fontFamily: "Orbitron, sans-serif",
                    backgroundColor: "white",
                    width: "50%",
                    height: "60%",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: 1,
                    padding: "18.5px 14px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {condition}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <div
            style={{
              pointerEvents: "none",
            }}
          >
            <Box marginLeft="10%" sx={{ marginTop: 4 }}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12}>
                  <Grid container alignItems="flex-start" justify="flex-start">
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={useAlternativeParts}
                            name="useAlternativeParts"
                          />
                        }
                        label="Use Alternative Parts"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    align="left"
                    variant="subtitle1"
                    sx={{ color: "black", fontFamily: "Orbitron, sans-serif" }}
                  >
                    Labor Rates for Cost Accuracy
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField label="Body" value={body} fullWidth />
                </Grid>
                <Grid item xs={2}>
                  <TextField label="Bdy-S" value={bdyS} fullWidth />
                </Grid>
                <Grid item xs={2}>
                  <TextField label="Refinish" value={refinish} fullWidth />
                </Grid>
                <Grid item xs={2}>
                  <TextField label="Glass" value={glass} fullWidth />
                </Grid>
                <Grid item xs={2}>
                  <TextField label="Frame" value={frame} fullWidth />
                </Grid>
                <Grid item xs={2}>
                  <TextField label="Mechanical" value={mechanical} fullWidth />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Paint Materials Rate"
                    value={paintMaterialsRate}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Tax Rate for Parts"
                    value={taxRateForParts}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Tax Rate for Labor"
                    value={taxRateForLabor}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
          <Box
            marginLeft={"10%"}
            sx={{ fontFamily: "Orbitron, sans-serif", marginTop: 4 }}
          ></Box>
          <Box>
            <Typography
              marginLeft={"10%"}
              align="left"
              variant="subtitle1"
              sx={{
                fontFamily: "Orbitron, sans-serif",
                color: "black",
                marginBottom: "20px",
              }}
            >
              Exterior Images
            </Typography>
            {[
              ["Front Left Corner", "Front Right Corner"],

              ["Back Left Corner", "Back Right Corner"],
            ].map((row, rowIndex) => (
              <Grid
                marginLeft={"25%"}
                sx={{
                  fontFamily: "Orbitron, sans-serif",
                  marginLeft: "30%",
                  width: "40%",
                }}
                container
                spacing={1}
                justifyContent="center"
                key={rowIndex}
              >
                {row.map((fieldName, fieldIndex) => (
                  <Grid item xs={12} sm={6} md={4} key={fieldName}>
                    <Box
                      sx={{
                        fontFamily: "Orbitron, sans-serif",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label htmlFor={fieldName}>
                        {images[fieldName] && (
                          <Typography
                            variant="subtitle1"
                            align="center"
                            sx={{
                              fontFamily: "Orbitron, sans-serif",
                              color: "black",
                            }}
                          >
                            {fieldName.replace(/\s*\([^)]*\)/g, "")}
                          </Typography>
                        )}
                        <Box
                          color={"white"}
                          sx={{
                            fontFamily: "Orbitron, sans-serif",
                            width: 115,
                            height: 90,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: images[fieldName]
                              ? "transparent"
                              : "#00457c",
                            marginBottom: 1,
                          }}
                        >
                          {images[fieldName] ? (
                            <img
                              src={getImageSrc(images[fieldName])}
                              alt={fieldName}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              onClick={() => {
                                setModalHeader(fieldName);
                                handleOpenImg(getImageSrc(images[fieldName]));
                              }}
                            />
                          ) : (
                            fieldName
                          )}
                        </Box>
                      </label>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ))}

            <Typography
              marginLeft={"10%"}
              align="left"
              variant="subtitle1"
              sx={{
                fontFamily: "Orbitron, sans-serif",
                color: "black",
                marginBottom: "20px",
              }}
            >
              Informational Images
            </Typography>
            {[
              [
                "Interior (Open Driver's Door, Step Back, Take Picture)",
                "VIN Sticker",
                "Milage/Odometer",
              ],
            ].map((row, rowIndex) => (
              <Grid
                marginLeft={"25%"}
                sx={{
                  fontFamily: "Orbitron, sans-serif",
                  marginLeft: "30%",
                  width: "40%",
                }}
                container
                spacing={1}
                justifyContent="center"
                key={rowIndex}
              >
                {row.map((fieldName, fieldIndex) => (
                  <Grid item xs={12} sm={6} md={4} key={fieldName}>
                    <Box
                      sx={{
                        fontFamily: "Orbitron, sans-serif",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label htmlFor={fieldName}>
                        {images[fieldName] && (
                          <Typography
                            variant="subtitle1"
                            align="center"
                            sx={{
                              fontFamily: "Orbitron, sans-serif",
                              color: "black",
                            }}
                          >
                            {fieldName.replace(/\s*\([^)]*\)/g, "")}
                          </Typography>
                        )}
                        <Box
                          color={"white"}
                          sx={{
                            fontFamily: "Orbitron, sans-serif",
                            width: 115,
                            height: 90,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: images[fieldName]
                              ? "transparent"
                              : "#00457c",
                            marginBottom: 1,
                          }}
                        >
                          {images[fieldName] ? (
                            <img
                              src={getImageSrc(images[fieldName])}
                              alt={fieldName}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              onClick={() => {
                                setModalHeader(fieldName);
                                handleOpenImg(getImageSrc(images[fieldName]));
                              }}
                            />
                          ) : (
                            fieldName
                          )}
                        </Box>
                      </label>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ))}

            <Typography
              marginLeft={"10%"}
              align="left"
              variant="subtitle1"
              sx={{
                fontFamily: "Orbitron, sans-serif",
                color: "black",
                marginBottom: "10px",
              }}
            >
              Damage Images
            </Typography>
            {Array.from(
              { length: Math.ceil(additionalImages.length / 5) },
              (_, i) => i
            ).map((rowIndex) => (
              <Grid
                container
                spacing={4}
                justifyContent="center"
                key={rowIndex}
              >
                {Array.from({ length: 5 }, (_, i) => i)
                  .map((i) => rowIndex * 5 + i)
                  .filter((i) => i < additionalImages.length)
                  .map((i) => (
                    <Grid item key={i}>
                      <label htmlFor={`additional-${i}`}>
                        {additionalImages[i] && (
                          <Typography
                            variant="subtitle1"
                            align="center"
                            sx={{
                              fontFamily: "Orbitron, sans-serif",
                              color: "black",
                            }}
                          >
                            Damage {i + 1}
                          </Typography>
                        )}
                        <Box
                          color={"white"}
                          sx={{
                            fontFamily: "Orbitron, sans-serif",
                            width: 115,
                            height: 90,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",

                            backgroundColor: additionalImages[i]
                              ? "transparent"
                              : "#00457c",
                          }}
                        >
                          {additionalImages[i] ? (
                            <img
                              src={URL.createObjectURL(additionalImages[i])}
                              alt={`Damage ${i + 1}`}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              onClick={() => {
                                setModalHeader("Damage " + (i + 1));
                                handleOpenImg(
                                  URL.createObjectURL(additionalImages[i])
                                );
                              }}
                            />
                          ) : (
                            "Damage " + (i + 1)
                          )}
                        </Box>
                      </label>
                    </Grid>
                  ))}
              </Grid>
            ))}

            {(state.rowData.status === "0" ||
              state.rowData.status === "1" ||
              state.rowData.status === "4" ||
              state.rowData.status === "5") && (
              <Box marginLeft={"10%"} align="left" mt={2}>
                {canEditFields && (
                  <button onClick={downloadAllImages}>
                    Download All Images
                  </button>
                )}
              </Box>
            )}

            <Typography
              variant="h5"
              gutterBottom
              align="left"
              sx={{
                fontFamily: "Orbitron, sans-serif",
                color: "black",
                fontWeight: "bold",
                marginLeft: "10%",
                marginTop: "30px",
              }}
            >
              Damage Notes
            </Typography>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item xs={12} sm={6}>
                <Box
                  component="div"
                  sx={{
                    fontFamily: "Orbitron, sans-serif",
                    backgroundColor: "white",
                    width: "75%",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: 1,
                    padding: "18.5px 14px",
                    overflow: "auto",
                    whiteSpace: "pre-wrap",
                    height: "auto",
                    minHeight: "80px", // Adjust to match the height of 4 rows
                    marginLeft: "20%",
                    textAlign: "left", // Add this line to align the text to the left
                  }}
                >
                  {optionalNotes}
                </Box>
              </Grid>
            </Grid>

            <Typography
              variant="h5"
              gutterBottom
              align="left"
              sx={{
                fontFamily: "Orbitron, sans-serif",
                color: "black",
                fontWeight: "bold",
                marginLeft: "10%",
                marginTop: "30px",
              }}
            >
              Special Instructions
            </Typography>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item xs={12} sm={6}>
                <Box
                  component="div"
                  sx={{
                    fontFamily: "Orbitron, sans-serif",
                    width: "75%",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: 1,
                    padding: "18.5px 14px",
                    overflow: "auto",
                    whiteSpace: "pre-wrap",
                    height: "auto",
                    minHeight: "80px", // Adjust to match the height of 4 rows
                    marginLeft: "20%",
                    textAlign: "left", // Add this line to align the text to the left
                  }}
                >
                  {specialInstructions}
                </Box>
              </Grid>
            </Grid>
          </Box>
          {state.rowData.status === "0" && (
            <Box
              sx={{
                fontFamily: "Orbitron, sans-serif",
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 2,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    onClick={handleClickClaimJob}
                    variant="contained"
                    color="primary"
                  >
                    Claim Job
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
          {(state.rowData.status === "1" ||
            state.rowData.status === "4" ||
            state.rowData.status === "5") && (
            <Box
              sx={{
                fontFamily: "Orbitron, sans-serif",
                flexGrow: 1,
                marginTop: "30px",
                width: "80%",
                marginLeft: "10%",
                marginBottom: "50px",
              }}
            >
              <Grid container spacing={3}>
                <Grid item>
                  <Button
                    disabled={checkingPdf}
                    variant="contained"
                    sx={{
                      fontFamily: "Orbitron, sans-serif",
                      backgroundColor: "black",
                      marginRight: 2,
                    }}
                    onClick={handleClickUploadPdf}
                  >
                    Upload pdf
                  </Button>
                  <input
                    ref={inputRef}
                    type="file"
                    accept="application/pdf"
                    style={{ display: "none" }}
                    onChange={handleFileSelect}
                  />
                </Grid>
                <Grid item>
                  {pdfOk === true && (
                    <Typography
                      sx={{
                        fontFamily: "Orbitron, sans-serif",
                        color: "green",
                      }}
                      variant="h6"
                    >
                      Pdf Ok
                    </Typography>
                  )}
                  {pdfOk === false && (
                    <Typography
                      sx={{
                        fontFamily: "Orbitron, sans-serif",
                        color: "red",
                      }}
                      variant="h6"
                    >
                      Pdf Invalid
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Box sx={{ fontFamily: "Orbitron, sans-serif", marginTop: 4 }}>
                <Grid container spacing={3} justifyContent="space-between">
                  <Grid item>
                    <Button
                      disabled={checkingPdf}
                      onClick={handleSubmitJob}
                      variant="contained"
                      sx={{
                        fontFamily: "Orbitron, sans-serif",
                        backgroundColor: "green",
                      }}
                    >
                      Submit Job
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={checkingPdf}
                      onClick={handleAbandonJob}
                      variant="contained"
                      sx={{
                        fontFamily: "Orbitron, sans-serif",
                        backgroundColor: "red",
                      }}
                    >
                      Abandon Job
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </BaseComponentTotalLoss>
  );
};

export default TotalLossJobDetailsAppraiser;
