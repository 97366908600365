import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Box,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Paper,
  Grid,
} from "@mui/material";
import { Warning } from "@mui/icons-material";
import axios from "axios";
import { API_URI } from "../../../Globals";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Badge from "@mui/material/Badge";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import WarningIcon from "@mui/icons-material/Warning";
import { useNavigate } from "react-router-dom";
import BaseComponentTotalLoss from "./totallossv2baseapp";
import { styled } from "@mui/system";
import { useAuth } from "../../../AuthContext";

const TotalLossCustomerUsage = () => {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const [showWarning, setShowWarning] = useState(false);
  const [tableData, setTableData] = useState({
    columns: [],
    data: [],
    startIndex: 0,
    resultCount: 10,
    totalCount: 0,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [columns, setColumns] = useState([
    { title: "Index", field: "index" },
    { title: "Status", field: "status" },
    { title: "VIN", field: "vin" },
    { title: "Claimed Date", field: "submit_date", type: "date" },
    { title: "Time Elapsed", field: "startTime", type: "date" },
  ]);

  const columnsPending = [
    { title: "Index", field: "index" },
    { title: "Status", field: "status" },
    { title: "VIN", field: "vin" },
    { title: "Claimed Date", field: "submit_date", type: "date" },
    { title: "Time Elapsed", field: "startTime", type: "date" },
  ];

  const columnsCompleted = [
    { title: "Index", field: "index" },
    { title: "Status", field: "status" },
    { title: "VIN", field: "vin" },
    { title: "Claimed Date", field: "submit_date", type: "date" },
  ];

  const statusMap = {
    0: "Unclaimed",
    1: "Claimed - Working",
    2: "Paused - Waiting for Customer Response",
    3: "Completed - Pending Customer Review",
    4: "Issue Raised - Waiting for Appraiser’s Response",
    5: "Issue Raised - Working",
    6: "Final - Locked",
    7: "Canceled - Locked",
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await fetchData(
      parseInt(localStorage.getItem("tab")),
      newPage,
      rowsPerPage
    );
  };

  const handleChangeRowsPerPage = async (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    await fetchData(parseInt(localStorage.getItem("tab")), 0, newRowsPerPage);
  };

  const fetchData = async (newValue, startIndex, resultCount) => {
    try {
      console.log("User:", user);
      let statusFilter = "";
      switch (newValue) {
        case 0: // Unclaimed
          statusFilter = "0,7"; // Adjust the status codes as per your application logic
          setColumns(columnsPending);
          break;
        case 1: // In Progress
          statusFilter = "1,2,4,5"; // Adjust the status codes as per your application logic
          setColumns(columnsPending);
          break;
        case 2: // Completed
          statusFilter = "3";
          setColumns(columnsCompleted);
          break;
        case 3: // Completed
          statusFilter = "6";
          setColumns(columnsCompleted);
          break;
        default:
        // Handle default case
      }

      setPage(startIndex);
      setRowsPerPage(resultCount);
      setIsActiveRequest(true);
      const response = await axios.get(
        API_URI() +
          `/totalloss/getJobsByStatus?status=${statusFilter}&startIndex=${startIndex}&resultCount=${resultCount}&customer_id=${user.Serial}`
      );
      setTableData(response.data);
      var arrData = Array.from(response.data.data);
      const hasUnclaimedJob = arrData.some((job) => job.status === "2");
      setShowWarning(hasUnclaimedJob);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsActiveRequest(false);
    }
  };

  useEffect(() => {
    fetchData(parseInt(localStorage.getItem("tab")), 0, 10);
  }, [isAuthenticated, user]);

  const handleChange = async (event, newValue) => {
    cancelRefresh();
    localStorage.setItem("tab", newValue.toString());
    fetchData(newValue, 0, 10).finally(() => {
      setupAutoRefresh();
    });
  };

  const handleRowClick = (rowData) => {
    if ((rowData.status != "3") & (rowData.status != "6")) {
      navigate("/dashboard/totallossv2/customer/job/update", {
        state: { rowData },
      });
    } else {
      navigate("/dashboard/totallossv2/customer/job/rekey", {
        state: { rowData },
      });
    }
  };

  const refreshInterval = useRef(null);
  const [isActiveRequest, setIsActiveRequest] = useState(false);

  const cancelRefresh = () => {
    if (refreshInterval.current) {
      clearInterval(refreshInterval.current);
    }
  };

  const setupAutoRefresh = () => {
    cancelRefresh();

    refreshInterval.current = setInterval(() => {
      if (!isActiveRequest) {
        console.log("Fetching data...");
        setIsActiveRequest(true);
        fetchData(
          parseInt(localStorage.getItem("tab")),
          page,
          rowsPerPage
        ).finally(() => {
          setIsActiveRequest(false);
        });
      }
    }, 5000);
  };

  useEffect(() => {
    setupAutoRefresh();

    return () => {
      cancelRefresh();
    };
  }, []);

  // ... (rest of your component implementation including styled components)

  const StyledTableContainer = styled(TableContainer)`
    border-bottom: 4px solid black;
    margin-left: 16px;
    width: 98.5%;
  `;

  const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    backgroundColor: "white",
    color: "#00457c",
    borderBottom: "4px solid black",
    fontSize: "1.6em", // Adjust the font size as needed
    textAlign: "center",
  }));

  const StyledTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: white;
    }

    &:nth-of-type(even) {
      background-color: #e6e7e8;
    }

    &:hover {
      background-color: #5d4479;
      cursor: pointer;
    }
  `;

  const StyledTableCell = styled(TableCell)`
    font-weight: bold;
    font-family: "Lato", sans-serif;
    text-align: center;
  `;

  const StyledTablePagination = styled(TablePagination)`
    font-weight: bold;
    font-size: 1.2em;
  `;

  const StyledTab = ({ label, ...otherProps }) => {
    const selected = otherProps.selected || false;

    return (
      <Tab
        label={
          <Typography
            style={{
              color: selected ? "#e6e7e8" : "black",
              fontWeight: 900,
              fontFamily: "Orbitron",
              fontSize: "1.4em",
              opacity: 1,
            }}
          >
            {label}
          </Typography>
        }
        style={{
          backgroundColor: selected ? "#00457c" : "#e6e7e8",
        }}
        {...otherProps}
      />
    );
  };
  function extractDateDifference(inputStr) {
    console.log("InputStr:", inputStr);
    // Parse the date string
    const givenDate = new Date(inputStr);

    // Get the current date and its timezone offset in minutes
    const currentDate = new Date();
    const currentOffset = currentDate.getTimezoneOffset();

    // Get the timezone offset of the given date in minutes
    const givenOffset = givenDate.getTimezoneOffset();

    // Adjust for the timezone difference
    const adjustedCurrentDate = new Date(
      currentDate.getTime() - (givenOffset - currentOffset) * 60000
    );

    // Calculate the difference in milliseconds
    let difference = adjustedCurrentDate - givenDate;

    // Calculate the days, hours, minutes, and seconds from the difference
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    difference -= days * (1000 * 60 * 60 * 24);

    const hours = Math.floor(difference / (1000 * 60 * 60));
    difference -= hours * (1000 * 60 * 60);

    const minutes = Math.floor(difference / (1000 * 60));
    difference -= minutes * (1000 * 60);

    const seconds = Math.floor(difference / 1000);

    return `${days}D:${hours}H:${minutes}M:${seconds}S`;
  }

  return (
    <BaseComponentTotalLoss imageUrl="../../../..\img\totalloss_header.png">
      {showWarning && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="#f8d7da"
          borderRadius={1}
          p={1}
          mb={2}
        >
          <Warning color="error" />
          <Typography
            variant="body2"
            color="error.main"
            style={{ fontWeight: "bold" }}
          >
            WARNING!!! You have job(s) that have had more information requested
            on them. Please go to these job(s) and fill out the info.
          </Typography>
          <Warning color="error" />
        </Box>
      )}

      <Box sx={{ width: "90%", marginLeft: "5%" }}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ backgroundColor: "white", padding: "0 16px" }}
          >
            <Grid item>
              <Tabs
                value={parseInt(localStorage.getItem("tab"))}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="standard"
              >
                <StyledTab
                  label={
                    <Badge
                      badgeContent={
                        tableData && tableData.statusCounts
                          ? tableData.statusCounts["UNASSIGNED"]
                          : 0
                      }
                      color="primary"
                    >
                      UNASSIGNED
                    </Badge>
                  }
                />

                <StyledTab
                  value={1}
                  label={
                    <Badge
                      badgeContent={
                        tableData && tableData.statusCounts
                          ? tableData.statusCounts["In Progress"]
                          : 0
                      }
                      color="primary"
                    >
                      In Progress
                    </Badge>
                  }
                />
                <StyledTab
                  value={2}
                  label={
                    <Badge
                      badgeContent={
                        tableData && tableData.statusCounts
                          ? tableData.statusCounts["Completed"]
                          : 0
                      }
                      color="primary"
                    >
                      Completed
                    </Badge>
                  }
                />
              </Tabs>
            </Grid>

            <Grid item xs>
              <Typography
                variant="h2"
                align="center"
                gutterBottom
                style={{
                  color: "#00457c",
                  fontWeight: 900,
                  fontFamily: "Orbitron",
                  fontSize: "3em",
                  opacity: 1,
                }}
              >
                Total Loss Usage Reports
              </Typography>
            </Grid>

            <Grid item>
              <Tabs
                value={parseInt(localStorage.getItem("tab"))}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="standard"
              >
                <Tab value={5} label="" disabled /> {/* Invisible tab */}
                <Tab value={5} label="" disabled /> {/* Invisible tab */}
                <StyledTab
                  value={3}
                  label={
                    <Badge
                      badgeContent={
                        tableData && tableData.statusCounts
                          ? tableData.statusCounts["Archive"]
                          : 0
                      }
                      color="primary"
                    >
                      Archived
                    </Badge>
                  }
                />
              </Tabs>
            </Grid>
          </Grid>
        </AppBar>

        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableHeadCell key={column.field}>
                    {column.title}
                  </StyledTableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.data.map((row, index) => (
                <StyledTableRow
                  key={row.jobId}
                  hover
                  onClick={() => handleRowClick(row)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: row.status === "2" ? "#5d4479" : "inherit",
                  }}
                >
                  <StyledTableCell
                    style={{
                      color: row.status === "2" ? "red" : "inherit",
                    }}
                  >
                    {row.status === "2" && <WarningIcon color="warning" />}
                    {tableData.startIndex + index + 1}
                  </StyledTableCell>
                  {columns.slice(1).map((column) => {
                    if (column.field === "status") {
                      return (
                        <StyledTableCell
                          style={{
                            color: row.status === "2" ? "red" : "inherit",
                          }}
                          key={`${row.jobId}_${column.field}`}
                        >
                          {statusMap[row[column.field]]}
                        </StyledTableCell>
                      );
                    } else if (column.field === "startTime") {
                      return (
                        <StyledTableCell key={`${row.jobId}_${column.field}`}>
                          {extractDateDifference(row[column.field])}
                        </StyledTableCell>
                      );
                    } else {
                      return (
                        <StyledTableCell
                          style={{
                            color: row.status === "2" ? "red" : "inherit",
                          }}
                          key={`${row.jobId}_${column.field}`}
                        >
                          {row[column.field]}
                        </StyledTableCell>
                      );
                    }
                  })}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <StyledTablePagination
          component="div"
          count={tableData.totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </BaseComponentTotalLoss>
  );
};

export default TotalLossCustomerUsage;
