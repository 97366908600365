import axios from "axios";
import moment from "moment";
import { ValidatePDF } from "../../../services/autoestimaterekey.service";
import { lib } from "crypto-js";

export const SET_SELECTED_FILE = "SET_SELECTED_FILE";
export const SET_FILE_VALIDATION_STATUS = "SET_FILE_VALIDATION_STATUS";
export const START_FILE_UPLOAD = "START_FILE_UPLOAD";
export const END_FILE_UPLOAD = "END_FILE_UPLOAD";
export const SET_LOADING = "SET_LOADING";
export const SET_PDF_VALIDATION_MESSAGE = "SET_PDF_VALIDATION_MESSAGE";
export const SET_ESTIMATE_DATA = "SET_ESTIMATE_DATA";
export const SET_VEHICLE_DATA = "SET_VEHICLE_DATA";
export const SET_ESTIMATE_TOTAL = "SET_ESTIMATE_TOTAL";
export const SET_MILEAGE = "SET_MILEAGE";
export const SET_DATE_OF_LOSS = "SET_DATE_OF_LOSS";
export const SET_CLAIM_NO = "SET_CLAIM_NO";
export const SET_LICENSE_PLATE = "SET_LICENSE_PLATE";
export const SET_OWNER_NAME = "SET_OWNER_NAME";
export const SET_VIN = "SET_VIN";
export const SET_VEHICLE_MAKE = "SET_VEHICLE_MAKE";
export const SET_VEHICLE_MODEL = "SET_VEHICLE_MODEL";
export const SET_VEHICLE_TYPE = "SET_VEHICLE_TYPE";
export const SET_RAW_VEHICLE_YEAR = "SET_RAW_VEHICLE_YEAR";
export const SET_VEHICLE_YEAR = "SET_VEHICLE_YEAR";
export const SET_TRIMS = "SET_TRIMS";
export const SET_HAS_TRIMS = "SET_HAS_TRIMS";
export const SET_MANUAL_TRIMS = "SET_MANUAL_TRIMS";
export const SET_INSURANCE_COMPANY = "SET_INSURANCE_COMPANY";
export const SET_DAMAGE_SEVERITY = "SET_DAMAGE_SEVERITY";
export const SET_OWNER_EMAIL = "SET_OWNER_EMAIL";
export const SET_OWNER_ADDRESS = "SET_OWNER_ADDRESS";
export const SET_OWNER_CITY = "SET_OWNER_CITY";
export const SET_OWNER_STATE = "SET_OWNER_STATE";
export const SET_OWNER_ZIP = "SET_OWNER_ZIP";
export const SET_MARKET_VALUE = "SET_MARKET_VALUE";
export const SET_VEHICLE_CONDITION = "SET_VEHICLE_CONDITION";
export const SET_DV_LINK = "SET_DV_LINK";
export const SET_DV_VALUE = "SET_DV_VALUE";
export const SET_VEHICLE_SUBMODEL = "SET_VEHICLE_SUBMODEL";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const ADD_COMPLETED_STEP = "ADD_COMPLETED_STEP";
export const RESET_STEPS = "RESET_STEPS";
export const SET_VIN_ENTERED = "SET_VIN_ENTERED";
export const SET_VEHICLE_DAMAGE = "SET_VEHICLE_DAMAGE";
export const SET_JOB_ID = "SET_JOB_ID";
export const SET_RANGE = "SET_RANGE";

export const setJobId = (id) => ({
  type: SET_JOB_ID,
  payload: id,
});

export const setVehicleDamage = (damage) => ({
  type: SET_VEHICLE_DAMAGE,
  payload: damage,
});

export const addCompletedStep = (step) => ({
  type: ADD_COMPLETED_STEP,
  payload: step,
});

export const resetSteps = () => ({
  type: RESET_STEPS,
  payload: null,
});

const apiEndpoint = "https://aerkbackendv2.azurewebsites.net";
//https://aerkbackendv2.azurewebsites.net
export const setMarketValue = (value) => ({
  type: SET_MARKET_VALUE,
  payload: value,
});

export const setVehicleSubmodel = (submodel) => ({
  type: SET_VEHICLE_SUBMODEL,
  payload: submodel,
});

export const setVinEntered = (step) => ({
  type: SET_VIN_ENTERED,
  payload: step,
});

export const setCurrentStep = (step) => ({
  type: SET_CURRENT_STEP,
  payload: step,
});

export const setOwnerEmail = (email) => ({
  type: SET_OWNER_EMAIL,
  payload: email,
});

export const selectTotalProjectedDimValue = (dv) => ({
  type: SET_DV_VALUE,
  payload: dv,
});

export const setDvLink = (address) => ({
  type: SET_DV_LINK,
  payload: address,
});

export const setOwnerAddress = (address) => ({
  type: SET_OWNER_ADDRESS,
  payload: address,
});

export const setVehicleCondition = (condition) => ({
  type: SET_VEHICLE_CONDITION,
  payload: condition,
});

export const setOwnerCity = (city) => ({
  type: SET_OWNER_CITY,
  payload: city,
});

export const setOwnerState = (state) => ({
  type: SET_OWNER_STATE,
  payload: state,
});

export const setOwnerZip = (zip) => ({
  type: SET_OWNER_ZIP,
  payload: zip,
});

export const setDamageSeverity = (severity) => ({
  type: SET_DAMAGE_SEVERITY,
  payload: severity,
});

export const setInsuranceCompany = (insurance) => ({
  type: SET_INSURANCE_COMPANY,
  payload: insurance,
});

export const setSelectedFile = (file) => ({
  type: SET_SELECTED_FILE,
  payload: file,
});

export const setFileValidationStatus = (isValid) => ({
  type: SET_FILE_VALIDATION_STATUS,
  payload: isValid,
});

export const startFileUpload = () => ({
  type: START_FILE_UPLOAD,
});

export const endFileUpload = () => ({
  type: END_FILE_UPLOAD,
});

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading,
});

export const setPdfValidationMessage = (message) => ({
  type: SET_PDF_VALIDATION_MESSAGE,
  payload: message,
});

export const setEstimateData = (data) => ({
  type: SET_ESTIMATE_DATA,
  payload: data,
});

export const setVehicleData = (data) => ({
  type: SET_VEHICLE_DATA,
  payload: data,
});

export const setEstimateTotal = (total) => ({
  type: SET_ESTIMATE_TOTAL,
  payload: total,
});

export const setMileage = (mileage) => ({
  type: SET_MILEAGE,
  payload: mileage,
});

export const setDateOfLoss = (date) => ({
  type: SET_DATE_OF_LOSS,
  payload: date,
});

export const setClaimNo = (claimNo) => ({
  type: SET_CLAIM_NO,
  payload: claimNo,
});

export const setLicensePlate = (licensePlate) => ({
  type: SET_LICENSE_PLATE,
  payload: licensePlate,
});

export const setOwnerName = (ownerName) => ({
  type: SET_OWNER_NAME,
  payload: ownerName,
});

export const setRange = (range) => ({
  type: SET_RANGE,
  payload: range,
});

export const setVin = (vin) => ({
  type: SET_VIN,
  payload: vin,
});

export const setVehicleMake = (make) => ({
  type: SET_VEHICLE_MAKE,
  payload: make,
});

export const setVehicleModel = (model) => ({
  type: SET_VEHICLE_MODEL,
  payload: model,
});

export const setVehicleType = (vehicleType) => ({
  type: SET_VEHICLE_TYPE,
  payload: vehicleType,
});

export const setRawVehicleYear = (year) => ({
  type: SET_RAW_VEHICLE_YEAR,
  payload: year,
});

export const setVehicleYear = (year) => ({
  type: SET_VEHICLE_YEAR,
  payload: year,
});

export const setTrims = (trims) => ({
  type: SET_TRIMS,
  payload: trims,
});

export const setHasTrims = (hasTrims) => ({
  type: SET_HAS_TRIMS,
  payload: hasTrims,
});

export const setManualTrims = (manualTrims) => ({
  type: SET_MANUAL_TRIMS,
  payload: manualTrims,
});

// Method to calculate the DV value
// Method to calculate the DV value
export const calculateDVValue = () => async (dispatch, getState) => {
  console.log("Calculating DV Value...");

  const state = getState().diminishedValue;

  // Get the required data from the state
  const {
    marketValue,
    mileage,
    damageSeverity,
    vehicleCondition,
    modifierPercentToVal,
    ageModifierUsed,
  } = state;

  // Calculate all the values
  const values = calculateAllValues(
    marketValue,
    0,
    mileage,
    damageSeverity,
    vehicleCondition,
    1,
    1
  );

  // Extract the required values from the calculated values
  const { totalProjectedDimValue } = values;

  console.log("Diminished Value: ", values.totalProjectedDimValue);

  // Dispatch an action to update the totalProjectedDimValue in the state
  dispatch(selectTotalProjectedDimValue(values.totalProjectedDimValue));

  return totalProjectedDimValue;
};

function percentOfValuerX(estimateTotal, marketValue) {
  // Ensure inputs are strings and remove non-numeric characters
  let cleanedEstimateTotal = parseFloat(String(estimateTotal).replace(/[^0-9.]/g, ''));
  let cleanedMarketValue = parseFloat(String(marketValue).replace(/[^0-9.]/g, ''));
  
  // Ensure both values are numbers
  if (isNaN(cleanedEstimateTotal) || isNaN(cleanedMarketValue) || cleanedMarketValue === 0) {
      throw new Error('Invalid input: Both estimateTotal and marketValue should be valid numbers and marketValue should not be zero.');
  }
  
  // Calculate the percentage
  let percentage = (cleanedEstimateTotal / cleanedMarketValue) * 100;
  return percentage;
}

// Updated method to generate the DV PDF
export const generateDVPdfFromState = () => async (dispatch, getState) => {
  try {
    // Calculate the DV value
    const totalProjectedDimValue = await dispatch(calculateDVValue());

    const state = getState().diminishedValue;

    const damageExtentDescriptions = {
      1.0: "Severe damage to the structure of vehicle",
      0.75: "Major damage to structure and panels",
      0.5: "Moderate damage to structure and panels",
      0.25: "Minor damage to structure of vehicle",
      0.0: "No structural damage or replaced panels",
    };

    // Get the required data from the state
    let {
      ownerName,
      vehicleYear,
      vehicleMake,
      vehicleModel,
      vehicleSubmodel,
      vin,
      licensePlate,
      estimateData,
      estimateTotal,
      marketValue,
      percentOfValue,
      dateOfLoss,
      claimNo,
      user,
      damageSeverity,
      selectedFile
    } = state;
    let shopData = {};


    let shopInfo =await dispatch(fetchShop( localStorage.getItem("localSerial"), ()=>{} , ()=>{}));
    if(shopInfo !== undefined && shopInfo.data !== undefined)
      {
    console.log(shopInfo)
    shopData.Company = shopInfo.data.Company;
    shopData.City = shopInfo.data.City;
    shopData.StateProvince = shopInfo.data.StateProvince;
}
else
{
  shopData.Company = "Kri-Tech";
  shopData.City = "admin";
  shopData.StateProvince = "admin";
}
let manualDescriptions = "none";
  
  if(!selectedFile)
    {
      estimateData = damageExtentDescriptions[damageSeverity];
      manualDescriptions =  damageExtentDescriptions[damageSeverity];
      console.log("This is manual dv");
    }

    const percentOfValuer = percentOfValuerX(estimateTotal , marketValue);
     // Create the PDF payload
    let pdfPayload = createPdfPayload(
      ownerName,
      vehicleYear,
      vehicleMake,
      vehicleModel,
      vehicleSubmodel,
      vin,
      licensePlate,
      estimateData,
      estimateTotal,
      marketValue,
      percentOfValuer,
      totalProjectedDimValue,
      dateOfLoss,
      shopData,
      claimNo,
      user,
      manualDescriptions
    );

    console.log("PDF Payload: ", pdfPayload);
    // Generate the DV PDF
    if(localStorage.getItem("localSerial") !== null)
      pdfPayload.userSerial = localStorage.getItem("localSerial");
    
    const response = await dispatch(generateDVPdf(pdfPayload));

    console.log("DV PDF generated successfully:", response);
    return response;
  } catch (error) {
    console.error("Error generating DV PDF:", error);
    throw error;
  }
};

export const uploadFile = (file) => (dispatch) => {
  console.log("Starting file upload...");
  dispatch(startFileUpload());
  dispatch(setLoading(true));
  dispatch(setSelectedFile(file));

  const data = new FormData();
  data.append("file", file);

  axios
    .post(apiEndpoint + "/uploadBlob/", data)
    .then((res) => {
      console.log("File uploaded successfully:", res.data);
      const uploadedFileName = res.data.filename;
      localStorage.setItem("uploadedPdf", file.name);
      dispatch(onCompleteUpload(res.data));
    })
    .catch((error) => {
      console.error("Upload error:", error);
      dispatch(endFileUpload());
      dispatch(setLoading(false));
    });
};

const onCompleteUpload = (data) => (dispatch, getState) => {
  console.log("Processing completed upload...");
  let config = getState().diminishedValue.config || {};
  config["pdf"] = data.filename;
  config.Platform = "DV";
  config.Application = "ReKey";
  config.Args = {};
  config.Args.Pdf = data.filename;

  if (data.filename && data.filename.length > 5) {
    ValidatePDF(config, (validationResponse) => {
      console.log("PDF validation response:", validationResponse);
      try {
        const message = JSON.parse(validationResponse.message);
        dispatch(setEstimateData(message));
        dispatch(setVehicleData(message));

        const documentFields = message.EstimateDocument.DocumentFields;
        const tableRows =
          message.UnivEstimateDocument.CCCEstimateTotals.TableRows;

        let totalRow = tableRows.find((row) => {
          const firstCellText = row.RowCells[0].CellValue.trim().toLowerCase();
          return (
            firstCellText.includes("total cost") ||
            firstCellText.includes("grand total")
          );
        });

        if (!totalRow) {
          totalRow = tableRows.find((row) => {
            const firstCellText =
              row.RowCells[0].CellValue.trim().toLowerCase();
            return firstCellText.includes("gross total");
          });
        }

        let estTotal = 0;
        if (totalRow) {
          const totalValue = totalRow.RowCells[2].CellValue.trim().replace(
            "$",
            ""
          );
          estTotal = parseFloat(totalValue.replace(/,/g, ""));
        }

        if (isNaN(estTotal) || estTotal <= 0) {
          dispatch(setEstimateTotal(""));
        } else {
          dispatch(setEstimateTotal(estTotal));
        }

        const vinField = documentFields.find(
          (field) => field.FieldName === "VIN"
        );

        const licenseField = documentFields.find(
          (field) => field.FieldName === "License"
        );

        const ownerField = documentFields.find(
          (field) => field.FieldName === "Owner"
        );

        let mileageField = documentFields.find(
          (field) => field.FieldName === "Mileage In"
        );

        if (!mileageField)
          mileageField = documentFields.find(
            (field) => field.FieldName === "Odometer"
          );

        const dateOfLoss = documentFields.find(
          (field) => field.FieldName === "Date of Loss"
        );

        const claimNoField = documentFields.find(
          (field) => field.FieldName === "Claim #"
        );

        if (mileageField) {
          const sanitizedMileage = mileageField.FieldValue.replace(/\D/g, "");
          dispatch(setMileage(sanitizedMileage));
        } else {
          console.error("Mileage field not found");
        }

        if (dateOfLoss) dispatch(setDateOfLoss(dateOfLoss.FieldValue));

        if (claimNoField) dispatch(setClaimNo(claimNoField.FieldValue));

        if (licenseField) dispatch(setLicensePlate(licenseField.FieldValue));

        if (ownerField) dispatch(setOwnerName(ownerField.FieldValue));

        if (vinField) {
          dispatch(setVin(vinField.FieldValue));
          dispatch(fetchVehicleData(vinField.FieldValue));
        } else {
          console.log("VIN not found");
          dispatch(setSelectedFile(null));
        }

        dispatch(
          setPdfValidationMessage(
            "File: " +
              data.filename +
              " , Platform:" +
              validationResponse.message
          )
        );

        if (validationResponse.success === true) {
          dispatch(setFileValidationStatus(true));
        } else {
          dispatch(setFileValidationStatus(false));
        }

        dispatch(setLoading(false));
      } catch (error) {
        console.error("Error processing validation response:", error);
        dispatch(setLoading(false));
        dispatch(setSelectedFile(null));
        dispatch(setFileValidationStatus(true));
      }
    });
  }
};

export const fetchShop =
  (serial, onSuccess, onFail) =>
  async (dispatch) => {
    console.log("Submitting K8s Job");
    try {
      const response = await axios.get(
        `${apiEndpoint}/adminShop/shop/${serial}`
      );
      return response;
    } catch (error) {
      console.error("Error submitting K8s job:", error);
    }
  };

// actions.js

export const submitPricesK8sJob =
  (model, make, year, selectTrim, mileage, onSuccess, onFail) =>
  async (dispatch) => {
    console.log("Submitting K8s Job");
    try {
      const response = await axios.get(
        `${apiEndpoint}/submitcrrjob?Make=${make}&Year=${year}&Model=${model}&Trim=${selectTrim}&Mileage=${mileage}`
      );
      console.log("submit job request k8s response:", response.data);
    } catch (error) {
      console.error("Error submitting K8s job:", error);
    }

    // Correct the timeout to 15 seconds and increment jobSubmitted correctly
    setTimeout(() => {
      dispatch(
        fetchPricesOptions(
          model,
          make,
          year,
          selectTrim,
          mileage,
          onSuccess,
          onFail,
          1
        )
      );
    }, 15000); // Corrected the timeout to 15000 milliseconds for 15 seconds
  };

// actions.js

let pricesCheckStartTime = null;

export const scheduleNextPricesCheck =
  (model, make, year, selectTrim, mileage, onSuccess, onFail, jobSubmitted) =>
  (dispatch) => {
    if (jobSubmitted >= 10) {
      // dispatch(setManualDv(true));
      // dispatch(step(0));
      onFail();
      return;
    }

    console.log("Scheduling next attempt in 5 seconds.");
    setTimeout(() => {
      dispatch(
        fetchPricesOptions(
          model,
          make,
          year,
          selectTrim,
          mileage,
          onSuccess,
          onFail,
          jobSubmitted
        )
      );
    }, 5000);
  };

export const fetchPricesOptions =
  (
    model,
    make,
    year,
    selectTrim,
    mileage,
    onSuccess,
    onFail,
    jobSubmitted = 0
  ) =>
  async (dispatch) => {
    console.log("Requested:", jobSubmitted);

    dispatch(setHasTrims(false));
    let tmpMileage = mileage;
    if (tmpMileage === null) tmpMileage = "1000";

    try {
      const response = await axios.get(
        `${apiEndpoint}/statuscrr?SerialId=${make}_${year}_${model}_${tmpMileage}_${selectTrim}`
      );

      let array = response.data;
      let wasValid = jobSubmitted === 0 ? false : true;

      if (array && array.length > 0) {
        let lastElement = array[array.length - 1];
        try {
          const jsonObject = JSON.parse(lastElement.LastMessage);
          const cleanElement = jsonObject.Values.find(
            (element) => element.Condition.trim() === "Clean"
          );

          if (cleanElement) {
            dispatch(
              setMarketValue(
                Number(cleanElement.PrivatePartyValue.replace(/[^0-9.-]+/g, ""))
              )
            );
            onSuccess();
            wasValid = true;
          } else {
            console.log("No element with 'Clean' condition found.");
            dispatch(
              scheduleNextPricesCheck(
                model,
                make,
                year,
                selectTrim,
                mileage,
                onSuccess,
                onFail,
                ++jobSubmitted
              )
            );
          }
        } catch (error) {
          console.log(jobSubmitted);
          console.error("LastMessage is not a valid JSON.");
          dispatch(
            scheduleNextPricesCheck(
              model,
              make,
              year,
              selectTrim,
              tmpMileage,
              onSuccess,
              onFail,
              ++jobSubmitted
            )
          );
        }
      } else {
        dispatch(
          scheduleNextPricesCheck(
            model,
            make,
            year,
            selectTrim,
            tmpMileage,
            onSuccess,
            onFail,
            ++jobSubmitted
          )
        );
      }

      if (wasValid === false) {
        console.log("Submitting refetch prices");
        //dispatch(setHasSubmitedK8sJobPrices(true));
        dispatch(
          submitPricesK8sJob(
            model,
            make,
            year,
            selectTrim,
            tmpMileage,
            onSuccess,
            onFail
          )
        );
        dispatch(
          scheduleNextPricesCheck(
            model,
            make,
            year,
            selectTrim,
            tmpMileage,
            onSuccess,
            onFail,
            ++jobSubmitted
          )
        );
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

export const fetchVehicleData = (vinNo) => async (dispatch) => {
  try {
    console.log("Fetching vehicle data for VIN:", vinNo);
    const response = await axios.get(
      `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/${vinNo}?format=json`
    );

    const results = response.data.Results;
    const vehicleMake =
      results.find((result) => result.Variable === "Make")?.Value || "";
    const vehicleModel =
      results.find((result) => result.Variable === "Model")?.Value || "";
    const vehicleType =
      results.find((result) => result.Variable === "Vehicle Type")?.Value || "";
    const vehicleYear =
      results.find((result) => result.Variable === "Model Year")?.Value || "";

    console.log("Vehicle data fetched:", {
      vehicleMake,
      vehicleModel,
      vehicleType,
      vehicleYear,
    });

    dispatch(setVehicleMake(vehicleMake));
    dispatch(setVehicleModel(vehicleModel));
    dispatch(setVehicleType(vehicleType));
    //dispatch(setVehicleYear(moment(vehicleYear, "YYYY")));
    dispatch(setVehicleYear(vehicleYear));
    dispatch(setRawVehicleYear(vehicleYear));

    // Simulate calling fetchCustomOptions
    dispatch(
      fetchCustomOptions(vehicleModel, vehicleMake, vehicleYear, "", "")
    );

    if (vehicleMake && vehicleModel && vehicleType) {
      dispatch(setVehicleData(response.data));
    }

    // TODO: Handle Azure function call with make, model, and year

    console.log("Vehicle data state updated successfully.");
  } catch (error) {
    console.error("Error fetching vehicle data:", error);
  }
};
export const fetchCustomOptions =
  (model, make, year, selectTrim, mileage, jobSubmitted = false, callCount = 0) =>
  async (dispatch) => {
    console.log("Starting fetchCustomOptions, call count:", callCount);

    if (callCount >= 10) {
      console.log("Reached maximum call count (10). Stopping and setting loading to false.");
      dispatch(setLoading(false));
      // You might want to dispatch an action to notify the user
      // dispatch(setError("Unable to fetch custom options after multiple attempts. Please try again later."));
      return;
    }

    const timeout = new Promise((_, reject) =>
      setTimeout(() => {
        console.log("Timeout triggered after 10 seconds");
        reject(new Error('Request timed out'));
      }, 10000)
    );

    try {
      dispatch(setLoading(true));
      
      const fetchPromise = axios.get(
        `${apiEndpoint}/statuscrr?SerialId=${make}_${year}_${model}_0_${selectTrim}`
      );

      const response = await Promise.race([fetchPromise, timeout]);

      let array = response.data;
      console.log("Custom options response:", array);

      if (array && array.length > 0) {
        let lastElement = array[array.length - 1];
        try {
          if (
            lastElement.LastMessage &&
            lastElement.LastMessage.includes("ERR: Could not")
          ) {
            console.log("Error in LastMessage:", lastElement.LastMessage);
            dispatch(setLoading(false));
            dispatch(setHasTrims(true));
            dispatch(setManualTrims(true));
            return;
          }
          let parsedMessage = JSON.parse(lastElement.LastMessage);
          if (Array.isArray(parsedMessage)) {
            console.log("Setting trims:", parsedMessage);
            dispatch(setLoading(false));
            dispatch(setTrims(parsedMessage));
            dispatch(setHasTrims(true));
            dispatch(endFileUpload());
          } else {
            console.log("LastMessage is not a valid JSON array, jobSubmitted:", jobSubmitted);
            dispatch(
              scheduleNextAttempt(
                model,
                make,
                year,
                selectTrim,
                mileage,
                jobSubmitted,
                callCount + 1
              )
            );
          }
        } catch (error) {
          console.error("Error parsing LastMessage:", error);
          dispatch(
            scheduleNextAttempt(
              model,
              make,
              year,
              selectTrim,
              mileage,
              jobSubmitted,
              callCount + 1
            )
          );
        }
      } else if (!jobSubmitted) {
        console.log("Array is empty and job not submitted, submitting K8s job");
        dispatch(submitK8sJob(model, make, year, selectTrim, mileage));
        dispatch(
          scheduleNextAttempt(
            model,
            make,
            year,
            selectTrim,
            mileage,
            true, // Set jobSubmitted to true
            callCount + 1
          )
        );
      } else {
        console.log("Waiting for job completion, scheduling next attempt");
        dispatch(
          scheduleNextAttempt(
            model,
            make,
            year,
            selectTrim,
            mileage,
            jobSubmitted,
            callCount + 1
          )
        );
      }
    } catch (error) {
      console.error("Error in fetchCustomOptions:", error);
      
      if (error.message === 'Request timed out') {
        console.log("Request timed out after 10 seconds");
      }
      
      dispatch(
        scheduleNextAttempt(
          model,
          make,
          year,
          selectTrim,
          mileage,
          jobSubmitted,
          callCount + 1
        )
      );
    }
  };

export const submitK8sJob =
  (model, make, year, selectTrim, mileage) => async (dispatch) => {
    console.log("Submitting K8s Job for:", {
      model,
      make,
      year,
      selectTrim,
      mileage,
    });
    try {
      const response = await axios.get(
        `${apiEndpoint}/submitcrrjob?Make=${make}&Year=${year}&Model=${model}&Trim=${selectTrim}&Mileage=0`
      );
      console.log("submit job request k8s response:", response.data);
    } catch (error) {
      console.error("Error submitting K8s job:", error);
    }
    dispatch(scheduleNextAttempt(model, make, year, selectTrim, mileage, true));
  };

  export const scheduleNextAttempt = (model, make, year, selectTrim, mileage, jobSubmitted, callCount) => {
    return (dispatch) => {
      setTimeout(() => {
        dispatch(fetchCustomOptions(model, make, year, selectTrim, mileage, jobSubmitted, callCount));
      }, 2000); // 5 second delay before next attempt
    };
  };
// Function to calculate the preloss value
export const calculateVehiclePreLossValue = (base, specialConsiderations) => {
  const numericBase = parseFloat(base);
  const numericSpecialConsiderations = parseFloat(specialConsiderations);

  let total = 0;
  let validInputs = 0;

  if (!isNaN(numericBase)) {
    total += numericBase;
    validInputs += 1;
  }

  if (!isNaN(numericSpecialConsiderations)) {
    total += numericSpecialConsiderations;
    validInputs += 1;
  } else {
    validInputs += 1;
  }

  if (validInputs > 0) {
    return total;
  } else {
    console.log("Both inputs are invalid");
    return 0;
  }
};

// Function to calculate the base LOV
export const calculateBaseLOV = (preloss) => {
  return preloss * 0.25;
};

// Function to calculate the mileage modifier
export const calculateMileageModifier = (mileage) => {
  var modifier = 1 - parseFloat(mileage) / 200000;
  return Math.round(modifier * 100) / 100; // Rounding to 2 decimal places
};

// Function to calculate the total projected diminished value
export const calculateTotalProjectedDimValue = (
  preloss,
  mileageModifier,
  damageExtent,
  damageModifier,
  modifierPercentToVal,
  ageModifierUsed
) => {
  return parseFloat(
    (
      0.25 *
      preloss *
      mileageModifier *
      damageExtent *
      damageModifier *
      modifierPercentToVal *
      ageModifierUsed
    ).toFixed(2)
  );
};

// Function to calculate the total projected post-repair value
export const calculateTotalProjectedPostRepairValue = (
  preloss,
  mileageModifier,
  damageExtent,
  damageModifier,
  modifierPercentToVal,
  ageModifierUsed
) => {
  return parseFloat(
    (
      preloss -
      0.25 *
        preloss *
        mileageModifier *
        damageExtent *
        damageModifier *
        modifierPercentToVal *
        ageModifierUsed
    ).toFixed(2)
  );
};

export const resetDvState = () => ({
  type: "diminishedValue/resetState",
});

// Function to calculate all the values
// Function to calculate all the values
export const calculateAllValues = (
  baseValue,
  specialConsideration,
  mileage,
  damageExtent,
  damageModifier,
  modifierPercentToVal,
  ageModifierUsed
) => {
  console.log(
    "baseValue:",
    baseValue,
    ",specialConsideration:",
    specialConsideration,
    ",mileage:",
    mileage,
    ",damageExtent:",
    damageExtent,
    ",damageModifier:",
    damageModifier,
    ",modifierPercentToVal:",
    modifierPercentToVal,
    ",ageModifierUsed:",
    ageModifierUsed
  );
  const preloss = calculateVehiclePreLossValue(baseValue, specialConsideration);
  const baseLOV = calculateBaseLOV(preloss);
  const mileageModifier = calculateMileageModifier(mileage);
  const totalProjectedDimValue = calculateTotalProjectedDimValue(
    preloss,
    mileageModifier,
    damageExtent,
    damageModifier,
    modifierPercentToVal,
    ageModifierUsed
  );
  const totalProjectedPostRepairValue = calculateTotalProjectedPostRepairValue(
    preloss,
    mileageModifier,
    damageExtent,
    damageModifier,
    modifierPercentToVal,
    ageModifierUsed
  );

  console.log("Preloss Value:", preloss);
  console.log("Base LOV:", baseLOV);
  console.log("Mileage Modifier:", mileageModifier);
  console.log("Total Projected Diminished Value:", totalProjectedDimValue);
  console.log(
    "Total Projected Post-Repair Value:",
    totalProjectedPostRepairValue
  );

  return {
    preloss,
    baseLOV,
    mileageModifier,
    totalProjectedDimValue,
    totalProjectedPostRepairValue,
  };
};

export const generateDVPdf = (payloadData) => async (dispatch) => {
  try {
    const response = await axios.post(
      "https://crashfunctions20240821023624.azurewebsites.net/api/GenDVPdf2?clientId=blobs_extension",
      payloadData
    );
    console.log("Response from server:", response.data);
    dispatch(setDvLink(response.data.pdfUrl));
    dispatch(setJobId(response.data.jobId));
    return response.data;
  } catch (error) {
    console.error("Error making the request:", error);
    throw error;
  }
};

// Function to create the payload for generating the PDF
export const createPdfPayload = (
  ownerName,
  vehicleYear,
  vehicleMake,
  vehicleModel,
  vehicleSubmodel,
  vin,
  licensePlate,
  estimateData,
  estimateTotal,
  baseValue,
  percentOfValue,
  totalProjectedDimValue,
  dateOfLoss,
  shopData,
  claimNo,
  user,
  manualDescriptions
) => {
  const today = new Date();
  const prettyDateString = today.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const payloadData = {
    ownerName: ownerName,
    date: prettyDateString,
    vehicleYear: vehicleYear,
    vehicleMake: vehicleMake,
    vehicleModel: vehicleModel,
    vehicleSubmodel: vehicleSubmodel,
    vin: vin,
    licensePlate: licensePlate,
    estimateData: estimateData,
    estimateTotal: estimateTotal,
    licensePlate: licensePlate,
    baseValue: baseValue,
    percentOfValue: percentOfValue,
    diminishedValue: totalProjectedDimValue,
    totalEstimateCost: estimateTotal,
    repairDate: dateOfLoss,
    shopName: shopData.Company,
    shopCity: shopData.City,
    shopState: shopData.StateProvince,
    claim: claimNo,
    userSerial: localStorage.getItem("serial"),
    userName: "admin",
    manualDescriptions:manualDescriptions
  };

  console.log("Payload Data:", payloadData);

  return payloadData;
};

//const values = calculateAllValues(baseValue, specialConsideration, mileage, damageExtent, damageModifier, modifierPercentToVal, ageModifierUsed);
