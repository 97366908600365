import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { API_URI } from "./Globals";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const storedToken = localStorage.getItem("token");
        console.log("Stored token:", storedToken);

        if (storedToken) {
          setIsLoading(true);
          const response = await verifyToken(storedToken);
          console.log("Verification response:", response);

          if (response.data && response.data.user.Serial) {
            console.log("Token is valid.");
            setUser(response.data.user);
            setToken(storedToken);
            setIsAuthenticated(true);
          } else {
            console.log("Token is invalid. :", response.data);
            localStorage.removeItem("token");
          }
        } else {
          console.log("No stored token found.");
        }
      } catch (error) {
        console.error("Token verification failed", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, []);

  const verifyToken = async (storedToken) => {
    try {
      console.log("Verifying token...");
      const response = await axios.get(API_URI() + "/auth/verify", {
        headers: { Authorization: `Bearer ${storedToken}` },
      });
      return response;
    } catch (error) {
      console.error("Token verification failed", error);
      throw error;
    }
  };

  const login = async (Serial) => {
    try {
      const response = await axios.post(API_URI() + "/auth", {
        Serial,
      });
      console.log(response);

      if (response.data && response.data.token) {
        localStorage.setItem("token", response.data.token);
        setToken(response.data.token);
        setUser(response.data.user);
        setIsAuthenticated(true);
        return response.data;
      }
    } catch (error) {
      console.error("Login failed", error);
      throw error;
    }
  };

  const logout = () => {
    setToken(null);
    setUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem("token");
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        isAuthenticated,
        isLoading,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
