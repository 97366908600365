import React from "react";
import Button from "../../common/Button/Button";
import "./Header.css";

const Header = ({ onLogin, onSignUp }) => {
  return (
    <header className="header">
      <div className="header-content">
        <Button onClick={onLogin}>Login</Button>
        <Button onClick={onSignUp}>Sign Up</Button>
      </div>
    </header>
  );
};

export default Header;
