import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { API_URI } from "../Globals";

export default function NCSCodeGen() {
  const [formData, setFormData] = useState({
    shopName: "",
    shopEmail: "",
    ncsCustomerAccountNumber: "",
    shopType: "independent",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.shopName.trim()) {
      errors.shopName = "Shop Name is required";
    }
    if (!formData.shopEmail.trim()) {
      errors.shopEmail = "Shop Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.shopEmail)) {
      errors.shopEmail = "Invalid email address";
    }
    if (!formData.ncsCustomerAccountNumber.trim()) {
      errors.ncsCustomerAccountNumber =
        "NCS Customer Account Number is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleGenerateCode = async () => {
    if (validateForm()) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${API_URI()}/admin/generate-ncs-coupon?shopType=${
            formData.shopType
          }`,
          formData
        );
        if (response.data.success) {
          setShowNotification(true);
        } else {
          alert("Failed to generate coupon code. Please try again.");
        }
      } catch (error) {
        console.error("Error generating coupon code:", error);
        alert("An error occurred. Please try again.");
      }
      setIsLoading(false);
    }
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowNotification(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card sx={{ maxWidth: 500 }}>
        <CardContent>
          <Typography variant="h5" align="center" gutterBottom>
            NCS Coupon Code Generator
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Shop Type</FormLabel>
                <RadioGroup
                  row
                  name="shopType"
                  value={formData.shopType}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="independent"
                    control={<Radio />}
                    label="Single Location"
                  />
                  <FormControlLabel
                    value="mso"
                    control={<Radio />}
                    label="MSO"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Shop Name"
                name="shopName"
                value={formData.shopName}
                onChange={handleInputChange}
                error={!!formErrors.shopName}
                helperText={formErrors.shopName}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Shop Email"
                name="shopEmail"
                value={formData.shopEmail}
                onChange={handleInputChange}
                error={!!formErrors.shopEmail}
                helperText={formErrors.shopEmail}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="NCS Customer Account Number"
                name="ncsCustomerAccountNumber"
                value={formData.ncsCustomerAccountNumber}
                onChange={handleInputChange}
                error={!!formErrors.ncsCustomerAccountNumber}
                helperText={formErrors.ncsCustomerAccountNumber}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleGenerateCode}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Generate Code"
                )}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showNotification}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        message="Coupon code generated and email sent successfully."
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseNotification}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
}
