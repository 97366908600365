// reducers.js
import { combineReducers } from "redux";
import shopsReducer from "./shopsReducer";
import usersReducer from "./usersReducer";
import licensesReducer from "./licensesReducer";
import usageReportsReducer from "./usageReportsReducer";
import authReducer from "./authReducer";
import productsReducer from "./productsReducer";
import stripeReducer from "./stripeReducer";

const rootReducer = combineReducers({
  shops: shopsReducer,
  users: usersReducer,
  licenses: licensesReducer,
  usageReports: usageReportsReducer,
  auth: authReducer,
  products: productsReducer,
  stripe: stripeReducer,
});

export default rootReducer;
