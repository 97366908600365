import React, { useState, useEffect } from "react";
import SeeMoreInfoModal from "./totallossv2.job.moreinfo.modal";
import CryptoJS from "crypto-js";
import CustomInfoModal from "./totallossv2.custominfomodal";
import { updateJobRequest, updateRequestMessage } from "./totallossv2.service";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

const TotalLossJobDetails = () => {
  const { state } = useLocation();
  const [platform, setPlatform] = useState("");
  const [vin, setVin] = useState("");
  const [images, setImages] = useState({});
  const [open, setOpen] = useState(false);
  const [appraiserMsg, setAppraiserMsg] = useState("");
  const [customerMsg, setCustomerMsg] = useState("");
  const [optionalNotes, setOptionalNotes] = useState("");

  //Modal data
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [canEditFields, seCanEditFields] = useState(true);
  const [timer, setTimer] = useState(0);

  const navigate = useNavigate();
  const handleOpenModal = (modalTitle, modalBody, showSpinner, showClose) => {
    setModalBody(modalBody);
    setModalTitle(modalTitle);
    setShowSpinner(showSpinner);
    setShowClose(showClose);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(timer - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    console.log("UseEffect:", state.rowData);
    const rawData = state.rowData;

    setVin(rawData.vin);
    setPlatform(rawData.platform);
    setImagesFromUrls(rawData.imagePaths);
    setOptionalNotes(rawData.optional_notes);
    setAppraiserMsg(rawData.msg_appraiser);

    var dateToUse = rawData.startDate;

    if (
      rawData.status === "1" ||
      rawData.status === "4" ||
      rawData.status === "5"
    ) {
      seCanEditFields(false);

      setTimer(result);
      console.log("We can't edit this");
    } else if (rawData.status === "2") {
      dateToUse = rawData.updateTime;
    }

    const currentDate = new Date().getUTCDate();

    const differenceInMilliseconds = currentDate - dateToUse;

    // Convert the difference to hours
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

    // Subtract 12 hours from the difference
    const result = 12 - differenceInHours;
  }, [state.rowData]);

  const statusMap = {
    0: "Unclaimed",
    1: "Claimed - Working",
    2: "Paused - Waiting for Customer Response",
    3: "Completed - Pending Customer Review",
    4: "Issue Raised - Waiting for Appraiser’s Response",
    5: "Issue Raised - Working",
    6: "Final - Locked",
    7: "Canceled - Locked",
  };

  // Utility function to get the image position from the URL
  const getImagePositionFromUrl = (url) => {
    const imageName = url.substring(url.lastIndexOf("/") + 1);
    const { position } = decodeImageName(imageName);
    return position;
  };

  const decodeImageName = (imageName) => {
    const [position, hashWithExt] = imageName.split("_");
    const hash = hashWithExt.replace(".jpg", "");
    return { position, hash };
  };

  // Function to set the images state from an array of image URLs
  const setImagesFromUrls = async (imageUrls) => {
    if (!Array.isArray(imageUrls)) {
      console.error("setImagesFromUrls: imageUrls is not an array", imageUrls);
      return;
    }
    const imageFilesPromises = imageUrls.map((url) => {
      const position = getImagePositionFromUrl(url);
      return fetchImageAsFile(url, position);
    });

    const imageFilesArray = await Promise.all(imageFilesPromises);
    const newImages = imageFilesArray.reduce((acc, item) => {
      const position = Object.keys(item)[0];
      return { ...acc, [position]: item[position] };
    }, {});

    setImages(newImages);
    // rest of your code...
  };

  const getImageSrc = (item) => {
    if (item.type === "file") {
      return URL.createObjectURL(item.data);
    } else if (item.type === "url") {
      return item.data;
    }
    return null;
  };

  const generateImageHash = (imageData) => {
    const hash = CryptoJS.SHA256(imageData).toString(CryptoJS.enc.Hex);
    return hash;
  };

  const encodeImageName = (position, imageData) => {
    const hash = generateImageHash(imageData);
    return `${position}_${hash}.jpg`;
  };

  const getFilesFromImages = () => {
    const files = Object.keys(images)
      .map((position) => images[position])
      .filter((item) => item.type === "file")
      .map((item) => item.data);
    return files;
  };

  const fetchImageAsFile = async (url, position) => {
    const response = await fetch(url);
    const blob = await response.blob();

    // Extract the original file name from the URL
    const originalFileName = url.substring(url.lastIndexOf("/") + 1);

    // Create a File object using the original file name
    const file = new File([blob], originalFileName, { type: blob.type });

    return { [position]: { type: "file", data: file } };
  };

  const handleImageChange = (event, position) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const imageData = reader.result;
      const encodedImageName = encodeImageName(position, imageData);

      // Create a new File object with the encoded image name
      const fileWithEncodedName = new File([file], encodedImageName, {
        type: file.type,
      });

      // Store the new File object in the state with the position as the key
      setImages({
        ...images,
        [position]: { type: "file", data: fileWithEncodedName },
      });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateJob = (desiredStatus) => {
    let newState = state.rowData.status === "2" ? "1" : "0";
    newState = desiredStatus === "7" ? "7" : newState;

    handleOpenModal("Updating Job", "", true, false);
    updateJobRequest(
      state.rowData.jobId,
      vin,
      platform,
      getFilesFromImages(),
      optionalNotes,
      appraiserMsg,
      customerMsg,
      newState,
      () => {
        navigate("/dashboard/totallossv2/customer/usage");
      },
      () => {
        handleOpenModal(
          "Updating Job Failed",
          "We encountered a issue while updating your Job. Please try again later",
          false,
          true
        );
      }
    );
    console.log({ platform, vin, images });
  };

  const cancelJob = () => {
    // Handle form cancel logic here
    console.log("Cancel job");
  };

  const handleSubmitMsgResponse = (requestInfoNotes, customerResponse) => {
    handleOpenModal("", "", true, false);
    updateRequestMessage(
      state.rowData.jobId,
      customerResponse,
      "",
      () => {
        navigate("/dashboard/totallossv2/customer/usage");
      },
      () => {
        handleOpenModal(
          "Error",
          "We encountered a error while handling your request",
          false,
          true
        );
      }
    );
  };

  return (
    <Container>
      <CustomInfoModal
        open={openModal}
        handleClose={handleCloseModal}
        title={modalTitle}
        showSpinner={showSpinner}
        showCloseButton={showClose}
        bodyText={modalBody}
      />

      <Box sx={{ position: "absolute", top: 8, left: 8 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            window.location.href = "/dashboard/totallossv2/customer/usage";
          }}
        >
          Total Loss Dashboard Home
        </Button>
      </Box>
      <Box sx={{ position: "absolute", top: 8, right: 8 }}>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          See more Information Request
        </Button>
        <SeeMoreInfoModal
          open={open}
          handleClose={handleClose}
          onSubmit={handleSubmitMsgResponse}
          initialText1={[]}
          initialText2={[]}
          disableInput1={true}
          disableInput2={false}
        />
      </Box>

      <Typography
        variant="h3"
        gutterBottom
        sx={{ paddingTop: 4, paddingBottom: 4, color: "white" }}
      >
        Total Loss Job Details
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", color: "white" }}
      >
        {statusMap[state.rowData.status]}
      </Typography>

      <Box sx={{ marginTop: 4 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" sx={{ color: "white" }}>
              Time left
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              value={timer}
              fullWidth
              readOnly={!canEditFields}
              InputProps={{ readOnly: true }}
              sx={{ backgroundColor: "white", width: "100%", height: "80%" }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ marginTop: 4 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" sx={{ color: "white" }}>
              Platform Going Into
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Select
              value={platform}
              onChange={(e) => setPlatform(e.target.value)}
              sx={{ backgroundColor: "white", width: "100%", height: "80%" }}
              disabled={!canEditFields}
            >
              <MenuItem value="CccOne">CccOne</MenuItem>
              <MenuItem value="Mitchell">Mitchell</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ marginTop: 4 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" sx={{ color: "white" }}>
              VIN
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              value={vin}
              onChange={(e) => setVin(e.target.value)}
              fullWidth
              disabled={!canEditFields}
              sx={{ backgroundColor: "white", width: "100%", height: "80%" }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ marginTop: 4 }}>
        <Typography variant="subtitle1" sx={{ color: "white" }}>
          Images
        </Typography>
        {[
          ["Front"],
          ["Front Left", "Front Right"],
          ["Left", "Roof", "Right"],
          ["Back Left", "Back Right"],
          ["Back"],
        ].map((row, rowIndex) => (
          <Grid container spacing={3} justifyContent="center" key={rowIndex}>
            {row.map((fieldName) => (
              <Grid item key={fieldName}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id={fieldName}
                  type="file"
                  disabled={!canEditFields}
                  onChange={(e) => handleImageChange(e, fieldName)}
                />
                <label htmlFor={fieldName}>
                  {images[fieldName] && (
                    <img
                      src={getImageSrc(images[fieldName])}
                      alt={fieldName}
                      disabled={!canEditFields}
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        width: 150,
                        height: 100,
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    component="span"
                    sx={{ width: 150, height: 100 }}
                  >
                    {fieldName}
                  </Button>
                  {images[fieldName] && (
                    <Typography
                      variant="subtitle1"
                      sx={{
                        position: "absolute",
                        zIndex: 2,
                        top: 0,
                        left: 0,
                        right: 0,
                        textAlign: "center",
                        color: "white",
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                      }}
                    ></Typography>
                  )}
                </label>
              </Grid>
            ))}
          </Grid>
        ))}
        <TextField
          label="Optional Notes"
          multiline
          rows={4}
          fullWidth
          margin="normal"
          onChange={(e) => setOptionalNotes(e.target.value)}
          value={optionalNotes}
          disabled={!canEditFields}
          className={!canEditFields}
          sx={{ backgroundColor: "white" }}
        />
      </Box>
      {state.rowData.status !== "7" && (
        <Box sx={{ marginTop: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => updateJob("")}
              >
                Update Job
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => updateJob("7")}
              >
                Cancel Job
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  );
};

export default TotalLossJobDetails;
