import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useAuth } from "../../../AuthContext";
import { API_URI, fetchUnitPrice, checkProductPayment } from "../../../Globals";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box } from "@mui/material";
import { mkConfig, generateCsv, download } from "export-to-csv";
import axios from "axios";
import { Snackbar, Alert } from "@mui/material";
import {
  Modal,
  Paper,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const DiminishedValueMaterialTable = () => {
  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([{ id: "date", desc: true }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const { user, isVerifying } = useAuth();
  const [sendingEmail, setSendingEmail] = useState(false);
  const [pricePerUnit, setPricePerUnit] = useState(0);
  const [modalMessage, setModalMessage] = useState("");
  const [modalSeverity, setModalSeverity] = useState("success");
  const [availableTransactions, setAvailableTransactions] = useState(0);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleOpenSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const sendEmailToShop = (subject, body, id) => {
    console.log("SENDING EMAIL");
    setSendingEmail(true);
    handleOpenSnackbar("Sending Email please wait.", "success"); // Open Snackbar
    const data = {
      serial: user.Serial,
      subject: subject,
      body: body,
      jobId: id,
    };

    axios
      .post(API_URI() + "/admin/sendDvEmailToShop", data)
      .then((response) => {
        console.log("Email sent successfully:", response.data);
        setSendingEmail(false);
        handleOpenSnackbar("Email sent successfully", "success"); // Open Snackbar
      })
      .catch((error) => {
        console.error(
          "Error sending email to shop:",
          error.response ? error.response.data : error
        );
        setSendingEmail(false);
        handleOpenSnackbar("Failed to send email", "error"); // Open Snackbar on Error
      });
  };

  const handlePurchaseClick = (rowData) => {
    setSelectedRow(rowData);
    setShowPurchaseModal(true);
  };

  const handlePurchaseConfirm = async () => {
    if(purchaseLoading)
      return;
    setPurchaseLoading(true);
    let selectedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
    try {
      const response = await checkProductPayment(
        user.Serial,
        selectedProduct._id,
        selectedRow.id
      );

      if (response.success) {
        if (availableTransactions > 0) {
          // Update the available transactions count in local storage
          selectedProduct.AvailableTransactions -= 1;
          localStorage.setItem(
            "selectedProduct",
            JSON.stringify(selectedProduct)
          );
          setAvailableTransactions(selectedProduct.AvailableTransactions);
        }
        refreshTable();
        setShowPurchaseModal(false);
        setModalMessage("");
        setModalSeverity("success");
      } else {
        // Purchase failed, show error message in modal
        setModalMessage("Purchase failed. Please try again.");
        setModalSeverity("error");
      }
    } catch (error) {
      // Handle API error, show error message in modal
      console.error("Error processing purchase:", error);
      setModalMessage("An error occurred while processing the purchase.");
      setModalSeverity("error");
    }

    setPurchaseLoading(false);
  };

  const refreshTable = () => {
    fetchData();
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const handleSendEmail = (pdfUri, id) => {
    sendEmailToShop(
      "Your Diminished Value estimate has been generated",
      "Find your files attached",
      id
    );
  };

  const fetchData = async () => {
    if (!Array.isArray(data) || !data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    const url = new URL(API_URI() + "/admin/dvdata");
    url.searchParams.set(
      "start",
      `${pagination.pageIndex * pagination.pageSize}`
    );
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("globalFilter", globalFilter ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("serial", user.Serial);
    try {
      const response = await fetch(url.href);
      const json = await response.json();
      console.log("Received DV Table Data:", json);
      setData(json.data);
      setRowCount(json.meta.totalRowCount);
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  async function fetchDvPrice() {
    let selectedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
    console.log("User:", user);
    let serial = user.Serial;
    let fetchPriceResponse = await fetchUnitPrice(serial, selectedProduct._id);
    console.log("FPR TEMP:", fetchPriceResponse);

    if (fetchPriceResponse.message === "No payment required") {
      console.log("No payment required");
      // Handle the case when no payment is required
    } else if (fetchPriceResponse.state === "OK") {
      console.log("UnitPrice:", fetchPriceResponse);
      setPricePerUnit(fetchPriceResponse.unitPrice);
      console.log(
        "Available Transactions:",
        selectedProduct.AvailableTransactions
      );
      setAvailableTransactions(selectedProduct.AvailableTransactions); // Set the available transactions count
    }
  }

  useEffect(() => {
    if (isVerifying || !user) {
      return;
    }

    fetchData();
    fetchDvPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    isVerifying,
    user,
  ]);

  const columns = useMemo(
    () => [
      {
        enableResizing: true,
        maxSize: 80,
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => {
          const status = cell.row.original.status;
          const isPurchased = cell.row.original.purchased;
          let statusLabel = "";
          let customStyle = {};

          if (status === 1 && isPurchased) {
            statusLabel = "Success";
            customStyle = { backgroundColor: "green", color: "white" };
          } else {
            statusLabel = "Pending";
            customStyle = { backgroundColor: "orange", color: "white" };
          }

          return (
            <div className="Aoo-spaceBetween">
              <Button
                variant="contained"
                style={{ borderRadius: "20px", ...customStyle }}
                size="small"
                onClick={() => {}}
              >
                {statusLabel}
              </Button>
            </div>
          );
        },
      },
      {
        enableResizing: true,
        maxSize: 80,
        accessorKey: "username",
        header: "User Name",
      },
      {
        minWidth: 80,
        accessorKey: "date",
        filterVariant: "datetime-range",
        accessorFn: (originalRow) => new Date(originalRow.date),
        header: "Date",
        Cell: ({ cell }) => {
          const dateString = cell.row.original.date;
          const dateObject = new Date(dateString);
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZoneName: "short",
          };
          const formattedDate =
            dateObject.toString() === "Invalid Date"
              ? "N.a"
              : new Intl.DateTimeFormat("en-US", options).format(dateObject);

          return <div>{formattedDate}</div>;
        },
      },
      {
        enableResizing: true,
        maxSize: 80,
        accessorKey: "owner",
        header: "Customer Name",
      },
      {
        enableResizing: true,
        maxSize: 80,
        accessorKey: "vin",
        header: "VIN",
      },
      {
        enableResizing: true,
        maxSize: 80,
        accessorKey: "make",
        header: "Make",
      },
      {
        enableResizing: true,
        maxSize: 80,
        accessorKey: "model",
        header: "Model",
      },
      {
        enableResizing: true,
        maxSize: 80,
        accessorKey: "year",
        header: "Year",
      },
      {
        enableResizing: true,
        maxSize: 80,
        accessorKey: "totaldv",
        header: "Total DV",
        Cell: ({ cell }) => {
          const status = cell.row.original.status;
          const isPurchased = cell.row.original.purchased;
          return (
            <div>
              {status === 1 && isPurchased ? cell.row.original.totaldv : "N/A"}
            </div>
          );
        },
      },
      {
        enableResizing: true,
        maxSize: 80,
        accessorKey: "actions",
        header: "Actions",
        Cell: ({ cell }) => {
          const downloadLink = cell.row.original.download;
          const isPurchased = cell.row.original.purchased;
          const downloadButtonStyle = {
            borderRadius: "20px",
            backgroundColor: "blue",
            color: "white",
            marginRight: "10px",
          };
          const emailButtonStyle = {
            borderRadius: "20px",
            backgroundColor: "green",
            color: "white",
          };
          const purchaseButtonStyle = {
            borderRadius: "20px",
            backgroundColor: "orange",
            color: "white",
          };

          return (
            <>
              <div className="Aoo-spaceBetween">
                {cell.row.original.status === 1 && isPurchased && (
                  <>
                    <Button
                      variant="contained"
                      style={downloadButtonStyle}
                      size="small"
                      onClick={() => {
                        window.open(downloadLink, "_blank");
                      }}
                    >
                      Download
                    </Button>
                    <Button
                      variant="contained"
                      style={emailButtonStyle}
                      size="small"
                      disabled={sendingEmail}
                      onClick={() => {
                        handleSendEmail(downloadLink, cell.row.original.id);
                      }}
                    >
                      Email
                    </Button>
                  </>
                )}
                {(!isPurchased || cell.row.original.status === 0) && (
                  <>
                    <Button
                      variant="contained"
                      style={purchaseButtonStyle}
                      size="small"
                      onClick={() => handlePurchaseClick(cell.row.original)}
                    >
                      Purchase Report
                    </Button>
                  </>
                )}
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    getRowId: (row) => row.phoneNumber,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <Modal
        open={showPurchaseModal}
      
      >
        <Paper
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
            borderRadius: 8,
            textAlign: "center",
          }}
        >
          {/* ... */}
          <Typography style={{ paddingTop: "10px", marginBottom: "30px" }}>
            {availableTransactions > 0 ? (
              <>
                You will be using one of your Available Uses to purchase this
                report. You will have {availableTransactions - 1} Available Uses
                left after this purchase.
              </>
            ) : (
              <>
                Confirming this action will charge your shop's Kri-Tech account
                ${pricePerUnit}.00 USD for the Final Diminished Value Report
              </>
            )}
          </Typography>
          {/* Add the Terms and Conditions checkbox */}
          <div style={{ padding: "20px", marginBottom: "20px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  color="primary"
                />
              }
              label={
                <span>
                  I Understand and Agree to the{" "}
                  <a
                    href="https://aerksa.blob.core.windows.net/legal/KriTech_terms_and_conditions_(current).pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>{" "}
                  for this Diminished Value Application and the Charge for the
                  Transaction.
                </span>
              }
            />
          </div>
          {purchaseLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {modalMessage && (
                <Alert
                  severity={modalSeverity}
                  style={{ marginBottom: "20px" }}
                >
                  {modalMessage}
                </Alert>
              )}
              {modalMessage === "Purchase successful!" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowPurchaseModal(false)}
                  >
                    Close
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePurchaseConfirm}
                    style={{ marginRight: "10px" }}
                    disabled={purchaseLoading || !termsAccepted} // Disable the button if terms are not accepted
                  >
                    Confirm
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setShowPurchaseModal(false)}
                    disabled={purchaseLoading}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </>
          )}
        </Paper>
      </Modal>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DiminishedValueMaterialTable;
