import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import StepHeader from "../components/StepHeader";
import PieMenu, { Slice } from "react-pie-menu";
import { styled } from "@mui/system";
import { ThemeProvider, css } from "styled-components";
import { Button, DialogActions, Dialog } from "@mui/material";
import { setVehicleDamage } from "../../../redux/diminishedvalue/actions";
const MainContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [selectedButton, setSelectedButton] = React.useState(null);
  const OFFSET_ANGLE = "15.2deg"; // You can adjust this value to move the conic-gradient around.

  const updatedColors = useSelector(
    (state) => state.diminishedValue.vehicleDamage
  );

  const StyledSlice = styled(Slice)`
    background: ${(props) =>
      updatedColors[props.kind] && updatedColors[props.kind] !== "white"
        ? updatedColors[props.kind]
        : `conic-gradient(
           from ${OFFSET_ANGLE},
           grey 0% 0.1%, 
           white 0.1% 99.9%, 
           grey 99.9% 100%
         )`};
  `;

  const theme = {
    pieMenu: {
      container: css`
        border: 4px solid white;
        background: radial-gradient(
          transparent
            ${({ centerRadius }) =>
              `${centerRadius}, rgba(255, 255, 255, 0.85) ${centerRadius}`}
        );
        overflow: visible;
      `,
    },
    slice: {
      container: css`
        cursor: ${(props) => (open ? "default" : "pointer")};
        border: 1px solid lightgrey;

        /* Dynamically adjust background based on slice kind */
        background: ${(props) => updatedColors[props.kind] || "white"};

        // ... other slice styles ...
      `,
      content: css`
        padding-left: 60px;
        padding-right: 60px;
      `,
    },
  };

  const handleButtonClick = (id) => {
    setSelectedButton(id);
    setOpen(true);
  };

  const handleDialogOption = (color) => {
    dispatch(
      setVehicleDamage({
        ...updatedColors,
        [selectedButton]: color,
      })
    );
    setOpen(false);
  };

  const handleClickSave = () => {
    navigate("/instantdv/5_ClaimInformation");
  };

  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "40px",
        minHeight: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ maxWidth: "750px", width: "100%" }}>
        <div
          style={{
            backgroundColor: "#f0f0f0",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            textAlign: "center",
            position: "relative", // Add position relative to the parent container
          }}
        >
          <h3
            onClick={() => navigate("/instantdv/3m_VehicleDetails")}
            style={{
              color: "rgb(192, 44, 122)",
              fontSize: "24px",
              cursor: "pointer", // Change cursor on hover
            }}
          >
            Indicate all damage on Vehicle
          </h3>
          <div
            style={{
              position: "relative",
              width: "700px",
              height: "700px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pointerEvents: open ? "none" : "auto", // Add this line
            }}
          >
            <ThemeProvider theme={theme}>
              <PieMenu
                radius="300px"
                centerRadius="120px"
                centerX="350px"
                centerY="350px"
                style={{
                  zIndex: -100,
                }}
              >
                <StyledSlice kind="12" onSelect={() => handleButtonClick(12)}>
                  Front
                </StyledSlice>
                <StyledSlice kind="1" onSelect={() => handleButtonClick(1)}>
                  R Front Corner
                </StyledSlice>
                <StyledSlice kind="2" onSelect={() => handleButtonClick(2)}>
                  R Front Side
                </StyledSlice>
                <StyledSlice kind="3" onSelect={() => handleButtonClick(3)}>
                  R Side
                </StyledSlice>
                <StyledSlice kind="4" onSelect={() => handleButtonClick(4)}>
                  R Rear Side
                </StyledSlice>
                <StyledSlice kind="5" onSelect={() => handleButtonClick(5)}>
                  R Rear Corner
                </StyledSlice>
                <StyledSlice kind="6" onSelect={() => handleButtonClick(6)}>
                  Rear
                </StyledSlice>
                <StyledSlice kind="7" onSelect={() => handleButtonClick(7)}>
                  L Rear Corner
                </StyledSlice>
                <StyledSlice kind="8" onSelect={() => handleButtonClick(8)}>
                  L Rear Side
                </StyledSlice>
                <StyledSlice kind="9" onSelect={() => handleButtonClick(9)}>
                  L Side
                </StyledSlice>
                <StyledSlice kind="10" onSelect={() => handleButtonClick(10)}>
                  L Front Side
                </StyledSlice>
                <StyledSlice kind="11" onSelect={() => handleButtonClick(11)}>
                  L Front Corner
                </StyledSlice>
              </PieMenu>
            </ThemeProvider>

            {/* Image in the center */}
            <img
              src="../../../..\img\vehicle-outline.png"
              alt="Vehicle Outline"
              style={{
                position: "absolute",
                width: "160px",
                height: "290px",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
              }}
            />
            <Button
              variant="contained"
              onClick={() => handleButtonClick(13)} // Assuming 13 is the ID for the Rollover button
              style={{
                position: "absolute",
                width: "60px", // Adjust as needed for desired button size
                height: "60px",
                borderRadius: "60px", // Half of width/height to make it circular
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -20%)",
                zIndex: 2, // Ensure the button is on top
                backgroundColor: updatedColors[13] || "white",
                color: "black",
              }}
            >
              Rollover
            </Button>
          </div>
          <button
            onClick={handleClickSave}
            style={{
              backgroundColor: "rgb(192, 44, 122)",
              color: "white",
              fontWeight: "bold",
              fontSize: "18px",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              marginTop: "20px",
              cursor: "pointer",
              position: "relative", // Add position relative to the button
              zIndex: 1, // Increase the z-index of the button
            }}
          >
            Save
          </button>
        </div>
        {open && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              zIndex: 2,
            }}
          />
        )}
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              overflow: "hidden",
            },
          }}
        >
          <DialogActions
            style={{
              position: "relative",
              zIndex: 1,
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            }}
          >
            <Button
              onClick={() => handleDialogOption("darkgrey")}
              style={{
                backgroundColor: "darkgrey",
                fontSize: "1.5em",
                padding: "12px 24px",
                color: "white",
              }}
            >
              Minor
            </Button>
            <Button
              onClick={() => handleDialogOption("magenta")}
              style={{
                backgroundColor: "magenta",
                fontSize: "1.5em",
                padding: "12px 24px",
                color: "white",
              }}
            >
              Major
            </Button>
            <Button
              onClick={() => handleDialogOption("white")}
              style={{
                backgroundColor: "white",
                fontSize: "1.5em",
                padding: "12px 24px",
                color: "black",
              }}
            >
              Clear
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

const _4m_VehicleDamage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div style={{ maxWidth: "1500px", margin: "0 auto" }}>
      <Header
        isGrey={true}
        buttonText="DRIVE AWAY WITH A FAIR COMPENSATION"
        style={{ marginLeft: 0 }}
      />
      <div
        style={{ position: "relative", overflow: "hidden", minHeight: "400px" }}
      >
        <img
          src="/img/diminishedvalue/dv_car_r.png"
          alt="DV Car"
          style={{
            position: "absolute",
            left: "0",
            top: "50%",
            transform: "translateY(-50%)",
            width: "25%",
            height: "auto",
            zIndex: -1,
          }}
        />
        <img
          src="/img/diminishedvalue/dv_car_l.png"
          alt="DV Car"
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translateY(-50%) ",
            width: "25%",
            height: "auto",
            zIndex: -1,
          }}
        />
        <div style={{ position: "relative", zIndex: 1 }}>
          <StepHeader tstep={"Damage Report"} />
        </div>
        <MainContent />
      </div>
      <Footer isGrey={true} />
    </div>
  );
};

export default _4m_VehicleDamage;
