import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import StepHeader from "../components/StepHeader";
import InputField from "../components/InputField";
import {
  setOwnerName,
  setOwnerEmail,
  setOwnerAddress,
  setOwnerCity,
  setOwnerState,
  setOwnerZip,
  generateDVPdfFromState,
  setJobId,
} from "../../../redux/diminishedvalue/actions";
import CircularProgress from "@mui/material/CircularProgress";

const US_STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const MainContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    ownerName,
    ownerEmail,
    ownerAddress,
    ownerCity,
    ownerState,
    ownerZip,
    dvLink,
  } = useSelector((state) => state.diminishedValue);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleNextClick = () => {
    let validationErrors = {};
    if (!ownerName) validationErrors.ownerName = "Name is required";
    if (!ownerEmail) {
      validationErrors.ownerEmail = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(ownerEmail)) {
      validationErrors.ownerEmail = "Invalid email format";
    }
    if (!ownerCity) validationErrors.ownerCity = "City is required";
    if (!ownerState) validationErrors.ownerState = "State is required";
    if (!/^\d+$/.test(ownerZip))
      validationErrors.ownerZip = "Zip code must be numerical";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true);
      dispatch(generateDVPdfFromState());
    }
  };
  useEffect(() => {
    if (dvLink !== null) {
      navigate("/instantdv/7_GetReport");
    }
  }, [dvLink]);

  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "40px",
        minHeight: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <p>Please wait, we are processing your information...</p>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div style={{ maxWidth: "400px", width: "100%" }}>
            <h2
              onClick={() => navigate("/instantdv/3m_VehicleDetails")}
              style={{
                color: "rgb(192, 44, 122)",
                fontSize: "24px",
                fontWeight: "bold",
                fontFamily: "Orbitron, sans-serif",
                cursor: "pointer",
              }}
            >
              Your Information
            </h2>
            <InputField
              label="NAME *"
              value={ownerName}
              onChange={(e) => dispatch(setOwnerName(e.target.value))}
              placeholder="NAME"
              error={errors.ownerName}
              isRequired={true}
            />
            <InputField
              label="EMAIL *"
              value={ownerEmail}
              onChange={(e) => dispatch(setOwnerEmail(e.target.value))}
              placeholder="EMAIL"
              error={errors.ownerEmail}
              isRequired={true}
              type="email"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                gap: "10px",
              }}
            >
              <InputField
                label="City *"
                value={ownerCity}
                onChange={(e) => dispatch(setOwnerCity(e.target.value))}
                placeholder="City"
                error={errors.ownerCity}
                isRequired={true}
                style={{ width: "48%" }}
              />
              <InputField
                label="ST *"
                value={ownerState}
                onChange={(e) => dispatch(setOwnerState(e.target.value))}
                error={errors.ownerState}
                isRequired={true}
                style={{ width: "18%" }}
                type="select"
              >
                <option value="">ST *</option>
                {US_STATES.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </InputField>
              <InputField
                label="ZIP *"
                value={ownerZip}
                onChange={(e) => dispatch(setOwnerZip(e.target.value))}
                placeholder="ZIP"
                error={errors.ownerZip}
                isRequired={true}
                type="text"
                style={{ width: "28%" }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <button
              onClick={() => {
                navigate("/instantdv/5_ClaimInformation");
              }}
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "rgb(192, 44, 122)",
                fontFamily: "Roboto, sans-serif",
                fontSize: "18px",
                fontWeight: "bold",
                cursor: "pointer",
                marginRight: "10px",
              }}
            >
              &lt; BACK
            </button>
            <button
              onClick={handleNextClick}
              style={{
                backgroundColor: "transparent",
                color: "rgb(192, 44, 122)",
                fontWeight: "bold",
                fontSize: "18px",
                padding: "10px 20px",
                border: "none",
                cursor: "pointer",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              NEXT &gt;
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const _6_OwnerInformation = () => {
  return (
    <div style={{ maxWidth: "1500px", margin: "0 auto" }}>
      <Header
        isGrey={true}
        buttonText="DRIVE AWAY WITH A FAIR COMPENSATION"
        style={{ marginLeft: 0 }}
      />
      <div
        style={{ position: "relative", overflow: "hidden", minHeight: "400px" }}
      >
        <img
          src="/img/diminishedvalue/dv_car_r.png"
          alt="DV Car"
          style={{
            position: "absolute",
            left: "0",
            top: "50%",
            transform: "translateY(-50%)",
            width: "25%",
            height: "auto",
            zIndex: -1,
          }}
        />
        <img
          src="/img/diminishedvalue/dv_car_l.png"
          alt="DV Car"
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translateY(-50%) ",
            width: "25%",
            height: "auto",
            zIndex: -1,
          }}
        />
        <div style={{ position: "relative", zIndex: 1 }}>
          <StepHeader tstep={"Owner Information"} />
          <MainContent />
        </div>
      </div>
      <Footer isGrey={true} />
    </div>
  );
};

export default _6_OwnerInformation;
