import { DataGrid, GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState, useEffect } from "react";
import { API_URI } from "../../../../Globals";
import axios from "axios";
import Notification from "./Notification"; // adjust the import path as needed
import LicensesModal from "./LicensesModal";

function UsersTable({ serial, shop, users, fetchUsers, isRefetchingUsers }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  // Triggering Notification
  const handleNotify = (message, type) => {
    setNotify({ isOpen: true, message, type });
  };

  const handleClick = (event, params) => {
    console.log(params.row);
    setAnchorEl(event.currentTarget);
    setSelectedRow(params.row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLicensesModalOpen, setLicensesModalOpen] = useState(false);

  const updateRole = async (email, role) => {
    try {
      const response = await axios.put(
        API_URI() + "/adminShop/updateUserRole",
        {
          Email: email,
          newRole: role,
        }
      );

      // Handle the response as needed
      console.log(response.data);
      setLoading(true);
      fetchUsers();
      return response.data;
    } catch (error) {
      console.error(
        "Error updating user role:",
        error.response?.data || error.message
      );
      throw error;
    }
  };

  const onDelete = (serial) => {
    console.log(serial);
    axios
      .delete(API_URI() + `/adminShop/users/delete/${serial}`)
      .then((response) => {
        console.log(response.data); // User deleted successfully
        document.location.reload();
        // You might also want to remove the deleted user from your component's state here
      })
      .catch((error) => console.error("Error deleting user:", error));
  };

  const onResetPin = async (rowData) => {
    handleNotify("Sending reset pin email, do not refresh the page", "info");
    try {
      var email = rowData.Email;
      var link =
        "https://www.mycrash.net/dashboard/resetpin?user=" + rowData.Serial;

      const response = await axios.post(API_URI() + "/user/resetpin", {
        email,
        link,
      });

      if (response.status === 200) {
        console.log("Pin reset email sent successfully.");
        handleNotify("Sent pin reset email to " + rowData.Email, "info");
      }
    } catch (error) {
      handleNotify("Failed To send reset email to " + rowData.Email, "info");
      console.error("Error sending pin reset email:", error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Request data:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
      }
    }
  };

  const openLicensesModal = () => {
    setSelectedUser(selectedRow); // assuming you have a state variable for the selected user
    setLicensesModalOpen(true);
  };

  const handleSaveLicenses = (user, enabledProducts) => {
    // Update the user's product access permissions in your database
    // based on the state of enabledProducts
  };

  useEffect(() => {
    setLoading(true);

    fetchUsers();
    const fetchProducts = async () => {
      try {
        const response = await axios.get(API_URI() + "/admin/getAllProducts");
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [serial]); // Re-run the effect if `serial` changes

  //if (loading || isRefetchingUsers) return <div>Loading...</div>;
  //if (error) return <div>Error: {error}</div>;

  const columns: GridColDef[] = [
    { field: "Name", headerName: "Name", width: 150, flex: 1 },
    { field: "Email", headerName: "Email", width: 200, flex: 1 },
    {
      field: "role",
      headerName: "Role",
      width: 200,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.role === "shopAdmin" && <>Admin</>}
          {params.row.role === "shopUser" && <>User</>}
          {params.row.Email === "ADMIN" && <>SuperAdmin</>}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 50,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <>
          <Notification
            open={notify.isOpen}
            onClose={() => setNotify({ ...notify, isOpen: false })}
            message={notify.message}
            severity={notify.type}
          />
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(event) => {
              event.stopPropagation();
              handleClick(event, params);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={selectedRow}
            onClose={handleClose}
          >
            {selectedRow !== null && selectedRow.Name !== "Principal" && (
              <MenuItem
                onClick={() => {
                  onDelete(selectedRow.Serial);
                  handleClose();
                }}
              >
                Delete
              </MenuItem>
            )}
            {selectedRow !== null &&
              (selectedRow.role === "shopUser" ||
                selectedRow.Email === "ADMIN") && (
                <MenuItem
                  onClick={() => {
                    updateRole(selectedRow.Email, "shopAdmin");
                    handleClose();
                  }}
                >
                  Set Role To Admin
                </MenuItem>
              )}
            {selectedRow !== null &&
              selectedRow.role === "shopAdmin" &&
              selectedRow.Name !== "Principal" && (
                <MenuItem
                  onClick={() => {
                    updateRole(selectedRow.Email, "shopUser");
                    handleClose();
                  }}
                >
                  Set Role To User
                </MenuItem>
              )}
            <MenuItem
              onClick={() => {
                onResetPin(selectedRow);
                handleClose();
              }}
            >
              Reset Pin
            </MenuItem>
            <MenuItem
              onClick={() => {
                console.log("Opening Licenses for user:", selectedRow);
                openLicensesModal();
              }}
            >
              Licenses
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={users}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        onRowClick={(rowParams) => console.log("Row clicked", rowParams)}
        getRowId={(row) => row._id} // specifying _id as the unique identifier for each row
      />
      <LicensesModal
        open={isLicensesModalOpen}
        onClose={() => setLicensesModalOpen(false)}
        user={selectedUser}
        products={products}
        shop={shop}
        onSave={handleSaveLicenses}
      />
    </div>
  );
}

export default UsersTable;
