// authReducer.js
const initialState = {
  user: {
    id: 1,
    name: "John Doe",
    email: "john.doe@example.com",
    role: "admin",
  },
  loading: false,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

export default authReducer;
