import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import { GooSpinner } from "react-spinners-kit";
import axios from "axios";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";

import { API_URI } from "../Globals";

const SignupPage = () => {
  const { login, user } = useAuth();
  const [serial, setSerial] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const mainLogoPath = "../../img/dashboard/CrashLogo_Final_NoFooter.png";
  const currentYear = new Date().getFullYear();
  const { isAuthenticated, isVerifying } = useAuth();
  const navigate = useNavigate(); // or useHistory if you are using an older version
  const [isAuthentificating, setIsAuthenticating] = useState(true);
  const [inactiveProducts, setInactiveProducts] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [modalError, setModalError] = useState("");
  const [openSignUpModal, setOpenSignUpModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEmail("");
    setModalError("");
  };

  const handleForgotSerial = async () => {
    setModalLoading(true);
    setModalError("");

    try {
      const response = await axios.post(API_URI() + "/forgotSerial", { email });

      if (response.status === 200) {
        // Success, handle the response as needed
        console.log("Forgot serial request successful");
      } else {
        setModalError(
          "Sorry, we cannot find a CRASH Account with that email. Please contact Kri-Tech Support at Support@Kritech.com or 949-922-0018."
        );
      }
    } catch (error) {
      console.error("Forgot serial request failed", error);
      setModalError(
        "Sorry, we cannot find a CRASH Account with that email. Please contact Kri-Tech Support at Support@Kritech.com or 949-922-0018."
      );
    } finally {
      setModalLoading(false);
    }
  };

  useEffect(() => {
    const currentDomain = window.location.hostname;
    if (currentDomain === "instantdv.com") {
      window.location.href = "https://mycrash.net/instantdv";
    }
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          API_URI() + "/admin/getAllSignupProducts"
        );

        setInactiveProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
    try {
      const currentDomain = window.location.hostname;
      if (isAuthenticated) {
        console.log("User:", user);
        localStorage.setItem("serial", user.serial);
        if (currentDomain === "instantdv.com") {
          navigate("/dashboard/diminishedvalue");
        } else {
          navigate("/dashboard"); // or the path you want to redirect to
        }
      } else {
        setTimeout(() => {
          if (currentDomain === "instantdv.com") {
            window.location.href = "https://mycrash.net/instantdv";
          }
          setIsAuthenticating(false);
        }, 5000);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      window.location.reload();
    }
  }, [isAuthenticated]);

  const handleVerify = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const data = await login(serial); // assuming login returns the axios response
      console.log("Handle Verify", data);

      if (data && data.token) {
        localStorage.setItem("token", data.token);
        document.location.reload();
      }
    } catch (error) {
      console.error("Verification failed", error);
      setError("Verification failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  if (isAuthentificating)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <GooSpinner size={180} color="#686769" loading={true} />
      </div>
    );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: "1 0 auto",
        }}
      >
        <Container component="main">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <img
              src={mainLogoPath}
              alt="main logo"
              style={{ maxWidth: "750px" }}
            />
            <Paper
              elevation={3}
              sx={{ padding: "20px", marginTop: "20px", width: "100%" }}
            >
              <Typography
                component="h1"
                variant="h4"
                sx={{
                  fontSize: "2em",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                My CRASH Network
              </Typography>
              <Typography
                variant="body1"
                color="error"
                paragraph
                sx={{ fontSize: "1.4em" }}
              >
                We could not verify this browser, please enter your Serial
                Number below and press Verify.
              </Typography>
              <Typography
                variant="body1"
                color="error"
                paragraph
                sx={{ fontSize: "1.4em" }}
              >
                If you believe you have activated your product on this computer
                before then please make sure to check other browsers (chrome,
                edge, firefox, etc) you have on your computer to see if your
                account was activated on a different browser before trying to
                activate.
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleVerify}
                sx={{
                  mt: 1,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TextField
                  value={serial}
                  onChange={(e) => setSerial(e.target.value)}
                  margin="normal"
                  required
                  id="serialNumber"
                  label="Enter Serial Number"
                  name="serialNumber"
                  autoComplete="off"
                  autoFocus
                  variant="outlined"
                  sx={{ fontSize: "1.2em", width: "80%" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    width: "80%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={handleVerify}
                    disabled={loading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 3,
                      mb: 2,
                      fontSize: "1.2em",
                      width: "200px",
                      height: "50px",
                    }}
                  >
                    {loading ? "Verifying..." : "Verify"}
                  </Button>
                  <Button
                    onClick={() => setOpenSignUpModal(true)}
                    variant="contained"
                    color="secondary"
                    sx={{
                      mt: 3,
                      mb: 2,
                      fontSize: "1.2em",
                      width: "200px",
                      height: "50px",
                    }}
                  >
                    Sign Up
                  </Button>
                </Box>
                {error && <Typography color="error">{error}</Typography>}
              </Box>

              <Link to="/signupform">
                <Button
                  onClick={handleOpenModal}
                  variant="text"
                  color="secondary"
                >
                  Forgot Serial? Click Here
                </Button>
              </Link>
              {inactiveProducts.length > 0 && (
                <>
                  <h3 className="additional-solutions-title">
                    DISCOVER SOLUTIONS TO POWER YOUR BUSINESS
                  </h3>
                  <div className="product-grid-greyed">
                    {inactiveProducts.map((product, index) => (
                      <button
                        key={index}
                        disabled={!product.ProdDetailUrl}
                        className={`product-button ${
                          !product.ProdDetailUrl ? "inactive" : ""
                        }`}
                        onClick={() => {
                          if (product.ProdDetailUrl) {
                            window.open(product.ProdDetailUrl, "_blank");
                          }
                        }}
                      >
                        <img
                          className={`product-image ${
                            !product.ProdDetailUrl ? "greyed" : ""
                          }`}
                          src={product.ProductActiveImage}
                          alt={product.ProductName}
                        />
                      </button>
                    ))}
                  </div>
                </>
              )}
            </Paper>
          </Box>
        </Container>
      </Box>
      <Box
        component="footer"
        sx={{ textAlign: "center", py: "10px", marginTop: "auto" }}
      >
        <Typography variant="body2" color="textSecondary" sx={{ mx: "auto" }}>
          © {currentYear} KRI-TECH SOLUTIONS LLC
        </Typography>
      </Box>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: 400,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Forgot Serial
          </Typography>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            fullWidth
            label="Email"
            autoComplete="email"
            autoFocus
          />
          {modalLoading ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={handleForgotSerial}
              variant="contained"
              color="primary"
              fullWidth
            >
              Submit
            </Button>
          )}
          {modalError && (
            <Typography color="error" mt={2}>
              {modalError}
            </Typography>
          )}
        </Box>
      </Modal>
      <Modal open={openSignUpModal} onClose={() => setOpenSignUpModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: 400,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Select your Company Type
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 3 }}>
            <Button
              onClick={() => navigate("/signupform?shopType=independent")}
              variant="contained"
              sx={{
                width: "45%",
                height: 180,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <img
                src="/img/single_shop.png"
                alt="Independent Shop"
                style={{ width: 128, height: 128 }}
              />
            </Button>
            <Button
              onClick={() => navigate("/signupform?shopType=mso")}
              variant="contained"
              color="secondary"
              sx={{
                width: "45%",
                height: 180,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <img
                src="/img/multi_shop.png"
                alt="MSO"
                style={{ width: 128, height: 128 }}
              />
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default SignupPage;
