import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal, Box, Typography, Select, MenuItem } from '@mui/material';
import { GooSpinner } from 'react-spinners-kit';
import { useAuth } from '../AuthContext';
import { API_URI } from '../Globals';
import PinModal from './PinModal';
import './DashboardV2.css';
import { styled } from '@mui/material/styles';

const MAIN_LOGO_PATH = '../../img/dashboard/CrashLogo_Final.png';
const FOOTER_LOGO_PATH = '../../img/dashboard/KriTech_PrimaryLogo_Final.png';
const FOOTER_BG_PATH = 'path_to_your_footer_background_image.jpg';

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: 'white',
  fontSize: '16px',
  height: '40px',
  width: '200px',
  '& .MuiSelect-select': {
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 14px',
    textAlign: 'center',
  },
  '& .MuiMenuItem-root': {
    fontSize: '16px',
    justifyContent: 'center',
  },
  '& .MuiSelect-icon': {
    right: '8px',
  },
}));

const DashboardV2 = () => {
  const [products, setProducts] = useState({ active: [], inactive: [] });
  const [dropdownSelection, setDropdownSelection] = useState('');
  const [userlist, setUserlist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalState, setModalState] = useState({ isPinOpen: false, isEstimatorOpen: false });
  const [currentUserSerial, setCurrentUserSerial] = useState(localStorage.getItem('localSerial') || '');

  const navigate = useNavigate();
  const { isAuthenticated, user, login } = useAuth();

  const fetchProducts = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URI()}/admin/getShopActiveProducts/${user.Serial}`);
      const allProducts = response.data;
      setProducts({
        active: allProducts.filter(product => product.IsEnabled === 'true' && product.isOwned),
        inactive: allProducts.filter(product => (product.IsEnabled === 'false' || !product.isOwned) && product.DisplayIfNotOwned)
      });
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }, [user.Serial]);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URI()}/adminShop/users?serial=${user.Serial}`);
      setUserlist(response.data);
      
      // Check if there's a stored selection
      const storedSelection = localStorage.getItem('selectedOption');
      if (storedSelection) {
        setDropdownSelection(storedSelection);
        setCurrentUserSerial(storedSelection);
      } else {
        setDropdownSelection('');
        setCurrentUserSerial('');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      navigate('/');
    }
  }, [user?.Serial, navigate]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
      return;
    }

    const initializeDashboard = async () => {
      setIsLoading(true);
      try {
        await Promise.all([fetchProducts(), fetchUsers()]);
      } catch (error) {
        console.error('Error initializing dashboard:', error);
      } finally {
        setIsLoading(false);
      }
    };

    initializeDashboard();
  }, [isAuthenticated, fetchProducts, fetchUsers, navigate]);

  const handleDropdownChange = (event) => {
    const selectedSerial = event.target.value;
    const selectedUser = userlist.find(user => user.Serial === selectedSerial);

    if (!selectedUser) {
      alert('User not found');
      return;
    }

    if (selectedUser.Pin?.length === 4) {
      setModalState(prev => ({ ...prev, isPinOpen: true }));
      setDropdownSelection(selectedSerial);
      // Don't set localStorage here, wait for PIN verification
    } else {
      alert('User does not have a valid PIN. Please contact an administrator.');
      setDropdownSelection('');
    }
  };

  const handlePinModalSubmit = async (pin) => {
    const selectedUser = userlist.find(user => user.Serial === dropdownSelection);
    console.log(selectedUser);
    if (pin !== selectedUser.Pin) {
      alert('Incorrect PIN');
      setDropdownSelection('');
    } else {
      try {
        const data = await login(dropdownSelection);
        if (data?.token) {
          localStorage.setItem('token', data.token);
          localStorage.setItem('selectedOption', dropdownSelection);
          localStorage.setItem('localSerial', dropdownSelection);
          localStorage.setItem('localPin', selectedUser.Pin);
          setCurrentUserSerial(dropdownSelection);
          navigate('/');
        }
      } catch (error) {
        console.error('Verification failed:', error);
      }
    }
    setModalState(prev => ({ ...prev, isPinOpen: false }));
  };

  const handleButtonClick = (product) => {
   /* if (!dropdownSelection && (user.role != "superAdmin")) {
      setModalState(prev => ({ ...prev, isEstimatorOpen: true }));
      return;
    }*/

    const routes = {
      'Shop Profile': '/dashboard/kritechadmin/',
      'Shop Dashboard': '/dashboard/shopadmin'
    };

    if (routes[product]) {
      navigate(routes[product]);
    } else {
      navigate(`/dashboard/${product.toLowerCase()}`);
    }
  };

  const formatDescription = (description) => description.replace(/__/g, ' - ').replace(/_/g, ' ');

  if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <GooSpinner size={180} color="#686769" loading={true} />
      </div>
    );
  }

  return (
    <div className="dashboard-v2">
      {user.role === 'superAdmin' && (
        <h1 className="admin-title" onClick={() => navigate('/dashboard/shopadmin')}>
          ADMIN HUB &gt;
        </h1>
      )}
      
      <header className="header" />
      
      <main className="main-content">
        <img className="main-logo" src={MAIN_LOGO_PATH} alt="main logo" />
        <h2 className="active-solutions-title">
          YOUR <span className="active">ACTIVE</span> SOLUTIONS
        </h2>
        
        <div className="product-grid">
          {products.active.map((product, index) => (
            <button
              key={index}
              onClick={() => {
                  localStorage.setItem('selectedProduct', JSON.stringify(product));
                  handleButtonClick(product.ProductName);
              
              }}
              disabled={product.UnderMaintenance }
              className={`product-button ${product.UnderMaintenance ? 'maintenance' : ''} ${!dropdownSelection ? 'disabled' : ''}`}
            >
              <div className="product-image-container">
                <img className="product-image" src={product.ProductActiveImage} alt={product.ProductName} />
              </div>
              <div className="product-details">
                <div className="product-name">{formatDescription(product.ProductLabel)}</div>
                <div className="product-transactions">
                  {product.Transactional && product.AvailableTransactions > 0
                    ? `Available Uses: ${product.AvailableTransactions}`
                    : '\u00A0'}
                </div>
              </div>
              {product.UnderMaintenance && (
                <div className="product-maintenance">Under Maintenance</div>
              )}
            </button>
          ))}
        </div>

        {products.inactive.length > 0 && (
          <>
            <h3 className="additional-solutions-title">
              DISCOVER ADDITIONAL SOLUTIONS TO POWER YOUR BUSINESS
            </h3>
            <div className="product-grid-greyed">
              {products.inactive.map((product, index) => (
                <button
                  key={index}
                  disabled={!product.ProdDetailUrl}
                  className={`product-button ${!product.ProdDetailUrl ? 'inactive' : ''}`}
                  onClick={() => product.ProdDetailUrl && window.open(product.ProdDetailUrl, '_blank')}
                >
                  <img
                    className={`product-image ${!product.ProdDetailUrl ? 'greyed' : ''}`}
                    src={product.ProductInactiveImage}
                    alt={product.ProductLabel}
                  />
                </button>
              ))}
            </div>
          </>
        )}
      </main>

      <footer className={user.role === 'superAdmin' ? 'footer-admin' : 'footer'}>
        <div className="footer-logo">
          <img src={FOOTER_LOGO_PATH} alt="footer logo" />
        </div>
        <div className="footer-content" style={{
          marginBottom: ['shopUser', null, undefined, ''].includes(user.role) || 
                        dropdownSelection?.toLowerCase().includes('select estimator') ? '30px' : '0',
          marginTop: ['shopUser', null, undefined, ''].includes(user.role) || 
                     dropdownSelection?.toLowerCase().includes('select estimator') ? '30px' : '0',
        }}>
          {['shopAdmin', 'shopUser'].includes(user.role) && (
            <>
              <img className="bg-image" src={FOOTER_BG_PATH} alt="footer background" />
              <h4 className="estimator-title">CURRENT ESTIMATOR</h4>
             
              <StyledSelect
        className="dropdown"
        value={dropdownSelection}
        onChange={handleDropdownChange}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return <em style={{ color: '#757575' }}>-- Select Estimator --</em>;
          }
          const selectedUser = userlist.find(user => user.Serial === selected);
          return selectedUser ? (selectedUser.Name === 'Principal' ? 'Owner Admin' : selectedUser.Name) : '';
        }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: 'white',
                      '& .MuiMenuItem-root': {
                        fontSize: '16px',
                      },
                    },
                  },
                }}
              >
                {userlist.map((user, index) => (
          <MenuItem key={index} value={user.Serial}>
            {user.Name === 'Principal' ? 'Owner Admin' : user.Name}
          </MenuItem>
        ))}
              </StyledSelect>
            </>
          )}

          {user.role === 'shopAdmin' && dropdownSelection && (
            <div className="footer-buttons">
              <button className="btn-dashboard" onClick={() => handleButtonClick('Shop Dashboard')}>
                Shop Dashboard
              </button>
            </div>
          )}
        </div>
      </footer>

      <PinModal
        open={modalState.isPinOpen}
        onClose={() => setModalState(prev => ({ ...prev, isPinOpen: false }))}
        onSubmit={handlePinModalSubmit}
      />
      
      <Modal
        open={modalState.isEstimatorOpen}
        onClose={() => setModalState(prev => ({ ...prev, isEstimatorOpen: false }))}
        aria-labelledby="estimator-modal-title"
        aria-describedby="estimator-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="estimator-modal-title" variant="h6" component="h2">
            No Estimator Selected
          </Typography>
          <Typography id="estimator-modal-description" sx={{ mt: 2 }}>
            Please select an estimator from the dropdown before proceeding.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default DashboardV2;