import React, { useState, createRef, useEffect } from "react";
import {
  Modal,
  Card,
  CardContent,
  TextField,
  Box,
  Typography,
  Button, // Importing Button for Clear
} from "@mui/material";

const PinInputModal = ({ open, onSubmit, pinLength = 4 }) => {
  const [pin, setPin] = useState(Array(pinLength).fill(""));
  const inputRefs = Array.from({ length: pinLength }).map(() => createRef());

  // Reset the pin inputs when the modal opens
  useEffect(() => {
    if (open) setPin(Array(pinLength).fill(""));
  }, [open, pinLength]);

  const handleChange = (value, index) => {
    if (/[^0-9]/.test(value)) return; // Prevent non-digit input
    pin[index] = value;
    setPin([...pin]);
    if (index < pinLength - 1 && value !== "") {
      inputRefs[index + 1].current.focus(); // Move focus to the next input
    }
    if (value !== "" && index === pinLength - 1) {
      onSubmit(pin.join(""));
    }
  };

  // Clearing input on button click
  const clearInput = () => {
    setPin(Array(pinLength).fill(""));
    inputRefs[0].current.focus();
  };

  return (
    <Modal open={open} aria-labelledby="pin-input-modal">
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          p: 3,
          boxShadow: 3,
        }}
      >
        <CardContent>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            textAlign="center"
            fontWeight="bold"
            mb={3}
          >
            Enter Pin
          </Typography>
          <Box display="flex" justifyContent="center" gap={2}>
            {Array.from({ length: pinLength }).map((_, index) => (
              <TextField
                key={index}
                variant="outlined"
                type="text"
                inputProps={{
                  style: {
                    textAlign: "center",
                    fontSize: "2rem",
                    width: "4rem",
                    height: "4rem",
                  },
                  maxLength: 1,
                }}
                value={pin[index]}
                onChange={(e) => handleChange(e.target.value, index)}
                inputRef={inputRefs[index]}
              />
            ))}
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default PinInputModal;
