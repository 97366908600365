import React from "react";
import ProductButton from "../ProductButton/ProductButton";
import "./ProductGrid.css";

const ProductGrid = ({ products }) => {
  return (
    <div className="product-grid">
      {products.map((product, index) => (
        <ProductButton key={index} product={product} />
      ))}
    </div>
  );
};

export default ProductGrid;
