import React, { useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Box,
  Chip,
  Button,
  Card,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import { styled } from "@mui/system";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import PaymentIcon from "@mui/icons-material/Payment";
import LicenseIcon from "@mui/icons-material/Description";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts, fetchLocalShop } from "../redux/actions";
import { addPaymentMethod, removePaymentMethod } from "../redux/stripeActions";
import CircularProgress from "@mui/material/CircularProgress";

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
  fontWeight: "bold",
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  width: 48,
  height: 48,
  borderRadius: "50%",
  marginBottom: theme.spacing(1),
}));

const ShopDetails = ({ shop }) => {
  const isIndividualShop = shop.ShopLimit === 1;
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.data);
  const loadingLicenses = useSelector((state) => state.licenses.loading);
  const stripeLoading = useSelector((state) => state.stripe.loading);
  const loadingLocalShop = useSelector((state) => state.shops.loadingLocalShop);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  if (loadingLicenses || loadingLocalShop) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const handleUpdatePayment = () => {
    dispatch(addPaymentMethod());
  };

  const handleRemovePayment = async () => {
    await dispatch(removePaymentMethod());
    dispatch(fetchLocalShop());
  };

  const handleAddPayment = () => {
    dispatch(addPaymentMethod());
  };

  return (
    <Box p={4}>
      <Paper elevation={3}>
        <Box p={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <SectionTitle variant="h5">Shop Details</SectionTitle>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Name"
                    secondary={shop.Name}
                    primaryTypographyProps={{
                      variant: "subtitle1",
                      color: "textSecondary",
                    }}
                    secondaryTypographyProps={{ variant: "body1" }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Email"
                    secondary={shop.Email}
                    primaryTypographyProps={{
                      variant: "subtitle1",
                      color: "textSecondary",
                    }}
                    secondaryTypographyProps={{ variant: "body1" }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Phone"
                    secondary={shop.Phone}
                    primaryTypographyProps={{
                      variant: "subtitle1",
                      color: "textSecondary",
                    }}
                    secondaryTypographyProps={{ variant: "body1" }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Company"
                    secondary={shop.Company}
                    primaryTypographyProps={{
                      variant: "subtitle1",
                      color: "textSecondary",
                    }}
                    secondaryTypographyProps={{ variant: "body1" }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Address"
                    secondary={
                      <>
                        {shop.Street1}
                        {shop.Street2 && `, ${shop.Street2}`}
                        <br />
                        {shop.City}, {shop.StateProvince} {shop.PostalCode}
                        <br />
                        {shop.Country}
                      </>
                    }
                    primaryTypographyProps={{
                      variant: "subtitle1",
                      color: "textSecondary",
                    }}
                    secondaryTypographyProps={{ variant: "body1" }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    secondary={
                      <>
                        <Chip
                          label={isIndividualShop ? "Individual Shop" : "MSO"}
                          color={isIndividualShop ? "primary" : "secondary"}
                          sx={{ marginRight: 1 }}
                        />
                      </>
                    }
                    primaryTypographyProps={{
                      variant: "subtitle1",
                      color: "textSecondary",
                    }}
                    secondaryTypographyProps={{ variant: "body1" }}
                  />
                </ListItem>
              </List>
            </Grid>
            {(shop.parentSerial === undefined || !shop.parentSerial) && (
              <Grid item xs={12} md={6}>
                <SectionTitle variant="h5">Payment Details</SectionTitle>
                <Card>
                  <CardContent>
                    <IconWrapper>
                      <PaymentIcon fontSize="large" />
                    </IconWrapper>
                    {stripeLoading ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={2}
                      >
                        <CircularProgress />
                      </Box>
                    ) : shop.StripeDefaultPaymentMethod ? (
                      <Box display="flex" alignItems="center" mt={2}>
                        <Avatar
                          alt={
                            shop.StripeDefaultPaymentMethod.split(",")[0].split(
                              ": "
                            )[1]
                          }
                          src={`/images/${
                            shop.StripeDefaultPaymentMethod.split(",")[0].split(
                              ": "
                            )[1]
                          }.png`}
                          sx={{ width: 48, height: 48, marginRight: 2 }}
                        />
                        <Box>
                          <Typography variant="h6" color="textPrimary">
                            {shop.StripeDefaultPaymentMethod.split(",")[0]
                              .split(": ")[1]
                              .toUpperCase()}
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            **** **** ****{" "}
                            {
                              shop.StripeDefaultPaymentMethod.split(
                                ","
                              )[1].split(": ")[1]
                            }
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Typography variant="body1" color="textSecondary" mt={2}>
                        No payment method available
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions>
                    {stripeLoading ? (
                      <Button variant="contained" color="primary" disabled>
                        Loading...
                      </Button>
                    ) : shop.StripeDefaultPaymentMethod ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleUpdatePayment}
                        >
                          Update Payment Method
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleRemovePayment}
                        >
                          Remove Payment Method
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddPayment}
                      >
                        Add Payment Method
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            )}
            <Grid item xs={12}>
              <SectionTitle variant="h5">Active Licenses</SectionTitle>
              <Grid container spacing={2}>
                {shop !== undefined &&
                  shop.ActiveProducts !== undefined &&
                  Object.entries(shop.ActiveProducts).map(
                    ([productId, product]) => {
                      const productDetails = products.find(
                        (p) => p._id === productId
                      );
                      return (
                        <Grid item xs={12} sm={6} md={4} key={productId}>
                          <Card>
                            <CardContent>
                              <IconWrapper>
                                <img
                                  src={productDetails?.ProductActiveImage}
                                  alt={productDetails?.ProductName}
                                  style={{ width: "100%", height: "auto" }}
                                />
                              </IconWrapper>
                              <List>
                                <ListItem>
                                  <ListItemText
                                    primary="Product Name"
                                    secondary={productDetails?.ProductName}
                                    primaryTypographyProps={{
                                      variant: "subtitle1",
                                      color: "textSecondary",
                                    }}
                                    secondaryTypographyProps={{
                                      variant: "body1",
                                    }}
                                  />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                  <ListItemText
                                    primary="Enabled"
                                    secondary={product.enabled ? "Yes" : "No"}
                                    primaryTypographyProps={{
                                      variant: "subtitle1",
                                      color: "textSecondary",
                                    }}
                                    secondaryTypographyProps={{
                                      variant: "body1",
                                    }}
                                  />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                  <ListItemText
                                    primary="Available Transactions"
                                    secondary={
                                      (shop.ActiveProductsMeta &&
                                        shop.ActiveProductsMeta
                                          .AvailableTransactions &&
                                        shop.ActiveProductsMeta
                                          .AvailableTransactions[productId]) ||
                                      0
                                    }
                                    primaryTypographyProps={{
                                      variant: "subtitle1",
                                      color: "textSecondary",
                                    }}
                                    secondaryTypographyProps={{
                                      variant: "body1",
                                    }}
                                  />
                                </ListItem>
                              </List>
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    }
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default ShopDetails;
