import React from "react";

const Footer = ({ isGrey, style }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      style={{
        textAlign: "center",
        backgroundColor: isGrey ? "#f0f0f0" : "transparent",
        ...style,
        position: "relative",
        zIndex: 0,
        padding: " 0", // Added padding for better spacing
      }}
    >
      <hr style={{ width: "100%", margin: "20px auto" }} />
      <img
        src="/img/diminishedvalue/kritech_logo.png"
        alt="Kritech Logo"
        style={{ width: "200px", marginBottom: "20px" }}
      />
      <div style={{ fontSize: "14px", color: "#555" }}>
        © {currentYear} KRI-TECH SOLUTIONS LLC
      </div>
    </footer>
  );
};

export default Footer;
