import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchShopUsers, deleteUser, resetPin } from "../redux/actions";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  RotateLeft as ResetIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UserForm from "./UserForm";
import ConfirmationDialog from "../common/ConfirmationDialog";

const UserList = ({ serial }) => {
  const dispatch = useDispatch();
  const shopUsers = useSelector((state) => state.shops.users);
  const isResetingPing = useSelector((state) => state.users.resetingPin);
  const isDeletingUser = useSelector((state) => state.users.isDeleting);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUserFormOpen, setIsUserFormOpen] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch(fetchShopUsers(serial));
  }, [dispatch]);

  const columns = [
    {
      accessorKey: "Name",
      header: "Name",
    },
    {
      accessorKey: "Email",
      header: "Email",
    },
    {
      accessorKey: "Serial",
      header: "Serial",
    },
    {
      accessorKey: "role",
      header: "Role",
      Cell: ({ row }) => <Box>{translateRole(row.original.role)}</Box>,
    },
    {
      id: "actions",
      header: "Actions",
      size: 50, // Adjust the size of the actions column
      Cell: ({ row }) => (
        <Box>
          <IconButton onClick={(event) => handleMenuOpen(event, row.original)}>
            <MoreVertIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const translateRole = (role) => {
    if (role === "shopAdmin") return "Shop Admin";
    if (role === "shopUser") return "Shop User";
    if (role === "superAdmin") return "Super Admin";
  };

  const handleMenuOpen = (event, user) => {
    setSelectedUser(user);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setSelectedUser(null);
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setIsUserFormOpen(true);
    handleMenuClose();
  };

  const handleResetPin = async () => {
    console.log("Reseting Pin:", selectedUser.Serial, selectedUser.Email);
    await dispatch(resetPin(selectedUser.Serial, selectedUser.Email));
    handleMenuClose();
  };

  const handleDelete = () => {
    setConfirmationDialog({
      message: `Are you sure you want to delete the user "${selectedUser.Name}"?`,
      onConfirm: async () => {
        setConfirmationDialog(null);
        await dispatch(deleteUser(selectedUser.Serial));
        dispatch(fetchShopUsers(serial));
        handleMenuClose();
      },
    });
  };

  const handleAddNewUser = () => {
    setSelectedUser(null);
    setIsUserFormOpen(true);
  };

  const table = useMaterialReactTable({
    columns,
    data: shopUsers || [],
    enableColumnActions: false,
    enableTopToolbar: true,
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: "pointer",
      },
    }),
    renderTopToolbarCustomActions: () => (
      <Box
        sx={{ display: "flex", gap: "16px", padding: "8px", flexWrap: "wrap" }}
      >
        <Button onClick={handleAddNewUser} startIcon={<AddCircleIcon />}>
          Add New User
        </Button>
      </Box>
    ),
  });

  return (
    <Box sx={{ marginTop: "50px" }}>
      {shopUsers && <MaterialReactTable table={table} />}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem onClick={handleResetPin}>
          <ListItemIcon>
            {isResetingPing && <CircularProgress size={20} />}
            {!isResetingPing && <ResetIcon />}
          </ListItemIcon>

          <ListItemText primary="Reset Pin" />
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            {isDeletingUser && <CircularProgress size={20} />}
            {!isDeletingUser && <DeleteIcon />}
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
      <UserForm
        open={isUserFormOpen}
        onClose={() => setIsUserFormOpen(false)}
        user={selectedUser}
        onSave={(updatedUser) => {
          dispatch(fetchShopUsers());
          setSelectedUser(null);
          setIsUserFormOpen(false);
        }}
        shopSerial={serial}
      />
      <ConfirmationDialog
        open={confirmationDialog !== null}
        title="Confirmation"
        message={confirmationDialog?.message}
        onConfirm={confirmationDialog?.onConfirm}
        onCancel={() => setConfirmationDialog(null)}
      />
    </Box>
  );
};

export default UserList;
