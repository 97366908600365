import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "../redux/actions";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box
} from "@mui/material";
import UserLicensesModal from "./UserLicenseModal";
import {CircularProgress} from "@mui/material";
const UserForm = ({ open, onClose, user, onSave, shopSerial }) => {
  const dispatch = useDispatch();
  const [stage , setStage] = useState(0);
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    role: "",
    ShopSerial: shopSerial,
  });
  const [localUser, setLocalUser] = useState(null);
  const [isLoading , setIsLoading] = useState(false);

  useEffect(() => {
    console.log("User:",user);
    if (user) {
      setFormData({
        Name: user.Name || "",
        Email: user.Email || "",
        role: user.role || "",
        ShopSerial: shopSerial,
      });
      setLocalUser(user);
    }
  }, [user]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (userRef = null) => {
    if(stage === 1)
      {
        setIsLoading(true);
        console.log("Updating user license:",userRef);
        dispatch(updateUser(userRef))
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error("Error updating user:", error);
      });
    }
    else
    {
      const updatedUser = {
        ...localUser,
        ...formData,
      };
      setIsLoading(true);
      let newUser = await dispatch(updateUser(updatedUser,false))
      .catch((error) => {
        console.error("Error updating user:", error);
      });
      setLocalUser(newUser);
      setIsLoading(false);
      console.log("Updated User:", newUser);
      setStage(1);
    }
  };

  return (
    <>
    {stage === 0  &&<Dialog open={open} onClose={onClose} PaperProps={{
            sx: isLoading ? { width: '200px', height: '150px' } : {},
          }}>
      <DialogTitle>Edit User</DialogTitle>
      {isLoading &&  <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress />
            </Box>}
      {!isLoading &&<><DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="Name"
          label="Name"
          fullWidth
          value={formData.Name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="Email"
          label="Email"
          fullWidth
          value={formData.Email}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Role</InputLabel>
          <Select
            name="role"
            value={formData.role}
            onChange={handleChange}
            label="Role"
          >
            <MenuItem value="shopAdmin">Shop Admin</MenuItem>
            <MenuItem value="shopUser">Shop User</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {stage === 0 && <>Next</>}
          {stage ===1 && <>Save</>}
        </Button>
      </DialogActions>
      </>}
      
    </Dialog>}
    {stage === 1 && <UserLicensesModal open={true} onClose={onClose} onSave={handleSubmit} licenses={localUser?.ActiveProducts} shop={localUser}></UserLicensesModal>}
  
    </>
    
  );
};

export default UserForm;
