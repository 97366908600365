import React, { useState } from "react";
import { API_URI } from "../../../../Globals";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import axios from "axios";
import { GooSpinner } from "react-spinners-kit";
import { useNavigate } from "react-router-dom";
function CreateUserModal({ open, onClose, onSave, ShopSerial, ShopCompany }) {
  const [user, setUser] = useState({
    Name: "",
    Email: "",
    ShopSerial,
    Active: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSwitchChange = (e) => {
    setUser((prevState) => ({
      ...prevState,
      Active: e.target.checked,
    }));
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();

    axios
      .post(API_URI() + "/adminShop/createUser", user)
      .then(async (response) => {
        console.log("Success:", response.data);

        const { email, serial } = response.data;

        try {
          var link =
            "https://www.mycrash.net/dashboard/resetpin?user=" + serial;

          const company = localStorage.getItem("Company");

          const response = await axios.post(API_URI() + "/user/resetpin", {
            company,
            email,
            link,
          });

          if (response.status === 200) {
            console.log("Pin reset email sent successfully.");
          }
          setIsLoading(false);
          onClose();
          onSave();
        } catch (error) {}
      })
      .catch((error) => {
        setIsLoading(false);
        onClose();
        onSave();
        console.error("There was an error creating the user:", error);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Create New User</DialogTitle>
      {isLoading === false && (
        <>
          <DialogContent>
            <TextField
              fullWidth
              margin="normal"
              label="Name"
              name="Name"
              value={user.Name}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              name="Email"
              value={user.Email}
              onChange={handleInputChange}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={user.Active}
                  onChange={handleSwitchChange}
                  name="Active"
                  color="primary"
                />
              }
              label="Active"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Save
            </Button>
          </DialogActions>
        </>
      )}
      {isLoading === true && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GooSpinner size={180} color="#686769" loading={true} />
        </div>
      )}
    </Dialog>
  );
}

export default CreateUserModal;
