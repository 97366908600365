// noinspection JSValidateTypes

import React, { Component } from "react";
import { checkLocalLicense } from "../../util/license.extension";
import {
  UploadPdf,
  SubmitJob,
  ValidatePDF,
} from "../../services/autoestimaterekey.service";
import Dropdown from "react-bootstrap/Dropdown";

import "bootstrap/dist/css/bootstrap.min.css";
import "./autoestimaterekey.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Dropzone from "react-dropzone";
import ClipLoader from "react-spinners/ClipLoader";

export default class TotallossPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      config: {},
      isSubmitingWork: false,
      isWorkValid: false,
      isPDFValid: false,
      pdfValidationMsg: "",
      isCheckingPdf: false,
    };

    this.setModalState = this.setModalState.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleSubmitWork = this.handleSubmitWork.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateWork = this.validateWork.bind(this);
    this.onCompleteUpload = this.onCompleteUpload.bind(this);
  }

  componentDidMount() {
    checkLocalLicense();
    let initialConfig = {};
    initialConfig["platform"] = "Select Platform";
    initialConfig["entry"] = "Select Entry Method";
    initialConfig["username"] = "";
    initialConfig["password"] = "";
    initialConfig["RO"] = "NULL";
    initialConfig["pdf"] = null;
    initialConfig["totalloss"] = true;

    this.setState({ config: initialConfig });
  }

  setModalState(state) {
    this.setState({ showModal: state });
  }

  setSelectedItem(key, selection) {
    let _config = this.state.config;
    _config[key] = selection;
    this.setState({ config: _config });

    this.validateWork();
  }

  getSelectedItem(key) {
    let _config = this.state.config;
    return _config[key];
  }

  async handleSubmitWork() {
    let _config = this.state.config;
    this.setState({ isSubmitingWork: true });
    SubmitJob(
      localStorage.getItem("aerk_license"),
      _config,
      this.submitJobComplete
    );
  }

  submitJobComplete() {
    window.location.href = "/dashboard/totalloss/submited/";
  }

  onCompleteUpload(data) {
    let _config = this.state.config;
    _config["pdf"] = data.filename;
    this.setState({ config: _config });

    if (data.filename != null && data.filename.length > 5) {
      ValidatePDF(_config, (validationResponse) => {
        console.log(validationResponse.success);
        console.log(validationResponse.message);

        this.setState({ pdfValidationMsg: validationResponse.message });
        if (validationResponse.success === true) {
          this.setState({ isPDFValid: true });
          this.validateWork();
        } else this.setState({ isPDFValid: false });
      });
    }

    console.log(_config);
  }

  handleInputChange(field, value) {
    this.setSelectedItem(field, value);
  }

  onChangeFileHandler = (acceptedFiles) => {
    this.setState({ isCheckingPdf: true });
    UploadPdf(acceptedFiles[0], (data) => {
      this.setState({ isCheckingPdf: false });
      this.onCompleteUpload(data);
    });

    console.log(acceptedFiles[0]);
  };

  validateWork() {
    let _config = this.state.config;

    if (_config["pdf"] == null) {
      this.setState({ isWorkValid: false });
      return;
    }

    if (
      _config["platform"] !== "Select Platform" &&
      _config["entry"] !== "Select Entry Method" &&
      _config["username"] !== "Username" &&
      _config["username"].length > 0 &&
      _config["password"] !== "Password" &&
      _config["password"].length > 0
    ) {
      if (_config["entry"] === "Into Existing") {
        if (_config["RO"] !== "NULL" && _config["RO"].length > 3) {
          this.setState({ isWorkValid: true });
          return;
        }
      } else {
        this.setState({ isWorkValid: true });
        return;
      }
    }

    this.setState({ isWorkValid: false });
  }

  render() {
    const platforms = ["CCC One", "Mitchell Cloud"];
    const entryMethod = ["New Workfile", "Into Existing"];

    let config = this.state.config;

    let docTypeStyle = {
      color: "green",
    };

    if (this.state.isPDFValid === false)
      docTypeStyle = {
        color: "red",
      };

    return (
      <div className="auth-wrapper">
        <div className={"auth-inner-app-form"}>
          <h3>Total Loss</h3>
          <div className="Aerk-form">
            <p>
              <b>Step 1:</b>
            </p>
            <div className={"container-step"}>
              <div className={"container-step-entry"}>
                Select Platform Going Into
              </div>
              <div className={"container-step-entry"}>
                <Dropdown>
                  <Dropdown.Toggle variant="success">
                    {" "}
                    {this.getSelectedItem("platform")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {platforms.map((item, i) => (
                      <Dropdown.Item
                        key={i}
                        as="button"
                        onClick={() => this.setSelectedItem("platform", item)}
                      >
                        {item}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className={"container-step-entry"}></div>
            </div>
            <p>
              <b>Step 2:</b>
            </p>
            <div className={"container-step"}>
              <div className={"container-step-entry"}>
                Enter Username and Password for Platform Going Into
              </div>
              <div className={"container-step-entry"}>
                <Form.Control
                  type="text"
                  placeholder="Enter Username"
                  onChange={(e) => {
                    this.handleInputChange("username", e.target.value);
                  }}
                />
              </div>

              <div className={"container-step-entry"}>
                <Form.Control
                  type="password"
                  placeholder="Enter Password"
                  onChange={(e) => {
                    this.handleInputChange("password", e.target.value);
                  }}
                />
              </div>
            </div>
            <p>
              <b>Step 3:</b>
            </p>
            <div className={"container-step"}>
              <div className={"container-step-entry"}>Select Entry Method</div>

              <div className={"container-step-entry"}>
                <Dropdown>
                  <Dropdown.Toggle variant="success">
                    {" "}
                    {this.getSelectedItem("entry")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {entryMethod.map((item, i) => (
                      <Dropdown.Item
                        key={i}
                        as="button"
                        onClick={() => this.setSelectedItem("entry", item)}
                      >
                        {item}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className={"container-step-entry"}></div>
            </div>
            {config["entry"] === entryMethod[1] && (
              <>
                <p>
                  <b>Step 4:</b>
                </p>
                <div className={"container-step"}>
                  <div className={"container-step-entry"}>
                    Enter Claim or RO #
                  </div>

                  <div className={"container-step-entry"}>
                    <Form.Control
                      type="text"
                      placeholder="Claim RO #"
                      onChange={(e) => {
                        this.handleInputChange("RO", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <>
              {config["entry"] === entryMethod[1] && (
                <p>
                  <b>Step 5:</b>
                </p>
              )}
              {config["entry"] !== entryMethod[1] && (
                <p>
                  <b>Step 4:</b>
                </p>
              )}
              <div className={"container-step"}>
                <div className={"container-step-entry"}>
                  Upload estimate PDF
                </div>
                <div className={"container-step-entry"}>
                  <Dropzone onDrop={this.onChangeFileHandler}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p>
                            Drag 'n' drop some files here, or click to select
                            files
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                <div style={docTypeStyle} className={"container-step-entry"}>
                  <ClipLoader
                    loading={this.state.isCheckingPdf}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  {this.state.pdfValidationMsg}
                </div>
              </div>
            </>
            <>
              {config["entry"] === entryMethod[1] && (
                <p>
                  <b>Step 6:</b>
                </p>
              )}
              {config["entry"] !== entryMethod[1] && (
                <p>
                  <b>Step 5:</b>
                </p>
              )}
              <div className={"container-step"}>
                <div className={"container-step-entry"}>
                  <Button
                    disabled={
                      this.state.isPDFValid === false ||
                      this.state.isSubmitingWork ||
                      this.state.isWorkValid === false
                    }
                    onClick={() => {
                      this.handleSubmitWork().then(() => {});
                    }}
                    variant="success"
                  >
                    Start Processing
                  </Button>{" "}
                  <Button
                    disabled={this.state.isSubmitingWork}
                    onClick={() => {
                      window.location.href = "/dashboard/";
                    }}
                    variant="danger"
                  >
                    Cancel
                  </Button>{" "}
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    );
  }
}
