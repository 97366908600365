import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputLabel,
  FormControl,
} from "@mui/material";
import "@fontsource/orbitron";
import "@fontsource/lato";
import {
  UploadPdf,
  SubmitJob,
  ValidatePDF,
} from "../../services/autoestimaterekey.service";
import ClipLoader from "react-spinners/ClipLoader";
import {
  GetClient,
  GetClientAsync,
  UpdateProfile,
} from "../../services/license.service";

const RekeyClassic = () => {
  const [config, setConfig] = useState({
    platform: "Select Platform",
    entry: "Select Entry Method",
    username: "",
    password: "",
    RO: "NULL",
    pdf: null,
    totalloss: false,
  });

  const [platform, setPlatform] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [entryMethod, setEntryMethod] = useState("");
  const [isCheckingPdf, setIsCheckingPdf] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [isSubmittingWork, setIsSubmittingWork] = useState(false);
  const [isWorkValid, setIsWorkValid] = useState(false);
  const [isPDFValid, setIsPDFValid] = useState(false);
  const [pdfValidationMsg, setPdfValidationMsg] = useState(null);
  const [roNumber, setRoNumber] = useState(""); // State to store RO number

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedFileName(file.filename);
      setIsCheckingPdf(true);
      UploadPdf(file, (data) => {
        onCompleteUpload(data);
      });
    }
  };

  useEffect(() => {
    setConfig({
      platform: "Select Platform",
      entry: "Select Entry Method",
      username: "",
      password: "",
      RO: "NULL",
      pdf: null,
      totalloss: false,
    });
  }, []);

  const validateWork = () => {
    console.log("Validating work");
    let _config = config;

    if (_config["pdf"] == null) {
      console.log("Pdf is null work invalid");
      isWorkValid(false);
      return;
    }

    _config["platform"] = platform;
    _config["username"] = username;
    _config["password"] = password;
    _config["entry"] = entryMethod;

    if (
      _config["platform"] !== "Select Platform" &&
      _config["entry"] !== "Select Entry Method" &&
      _config["username"] !== "Username" &&
      _config["username"].length > 0 &&
      _config["password"] !== "Password" &&
      _config["password"].length > 0
    ) {
      if (_config["entry"] === "Into Existing") {
        if (_config["RO"] !== "NULL" && _config["RO"].length > 3) {
          setIsWorkValid(true);
          return;
        }
      } else {
        setIsWorkValid(true);
        return;
      }
    }

    console.log("End function, pdf invalid");
    setIsWorkValid(false);
  };

  let docTypeStyle = {
    color: "green",
  };

  const submitJobComplete = () => {
    window.location.href = "/dashboard/autorekey/submited/";
  };

  const setModalState = (state) => {};

  const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === "[object Object]";
  };

  const handleSubmitWork = async () => {
    try {
      const data = await GetClientAsync();
      console.log("Customer Response Data:", data);
      if (data) {
        var clientObject = data.rsp.Customer;

        let _config = config;
        _config["backendengine"] = "classic_rekey";
        _config["Phone"] =
          isObject(clientObject.Phone) === true ? null : clientObject.Phone;
        _config["Addr1"] = clientObject.Street1;
        _config["Addr2"] =
          isObject(clientObject.Street2) === true ? "" : clientObject.Street2;
        _config["Country"] = clientObject.Country;
        _config["Company"] =
          isObject(clientObject.Company) === true
            ? clientObject.Company.CCCName
            : null;

        if (_config["Company"] != null && _config["Company"].length < 5) {
          _config["Company"] = null;
        }

        setConfig(_config);
        localStorage.setItem("aerk_company", _config["Company"]);

        const submitJobT = () => {
          SubmitJob(
            localStorage.getItem("aerk_license"),
            _config,
            submitJobComplete
          );
        };

        setModalState(true);
        submitJobT();
      }
    } catch (error) {
      console.error("Error in handleSubmitWork:", error);
    }
  };

  const onCompleteUpload = (data) => {
    let _config = config;
    _config["pdf"] = data.filename;
    console.log("Uploaded pdf name:", data.filename);
    setConfig(_config);

    if (data.filename != null && data.filename.length > 5) {
      ValidatePDF(_config, (validationResponse) => {
        console.log(validationResponse.success);
        console.log(validationResponse.message);

        setUploadedFileName(
          "File: " + data.filename + " , Platform:" + validationResponse.message
        );

        setPdfValidationMsg(validationResponse.message);
        if (validationResponse.success === true) {
          setIsPDFValid(true);
          validateWork();
        } else setIsPDFValid(false);

        setIsCheckingPdf(false);
      });
    }

    console.log(_config);
  };

  return (
    <Container maxWidth="xl">
      {/* Gap */}
      <Box mt={4} mb={4}>
        {/* Logo resized to half its size */}
        <img
          src="../../img/rekey_logo.png"
          alt="Logo"
          style={{ width: "50%", height: "auto", marginTop: "10vh" }} // Resize to half its width and let height adjust automatically
        />
      </Box>

      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "2em",
          padding: "2em",
          fontFamily: "Orbitron, sans-serif",
        }}
      >
        <Box marginLeft={"10%"} sx={{ marginTop: 0 }}>
          <Typography
            variant="h5"
            gutterBottom
            align="left"
            sx={{
              color: "black",
              fontWeight: "bold",
              fontFamily: "Orbitron, sans-serif",
            }}
          >
            Step 1:
          </Typography>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6} sm={1.8}>
              <Typography
                align="left"
                variant="subtitle1"
                sx={{ color: "black", fontFamily: "Orbitron, sans-serif" }}
              >
                Select Platform Going Into
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Select
                value={platform}
                onChange={(e) => setPlatform(e.target.value)}
                sx={{
                  backgroundColor: "white",
                  width: "50%",
                  height: "80%",
                  fontFamily: "Orbitron, sans-serif",
                }}
              >
                <MenuItem value="CccOne">CccOne</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>

        <Box marginLeft={"10%"} sx={{ marginTop: 4 }}>
          <Typography
            variant="h5"
            gutterBottom
            align="left"
            sx={{
              color: "black",
              fontWeight: "bold",
              fontFamily: "Orbitron, sans-serif",
            }}
          >
            Step 2:
          </Typography>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6} sm={1.8}>
              <Typography
                align="left"
                variant="subtitle1"
                sx={{ color: "black", fontFamily: "Orbitron, sans-serif" }}
              >
                Enter Username and Password for Platform going Into
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    fullWidth
                    label="Username"
                    sx={{
                      backgroundColor: "white",
                      fontFamily: "Orbitron, sans-serif",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    label="Password"
                    type="password"
                    sx={{
                      backgroundColor: "white",
                      fontFamily: "Orbitron, sans-serif",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* Step 3: Dropdown "Select Entry Method" */}
        <Box marginLeft={"10%"} sx={{ marginTop: 4 }}>
          <Typography
            variant="h5"
            gutterBottom
            align="left"
            sx={{
              color: "black",
              fontWeight: "bold",
              fontFamily: "Orbitron, sans-serif",
            }}
          >
            Step 3:
          </Typography>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6} sm={1.8}>
              <Typography
                align="left"
                variant="subtitle1"
                sx={{ color: "black", fontFamily: "Orbitron, sans-serif" }}
              >
                Select Entry Method
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormControl fullWidth sx={{ width: "50%" }}>
                <Select
                  value={entryMethod}
                  onChange={(e) => setEntryMethod(e.target.value)}
                  sx={{
                    backgroundColor: "white",
                    fontFamily: "Orbitron, sans-serif",
                  }}
                >
                  <MenuItem value="IntoNew">Into New</MenuItem>
                  <MenuItem value="IntoExisting">Into Existing</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {entryMethod === "IntoExisting" && (
          <Box marginLeft={"10%"} sx={{ marginTop: 4 }}>
            <Typography
              variant="h5"
              gutterBottom
              align="left"
              sx={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              Step 4:
            </Typography>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={6} sm={1.8}>
                <Typography
                  align="left"
                  variant="subtitle1"
                  sx={{ color: "black", fontFamily: "Orbitron, sans-serif" }}
                >
                  Please Insert RO Number
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  value={roNumber}
                  onChange={(e) => setRoNumber(e.target.value)}
                  fullWidth
                  label="RO Number"
                  sx={{
                    backgroundColor: "white",
                    fontFamily: "Orbitron, sans-serif",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}

        {/* Step 4: Upload Estimate PDF */}
        <Box marginLeft={"10%"} sx={{ marginTop: 4 }}>
          <Typography
            variant="h5"
            gutterBottom
            align="left"
            sx={{
              color: "black",
              fontWeight: "bold",
              fontFamily: "Orbitron, sans-serif",
            }}
          >
            Step {entryMethod === "IntoExisting" ? 5 : 4}:
          </Typography>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6} sm={1.8}>
              <Typography
                align="left"
                variant="subtitle1"
                sx={{ color: "black", fontFamily: "Orbitron, sans-serif" }}
              >
                Upload Estimate PDF
              </Typography>
              {pdfValidationMsg !== null && (
                <div className={"green-text"}>Successfully</div>
              )}
              <div style={docTypeStyle} className={"container-step-entry"}>
                <ClipLoader
                  loading={isCheckingPdf}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
              <div hidden>format</div>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormControl fullWidth sx={{ width: "50%" }}>
                <input
                  type="file"
                  accept=".pdf"
                  id="upload-pdf"
                  onChange={handleFileChange}
                  style={{
                    display: "none",
                    border: "1px solid #000",
                    padding: "10px",
                  }}
                />
                <label htmlFor="upload-pdf" style={{ width: "100%" }}>
                  <Box
                    sx={{
                      border: "1px solid black",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {uploadedFileName || "Select PDF"}
                  </Box>
                </label>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {/* Step 5: Start Processing and Cancel buttons */}
        <Box marginLeft={"10%"} sx={{ marginTop: 4, marginBottom: 4 }}>
          <Typography
            variant="h5"
            gutterBottom
            align="left"
            sx={{
              color: "black",
              fontWeight: "bold",
              fontFamily: "Orbitron, sans-serif",
            }}
          >
            Step {entryMethod === "IntoExisting" ? 6 : 5}:
          </Typography>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6}>
              <Button
                disabled={
                  isPDFValid === false ||
                  isSubmittingWork === true ||
                  isWorkValid === false
                }
                onClick={() => {
                  handleSubmitWork().then(() => {});
                }}
                variant="contained"
                color="primary"
                fullWidth
              >
                Start Processing
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={isSubmittingWork}
                onClick={() => {
                  window.location.href = "/dashboard/";
                }}
                variant="contained"
                color="secondary"
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default RekeyClassic;
