import React, { useState } from "react";
import Select from "react-select";

const SubmodelDropdown = ({
  submodel,
  setSubmodel,
  trims,
  customStyles,
  handleSubmodelChange,
  error,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const isLabelVisible = isFocused || submodel;

  const updatedCustomStyles = {
    ...customStyles,
    control: (provided) => ({
      ...provided,
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
      backgroundColor: "rgba(111, 111, 111, 0.039)",
    }),
    option: (provided) => ({
      ...provided,
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
    }),
  };

  return (
    <div
      style={{
        marginTop: "20px",
        position: "relative",
        backgroundColor: "#f9f9f9",
      }}
    >
      {isLabelVisible && submodel !== "SUBMODEL/TRIM *" && (
        <label
          style={{
            position: "absolute",
            top: "-10px",
            left: "10px",
            fontSize: "12px",
            fontWeight: "bold",
            fontFamily: "Roboto, sans-serif",
            backgroundColor: "white",
            padding: "0 5px",
            color: "black",
            transition: "0.2s ease-in-out",
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
        >
          SUBMODEL/TRIM *
        </label>
      )}
      <Select
        options={trims.map((trim) => ({ value: trim, label: trim }))}
        value={{ value: submodel, label: submodel }}
        onChange={handleSubmodelChange}
        styles={updatedCustomStyles}
        placeholder="SUBMODEL/TRIM *"
        onBlur={handleBlur}
      />
      {error && (
        <div
          style={{
            color: "red",
            fontSize: "12px",
            textAlign: "left",
            marginTop: "5px",
          }}
        >
          {error}
        </div>
      )}
    </div>
  );
};

export default SubmodelDropdown;
