import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSteps,
  setCurrentStep,
  addCompletedStep,
  setDvLink, // Ensure this action is defined and imported correctly
  
} from "../../../redux/diminishedvalue/actions";

const StepHeader = ({ tstep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { currentStep, completedSteps } = useSelector(
    (state) => state.diminishedValue
  );

  const steps = [
    {
      hidden: true,
      label: "Start Reporting",
      link: "/instantdv/2_StartReport",
    },
    {
      label: "Vehicle Information",
      link: "/instantdv/3m_VehicleDetails",
    },
    { label: "Damage Report", link: "/instantdv/4m_VehicleDamage" },
    {
      label: "Claim Information",
      link: "/instantdv/5_ClaimInformation",
    },
    {
      label: "Owner Information",
      link: "/instantdv/6_OwnerInformation",
    },
    { label: "Get Report", link: "/instantdv/7_GetReport" },
  ];

  useEffect(() => {
    console.log("tstep", tstep);

    const path = location.pathname;
    const pathParts = path.split("_");
    const stepNumber = parseInt(pathParts[0].match(/\d+/)[0], 10);

    // Check if the current step is valid
    const validStep = steps.some((step) => step.label === tstep);
    const currentStepIndex = steps.findIndex((step) => step.label === tstep);
    const previousStepIndex = currentStepIndex - 1;

    // Handle case where "Damage Report" can be missing
    const previousStep =
      previousStepIndex >= 0
        ? steps[previousStepIndex].label === "Damage Report"
          ? previousStepIndex - 1 >= 0
            ? steps[previousStepIndex - 1].label
            : null
          : steps[previousStepIndex].label
        : null;

    if (previousStep === null && stepNumber > 2) {
      navigate("/instantdv/2_StartReport"); // Redirect to the first step
      return;
    }

    if(stepNumber < 5)
      dispatch(setDvLink(null));

    console.log(stepNumber);
    console.log(previousStep);
    if (
      !validStep ||
      (previousStep &&
        previousStep !== "Vehicle Information" &&
        !completedSteps.includes(previousStep))
    ) {
      if (stepNumber > 2) {
        dispatch(resetSteps());
        navigate("/instantdv/2_StartReport"); // Redirect to the first step
        return;
      }
    }

    if (!completedSteps.includes(tstep)) {
      console.log("Adding completed step:", tstep);
      dispatch(addCompletedStep(tstep));
    }

    if (currentStep !== tstep) {
      console.log("Setting current step:", tstep);
      dispatch(setCurrentStep(tstep));
    }
  }, [tstep, dispatch, completedSteps, currentStep]);

  const handleStepClick = (step) => {
    if (completedSteps.includes(step.label)) {
      if (step.label === "Owner Information") {
        dispatch(setDvLink(null));
      }

      dispatch(setCurrentStep(step.label));
      navigate(step.link);
    }
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      {steps.map(
        (step, index) =>
          !step.hidden && (
            <React.Fragment key={index}>
              <button
                onClick={() => handleStepClick(step)}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color:
                    tstep === step.label
                      ? "rgb(192, 44, 122)"
                      : completedSteps.includes(step.label)
                      ? "#000000"
                      : "#a0a0a0",
                  fontFamily:
                    tstep === step.label
                      ? "Orbitron, sans-serif"
                      : "Roboto, sans-serif",
                  fontSize: tstep === step.label ? "18px" : "16px",
                  fontWeight: tstep === step.label ? "bold" : "normal",
                  cursor: completedSteps.includes(step.label)
                    ? "pointer"
                    : "not-allowed",
                  margin: "0 10px",
                }}
                disabled={!completedSteps.includes(step.label)}
              >
                {step.label}
              </button>
              {index < steps.length - 1 && (
                <span style={{ color: "#a0a0a0", fontSize: "18px" }}> | </span>
              )}
            </React.Fragment>
          )
      )}
    </div>
  );
};

export default StepHeader;
