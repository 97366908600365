import React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ReactJson from "react-json-view";

const PayloadModal = ({ open, handleClose, payload }) => {
  let data;
  let isError = false;

  try {
    data = payload;
  } catch (error) {
    console.log("ERRNO_JSON:", error);
    isError = true;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper
        style={{
          position: "absolute",
          width: "80%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "20px",
        }}
      >
        <ReactJson src={data} />
      </Paper>
    </Modal>
  );
};

export default PayloadModal;
