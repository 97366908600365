import React, { useState, useEffect, useMemo } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PrintIcon from "@mui/icons-material/Print";
import {
  MRT_ToggleDensePaddingButton,
  MRT_FullScreenToggleButton,
  MRT_ToggleRowActionMenuButton,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFiltersButton,
  MRT_ShowHideColumnsButton,
} from "material-react-table";
import {
  AppBar,
  Toolbar,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Paper,
  IconButton,
  Tooltip,
  Grid,
  Button,
  Box,
  TextField,
} from "@mui/material";

import { useAuth } from "../../../../AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URI } from "../../../../Globals";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FlowsSidebar from "./FlowsSidebar";
import FlowEditor from "./FlowEditor";

const queryClient = new QueryClient();

const FlowsPage = () => {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();

  const [currentFlow, setCurrentFlow] = useState(null);
  const [treeData, setTreeData] = useState([]);

  const fetchTreeData = async () => {
    try {
      const response = await axios.get(`${API_URI()}/admin/getTree`);
      const fetchedTreeData = response.data.treeData || [];
      setTreeData(fetchedTreeData);
    } catch (error) {
      console.error("Error fetching tree data:", error);
    }
  };

  useEffect(() => {
    fetchTreeData();
  }, []);

  const handleSelectFlow = (flow) => {
    console.log("Selected flow:", flow);
    setCurrentFlow((prev) => ({ ...prev, ...flow }));
  };

  const updateFlowRecursive = (items, updatedFlow) => {
    return items.map((item) => {
      if (item.id === updatedFlow.id && item.type === "flow") {
        return {
          ...item,
          data: updatedFlow.data, // Update the flow's data
        };
      }
      if (item.children) {
        return {
          ...item,
          children: updateFlowRecursive(item.children, updatedFlow), // Recurse into children
        };
      }
      return item; // Return item unmodified if it's not the updated flow
    });
  };

  const handleSaveFlow = (updatedFlow) => {
    console.log("Updated flow:", updatedFlow);

    // Traverse the treeData to find and update the flow with the corresponding ID
    const updatedTreeData = updateFlowRecursive(treeData, updatedFlow);

    console.log("Updated treeData:", updatedTreeData);
    setTreeData(updatedTreeData);
    setCurrentFlow(null); // Clear the current flow after saving
  };

  const handleSaveTree = async () => {
    try {
      const response = await axios.patch(`${API_URI()}/admin/saveTree`, {
        treeData,
      });
      console.log("Tree saved successfully:", response.data);
    } catch (error) {
      console.error("Error saving tree:", error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    } else {
      console.log("UserRole:", user.role);
    }
  }, [isAuthenticated, navigate]);

  const exitAdminDashboard = () => {
    if (user.role === "superAdmin") {
      navigate("/dashboard/admin/");
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <AppBar position="static">
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Button variant="contained" color="primary" onClick={handleSaveTree}>
            Save Tree
          </Button>
          <Typography variant="h6" style={{ flexGrow: 1, textAlign: "center" }}>
            Flows
          </Typography>
          <Button
            color="inherit"
            onClick={() => exitAdminDashboard()}
            startIcon={<ExitToAppIcon />}
          >
            Exit
          </Button>
        </Toolbar>
      </AppBar>
      <div style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
        <div
          style={{
            width: "450px",
            borderRight: "1px solid gray",
            overflowY: "auto",
          }}
        >
          <FlowsSidebar
            treeData={treeData}
            setTreeData={setTreeData}
            onSelectFlow={handleSelectFlow}
          />
        </div>
        <div style={{ flexGrow: 8, overflow: "auto" }}>
          {currentFlow && (
            <FlowEditor flow={currentFlow} onSave={handleSaveFlow} />
          )}
        </div>
      </div>
    </div>
  );
};

export default FlowsPage;
