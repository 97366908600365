// shopsReducer.js
const initialState = {
  shops: [],
  loading: false,
  loadingLocalShop: false,
  loadingUsers: false,
  sendingInvite: false,
  error: null,
  totalCount: 0,
  selectedShopLicenses: [],
  localShop: {},
  localUser: {},
  users: [],
  childShopInfo: {},
  activeShopSerial: null,
};

const shopsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACTIVE_SHOP_SERIAL":
      return {
        ...state,
        error: null,
        activeShopSerial: action.payload,
      };
    case "INVITE_CRASH_REQUEST":
      return {
        ...state,
        error: null,
        sendingInvite: true,
      };
    case "INVITE_CRASH_SUCCESS":
      return {
        ...state,
        sendingInvite: false,
        error: null,
      };
    case "INVITE_CRASH_FAILURE":
      return {
        ...state,
        sendingInvite: false,
        error: null,
      };
    case "STORE_USER_REQUEST":
      return {
        ...state,
        error: null,
        localUser: action.payload,
      };
    case "DELETE_SHOP_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "DELETEL_SHOP_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
      };
    case "DELETEL_SHOP_FAILURE":
      return {
        ...state,
        loading: false,
        error: null,
      };
    case "FETCH_SHOPS_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "FETCH_SHOPS_SUCCESS":
      return {
        ...state,
        shops: action.payload.data, // Extract the 'data' property from the payload
        totalCount: action.payload.totalCount,
        loading: false,
        error: null,
      };
    case "FETCH_SHOPS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CREATE_SHOP_SUCCESS":
      return {
        ...state,
        shops: [...state.shops, action.payload],
        loading: false,
        error: null,
        totalCount: state.totalCount + 1,
      };
    case "FETCH_SHOP_LICENSES_SUCCESS":
      return {
        ...state,
        selectedShopLicenses: action.payload,
        loading: false,
        error: null,
      };
    case "FETCH_LOCAL_SHOP_SUCCESS":
      return {
        ...state,
        loadingLocalShop: false,
        error: null,
        localShop: action.payload,
      };
    case "FETCH_LOCAL_SHOP_REQUEST":
      return {
        ...state,
        selectedShopLicenses: [],
        loadingLocalShop: true,
        error: null,
      };
    case "FETCH_SHOP_USERS_SUCCESS":
      return {
        ...state,
        loadingUsers: false,
        error: null,
        users: action.payload,
      };
    case "FETCH_SHOP_USERS_REQUEST":
      return {
        ...state,
        loadingUsers: true,
        error: null,
        users: null,
      };
    case "UPDATE_SHOP_LICENSES_SUCCESS":
      return {
        ...state,
        shops: state.shops.map((shop) =>
          shop.Serial === action.payload.Serial ? action.payload : shop
        ),
        loading: false,
        error: null,
      };
    case "FETCH_CHILD_SHOP_INFO_SUCCESS":
      return {
        ...state,
        childShopInfo: {
          ...state.childShopInfo,
          [action.payload.shopId]: action.payload.childShopInfo,
        },
      };
    default:
      return state;
  }
};

export default shopsReducer;
