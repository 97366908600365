import { GetClientLicenses } from "../services/license.service";
export const checkLocalLicense = async () => {};

export const getClientId = () => {
  return localStorage.getItem("customerId");
};

export const CheckLicense = async (customerId) => {
  await GetClientLicenses(
    customerId,
    async (dataType, data) => {
      console.log("Activating license check");

      let isAdmin = localStorage.getItem("isAdmin");
      let hasAerk = localStorage.getItem("hasAerk");
      let hasTotalLoss = localStorage.getItem("hasTotalLoss");
      let hasEAudit = localStorage.getItem("hasEAudit");

      let needReload = false;

      let jsonObj = data;

      try {
        jsonObj.rsp.Licenses.License.map(async (o) => {
          console.log("License:", o);
          //Check for each license
          if (o.Properties.HUBActivations !== null) {
            console.log("LicenseTemplateFound:", o.LicenseTemplateId);
            if (
              o.LicenseTemplateId === "98660e20-1d8e-4ccd-ad74-153ad909dd1a"
            ) {
              localStorage.setItem("isAdmin", true);
              if (isAdmin === "false") needReload = true;
              //Check for admin propreties
              localStorage.setItem("isOwner", o.Properties.Owner);
              localStorage.setItem("isSalesperson", o.Properties.Salesperson);
              localStorage.setItem("isReader", o.Properties.Reader);
            }
            if (
              o.LicenseTemplateId === "b5677d9e-4a44-4ab2-8b09-e102ea0d7bcf" ||
              o.LicenseTemplateId === "c2300b9d-0f0c-4dbb-abd7-b625ec3c6ba6" ||
              o.LicenseTemplateId === "3814474b-3678-4122-b022-a74c92b5d250" ||
              o.LicenseTemplateId === "33b9afab-326e-48cc-baab-ce9b3433bb84"
            ) {
              localStorage.setItem("hasAerk", true);
              if (hasAerk === "false") needReload = true;
            }
            if (
              o.LicenseTemplateId === "b4060d47-7eee-48e2-8269-64a3bb5ffa51"
            ) {
              localStorage.setItem("Customer", o.Properties.Customer);
              localStorage.setItem("Appraiser", o.Properties.Appraiser);

              localStorage.setItem("hasTotalLoss", true);
              if (hasTotalLoss === "false") needReload = true;
            }

            if (
              o.LicenseTemplateId === "04fd30bf-375f-4869-abb0-63b771b682df"
            ) {
              localStorage.setItem("hasEAudit", true);
              localStorage.setItem(
                "hasEAuditSerial",
                o.Properties.SerialNumber
              ); //ProfileLocked
              localStorage.setItem("hasEAuditLock", o.Properties.ProfileLocked); //ProfileLocked
              localStorage.setItem("hasEAuditSerial", o.SerialNumber);
              console.log("eaudit");
              console.log("SerialNo:", o.SerialNumber);
              console.log("Lock:", o.Properties.ProfileLocked);
              if (hasEAudit === "false") needReload = true;
            }
          }

          if (needReload) window.location.reload();
        });
      } catch {
        var o = jsonObj.rsp.Licenses.License;
        if (o.Properties.HUBActivations !== null) {
          console.log("LicenseTemplateFound:", o.LicenseTemplateId);
          if (o.LicenseTemplateId === "98660e20-1d8e-4ccd-ad74-153ad909dd1a") {
            localStorage.setItem("isAdmin", true);
            if (isAdmin === "false") needReload = true;
            //Check for admin propreties
            localStorage.setItem("isOwner", o.Properties.Owner);
            localStorage.setItem("isSalesperson", o.Properties.Salesperson);
            localStorage.setItem("isReader", o.Properties.Reader);
          }
          if (
            o.LicenseTemplateId === "b5677d9e-4a44-4ab2-8b09-e102ea0d7bcf" ||
            o.LicenseTemplateId === "c2300b9d-0f0c-4dbb-abd7-b625ec3c6ba6" ||
            o.LicenseTemplateId === "3814474b-3678-4122-b022-a74c92b5d250" ||
            o.LicenseTemplateId === "33b9afab-326e-48cc-baab-ce9b3433bb84"
          ) {
            localStorage.setItem("hasAerk", true);
            if (hasAerk === "false") needReload = true;
          }
          if (o.LicenseTemplateId === "b4060d47-7eee-48e2-8269-64a3bb5ffa51") {
            localStorage.setItem("Customer", o.Properties.Customer);
            localStorage.setItem("Appraiser", o.Properties.Appraiser);

            localStorage.setItem("hasTotalLoss", true);
            if (hasTotalLoss === "false") needReload = true;
          }

          if (o.LicenseTemplateId === "04fd30bf-375f-4869-abb0-63b771b682df") {
            localStorage.setItem("hasEAudit", true);
            if (hasEAudit === "false") needReload = true;
          }
        }

        if (needReload) window.location.reload();
      }
    },
    true
  );
};

export const isLicenseValid = (licenseXMLDoc, licenseDescriptionXMLDoc) => {
  if (licenseXMLDoc == null) return false;

  for (let i = 0; i < licenseXMLDoc.children.length; i++) {
    for (let j = 0; j < licenseXMLDoc.children[i].children.length; j++) {
      if (
        licenseXMLDoc.children[i].children[j].name === "LicenseStatus" &&
        licenseXMLDoc.children[i].children[j].value !== "Active"
      )
        return false;

      if (licenseXMLDoc.children[i].children[j].name === "Properties") {
        for (
          let k = 0;
          k < licenseXMLDoc.children[i].children[j].children.length;
          k++
        ) {
          if (
            licenseXMLDoc.children[i].children[j].children[k].name ===
            "HUBActivations"
          ) {
            let limitsXml =
              licenseDescriptionXMLDoc.children[i].getElementsByTagName(
                "Limit"
              );

            for (let l = 0; l < limitsXml.length; l++) {
              let endDate = limitsXml[l].attributes.endDate;
              if (endDate != null) {
                if (Date.now() < Date.parse(endDate)) {
                  return true;
                }
              }
            }
          }
        }
      }
    }
  }

  return false;
};
