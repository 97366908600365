import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Avatar,
} from "@mui/material";
import axios from "axios";
import { API_URI } from "../../../../Globals";

const EditProductModal = ({ product, open, onClose, onSave }) => {
  const [updatedProduct, setUpdatedProduct] = useState(product);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setUpdatedProduct((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedProductString = {
        ...updatedProduct,
        IsEnabled: updatedProduct.IsEnabled.toString(),
        UnderMaintenance: updatedProduct.UnderMaintenance,
        SalesforceProductId: updatedProduct.SalesforceProductId.toString(),
        ProductDescription: updatedProduct.ProductDescription,
      };
      await axios.put(
        API_URI() + `/admin/updateProduct/${updatedProduct._id}`,
        updatedProductString
      );
      onSave(); // Optionally: Refresh the product list or do other updates.
      onClose(); // Close the modal.
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit Product</DialogTitle>
      <DialogContent>
        <Avatar
          src={updatedProduct.ProductActiveImage}
          variant="square"
          sx={{ width: 50, height: 50 }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Product Name"
          name="ProductName"
          value={updatedProduct.ProductName}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Product Description"
          name="ProductDescription"
          value={updatedProduct.ProductDescription}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Custom Note"
          name="CustomNote"
          value={updatedProduct.CustomNote}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="SF Payments Object"
          name="customSfPaymentObject"
          value={updatedProduct.customSfPaymentObject}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="On Create Field Set"
          name="OnCreateFieldSet"
          value={updatedProduct.OnCreateFieldSet}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Salesforce Pricebook ID"
          name="SalesforceProductId"
          value={updatedProduct.SalesforceProductId}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Salesforce Product Code"
          name="SalesforceProductCode"
          value={updatedProduct.SalesforceProductCode}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Product Details URL"
          name="ProdDetailUrl"
          value={updatedProduct.ProdDetailUrl}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Product Label"
          name="ProdLabel"
          value={updatedProduct.ProductLabel}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="StripeReceiptTemplate ID"
          name="StripeReceiptTemplate"
          value={updatedProduct.StripeReceiptTemplateId}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="StripeInvoiceTemplate ID"
          name="StripeInvoiceTemplate"
          value={updatedProduct.StripeInvoiceTemplate}
          onChange={handleInputChange}
        />
        <FormControlLabel
          control={
            <Switch
              checked={updatedProduct.IsEnabled}
              onChange={handleSwitchChange}
              name="IsEnabled"
              color="primary"
            />
          }
          label="Is Enabled"
        />
        <FormControlLabel
          control={
            <Switch
              checked={updatedProduct.eCommerce}
              onChange={handleSwitchChange}
              name="eCommerce"
              color="primary"
            />
          }
          label="eCommerce"
        />
        <FormControlLabel
          control={
            <Switch
              checked={updatedProduct.transactional}
              onChange={handleSwitchChange}
              name="Transactional"
              color="primary"
            />
          }
          label="Transactional"
        />
        <FormControlLabel
          control={
            <Switch
              checked={updatedProduct.DisplayIfNotOwned}
              onChange={handleSwitchChange}
              name="DisplayIfNotOwned"
              color="primary"
            />
          }
          label="DisplayIfNotOwned"
        />
        <FormControlLabel
          control={
            <Switch
              checked={updatedProduct.UnderMaintenance}
              onChange={handleSwitchChange}
              name="UnderMaintenance"
              color="primary"
            />
          }
          label="Under Maintenance"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProductModal;
