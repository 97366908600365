import axios from "axios";

import { API_URI } from "../../../Globals";

const apiEndpoint = API_URI();

export async function createJobRequest(
  customerId,
  vin,
  platform,
  images,
  imagesExtras,
  optional_notes,
  specialInstructions,
  buttonColors,
  condition,
  body,
  bdyS,
  refinish,
  glass,
  frame,
  mechanical,
  paintrate,
  taxparts,
  taxlabor,
  usealternativeparts,
  onSuccess,
  onFail
) {
  try {
    // Get the current date and timezone
    const submitDate = new Date();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Create a FormData object and append the fields and images
    const formData = new FormData();
    formData.append("customer_id", customerId);
    formData.append("vin", vin);
    formData.append("platform", platform);
    formData.append("submit_date", submitDate.toISOString());
    formData.append("timezone", timezone);
    formData.append("optional_notes", optional_notes);
    formData.append("specialInstructions", specialInstructions);
    formData.append("status", 0);
    formData.append("buttonColors", JSON.stringify(buttonColors));
    formData.append("condition", condition);
    formData.append("body", body);
    formData.append("bdyS", bdyS);
    formData.append("refinish", refinish);
    formData.append("glass", glass);
    formData.append("frame", frame);
    formData.append("mechanical", mechanical);
    //
    formData.append("paintrate", paintrate);
    formData.append("taxparts", taxparts);
    formData.append("taxlabor", taxlabor);
    formData.append("usealternativeparts", usealternativeparts);

    for (const image of images) {
      formData.append("images", image, image.name);
    }

    for (const imageExtra of imagesExtras) {
      console.log("ImageExtra:", imageExtra);
      if (imageExtra != null)
        formData.append("imagesExtras", imageExtra, imageExtra.name);
    }

    // Send the POST request
    const response = await axios.post(
      apiEndpoint + "/totalloss/createJob",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("Job created successfully:", response.data);
    onSuccess();
  } catch (error) {
    console.error("Error creating job:", error);
    onFail();
  }
}

export async function submitJob(jobId, onSuccess, onFail) {
  try {
    const submitDate = new Date();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const response = await axios.post(apiEndpoint + "/totalloss/submitJob", {
      jobId,
      appSubmitDate: submitDate.toISOString(),
      appTimezone: timezone,
    });

    if (response.status === 200) {
      console.log(response.data);
      onSuccess(response.data.message);
    } else {
      onFail(`Failed to abandon job: ${response.data.message}`);
    }
  } catch (error) {
    console.error("Failed to abandon job:", error);
    onFail(`Failed to abandon job: ${error.message}`);
  }
}

export async function abandonJob(jobId, appraiser_id, onSuccess, onFail) {
  try {
    const response = await axios.post(apiEndpoint + "/totalloss/abandonJob", {
      jobId,
      appraiser_id: appraiser_id,
    });

    if (response.status === 200) {
      onSuccess(response.data.message);
    } else {
      onFail(`Failed to abandon job: ${response.data.message}`);
    }
  } catch (error) {
    console.error("Failed to abandon job:", error);
    onFail(`Failed to abandon job: ${error.message}`);
  }
}

export async function updateJobStatus(jobId, status, onSuccess, onFail) {
  try {
    // Create a FormData object and append the fields and images
    const formData = new FormData();
    formData.append("status", status);

    // Send the POST request
    const response = await axios.patch(
      apiEndpoint + `/totalloss/updateJob?jobId=${jobId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("Job created successfully:", response.data);
    onSuccess();
  } catch (error) {
    console.error("Error creating job:", error);
    onFail();
  }
}

export async function updateJobRequest(
  jobId,
  vin,
  platform,
  images,
  imagesExtras,
  optional_notes,
  specialInstructions,
  msg_appreisar,
  msg_customer,
  status,
  onSuccess,
  onFail
) {
  try {
    // Create a FormData object and append the fields and images
    const formData = new FormData();
    formData.append("vin", vin);
    formData.append("platform", platform);
    formData.append("optional_notes", optional_notes);
    formData.append("status", status);
    formData.append("specialInstructions", specialInstructions);

    for (const image of images) {
      formData.append("images", image, image.name);
    }

    for (const imageExtra of imagesExtras) {
      console.log("ImageExtra:", imageExtra);
      if (imageExtra != null)
        formData.append("imagesExtras", imageExtra, imageExtra.name);
    }

    // Send the POST request
    const response = await axios.patch(
      apiEndpoint + `/totalloss/updateJob?jobId=${jobId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("Job created successfully:", response.data);
    onSuccess();
  } catch (error) {
    console.error("Error creating job:", error);
    onFail();
  }
}

export async function updateRequestMessage(
  jobId,
  msg_customer,
  msg_appraiser,
  onSuccess,
  onFail,
  chatId
) {
  console.log("UpdatingChatId:", chatId);

  try {
    const response = await axios.put(
      apiEndpoint + "/totalloss/updateRequestMessage",
      {
        jobId,
        msg_customer,
        msg_appraiser,
        chatId,
      }
    );

    console.log(response.data);
    onSuccess(response.data);
  } catch (error) {
    console.error(`Error: ${error.response.data.message}`);
    onFail(error);
  }
}

export async function claimJob(jobId, appraiserId, onSuccess, onFail) {
  try {
    const response = await axios.get(apiEndpoint + "/totalloss/claimJob", {
      params: {
        jobId,
        appraiser_id: appraiserId,
      },
    });

    onSuccess(response.data);
  } catch (error) {
    console.error("Error claiming job:", error);
    onFail(error);
  }
}

export async function uploadPdf(jobId, file, onSuccess, onFail) {
  try {
    const formData = new FormData();
    formData.append("pdf", file);
    formData.append("jobId", jobId);

    const response = await axios.post(
      apiEndpoint + "/totalloss/uploadPDF",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("PDF uploaded successfully:", response.data);
    onSuccess(response.data);
  } catch (error) {
    console.error("Error uploading PDF:", error.response.data);
    onFail(error.response.data);
  }
}
