import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  Container,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import PDRTile from "./PDRTile";
import PDRGraph from "./PDRGraph";
import PDRTable from "./PDRTable";
import axios from "axios";
import { API_URI } from "../../../../Globals";

const PDRDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const [error, setError] = useState(null);

  // Determine the current month.
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString("default", { month: "long" });

  // State for the selected month, defaults to current month.
  const [month, setMonth] = useState([currentMonth]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleMonthChange = (event) => {
    const value = event.target.value;
    // If "All" is selected, set the month state to an array of all months
    if (value.includes("All")) {
      setMonth(months);
    } else {
      setMonth(value);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        let params = {};
        if (!month.includes("All")) {
          params.month = month.join(",");
        }
        const response = await axios.get(
          `${API_URI()}/axiom/pdr-dashboard-data`,
          { params }
        );
        setDashboardData(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [month]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography variant="h6" color="error">
          Error fetching data: {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box py={6}>
        <Typography variant="h3" gutterBottom align="center" fontWeight="bold">
          PDR Dashboard
        </Typography>
        <Typography variant="h5" gutterBottom align="center">
          <b>Current Month Owned:</b> {dashboardData.currentMonthOwned}
        </Typography>

        <Box sx={{ marginTop: 8 }}>
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5" gutterBottom>
              Month&nbsp;
            </Typography>
            <FormControl variant="standard" sx={{ minWidth: 120 }}>
              <Select
                multiple
                value={month}
                onChange={handleMonthChange}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                    },
                  },
                }}
              >
                <MenuItem value="All" key="all">
                  All
                </MenuItem>
                {months.map((monthName) => (
                  <MenuItem value={monthName} key={monthName}>
                    {monthName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="h5" gutterBottom>
              &nbsp;Usage Details
            </Typography>
          </Box>
          <Grid container spacing={3}>
            {dashboardData.usageDetails.map((detail) => (
              <Grid item xs={12} sm={6} md={4} key={detail.type}>
                <Paper elevation={4}>
                  <PDRTile {...detail} />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={{ marginTop: 8, marginBottom: 4 }}>
          <Typography variant="h5" gutterBottom>
            Year to Date Usage
          </Typography>
          <Paper elevation={4}>
            <PDRGraph
              data={dashboardData.yearToDateGraphData}
              chartType={dashboardData.yearToDateGraphData.chartType}
              visibleDatasets={
                dashboardData.yearToDateGraphData.visibleDatasets
              }
            />
          </Paper>
        </Box>

        <Box>
          <PDRTable data={dashboardData.tableEntries} selectedMonths={month} />
        </Box>
      </Box>
    </Container>
  );
};

export default PDRDashboard;
