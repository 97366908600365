import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  useTheme,
  styled,
  Box,
  LinearProgress,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  Store as ShopIcon,
  People as UserIcon,
  Assessment as ReportIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ShoppingCart as ProductsIcon,
  FiberDvr as FlowsIcon,
} from "@mui/icons-material";
import { useSelector } from "react-redux";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Sidebar = ({ isOpen, onToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const loading = useSelector((state) => state.shops.loading);
  const loadingLocalShop = useSelector((state) => state.shops.loadingLocalShop);
  const loadingUsers = useSelector((state) => state.shops.loadingUsers);
  const localUser = useSelector((state) => state.shops.localUser);
  const localShop = useSelector((state) => state.shops.localShop);

  const menuItems = [
    {
      text: "Shop Profile",
      icon: <DashboardIcon />,
      path: "/profile",
      disabled: loadingLocalShop,
      loading: loadingLocalShop,
      display: true,
    },
    {
      text: "Shop Management",
      icon: <ShopIcon />,
      path: "/shops",
      disabled: loadingLocalShop,
      loading: loadingLocalShop,
      display:
        localShop &&
        (localShop.ShopLimit > 1 || localUser.role === "superAdmin"),
    },
    {
      text: "User Management",
      icon: <UserIcon />,
      path: "/users",
      disabled: loadingUsers,
      loading: loadingUsers,
      display: true,
    },
    {
      text: "Usage Reports",
      icon: <ReportIcon />,
      path: "/reports",
      disabled: loadingLocalShop,
      loading: loadingLocalShop,
      display: true,
    },
    {
      text: "Products",
      icon: <ProductsIcon />,
      path: "/products/",
      disabled: loading,
      loading: loading,
      display: localUser.role === "superAdmin",
    },
    {
      text: "Flows",
      icon: <FlowsIcon />,
      path: "/flows/",
      disabled: loading,
      loading: loading,
      display: localUser.role === "superAdmin",
    },
  ];

  const handleItemClick = (path) => {
    const pathParts = location.pathname.split("/");
    const viewAsShopIndex = pathParts.indexOf("view-as-shop");

    if (viewAsShopIndex !== -1 && pathParts.length > viewAsShopIndex + 1) {
      const viewAsShopSerial = pathParts[viewAsShopIndex + 1];
      navigate(`/dashboard/shopadmin${path}/view-as-shop/${viewAsShopSerial}`);
    } else {
      navigate(`/dashboard/shopadmin${path}`);
    }
  };

  return (
    <Drawer
      variant="permanent"
      open={isOpen}
      sx={{
        width: isOpen ? 240 : theme.spacing(7),
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        "& .MuiDrawer-paper": {
          width: isOpen ? 240 : theme.spacing(7),
          marginTop: "64px",
          overflowX: "hidden",
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }}
    >
      <DrawerHeader>
        <IconButton onClick={onToggle}>
          {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {menuItems.map(
          (item) =>
            item.display && (
              <ListItem
                key={item.text}
                button
                selected={location.pathname.startsWith(
                  `/dashboard/shopadmin${item.path}`
                )}
                onClick={() => handleItemClick(item.path)}
                disabled={item.disabled}
                sx={{
                  opacity: item.disabled ? 0.5 : 1,
                  pointerEvents: item.disabled ? "none" : "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{ opacity: isOpen ? 1 : 0 }}
                  />
                </Box>
                {isOpen && item.loading && (
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                )}
              </ListItem>
            )
        )}
      </List>
    </Drawer>
  );
};

export default Sidebar;
