import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";

const PDRTile = ({ type, pdrsRun, cost }) => {
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      {" "}
      {/* Adjust width as needed */}
      <CardContent>
        <Typography variant="h5" component="div">
          {capitalizeFirstLetter(type)}
        </Typography>
        <Box sx={{ marginTop: 2 }}>
          <Typography color="text.secondary" gutterBottom>
            PDR's Run: {pdrsRun}
          </Typography>
          <Typography color="text.secondary">
            Cost: ${cost.toFixed(2)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PDRTile;
