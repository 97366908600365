import React from "react";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import {
  Box,
  Card,
  CardContent,
  Button,
  Link,
  Typography,
  Checkbox,
  FormControlLabel,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.Mui-checked": {
    color: theme.palette.primary.main,
  },
  transition: "all 0.3s",
}));

const CustomButton = styled(Button)(({ theme }) => ({
  transition: "all 0.3s",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.8rem",
  color: theme.palette.primary.dark,
  marginBottom: theme.spacing(2),
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.1rem",
  color: theme.palette.text.primary,
  lineHeight: "1.6",
}));

const stripePromise = loadStripe("pk_live_43CORcVH1TMkq2Zk2FSuPE9Y00oYjjelMf");

const StripeCheckoutButton = ({ disabled, sessionId }) => {
  const stripe = useStripe();
  console.log("Stripe Session ID btn:", sessionId);
  const handleStripeRedirect = async () => {
    try {
      const response = await axios.post(
        "https://aerkflows.azurewebsites.net/stripe/create-checkout-session",
        { sessionId }
      );
      const session = response.data;
      console.log("Create Checkout Ses Response:", response.data);

      if (stripe) {
        const { error } = await stripe.redirectToCheckout({
          sessionId: session.id,
        });
        if (error) console.error(error.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <CustomButton
      variant="contained"
      color="primary"
      fullWidth
      disabled={disabled}
      onClick={handleStripeRedirect}
    >
      Submit
    </CustomButton>
  );
};

const Checkout = () => {
  const [checked, setChecked] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("Id"); // Assuming the query parameter is named 'id'

  console.log("Checkout Session ID:", sessionId);
  console.log("Checkout Query Params:", queryParams);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Elements stripe={stripePromise}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        sx={{ backgroundColor: "#f5f5f7", padding: isMobile ? 2 : 4 }}
      >
        <Card
          sx={{
            p: 3,
            boxShadow: 3,
            maxWidth: "1000px",
            borderRadius: "12px",
            backgroundColor: "#fff",
            overflow: "hidden",
          }}
        >
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  sx={{
                    height: "auto",
                    width: "100%",
                    borderRadius: "8px",
                    marginBottom: 2,
                  }}
                  src="/img/dashboard/CrashLogo_Final.png"
                  alt="KriTech Solutions"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <HeaderTypography variant="h4" gutterBottom>
                  Checkout
                </HeaderTypography>

                <BodyTypography gutterBottom>
                  Please read and accept our{" "}
                  <Link
                    href="https://aerksa.blob.core.windows.net/legal/KriTech_terms_and_conditions_(current).pdf"
                    target="_blank"
                  >
                    Terms and Conditions
                  </Link>{" "}
                  and then click submit below. You will be redirected to our
                  check out page.
                </BodyTypography>

                <BodyTypography gutterBottom>
                  If you have any questions regarding your payment,
                  subscription, or installation, please feel free to reach out
                  to our team at any time.
                </BodyTypography>

                <BodyTypography>
                  Once you have submitted your information and your payment has
                  been accepted, you will receive one last email containing your{" "}
                  <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
                    AERK
                  </span>{" "}
                  installation and activation information.
                </BodyTypography>

                <Box mt={3}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        checked={checked}
                        onChange={handleCheckboxChange}
                        name="checkedTC"
                      />
                    }
                    label={
                      <BodyTypography>
                        I have read and accept the Terms and Conditions
                      </BodyTypography>
                    }
                  />
                </Box>
                <Box mt={2}>
                  <StripeCheckoutButton
                    disabled={!checked}
                    sessionId={sessionId}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Elements>
  );
};

export default Checkout;
