import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { Box, useTheme, useMediaQuery } from "@mui/material";

const PDRGraph = ({ data, chartType, visibleDatasets }) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  // Restructure data
  const transformedData = {
    labels: data.labels,
    datasets: data.datasets.map((dataset) => ({
      ...dataset,
      label: dataset.label.toLowerCase(), // Normalize dataset labels
    })),
  };

  const filteredData = transformedData.datasets.filter((dataset) =>
    visibleDatasets.includes(dataset.label)
  );

  // Inside your PDRGraph component, before return statement
  const mergedData = transformedData.labels.map((label, index) => {
    let obj = { month: label };
    filteredData.forEach((dataset) => {
      // Assuming `dataset.data` is an array matching `labels` in length
      obj[dataset.label] = dataset.data[index];
    });
    return obj;
  });

  return (
    <Box
      sx={{
        width: "100%", // Make sure it takes the full width of its parent
        height: "500px", // Define a minimum height for smaller screens and adjust as necessary
        margin: "20px auto",
        "& .recharts-wrapper": {
          borderRadius: "12px", // You can customize this value
          overflow: "hidden", // Ensures the rounded corners are visible
          boxShadow: theme.shadows[3], // Use MUI's shadow for some depth. Adjust the index [3] as needed.
          backgroundColor: theme.palette.background.paper, // Ensures chart background matches MUI theme
        },
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={mergedData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />
          <XAxis
            dataKey="month"
            tick={{
              fill: theme.palette.text.primary,
              fontSize: matchesSM ? 12 : 16,
            }}
          />
          <YAxis
            tickFormatter={(value) => `${value}`}
            tick={{
              fill: theme.palette.text.primary,
              fontSize: matchesSM ? 12 : 16,
            }}
          />
          <Tooltip />
          <Legend />
          {filteredData.map((dataset) => (
            <Bar
              key={dataset.label}
              dataKey={dataset.label}
              fill={dataset.color}
              radius={[10, 10, 0, 0]} // Optional: round the top edges
            >
              {/* Define the gradient for each dataset */}
              <defs>
                <linearGradient
                  id={`${dataset.label}-color`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  {/* Gradient goes from top (y1=0) to bottom (y2=1), you can adjust the colors and offsets as needed */}
                  <stop
                    offset="5%"
                    stopColor={dataset.color}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor={dataset.color}
                    stopOpacity={0.2}
                  />
                </linearGradient>
              </defs>
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default PDRGraph;
