import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import StepHeader from "../../components/StepHeader";
import VehicleConditionDropdown from "./VehicleConditionDropdown";
import LoadingModal from "./LoadingModal";
import EnterErrorModal from "./EnterErrorModal";
import SubmodelDropdown from "./SubmodelDropdown";
import InputField from "../../components/InputField";
import {
  fetchPricesOptions,
  fetchVehicleData,
  setVehicleMake,
  setVehicleModel,
  setVehicleType,
  setRawVehicleYear,
  setMileage,
  setLicensePlate,
  setVin,
  setVehicleYear,
  setMarketValue,
  setVehicleCondition,
  setLoading,
  setVehicleSubmodel,
  setFileValidationStatus,
  setSelectedFile,
  setVinEntered,
} from "../../../../redux/diminishedvalue/actions";
import { customStyles, options } from "./styles";

const MainContent = () => {
  const [errors, setErrors] = useState({});
  const [enterError, setEnterError] = useState("");
  const [manualEntry, setManualEntry] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedFile = useSelector(
    (state) => state.diminishedValue.selectedFile
  );
  const vehicleMake = useSelector((state) => state.diminishedValue.vehicleMake);
  const vehicleModel = useSelector(
    (state) => state.diminishedValue.vehicleModel
  );
  const licensePlate = useSelector(
    (state) => state.diminishedValue.licensePlate
  );
  const mileage = useSelector((state) => state.diminishedValue.mileage);
  const vehicleYear = useSelector((state) => state.diminishedValue.vehicleYear);
  const vin = useSelector((state) => state.diminishedValue.vin);
  const vehicleType = useSelector((state) => state.diminishedValue.vehicleType);
  const trims = useSelector((state) => state.diminishedValue.trims);
  const hasTrims = useSelector((state) => state.diminishedValue.hasTrims);
  const manualTrims = useSelector((state) => state.diminishedValue.manualTrims);
  const marketValue = useSelector((state) => state.diminishedValue.marketValue);
  const vinEntered = useSelector((state) => state.diminishedValue.vinEntered);
  const vehicleCondition = useSelector(
    (state) => state.diminishedValue.vehicleCondition
  );
  const loading = useSelector((state) => state.diminishedValue.loading);
  const submodel = useSelector(
    (state) => state.diminishedValue.vehicleSubmodel
  );

  const handleNextClick = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length === 0) {
      if (!selectedFile) {
        navigate("/instantdv/4m_VehicleDamage");
      } else {
        navigate("/instantdv/5_ClaimInformation");
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const handleSubmodelChange = (e) => {
    const selectedSubmodel = e.value;
    dispatch(setVehicleSubmodel(selectedSubmodel));

    dispatch(setLoading(true));
    dispatch(
      fetchPricesOptions(
        vehicleModel,
        vehicleMake,
        vehicleYear,
        selectedSubmodel,
        mileage || "10000",
        () => dispatch(setLoading(false)),
        () => dispatch(setLoading(false))
      )
    );
  };

  const handleEnterClick = () => {
    if (vin && vehicleYear) {
      setEnterError("");
      dispatch(setLoading(true));
      dispatch(setRawVehicleYear(vehicleYear));
      dispatch(fetchVehicleData(vin))
        .then(() => {
          dispatch(setVinEntered(true));
          dispatch(setLoading(false));
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    } else {
      setEnterError("Please enter VIN & Year to continue");
    }
  };

  const validateFields = () => {
    const errors = {};
    if (!vin) {
      errors.vin = "VIN is required";
    }
    if (
      !vehicleYear ||
      vehicleYear < 1950 ||
      vehicleYear > new Date().getFullYear()
    ) {
      errors.vehicleYear = "Year must be between 1950 and present";
    }
    if (!vehicleMake) {
      errors.vehicleMake = "Make is required";
    }
    if (!vehicleModel) {
      errors.vehicleModel = "Model is required";
    }
    if (!mileage) {
      errors.mileage = "Mileage is required";
    }
    if (submodel === "SUBMODEL/TRIM *") {
      errors.submodel = "Submodel is required";
    }
    if (!marketValue && !trims.length) {
      errors.marketValue = "Market value is required";
    }
    if (!vehicleCondition) {
      errors.vehicleCondition = "Vehicle condition is required";
    }
    return errors;
  };

  useEffect(() => {
    if (trims.length) {
      // dispatch(setMarketValue(""));
    }
  }, [trims, dispatch]);

  function removeFormatting(value) {
    return value.replace(/[^0-9.]/g, "");
  }

  function formatMoney(amount) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }

  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "40px",
        minHeight: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <LoadingModal loading={loading} />
      <EnterErrorModal enterError={enterError} setEnterError={setEnterError} />
      <div style={{ maxWidth: "400px", width: "100%" }}>
        <h2
          style={{
            fontFamily: "Orbitron, sans-serif",
            color: "rgb(192, 44, 122)",
            fontSize: "20px",
            fontWeight: 1000,
          }}
        >
          TELL US ABOUT YOUR VEHICLE
        </h2>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontFamily: "Orbitron, sans-serif",
            fontSize: "16px",
            fontWeight: "bold",
            marginTop: "40px",
          }}
        >
          <span>
            <span style={{ color: "red" }}>*</span> = Required Field
          </span>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "0",
          }}
        >
          <InputField
            label="VIN *"
            value={vin}
            onChange={(e) => dispatch(setVin(e.target.value))}
            placeholder="VIN"
            error={errors.vin}
            isRequired={true}
            disabled={selectedFile || vinEntered}
            highlight={true} // Highlight the VIN field by default
          />
          <InputField
            label="YEAR *"
            value={vehicleYear}
            onChange={(e) => dispatch(setVehicleYear(e.target.value))}
            placeholder="YEAR"
            error={errors.vehicleYear}
            isRequired={true}
            disabled={selectedFile || vinEntered}
          />
        </div>
        {!selectedFile && !vinEntered && (
          <>
            <button
              onClick={() => {
                dispatch(setSelectedFile(null));
                dispatch(setFileValidationStatus(false));
                dispatch(setVin(null));
                navigate("/instantdv/2_StartReport");
              }}
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "rgb(192, 44, 122)",
                fontFamily: "Roboto, sans-serif",
                fontSize: "18px",
                fontWeight: "bold",
                cursor: "pointer",
                marginRight: "10px",
              }}
            >
              &lt; BACK
            </button>
            <button
              onClick={handleEnterClick}
              style={{
                backgroundColor: "transparent",
                color: "rgb(192, 44, 122)",
                border: "none",
                fontWeight: "bold",
                cursor: "pointer",
                marginTop: "10px",
                fontFamily: "Roboto, sans-serif",
                fontSize: "18px",
                marginLeft: "10px",
              }}
            >
              DECODE VIN
            </button>
          </>
        )}

        {vinEntered || selectedFile ? (
          <>
            <InputField
              label="MAKE *"
              value={vehicleMake}
              onChange={(e) => dispatch(setVehicleMake(e.target.value))}
              placeholder="MAKE"
              error={errors.vehicleMake}
              isRequired={true}
              disabled={!vinEntered || selectedFile}
            />
            <InputField
              label="MODEL *"
              value={vehicleModel}
              onChange={(e) => dispatch(setVehicleModel(e.target.value))}
              placeholder="MODEL"
              error={errors.vehicleModel}
              isRequired={true}
              disabled={!vinEntered || selectedFile}
            />
               <div style={{ marginTop: "20px" }}>
              <label style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                <input
                  type="checkbox"
                  checked={manualEntry}
                  onChange={(e) => setManualEntry(e.target.checked)}
                  style={{ marginRight: "10px" }}
                />
                <span style={{ fontFamily: "Roboto, sans-serif", fontSize: "14px" }}>
                  Can't find your sub model? Check here and enter the sub model and market value manually
                </span>
              </label>
              
              {manualEntry ? (
                <>
                  <InputField
                    label="SUBMODEL/TRIM "
                    value={submodel}
                    onChange={(e) => dispatch(setVehicleSubmodel(e.target.value))}
                    placeholder="SUBMODEL/TRIM *"
                    error={errors.submodel}
                    isRequired={true}
                  />
                </>
              ) : (
                <>
                  {trims.length > 0 ? (
                    <SubmodelDropdown
                      submodel={submodel}
                      setSubmodel={(selectedSubmodel) =>
                        dispatch(setVehicleSubmodel(selectedSubmodel))
                      }
                      trims={trims}
                      customStyles={customStyles}
                      isLabelVisible={true}
                      handleSubmodelChange={handleSubmodelChange}
                      error={errors.submodel}
                    />
                  ) : (
                    <InputField
                      label="SUBMODEL/TRIM *"
                      value={submodel}
                      onChange={(e) => dispatch(setVehicleSubmodel(e.target.value))}
                      placeholder="SUBMODEL/TRIM *"
                      error={errors.submodel}
                      isRequired={true}
                    />
                  )}
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <InputField
                label="MILEAGE *"
                value={mileage}
                onChange={(e) => dispatch(setMileage(e.target.value))}
                placeholder="MILEAGE"
                error={errors.mileage}
                isRequired={true}
              />
              <InputField
                label="LICENSE PLATE"
                value={licensePlate}
                onChange={(e) => dispatch(setLicensePlate(e.target.value))}
                placeholder="LICENSE PLATE"
                error={errors.licensePlate}
                isRequired={false}
              />
            </div>
            <InputField
              label="VEHICLE MARKET VALUE *"
              value={marketValue}
              onChange={(e) => dispatch(setMarketValue(e.target.value))}
              placeholder="VEHICLE MARKET VALUE"
              error={errors.marketValue}
              isRequired={true}
              disabled={trims.length > 0 && !manualEntry}
            />
            {!marketValue && !trims && !loading && (
              <p style={{ marginTop: "10px", fontSize: "14px" }}>
                We couldn't find the market value of your vehicle, please check{" "}
                <a
                  href="https://www.kbb.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "rgb(192, 44, 122)",
                    textDecoration: "none",
                  }}
                >
                  Kelly Blue Book
                </a>{" "}
                or other marketplace sources and enter it here.
              </p>
            )}
            <div style={{ marginTop: "20px" }}>
              <VehicleConditionDropdown
                vehicleCondition={vehicleCondition}
                setVehicleCondition={(e) => dispatch(setVehicleCondition(e))}
                errors={errors}
                customStyles={customStyles}
                options={options}
              />
            </div>
            <button
              onClick={() => {
                dispatch(setSelectedFile(null));
                dispatch(setFileValidationStatus(false));
                navigate("/instantdv/2_StartReport");
              }}
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "rgb(192, 44, 122)",
                fontFamily: "Roboto, sans-serif",
                fontSize: "18px",
                fontWeight: "bold",
                cursor: "pointer",
                marginRight: "10px",
              }}
            >
              &lt; BACK
            </button>
            <button
              onClick={handleNextClick}
              style={{
                backgroundColor: "transparent",
                color: "rgb(192, 44, 122)",
                fontWeight: "bold",
                fontSize: "18px",
                padding: "10px 20px",
                border: "none",
                cursor: "pointer",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              NEXT &gt;
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default MainContent;
