import React, { useState, useEffect, useMemo } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PrintIcon from "@mui/icons-material/Print";
import {
  MRT_ToggleDensePaddingButton,
  MRT_ToggleRowActionMenuButton,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFiltersButton,
  MRT_ShowHideColumnsButton,
} from "material-react-table";
import {
  AppBar,
  Toolbar,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Paper,
  IconButton,
  Tooltip,
  Grid,
  Button,
  Box,
  TextField,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useAuth } from "../../../../AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URI } from "../../../../Globals";
import getTableConfigs from "./TableConfig";
import PayloadModal from "./PayloadModal"; // Adjust the import path as necessary
import { MaterialReactTable } from "material-react-table";

import FilterIcon from "@mui/icons-material/FilterList";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const queryClient = new QueryClient();

const UsageReportPage = () => {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [columns, setColumns] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [data, setData] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    totalDocs: 0,
    page: 1,
    pageSize: 10,
    totalPages: 0,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [columnFilters, setColumnFilters] = useState([]);

  const [isPayloadModalOpen, setIsPayloadModalOpen] = useState(false);
  const [payloadData, setPayloadData] = useState("");

  const [isFetching, setIsFetching] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sortModel, setSortModel] = useState([]);

  const handlePayloadClickTable = (rowData) => {
    console.log("Payload:", rowData);
    setPayloadData(rowData);
    setIsPayloadModalOpen(true);
  };

  const columnsConfig = useMemo(
    () => getTableConfigs(handlePayloadClickTable)[selectedProduct] || [],
    [selectedProduct]
  );

  useEffect(() => {
    console.log("Column Filters:", columnFilters);
  }, [columnFilters]);

  useEffect(() => {
    fetchData();
  }, [globalFilter]);

  useEffect(() => {
    console.log("Updated columns");
    if (Array.isArray(columnsConfig.sort)) {
      console.log("Updated sortModel:", columnsConfig.sort);
      setSortModel(columnsConfig.sort);
    }
  }, [columnsConfig]);

  useEffect(() => {
    console.log("SortModel:", sortModel);
    fetchData();
  }, [sortModel]);

  useEffect(() => {
    let timeoutId;
    if (!isFetching) {
      setIsFetching(true);

      fetchData(
        pagination.pageIndex + 1,
        pagination.pageSize,
        selectedProduct
      ).then(() => {
        setIsFetching(false);
        timeoutId = setTimeout(() => {}, 2000); // Set a timeout to fetch the data again in 2 seconds
      });
    }
    return () => clearTimeout(timeoutId); // Clear the timeout if the component is unmounted
  }, [data]); // Dependency array - this useEffect will run again whenever `data` changes

  const fetchData = async (page = 1, pageSize = 10, application = "") => {
    const prepareColumnFiltersForApi = (columnFilters) => {
      return columnFilters.reduce((acc, filter) => {
        // Prefix each filter key with 'filter'
        acc[`filter.${filter.id}`] = filter.value;
        return acc;
      }, {});
    };

    let columnFilterParams = prepareColumnFiltersForApi(columnFilters);

    try {
      var uri = "";

      if (user.role === "superAdmin") {
        uri = API_URI() + "/admin/data";
      } else if (user.role === "shopAdmin") {
        uri = API_URI() + "/admin/data?serial=" + user.Serial;
      } else {
        // Handle other user roles or default case if needed
      }
      let sortParam = sortModel
        .map((s) => `${s.id}:${s.desc ? "desc" : "asc"}`)
        .join(",");
      const response = await axios.get(uri, {
        params: {
          page,
          pageSize,
          application,
          globalFilter,
          sort: sortParam,
          startDate,
          endDate,
          dateField: columnsConfig.dateKey,
          ...columnFilterParams,
        },
      });

      console.log("Raw Data:", response.data);

      const transformedData = response.data.data.map((item) => {
        let vin = item.vin || ""; // Use the existing vin if it's valid, otherwise default to an empty string

        if (!vin && item.Payload) {
          // Only parse Payload if vin is not already valid
          try {
            const payload = JSON.parse(item.Payload);
            vin = payload.vin || ""; // Use the vin from Payload if present, otherwise use an empty string
          } catch (error) {
            console.error("Error parsing Payload JSON:", error);
            // Handle malformed JSON or log the error
          }
        }

        return {
          ...item, // Keep all existing data
          vin, // Add the extracted or existing valid vin
        };
      });

      setData(transformedData); // Update the data state with transformed data
      setPaginationInfo({
        totalDocs: response.data.totalDocs,
        page: response.data.page,
        pageSize: response.data.pageSize,
        totalPages: response.data.totalPages,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchProducts = async () => {
    try {
      var response = "";

      if (user.role === "superAdmin") {
        response = await axios.get(API_URI() + "/admin/applications");
        console.log("SuperAdmin detected");
      } else if (user.role === "shopAdmin") {
        response = await axios.get(
          API_URI() + "/admin/applications?serial=" + user.Serial
        );
      } else {
        console.log("Unhandled user role: ", user.role);
      }
      console.log("Raw Products:", response.data);

      setProducts(response.data);

      // Debug data
      console.log("User role: ", user.role);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    } else {
      console.log("UserRole:", user.role);
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    fetchProducts();
  }, [user, setProducts]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setData([]);
    fetchData(1, pagination.pageSize, newValue);
    setSelectedProduct(newValue);
  };

  const handlePayloadClick = (params) => {
    setPayloadData(params.row.Payload);
    setIsPayloadModalOpen(true);
  };

  const handleFilter = () => {
    console.log("Global Filter DateRange:", dateRange[0], "/", dateRange[1]);
    setStartDate(dateRange[0].format("YYYY-MM-DD"));
    setEndDate(dateRange[1].format("YYYY-MM-DD"));
  };

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  useEffect(() => {
    fetchData(
      pagination.pageIndex + 1,
      pagination.pageSize,
      selectedProduct,
      startDate,
      endDate
    );
  }, [pagination.pageIndex, pagination.pageSize]);

  const exitAdminDashboard = () => {
    if (user.role === "superAdmin") {
      navigate("/dashboard/admin/");
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <AppBar position="static">
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Typography variant="h6" style={{ flexGrow: 1, textAlign: "center" }}>
            Usage Report
          </Typography>
          <Button
            color="inherit"
            onClick={() => exitAdminDashboard()}
            startIcon={<ExitToAppIcon />}
          >
            Exit
          </Button>
        </Toolbar>
      </AppBar>
      <div style={{ flexGrow: 1, overflow: "auto" }}>
        <MaterialReactTable
          data={data}
          columns={
            columnsConfig.columns !== undefined ? columnsConfig.columns : []
          }
          state={{
            pagination,
            globalFilter,
            sorting: sortModel,
            columnFilters, // Include sort model state
          }}
          manualPagination
          manualFiltering={true}
          onColumnFiltersChange={setColumnFilters}
          manualSorting={true} // Enable manual sorting
          onGlobalFilterChange={(newValue) => {
            setGlobalFilter(newValue || "");
          }}
          onPaginationChange={(newState) => {
            setPagination(newState); // Update pagination state
          }}
          onSortingChange={setSortModel}
          pageCount={paginationInfo.totalPages}
          rowCount={paginationInfo.totalDocs}
          pageSizeOptions={[20, 50, 100, 500]}
          initialState={{ showColumnFilters: true }}
          renderToolbarInternalActions={({ table }) => (
            <Box>
              <MRT_ToggleGlobalFilterButton table={table} />
              <IconButton
                onClick={() => {
                  window.print();
                }}
              >
                <PrintIcon />
              </IconButton>

              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
            </Box>
          )}
          renderTopToolbarCustomActions={({ table }) => (
            <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
              <FormControl
                variant="outlined"
                sx={{ minWidth: 150, bgcolor: "#ffffff" }}
              >
                <InputLabel htmlFor="product-select">Product</InputLabel>
                <Select
                  label="Product"
                  id="product-select"
                  value={selectedProduct}
                  onChange={handleChange}
                >
                  {products.map((product) => (
                    <MenuItem key={product} value={product}>
                      {product}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateRangePicker
                  startText="Start Date"
                  endText="End Date"
                  value={dateRange}
                  onChange={(newRange) => setDateRange(newRange)}
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField
                        {...startProps}
                        helperText=""
                        variant="standard"
                        margin="normal"
                      />
                      <TextField
                        {...endProps}
                        helperText=""
                        variant="standard"
                        margin="normal"
                      />
                    </>
                  )}
                />
              </LocalizationProvider>
              <Tooltip title="Apply filter by Date">
                <IconButton onClick={handleFilter}>
                  <FilterIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
      </div>
      <PayloadModal
        open={isPayloadModalOpen}
        handleClose={() => setIsPayloadModalOpen(false)}
        payload={payloadData}
      />
    </div>
  );
};

export default UsageReportPage;
