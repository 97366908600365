import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Switch from "@mui/material/Switch";
import {
  Box,
  Typography,
  Button,
  Container,
  TextField,
  Grid,
} from "@mui/material";
import axios from "axios";
import { API_URI } from "../../../../Globals";

const ShopLicensesModal = ({ open, onClose, shop, licenses, onSave }) => {
  const [enabledLicenses, setEnabledLicenses] = useState({}); // Object to hold which licenses are enabled
  const [transactionalCounts, setTransactionalCounts] = useState({}); // Object to hold transactional counts

  useEffect(() => {
    if (shop) {
      const initialLicensesState = {};
      const initialTransactionalCounts = {};
      licenses.forEach((license) => {
        initialLicensesState[license._id] = shop.ActiveProducts
          ? shop.ActiveProducts[license._id] || false
          : false;
        initialTransactionalCounts[license._id] =
          shop.ActiveProductsMeta?.AvailableTransactions?.[license._id] || 0;
      });
      setEnabledLicenses(initialLicensesState);
      setTransactionalCounts(initialTransactionalCounts);
    }
  }, [shop, licenses]);

  const handleToggleLicense = (licenseId) => {
    setEnabledLicenses((prev) => ({
      ...prev,
      [licenseId]: !prev[licenseId],
    }));
  };

  const handleTransactionalCountChange = (licenseId, count) => {
    setTransactionalCounts((prev) => ({
      ...prev,
      [licenseId]: count,
    }));
  };

  const handleSave = async () => {
    try {
      onSave(shop, enabledLicenses, transactionalCounts); // Call the onSave callback if you still need it
      onClose(); // Close the modal
    } catch (error) {
      console.error("Error updating licenses:", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: "600px",
        }}
      >
        <Grid container spacing={0}>
          {licenses.map((license) => (
            <Grid item xs={12} key={license._id}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  my: 1,
                }}
              >
                <Typography variant="body1">{license.ProductName}</Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {license.Transactional && (
                    <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                      <Typography variant="body2" sx={{ mr: 1 }}>
                        Available Transactions:
                      </Typography>
                      <TextField
                        type="number"
                        inputProps={{
                          min: 0,
                          max: 99,
                        }}
                        value={transactionalCounts[license._id] || 0}
                        onChange={(e) =>
                          handleTransactionalCountChange(
                            license._id,
                            parseInt(e.target.value)
                          )
                        }
                        sx={{ width: "80px" }}
                      />
                    </Box>
                  )}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body2" sx={{ mr: 1 }}>
                      Enabled:
                    </Typography>
                    <Switch
                      checked={enabledLicenses[license._id] || false}
                      onChange={() => handleToggleLicense(license._id)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ mt: 4 }}
        >
          Save
        </Button>
      </Container>
    </Modal>
  );
};

export default ShopLicensesModal;
