import React, { useState, useEffect } from "react";
import axios from "axios";
import { AppBar, Toolbar, IconButton, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CreateShopModal from "./CreateShopModal";
import CreateUserModal from "./CreateUserModal";
import ShopSelector from "./ShopSelector";
import ShopsTable from "./ShopsTable";
import ShopDetails from "./ShopDetails";
import UsersTable from "./UsersTable";
import { API_URI } from "../../../../Globals";
import { useAuth } from "../../../../AuthContext";
import { useNavigate } from "react-router-dom";
import { GooSpinner } from "react-spinners-kit";

export default function Dashboard() {
  const [isShopModalOpen, setShopModalOpen] = useState(false);
  const [isUserModalOpen, setUserModalOpen] = useState(false);
  const [selectedShop, setSelectedShop] = useState("All");
  const [selectedShopData, setSelectedShopData] = useState(null);
  const [shops, setShops] = useState([]);
  const { isAuthenticated, user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate(); // or useHistory if you are using an older version

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    if (selectedShopData !== null) {
      const apiUrl =
        API_URI() + `/adminShop/users?serial=${selectedShopData.Serial}`;

      axios
        .get(apiUrl)
        .then((response) => {
          console.log("ShopUserrList:", response.data);
          setUsers(response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/"); // or the path you want to redirect to
    } else {
      console.log("UserRole:", user.role);
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (user.role === "superAdmin") {
      fetchShopsAdmin();
    } else if (user.role === "shopAdmin") {
      console.log("ShopAdmin:", user);
      fetchShopData();
    }
    fetchUsers();
  }, [user]);

  const fetchShopData = async () => {
    axios
      .get(API_URI() + "/adminShop/shop/" + user.Serial)
      .then((response) => {
        console.log("Shop Data", response.data);
        setShops([response.data]);
        setSelectedShop(response.data.Name);
        setSelectedShopData(response.data);
        setIsLoading(false);
      })
      .catch((error) =>
        console.error("There was a problem with the fetch operation:", error)
      );
  };

  const fetchShopsAdmin = async () => {
    axios
      .get(API_URI() + "/admin/")
      .then((response) => {
        setShops(response.data);
        setIsLoading(false);
      })
      .catch((error) =>
        console.error("There was a problem with the fetch operation:", error)
      );
  };

  const handleSaveShop = (newShop) => {
    setShops((prevShops) => [...prevShops, newShop]);
    setShopModalOpen(false);
  };

  const handleDelete = async (row) => {
    axios
      .delete(API_URI() + `/admin/shops/delete/${row._id}`)
      .then((response) => {
        console.log(response.data); // Handle the response
        const updatedShops = shops.filter((shop) => shop._id !== row._id);
        setShops(updatedShops);
      })
      .catch((error) => {
        console.error("Error deleting shop:", error); // Handle the error
      });
    console.log(row);
  };

  const exitAdminDashboard = () => {
    if (user.role === "superAdmin") {
      navigate("/dashboard/admin/");
    } else {
      navigate("/dashboard");
    }
  };

  const onSaveUser = () => {
    setUserModalOpen(false);
    fetchShopData();
  };

  const onSaveNewUser = () => {
    setUserModalOpen(false); // Close the modal
    fetchUsers(); // Fetch users again to refresh the list
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <GooSpinner size={180} color="#686769" loading={true} />
      </div>
    );
  }
  return (
    <div>
      {/* Conditionally Render the Modal based on selectedShop */}
      {selectedShop === "All" ? (
        <CreateShopModal
          open={isShopModalOpen}
          onClose={() => setShopModalOpen(false)}
          onNext={handleSaveShop}
        />
      ) : (
        <CreateUserModal
          open={isUserModalOpen}
          onClose={() => setUserModalOpen(false)}
          ShopSerial={selectedShopData.Serial}
          ShopCompany={selectedShopData.Company}
          onSave={onSaveNewUser}
        />
      )}

      <AppBar position="static" style={{ backgroundColor: "#3f51b5" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
          ></IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}></Typography>
          {user.role === "superAdmin" && (
            <ShopSelector
              shops={shops}
              selectedShop={selectedShop}
              onSelect={(e) => setSelectedShop(e.target.value)}
            />
          )}
          {selectedShop === "All" ? (
            <Button
              color="inherit"
              onClick={() => setShopModalOpen(true)}
              startIcon={<AddIcon />}
            >
              Create Shop
            </Button>
          ) : (
            <Button
              color="inherit"
              onClick={() => setUserModalOpen(true)}
              startIcon={<AddIcon />}
            >
              Create User
            </Button>
          )}

          <Button
            color="inherit"
            onClick={() => exitAdminDashboard()}
            startIcon={<ExitToAppIcon />}
          >
            Exit
          </Button>
        </Toolbar>
      </AppBar>

      {selectedShop === "All" ? (
        <ShopsTable
          shops={shops}
          onDelete={handleDelete}
          onRefresh={() => {
            setShops([]);
            if (user.role === "superAdmin") {
              fetchShopsAdmin();
            } else if (user.role === "shopAdmin") {
              fetchShopData();
            }
          }}
          onSelectShop={(shop) => {
            console.log(shop);
            setSelectedShopData(shop);
            setSelectedShop(shop.Name);
          }}
        />
      ) : (
        <div>
          <ShopDetails shop={selectedShopData} />
          <UsersTable
            serial={selectedShopData.Serial}
            shop={selectedShopData}
            users={users}
            fetchUsers={fetchUsers}
            isRefetchingUsers={loading}
          />
        </div>
      )}
    </div>
  );
}
