import React, { useState, useEffect } from "react";
import {
  TextField,
  Switch,
  FormControlLabel,
  Button,
  CircularProgress,
  Grid,
  Box,
  Typography,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { Settings as SettingsIcon } from "@mui/icons-material";
import {
  saveGroupOptions,
  getGroupOptions,
  fetchConfig,
  updateConfig,
} from "./apiService";
import GroupConfigEditor from "./GroupConfigEditor"; // Step 1: Import GroupConfigEditor

function GroupOptions({ group, onClose }) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingGroupId, setEditingGroupId] = useState(null);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const fetchAndUpdateOptions = async () => {
      setLoading(true);

      try {
        const [groupOptions, fetchedConfig] = await Promise.all([
          getGroupOptions(group._id),
          fetchConfig(`group/${group._id}/config`),
        ]);

        const mergedOptions = fetchedConfig.arr.map((configOption) => {
          const existingOption = groupOptions.find(
            (option) => option.id === configOption.id
          );
          return existingOption || configOption;
        });

        console.log("Merged Options:", mergedOptions);
        setOptions(mergedOptions);
      } catch (error) {
        console.error("Failed to fetch config or group options", error);
      }

      setLoading(false);
    };

    fetchAndUpdateOptions();
  }, [group]);

  const handleChange = (index, field, value) => {
    const newOptions = [...options];
    newOptions[index][field] = value;
    setOptions(newOptions);
  };

  const handleGroupClick = (groupId) => {
    fetchConfig(`group/${groupId}/config`).then((groupConfig) => {
      console.log("GroupConfig:", groupConfig);
    });
  };

  const handleGroupConfigOpen = (groupId) => {
    fetchConfig(`group/${groupId}/config`).then((fetchedConfig) => {
      setEditingGroupId(groupId);
      setConfig(fetchedConfig);
    });
  };

  const handleConfigSave = (newConfig) => {
    updateConfig(`group/${editingGroupId}/config`, newConfig).then(() => {
      setEditingGroupId(null);
    });
  };

  const renderOptionInput = (index, option) => {
    const type = option.type;
    if (type === "group") {
      //... group code remains the same
    } else if (type === "text") {
      return (
        <>
          <Typography variant="subtitle1" sx={{ marginRight: 1 }}>
            {option.label}
          </Typography>
          <TextField
            fullWidth
            value={option.value || ""}
            onChange={(e) => handleChange(index, "value", e.target.value)}
          />
        </>
      );
    } else if (type === "dropdown" || type === "multidropdown") {
      const optionsArray = option.options || [];

      return (
        <>
          <InputLabel id={`select-label-${index}`}>{option.label}</InputLabel>
          <Select
            labelId={`select-label-${index}`}
            fullWidth
            value={
              type === "multidropdown" ? option.value || [] : option.value || ""
            }
            onChange={(e) => handleChange(index, "value", e.target.value)}
            multiple={type === "multidropdown"}
          >
            {optionsArray.map((opt, idx) => (
              <MenuItem key={idx} value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
        </>
      );
    } else if (type === "color") {
      return (
        <>
          <Typography variant="subtitle1" sx={{ marginRight: 1 }}>
            {option.label}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <input
              type="color"
              value={option.value || "#000000"}
              onChange={(e) => handleChange(index, "value", e.target.value)}
              style={{ cursor: "pointer" }}
            />
          </Box>
        </>
      );
    } else if (type === "cssStyle") {
      return (
        <>
          <Typography variant="subtitle1" sx={{ marginRight: 1 }}>
            {option.label}
          </Typography>
          <TextField
            fullWidth
            value={option.value || ""}
            onChange={(e) => handleChange(index, "value", e.target.value)}
            placeholder="CSS Style"
          />
        </>
      );
    } else if (type === "toggle") {
      return (
        <FormControlLabel
          control={
            <Switch
              checked={option.checked || false}
              onChange={(e) => handleChange(index, "checked", e.target.checked)}
              color="primary"
            />
          }
          label={option.label}
        />
      );
    } else {
      return null;
    }
  };

  const handleSave = () => {
    saveGroupOptions(group._id, options)
      .then(() => {
        console.log("Group options saved");
        onClose();
      })
      .catch((error) => {
        console.error("Failed to save group options", error);
      });
  };

  if (loading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <Grid container spacing={3}>
        {options.map((option, index) => (
          <Grid item key={option.id} xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: 1,
                borderColor: "divider",
                borderRadius: 1,
                p: 2,
                backgroundColor: "background.paper",
              }}
            >
              {renderOptionInput(index, option)}
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ marginRight: 1 }}
        >
          Save
        </Button>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Close
        </Button>
      </Box>

      {editingGroupId && (
        <Dialog open={editingGroupId !== null}>
          <DialogTitle>Edit Configuration</DialogTitle>
          <DialogContent>
            <GroupConfigEditor
              initialConfig={config}
              onSave={(newConfig) => {
                console.log("NewConfig:", newConfig);
                var tempConfig = config;
                tempConfig.arr = newConfig;
                setConfig(tempConfig);
                handleConfigSave();

                setEditingGroupId(null);
              }}
              onClose={() => setEditingGroupId(null)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditingGroupId(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default GroupOptions;
