import { useNavigate } from "react-router-dom";

const BtnStartDv = ({ buttonLink, isLarge }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    console.log("buttonLink", buttonLink);
    if (buttonLink) {
      navigate(buttonLink);
    }
  };

  // Calculate size multiplier based on isLarge prop
  const sizeMultiplier = isLarge ? 1.5 : 1;

  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
    >
      <button
        onClick={handleClick}
        style={{
          backgroundColor: "rgb(192, 44, 122)",
          color: "white",
          fontWeight: "bold",
          fontSize: `${18 * sizeMultiplier}px`, // Adjust font size based on isLarge
          padding: `${10 * sizeMultiplier}px ${20 * sizeMultiplier}px`, // Adjust padding based on isLarge
          border: "none",
          fontFamily: "Orbitron, sans-serif",
        }}
      >
        START YOUR DV REPORT NOW &gt;
      </button>
    </div>
  );
};

export default BtnStartDv;
