import { API_URI, APU_URL_FLOWS } from "../../../../../Globals";
import axios from "axios";

export const addPaymentMethod = () => {
  return async (dispatch) => {
    dispatch({ type: "ADD_PAYMENT_METHOD_REQUEST" });

    try {
      const serialAdmin = localStorage.getItem("serial_admin");
      const response = await axios.post(
        `${APU_URL_FLOWS()}/stripe/add-payment-method`,
        {
          shopSerial: serialAdmin,
        }
      );

      console.log("ADD_PAYMENT_METHOD_REQUEST->response:", response.data);

      // Check if the response code is 200 and redirect to the URL
      if (response.status === 200 && response.data.url) {
        window.location.href = response.data.url;
      }

      dispatch({ type: "ADD_PAYMENT_METHOD_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "ADD_PAYMENT_METHOD_FAILURE", payload: error.message });
    }
  };
};

export const removePaymentMethod = () => {
  return async (dispatch) => {
    dispatch({ type: "REMOVE_PAYMENT_METHOD_REQUEST" });

    try {
      const serialAdmin = localStorage.getItem("serial_admin");
      const response = await axios.post(
        `${APU_URL_FLOWS()}/stripe/remove-payment-method`,
        {
          shopSerial: serialAdmin,
        }
      );

      console.log("REMOVE_PAYMENT_METHOD_REQUEST->response:", response.data);
      if (response.status === 200 && response.data.url) {
        window.location.href = response.data.url;
      }

      dispatch({
        type: "REMOVE_PAYMENT_METHOD_SUCCESS",
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: "REMOVE_PAYMENT_METHOD_FAILURE",
        payload: error.message,
      });
    }
  };
};
