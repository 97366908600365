import axios from "axios";
import { API_URI } from "../../utils/config";

export const setProducts = (products) => ({
  type: "SET_PRODUCTS",
  payload: products,
});

export const fetchProducts = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(API_URI() + "/products");
      dispatch(setProducts(response.data));
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
};
