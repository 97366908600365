import axios from "axios";
import { API_URI } from "../../../../../Globals";
import getTableConfigs from "../UsageReports/TableConfig";

export const fetchShops = ({
  pageIndex,
  pageSize,
  columnFilters,
  globalFilter,
  sorting,
}) => {
  return async (dispatch, getState) => {
    dispatch({ type: "FETCH_SHOPS_REQUEST" });

    try {
      const { shops } = getState();
      const { activeShopSerial } = shops;
      const serialAdmin = localStorage.getItem("serial_admin");

      const serial = activeShopSerial || serialAdmin;

      const response = await axios.get(`${API_URI()}/adminShop/shops`, {
        params: {
          serial,
          pageIndex,
          pageSize,
          columnFilters,
          globalFilter,
          sorting,
        },
      });

      console.log("response:", response.data);

      dispatch({ type: "FETCH_SHOPS_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "FETCH_SHOPS_FAILURE", payload: error.message });
    }
  };
};

export const deleteUser = (serial) => {
  return async (dispatch) => {
    dispatch({ type: "DELETE_USER_REQUEST" });

    try {
      const response = await axios.delete(
        API_URI() + `/adminShop/users/delete/${serial}`
      );

      console.log("response local shop:", response.data);

      dispatch({ type: "DELETE_USER_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "DELETE_USER_FAILURE", payload: error.message });
    }
  };
};

export const storeLocalUser = (user) => {
  return async (dispatch) => {
    dispatch({ type: "STORE_USER_REQUEST", payload: user });
  };
};

export const updateUser = (user, sendPin = true) => {
  return async (dispatch) => {

    if(sendPin)
      dispatch({ type: "FETCH_LOCAL_SHOP_REQUEST" });

    try {
      console.log("Creating User:",user);
      const response = await axios.post(
        API_URI() + "/adminShop/createUser",
        user
      );
      console.log("Success:", response.data);

      const { Email, Serial } = response.data;
      const link = "https://www.mycrash.net/dashboard/resetpin?user=" + Serial;
      const company = localStorage.getItem("Company");

      try {
        if(sendPin === true)
          {
        const resetResponse = await axios.post(API_URI() + "/user/resetpin", {
          company,
          email:Email,
          link,
        });

          if (resetResponse.status === 200) {
            console.log("Pin reset email sent successfully.");
          }
        }

        if(sendPin)
          dispatch({ type: "FETCH_LOCAL_SHOP_SUCCESS", payload: response.data });
      
        return response.data;
      } catch (error) {
        if(sendPin)
          dispatch({ type: "FETCH_LOCAL_SHOP_FAILURE", payload: error.message });
      }
    } catch (error) {
      console.error("There was an error creating the user:", error);
      dispatch({ type: "FETCH_LOCAL_SHOP_FAILURE", payload: error.message });
    }
  };
};

export const fetchChildShopInfo = (shopId) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_CHILD_SHOP_INFO_REQUEST" });

    try {
      // Simulating an asynchronous API call
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Hardcoded mock response
      const childShopInfo = [
        {
          id: 1,
          name: "Child Shop 1",
          email: "childshop1@example.com",
          phone: "123-456-7890",
          parentShopId: shopId,
        },
        {
          id: 2,
          name: "Child Shop 2",
          email: "childshop2@example.com",
          phone: "987-654-3210",
          parentShopId: shopId,
        },
      ];

      dispatch({
        type: "FETCH_CHILD_SHOP_INFO_SUCCESS",
        payload: { shopId, childShopInfo },
      });
    } catch (error) {
      dispatch({
        type: "FETCH_CHILD_SHOP_INFO_FAILURE",
        payload: error.message,
      });
    }
  };
};

export const deleteShop = (shopId) => {
  return async (dispatch) => {
    dispatch({ type: "DELETE_SHOP_REQUEST" });

    try {
      const response = await axios.delete(
        `${API_URI()}/admin/shops/delete/` + shopId
      );

      console.log("response local shop:", response.data);

      dispatch({ type: "DELETEL_SHOP_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "DELETEL_SHOP_FAILURE", payload: error.message });
    }
  };
};

export const fetchLocalShop = () => {
  return async (dispatch, getState) => {
    dispatch({ type: "FETCH_LOCAL_SHOP_REQUEST" });
    try {
      const { shops } = getState();
      const { activeShopSerial } = shops;
      const serialAdmin = localStorage.getItem("serial_admin");
      let serial;
      if (activeShopSerial) {
        serial = activeShopSerial;
      } else {
        serial = serialAdmin;
      }
      console.log("Fetching Local Shop Serial:", serial);

      const response = await axios.get(`${API_URI()}/adminShop/shop/${serial}`);

      console.log("response local shop:", response.data);

      dispatch({ type: "FETCH_LOCAL_SHOP_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "FETCH_LOCAL_SHOP_FAILURE", payload: error.message });
    }
  };
};

export const resetPin = (serial, email) => {
  return async (dispatch) => {
    dispatch({ type: "RESET_USER_PIN_REQUEST" });
    var link = "https://www.mycrash.net/dashboard/resetpin?user=" + serial;

    try {
      const response = await axios.post(API_URI() + "/user/resetpin", {
        email,
        link,
      });

      console.log("response local shop:", response.data);

      dispatch({ type: "RESET_USER_PIN_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "RESET_USER_PIN_FAILURE", payload: error.message });
    }
  };
};

export const inviteToCrash = (shopSerial, email) => {
  return async (dispatch) => {
    dispatch({ type: "INVITE_CRASH_REQUEST" });

    try {
      const response = await axios.post(API_URI() + "/user/inviteToCrash", {
        email,
        shopSerial,
      });

      dispatch({ type: "INVITE_CRASH_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "INVITE_CRASH_FAILURE", payload: error.message });
    }
  };
};

export const fetchShopUsers = (serial) => {
  return async (dispatch, getState) => {
    dispatch({ type: "FETCH_SHOP_USERS_REQUEST" });

    try {
      const { shops } = getState();
      const { activeShopSerial } = shops;
      const serialToUse = activeShopSerial || serial;

      const response = await axios.get(
        `${API_URI()}/adminShop/users?serial=${serialToUse}`
      );

      console.log("response shop users:", response.data);

      dispatch({ type: "FETCH_SHOP_USERS_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "FETCH_SHOP_USERS_FAILURE", payload: error.message });
    }
  };
};

// Mock function for fetchUsers
export const fetchUsers = () => {
  return (dispatch) => {
    // Simulating an asynchronous API call
    setTimeout(() => {
      const users = [
        { id: 1, name: "User 1", email: "user1@example.com" },
        { id: 2, name: "User 2", email: "user2@example.com" },
        { id: 3, name: "User 3", email: "user3@example.com" },
      ];
      dispatch({ type: "FETCH_USERS_SUCCESS", payload: users });
    }, 1000);
  };
};

// Mock function for fetchLicenses
export const fetchLicenses = () => {
  return (dispatch) => {
    // Simulating an asynchronous API call
    setTimeout(() => {
      const licenses = [
        { id: 1, name: "License 1", validity: "2023-12-31" },
        { id: 2, name: "License 2", validity: "2024-06-30" },
        { id: 3, name: "License 3", validity: "2023-09-30" },
      ];
      dispatch({ type: "FETCH_LICENSES_SUCCESS", payload: licenses });
    }, 1000);
  };
};

// Mock function for fetchUsageReports
export const fetchUsageReports = () => {
  return (dispatch) => {
    // Simulating an asynchronous API call
    setTimeout(() => {
      const usageReports = [
        { id: 1, shopId: 1, month: "January", usage: 500 },
        { id: 2, shopId: 1, month: "February", usage: 600 },
        { id: 3, shopId: 2, month: "January", usage: 400 },
        { id: 4, shopId: 2, month: "February", usage: 550 },
      ];
      dispatch({ type: "FETCH_USAGE_REPORTS_SUCCESS", payload: usageReports });
    }, 1000);
  };
};

export const createShop = (shopData) => {
  return async (dispatch) => {
    dispatch({ type: "CREATE_SHOP_REQUEST" });

    try {
      const response = await axios.post(`${API_URI()}/admin/create`, shopData);
      const createdShop = response.data.shopData;

      dispatch({ type: "CREATE_SHOP_SUCCESS", payload: createdShop });
      return createdShop;
    } catch (error) {
      dispatch({ type: "CREATE_SHOP_FAILURE", payload: error.message });
      throw error;
    }
  };
};

export const updateShopLicenses = (
  serial,
  enabledLicenses,
  transactionalCounts
) => {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_SHOP_LICENSES_REQUEST" });

    try {
      const response = await axios.patch(
        `${API_URI()}/adminShop/updateActiveProducts`,
        {
          Serial: serial,
          ActiveProducts: enabledLicenses,
          ActiveProductsMeta: {
            AvailableTransactions: transactionalCounts,
          },
        }
      );

      dispatch({
        type: "UPDATE_SHOP_LICENSES_SUCCESS",
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_SHOP_LICENSES_FAILURE",
        payload: error.message,
      });
      throw error;
    }
  };
};

export const fetchShopLicenses = (shopSerial) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_SHOP_LICENSES_REQUEST" });

    try {
      const response = await axios.get(
        `${API_URI()}/admin/getShopActiveProducts/${shopSerial}`
      );
      dispatch({ type: "FETCH_SHOP_LICENSES_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "FETCH_SHOP_LICENSES_FAILURE", payload: error.message });
      throw error;
    }
  };
};

export const fetchProducts = () => {
  return async (dispatch, getState) => {
    dispatch({ type: "FETCH_PRODUCTS_REQUEST" });

    try {
      const { user } = getState().auth;
      let response;

      response = await axios.get(API_URI() + "/admin/getAllProducts");

      console.log("Raw Products:", response.data);

      dispatch({ type: "FETCH_PRODUCTS_SUCCESS", payload: response.data });
    } catch (error) {
      console.error("Error fetching products:", error);
      dispatch({ type: "FETCH_PRODUCTS_FAILURE", payload: error.message });
    }
  };
};

export const fetchUsageReportData = (
  page,
  pageSize,
  application,
  startDate,
  endDate,
  columnFilters,
  globalFilter,
  sortModel,
  user,
  includeAllShopUsage
) => {
  return async (dispatch, getState) => {
    console.log("Fetching usage report data:", user);
    dispatch({ type: "FETCH_USAGE_REPORT_DATA_REQUEST" });

    try {
      const { shops } = getState();
      const { activeShopSerial } = shops;
      let uri;

      if (user.role === "superAdmin") {
        uri = API_URI() + "/admin/data";
      } else if (user.role === "shopAdmin") {
        const serialToUse = activeShopSerial || user.Serial;
        uri = API_URI() + "/admin/data?serial=" + serialToUse;
      } else {
        throw new Error("Unhandled user role");
      }

      const prepareColumnFiltersForApi = (columnFilters) => {
        return columnFilters.reduce((acc, filter) => {
          acc[`filter.${filter.id}`] = filter.value;
          return acc;
        }, {});
      };

      const columnFilterParams = prepareColumnFiltersForApi(columnFilters);
      const sortParam = sortModel
        .map((s) => `${s.id}:${s.desc ? "desc" : "asc"}`)
        .join(",");

      const response = await axios.get(uri, {
        params: {
          page,
          pageSize,
          application,
          globalFilter,
          sort: sortParam,
          startDate,
          endDate,
          dateField: getTableConfigs(application).dateKey,
          includeAllShopUsage,
          ...columnFilterParams,
        },
      });

      console.log("Raw Data:", response.data);

      const transformedData = response.data.data.map((item) => {
        let vin = item.vin || "";

        if (!vin && item.Payload) {
          try {
            const payload = JSON.parse(item.Payload);
            vin = payload.vin || "";
          } catch (error) {
            console.error("Error parsing Payload JSON:", error);
          }
        }

        return {
          ...item,
          vin,
        };
      });

      dispatch({
        type: "FETCH_USAGE_REPORT_DATA_SUCCESS",
        payload: {
          data: transformedData,
          paginationInfo: {
            totalDocs: response.data.totalDocs,
            page: response.data.page,
            pageSize: response.data.pageSize,
            totalPages: response.data.totalPages,
          },
        },
      });
    } catch (error) {
      console.error("Error fetching usage report data:", error);
      dispatch({
        type: "FETCH_USAGE_REPORT_DATA_FAILURE",
        payload: error.message,
      });
    }
  };
};

export const SET_ACTIVE_SHOP_SERIAL = "SET_ACTIVE_SHOP_SERIAL";

export const setActiveShopSerial = (shopSerial) => {
  return {
    type: SET_ACTIVE_SHOP_SERIAL,
    payload: shopSerial,
  };
};
