import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import axios from "axios";
import { API_URI, APU_URL_FLOWS } from "../Globals";
import { GooSpinner } from "react-spinners-kit";
import { InputAdornment } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import StoreIcon from "@mui/icons-material/Store";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PublicIcon from "@mui/icons-material/Public";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { CircularProgress } from "@mui/material";
import InputMask from "react-input-mask";
export default function SignupFormMSO() {
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    shopType: "", // Add this field to store the shop type
    shopName: "",
    shopEmail: "",
    shopPhone: "",
    shopContact: "",
    street: "",
    state: "",
    zipcode: "",
    billingStreet: "",
    billingState: "",
    billingZipcode: "",
    billingSameAsShop: true,
    totalShops: "", // Add this field for MSO
    signupShops: 1, // Add this field for MSO
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [inviteCode, setInviteCode] = useState(null);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const shopType = searchParams.get("shopType");
    const ic = searchParams.get("inviteCode");
    setInviteCode(ic);
    setFormData((prevState) => ({
      ...prevState,
      shopType: shopType || "",
    }));
  }, []);

  const validateForm = () => {
    let errors = {}; // Object to hold any form errors

    // Remove all special characters from shopName
    formData.shopName = formData.shopName.replace(/[^a-zA-Z0-9 ]/g, "");

    // List of fields to validate
    const fieldsToValidate = [
      "shopName",
      "shopEmail",
      "shopPhone",
      "shopContact",
      "street",
      "state",
      "zipcode",
    ];

    fieldsToValidate.forEach((field) => {
      if (!formData[field].trim()) {
        errors[field] = "This field is required";
      }
    });

    // Additional fields when billing address is separate
    if (!formData.billingSameAsShop) {
      const billingFields = ["billingStreet", "billingState", "billingZipcode"];
      billingFields.forEach((field) => {
        if (!formData[field].trim()) {
          errors[field] = "This field is required";
        }
      });
    }

    if (formData.shopType === "mso") {
      const msoFields = ["signupShops"];
      msoFields.forEach((field) => {
        if (!formData[field].trim()) {
          errors[field] = "This field is required";
        }
      });
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (event) => {
    setIsProcessing(true);
    async function submitCheckout(checkoutData) {
      const response = await axios.post(
        APU_URL_FLOWS() + `/stripe/handleInvite`,
        { newShopDetails: checkoutData, inviteCode }
      );

      if (response.data.status === true) {
        document.location = response.data.url;
      }
      console.log("Submit Checkout Response:", response);
    }

    event.preventDefault();

    if (validateForm()) {
      const checkoutData = {
        ...formData,
      };

      console.log("Checkout Data:", checkoutData);
      submitCheckout(checkoutData);
    } else {
      console.log("Validation failed. Errors:", formErrors);
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          height: "100vh", // Take full viewport height
        }}
      >
        <GooSpinner size={200} color="#000000" loading={true} />
      </div>
    );
  }

  return (
    <>
      {isProcessing && (
        <Modal
          open={true}
          onClose={() => {}}
          aria-labelledby="processing-modal-title"
          aria-describedby="processing-modal-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
          }}
        >
          <Card
            sx={{
              minWidth: 350,
              maxWidth: 600,
              m: 4,
              textAlign: "center",
              p: 4,
              borderRadius: "16px", // Increased border radius for a softer look
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)", // Added box shadow for depth
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  color: "primary.main",
                  mb: 4,
                }}
              >
                Processing...
              </Typography>
              <div
                style={{
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={60} color="primary" />
              </div>
              <Typography
                variant="body1"
                sx={{
                  mt: 4,
                  color: "text.secondary",
                  fontWeight: "medium",
                  maxWidth: "80%",
                  mx: "auto",
                }}
              >
                We are processing your information. This may take a moment.
                Please wait...
              </Typography>
            </CardContent>
          </Card>
        </Modal>
      )}
      <Modal
        open={true}
        onClose={() => {}}
        aria-labelledby="signup-modal-title"
        aria-describedby="signup-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Card sx={{ minWidth: 350, maxWidth: 600, m: 4 }}>
          <CardContent>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", mb: 3, textAlign: "center" }}
            >
              MyCRASH Signup
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={
                      formData.shopType === "mso"
                        ? "Parent Company Name"
                        : "Shop Name"
                    }
                    name="shopName"
                    value={formData.shopName}
                    onChange={handleInputChange}
                    error={!!formErrors.shopName}
                    helperText={formErrors.shopName}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <StoreIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={
                      formData.shopType === "mso"
                        ? "Parent Company Email"
                        : "Shop Email"
                    }
                    name="shopEmail"
                    value={formData.shopEmail}
                    error={!!formErrors.shopEmail}
                    helperText={formErrors.shopEmail}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    mask="(999) 999-9999"
                    value={formData.shopPhone}
                    onChange={handleInputChange}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        fullWidth
                        label={
                          formData.shopType === "mso"
                            ? "Parent Company Phone Number"
                            : "Shop Phone Number"
                        }
                        name="shopPhone"
                        error={!!formErrors.shopPhone}
                        helperText={formErrors.shopPhone}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={
                      formData.shopType === "mso"
                        ? "Parent Company Contact Name"
                        : "Shop Contact Name"
                    }
                    name="shopContact"
                    value={formData.shopContact}
                    error={!!formErrors.shopContact}
                    helperText={formErrors.shopContact}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={6} sm={5}>
                  <TextField
                    fullWidth
                    label="Street Address"
                    name="street"
                    value={formData.street}
                    error={!!formErrors.street}
                    helperText={formErrors.street}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationOnIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3} sm={3.5}>
                  <TextField
                    fullWidth
                    label="State"
                    name="state"
                    value={formData.state}
                    error={!!formErrors.state}
                    helperText={formErrors.state}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PublicIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3} sm={3.5}>
                  <TextField
                    fullWidth
                    label="Zipcode"
                    name="zipcode"
                    value={formData.zipcode}
                    error={!!formErrors.zipcode}
                    helperText={formErrors.zipcode}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {!formData.billingSameAsShop && (
                  <>
                    <Grid item xs={6} sm={5}>
                      <TextField
                        fullWidth
                        label="Billing Street Address"
                        name="billingStreet"
                        value={formData.billingStreet}
                        error={!!formErrors.billingStreet}
                        helperText={formErrors.billingStreet}
                        onChange={handleInputChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationOnIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3.5}>
                      <TextField
                        fullWidth
                        label="Billing State"
                        name="billingState"
                        value={formData.billingState}
                        error={!!formErrors.billingState}
                        helperText={formErrors.billingState}
                        onChange={handleInputChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PublicIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3.5}>
                      <TextField
                        fullWidth
                        label="Billing Zipcode"
                        name="billingZipcode"
                        value={formData.billingZipcode}
                        error={!!formErrors.billingZipcode}
                        helperText={formErrors.billingZipcode}
                        onChange={handleInputChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailOutlineIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" fullWidth>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Modal>
    </>
  );
}
