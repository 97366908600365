import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const _4_VehicleInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return <div></div>;
};

export default _4_VehicleInfo;
