import { combineReducers } from "redux";
import productsReducer from "./products/reducer";
import diminishedValueReducer from "./diminishedvalue/reducer";

const rootReducer = combineReducers({
  products: productsReducer,
  diminishedValue: diminishedValueReducer,
});

export default rootReducer;
