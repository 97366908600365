import axios from "axios";
import { API_URI } from "../Globals";

const apiEndpoint = API_URI();

const debugEnabled = true;

export const GetLicenseBySerial = (serial, onComplete) => {
  const options = {
    method: "GET",
    url: apiEndpoint + "/user/byserial/" + serial,
  };

  axios
    .request(options)
    .then(function (response) {
      let xml = response.data;
      console.log("LicenseBySerialResponse:", response.data);

      if (debugEnabled) {
        console.log(xml);
        console.log(response.data);
      }

      onComplete("licenseDoc", xml);
    })
    .catch(function (error) {
      onComplete("licenseDoc", null);

      if (debugEnabled) console.error(error);
    });
};

export const GetClientLicenses = async (
  clientId,
  onComplete,
  dontParseExml
) => {
  const options = {
    method: "GET",
    url: apiEndpoint + "/user/getclientlicenses/" + clientId,
  };

  await axios
    .request(options)
    .then(function (response) {
      let xml = response.data;

      if (debugEnabled) {
        console.log(xml);
        console.log(response.data);
      }

      if (dontParseExml === true) onComplete("licenses", response.data);
      else onComplete("licenses", xml);
    })
    .catch(function (error) {
      onComplete("licenses", null);

      if (debugEnabled) console.error(error);
    });
};

export const ValidateLicense = async (license, onComplete) => {
  console.log("Checking License:", license);
  const options = {
    method: "GET",
    url: apiEndpoint + "/user/validate/" + license,
  };

  await axios
    .request(options)
    .then(function (response) {
      onComplete(response.data);
    })
    .catch(function (error) {
      onComplete(null);
    });
};

export const SetProprety = async (serialno, value, onComplete) => {
  const options = {
    method: "GET",
    url: apiEndpoint + "/user/setproprety/" + serialno + "?value=" + value,
  };

  await axios
    .request(options)
    .then(function (response) {
      let xml = response.data;

      if (debugEnabled) {
        console.log(xml);
        console.log(response.data);
      }

      onComplete("setproprety", xml);
    })
    .catch(function (error) {
      onComplete("setproprety", null);

      //   console.error(error);
    });
};

export const LockProfile = async (onComplete) => {
  var serialno = localStorage.getItem("hasEAuditSerial");

  const options = {
    method: "GET",
    url: apiEndpoint + "/user/lockprofile/" + serialno,
  };

  await axios
    .request(options)
    .then(function (response) {
      let xml = response.data;

      if (debugEnabled) {
        console.log(xml);
        console.log(response.data);
      }

      onComplete("setproprety", xml);
    })
    .catch(function (error) {
      onComplete("setproprety", null);

      //   console.error(error);
    });
};

export const GetClientAsync = () => {
  return new Promise((resolve, reject) => {
    const options = {
      method: "GET",
      url:
        apiEndpoint + "/user/getclient/" + localStorage.getItem("customerId"),
    };

    axios
      .request(options)
      .then(function (response) {
        if (debugEnabled) {
          console.log(response.data);
        }

        try {
          const jsonObj = decodeURIComponent(
            response.data.rsp.Customer.Company
          );
          console.log("ReqGetClient", jsonObj);
          const buff = atob(jsonObj);
          jsonObj = JSON.parse(buff);
          response.data.rsp.Customer.Company = jsonObj;
          resolve(response.data);
        } catch {
          const companyObj = {};
          response.data.rsp.Customer.Company = companyObj;
          resolve(response.data); // If you want to continue even if there's an error in this block
        }
      })
      .catch(function (error) {
        if (debugEnabled) console.error(error);
        reject(error);
      });
  });
};

export const GetClient = (onComplete) => {
  const options = {
    method: "GET",
    url: apiEndpoint + "/user/getclient/" + localStorage.getItem("customerId"),
  };

  axios
    .request(options)
    .then(function (response) {
      if (debugEnabled) {
        console.log(response.data);
      }

      try {
        var jsonObj = decodeURIComponent(response.data.rsp.Customer.Company);
        console.log("ReqGetClient", jsonObj);
        const buff = atob(jsonObj);
        //   console.log(buff);
        //const str = buff.toString("utf-8");
        jsonObj = JSON.parse(buff);
        response.data.rsp.Customer.Company = jsonObj;
      } catch {
        var companyObj = {};

        response.data.rsp.Customer.Company = companyObj;
      }

      onComplete(response.data);
    })
    .catch(function (error) {
      onComplete(null);

      if (debugEnabled) console.error(error);
    });
};

export const DescribeLicense = (serial, onComplete) => {
  const options = {
    method: "GET",
    url: apiEndpoint + "/user/describelicense/" + serial,
  };

  axios
    .request(options)
    .then(function (response) {
      let xml = response.data;

      if (debugEnabled) {
        console.log(xml);
        console.log(response.data);
      }

      onComplete("licenseDescDoc", xml);
    })
    .catch(function (error) {
      onComplete("licenseDescDoc", null);

      if (debugEnabled) console.error(error);
    });
};

export const UpdateProfile = (
  customerid,
  email,
  phone,
  street1,
  street2,
  name,
  company,
  city,
  country,
  postalCode,
  state,
  onComplete
) => {
  var payload = {};
  payload.Email = email;
  payload.Phone = phone;
  payload.Street1 = street1;
  payload.Street2 = street2;
  payload.Name = name;
  payload.Company = company;
  payload.City = city;
  payload.Country = country;
  payload.State = state;

  const options = {
    method: "POST",
    url: apiEndpoint + "/user/updatecustomer/" + customerid,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(payload),
  };

  axios
    .request(options)
    .then(function (response) {
      console.log(response.data);
      onComplete();
    })
    .catch(function (error) {
      console.error(error);
    });
};
