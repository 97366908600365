// styles.js
export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: "15px",
    borderBottom: "1px solid #ccc",
    cursor: "pointer",
    backgroundColor: state.isSelected
      ? "rgba(192, 44, 122, 0.1)"
      : state.isFocused
      ? "rgba(192, 44, 122, 0.2)"
      : "white",
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "16px",
    fontFamily: "Orbitron, sans-serif",
    zIndex: 3, // Ensure option is above other elements
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "rgba(111, 111, 111, 0.039)",
    fontFamily: "Roboto, sans-serif",
    border: "1px solid #a0a0a0",
    borderRadius: "0",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#a0a0a0",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: "bold",
    color: "#000",
    fontSize: "16px",
    fontFamily: "Orbitron, sans-serif",
    textAlign: "left",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "rgb(135, 135, 135)",
    fontFamily: "Roboto, sans-serif",
    textAlign: "left",
    fontSize: "14px",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "300px",
    zIndex: 3, // Ensure menuList is above other elements
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
    backgroundColor: "white", // Solid background for the menu
  }),
};

export const options = [
  {
    modifier: 1,
    value: "excellent",
    label: (
      <span style={{ color: "#000", fontSize: "14px" }}>
        <span style={{ fontWeight: "bold", color: "rgb(192, 44, 122)" }}>
          EXCELLENT
        </span>{" "}
        - No prior repairs to vehicle, No cosmetic damages. Like New!
      </span>
    ),
  },
  {
    modifier: 0.75,
    value: "good",
    label: (
      <span style={{ color: "#000", fontSize: "14px" }}>
        <span style={{ fontWeight: "bold", color: "rgb(192, 44, 122)" }}>
          GOOD
        </span>{" "}
        - Vehicle is above average for year make and model and has low mileage
        in comparison to other like vehicles. No cosmetic damages or signs of
        previous repairs.
      </span>
    ),
  },
  {
    modifier: 0.5,
    value: "average",
    label: (
      <span style={{ color: "#000", fontSize: "14px" }}>
        <span style={{ fontWeight: "bold", color: "rgb(192, 44, 122)" }}>
          AVERAGE
        </span>{" "}
        - Typical condition for the year, make, and model. Might include
        cosmetic imperfections, but the vehicle has been well-maintained.
      </span>
    ),
  },
  {
    modifier: 0.25,
    value: "fair",
    label: (
      <span style={{ color: "#000", fontSize: "14px" }}>
        <span style={{ fontWeight: "bold", color: "rgb(192, 44, 122)" }}>
          FAIR
        </span>{" "}
        - The vehicle is in moderate condition. Previous repairs have been
        addressed, though it may display cosmetic damages, maintenance issues,
        and require minor repairs.
      </span>
    ),
  },
  {
    modifier: 0.1,
    value: "unrepaired",
    label: (
      <span style={{ color: "#000", fontSize: "14px" }}>
        <span style={{ fontWeight: "bold", color: "rgb(192, 44, 122)" }}>
          UNREPAIRED DAMAGES
        </span>{" "}
        - Vehicle has sustained a previous loss that has not been repaired.
      </span>
    ),
  },
  {
    modifier: 0,
    value: "salvage",
    label: (
      <span style={{ color: "#000", fontSize: "14px" }}>
        <span style={{ fontWeight: "bold", color: "rgb(192, 44, 122)" }}>
          TOTAL LOSS / SALVAGE TITLE
        </span>{" "}
        - Vehicle was a total loss and has a salvage title for a prior loss.
      </span>
    ),
  },
];
