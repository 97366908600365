import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import StepHeader from "../components/StepHeader";
import DamageSeverityDropdown from "../components/DamageSeverityDropdown"; // Import the new component
import InputField from "../components/InputField"; // Import the InputField component
import {
  setInsuranceCompany,
  setClaimNo,
  setDateOfLoss,
  setEstimateTotal,
  setDamageSeverity,
} from "../../../redux/diminishedvalue/actions";
import { customStyles } from "./3m_VehicleDetails/styles"; // Import styles

const MainContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [lockedFields, setLockedFields] = useState({
    dateOfClaim: false,
    repairCostTotal: false,
    damageSeverity: false,
  });

  const insuranceCompany = useSelector(
    (state) => state.diminishedValue.insuranceCompany
  );
  const claimNumber = useSelector((state) => state.diminishedValue.claimNo);
  const dateOfClaim = useSelector((state) => state.diminishedValue.dateOfLoss);
  const repairCostTotal = useSelector(
    (state) => state.diminishedValue.estimateTotal
  );
  const damageSeverity = useSelector(
    (state) => state.diminishedValue.damageSeverity
  );
  const selectedFile = useSelector(
    (state) => state.diminishedValue.selectedFile
  );
  const handleBackClick = () => {
    if(selectedFile)
      navigate("/instantdv/3m_VehicleDetails");
    else
    navigate("/instantdv/4m_VehicleDamage");
  }

  const handleNextClick = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length === 0) {
      navigate("/instantdv/6_OwnerInformation");
    } else {
      setErrors(validationErrors);
    }
  };

  const validateFields = () => {
    const errors = {};

    if (!dateOfClaim) {
      errors.dateOfClaim = "Date of Claim is required";
    }
    if (!repairCostTotal) {
      errors.repairCostTotal = "Repair Cost Total is required";
    }
    if (typeof damageSeverity === "") {
      errors.damageSeverity = "Damage Severity is required";
    }
    return errors;
  };

  function formatMoney(amount) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }

  function removeFormatting(value) {
    return value.replace(/[^0-9.]/g, "");
  }

  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "40px",
        minHeight: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ maxWidth: "400px", width: "100%", zIndex: 2 }}>
        <h2
          onClick={() => navigate("/instantdv/3m_VehicleDetails")}
          style={{
            color: "rgb(192, 44, 122)",
            fontSize: "24px",
            fontFamily: "Orbitron, sans-serif",
            fontWeight: "bold",
            cursor: "pointer", // Change cursor on hover
          }}
        >
          CLAIM INFORMATION
        </h2>
        <InputField
          label="Insurance Company"
          value={insuranceCompany}
          onChange={(e) => dispatch(setInsuranceCompany(e.target.value))}
          placeholder="Insurance Company"
          error={errors.insuranceCompany}
          isRequired={false}
        />
        <InputField
          label="Claim Number"
          value={claimNumber}
          onChange={(e) => dispatch(setClaimNo(e.target.value))}
          placeholder="Claim Number"
          error={errors.claimNumber}
          isRequired={false}
        />
       <div style={{ marginTop: "20px" }}>
  <label
    htmlFor="dateOfClaim"
    style={{
      display: "block",
      textAlign: "left",
      color: errors.dateOfClaim ? "red" : "inherit",
      fontWeight: errors.dateOfClaim ? "bold" : "normal",
      fontFamily: "Roboto, sans-serif", // Add font family
      fontSize: "16px", // Add font size
    }}
  >
    Date of Claim *
  </label>
  <input
    type="date"
    id="dateOfClaim"
    value={dateOfClaim}
    onChange={(e) => dispatch(setDateOfLoss(e.target.value))}
    style={{
      width: "100%",
      padding: "10px",
      borderRadius: "4px",
      border: errors.dateOfClaim ? "1px solid red" : "1px solid #ccc",
      boxSizing: "border-box",
      fontFamily: "Roboto, sans-serif", // Add font family
      fontSize: "15px", // Add font size
    }}
  />
  {errors.dateOfClaim && (
    <div style={{ color: "red", textAlign: "left" }}>
      {errors.dateOfClaim}
    </div>
  )}
</div>
        <InputField
          label="Repair Cost Total *"
          value={repairCostTotal}
          onChange={(e) => dispatch(setEstimateTotal(e.target.value))}
          placeholder="Repair Cost Total"
          error={errors.repairCostTotal}
          isRequired={true}
        />
        <div style={{ marginTop: "20px", zIndex: 10 }}>
          <DamageSeverityDropdown
            damageSeverity={damageSeverity}
            setDamageSeverity={(value) =>
              !lockedFields.damageSeverity && dispatch(setDamageSeverity(value))
            }
            errors={errors}
            customStyles={customStyles}
            options={[
              {
                modifier: 1.0,
                value: "severe",
                label: (
                  <span style={{ color: "#000", fontSize: "14px" }}>
                    <span
                      style={{ fontWeight: "bold", color: "rgb(192, 44, 122)" }}
                    >
                      SEVERE
                    </span>{" "}
                    - Severe structural damages to exterior and inner components
                    of vehicle. Severe damages to safety, reinforcement and
                    welded structural panels REPLACED.
                  </span>
                ),
              },
              {
                modifier: 0.75,
                value: "major",
                label: (
                  <span style={{ color: "#000", fontSize: "14px" }}>
                    <span
                      style={{ fontWeight: "bold", color: "rgb(192, 44, 122)" }}
                    >
                      MAJOR
                    </span>{" "}
                    - Major structural damages to exterior and inner components
                    of vehicle. Major damages to safety components and
                    reinforcement and welded structural panels REPAIRED and
                    REPLACED.
                  </span>
                ),
              },
              {
                modifier: 0.5,
                value: "moderate",
                label: (
                  <span style={{ color: "#000", fontSize: "14px" }}>
                    <span
                      style={{ fontWeight: "bold", color: "rgb(192, 44, 122)" }}
                    >
                      MODERATE
                    </span>{" "}
                    - Moderate structural and cosmetic damages to exterior and
                    inner components of vehicle. Exterior parts replacement
                    parts and moderate repairs to safety and welded structural
                    components of vehicle REPAIRED.
                  </span>
                ),
              },
              {
                modifier: 0.25,
                value: "minor",
                label: (
                  <span style={{ color: "#000", fontSize: "14px" }}>
                    <span
                      style={{ fontWeight: "bold", color: "rgb(192, 44, 122)" }}
                    >
                      MINOR
                    </span>{" "}
                    - Minor damages to exterior panels with possible bolt on
                    parts replacement. Minor repairs and parts replacement to
                    exterior of vehicle only.
                  </span>
                ),
              },
              {
                modifier: 0.0,
                value: "cosmetic",
                label: (
                  <span style={{ color: "#000", fontSize: "14px" }}>
                    <span
                      style={{ fontWeight: "bold", color: "rgb(192, 44, 122)" }}
                    >
                      COSMETIC
                    </span>{" "}
                    - Cosmetic damages to exterior of vehicle. i.e: surface
                    scratches, key scratches, small dent repairs and exterior
                    refinish only.
                  </span>
                ),
              },
            ]}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "40px",
        }}
      >
        <button
          onClick={handleBackClick}
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "rgb(192, 44, 122)",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "bold",
            cursor: "pointer",
            marginRight: "10px", // Adds space between the buttons
          }}
        >
          &lt; BACK
        </button>
        <button
          onClick={handleNextClick}
          style={{
            backgroundColor: "transparent",
            color: "rgb(192, 44, 122)",
            fontWeight: "bold",
            fontSize: "18px",
            padding: "10px 20px",
            border: "none",
            cursor: "pointer",
            fontFamily: "Roboto, sans-serif",
          }}
        >
          NEXT &gt;
        </button>
      </div>
    </div>
  );
};

const _5_ClaimInformation = () => {
  return (
    <div style={{ maxWidth: "1500px", margin: "0 auto" }}>
      <Header
        isGrey={true}
        buttonText="DRIVE AWAY WITH A FAIR COMPENSATION"
        style={{ marginLeft: 0 }}
      />
      <div
        style={{
          position: "relative",
          overflow: "visible",
          minHeight: "400px",
          paddingBottom: "60px",
        }}
      >
        <img
          src="/img/diminishedvalue/dv_car_r.png"
          alt="DV Car"
          style={{
            position: "absolute",
            left: "0",
            top: "50%",
            transform: "translateY(-50%)",
            width: "25%",
            height: "auto",
            zIndex: -1,
          }}
        />
        <img
          src="/img/diminishedvalue/dv_car_l.png"
          alt="DV Car"
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translateY(-50%) ",
            width: "25%",
            height: "auto",
            zIndex: -1,
          }}
        />
        <div style={{ position: "relative", zIndex: 1 }}>
          <StepHeader tstep={"Claim Information"} />
          <MainContent />
        </div>
      </div>
      <Footer isGrey={true} />
    </div>
  );
};

export default _5_ClaimInformation;
