import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/store"; // Assuming you have created a Redux store
import ShopDashboardAdmin from "./ShopDashboardAdmin";

const ShopDashboardReduxWrapper = () => {
  return (
    <Provider store={store}>
      <ShopDashboardAdmin />
    </Provider>
  );
};

export default ShopDashboardReduxWrapper;
