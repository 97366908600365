import React, { useEffect } from "react";
import { checkLocalLicense } from "../../util/license.extension";
import { Button, Box, Paper, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function AutoEstimateRekeySubmitted() {
  const mainLogoPath = "../../../img/dashboard/CrashLogo_Final_NoFooter.png";
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: "1 0 auto",
        }}
      >
        <Container component="main" maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <img
              src={mainLogoPath}
              alt="main logo"
              style={{ maxWidth: "750px" }}
            />
            <Paper
              elevation={3}
              sx={{ padding: "20px", marginTop: "20px", width: "100%" }}
            >
              <Typography
                variant="h2"
                sx={{ mb: 3, fontWeight: "bold", textAlign: "center" }}
              >
                Auto Estimate Rekey
              </Typography>
              <Typography
                sx={{
                  mb: 3,
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                Your estimate is being processed, You will receive an email when
                your estimate is done processing. Please make sure to review and
                verify all parts data is correct on your estimate after it has
                been rekeyed. You will also need to select an insurance company
                and enter the customer contact information if you selected into
                New Workfile.
              </Typography>
              <Typography
                sx={{
                  mb: 3,
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                If there are any issues with your estimate the Kri-Tech Support
                team will be notified and will contact you with further
                information/instructions.
              </Typography>
              <div
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  px: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mr: 9 }} // Right margin on the first button
                  onClick={() => {
                    navigate("/dashboard/rekey");
                  }}
                >
                  AERK Home Page
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ml: 9 }} // Left margin on the second button
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                >
                  Hub Home Page
                </Button>
              </div>
            </Paper>
          </Box>
        </Container>
      </Box>
      <Box
        component="footer"
        sx={{ textAlign: "center", py: "10px", marginTop: "auto" }}
      >
        <Typography variant="body2" color="textSecondary" sx={{ mx: "auto" }}>
          © {currentYear} KRI-TECH SOLUTIONS LLC
        </Typography>
      </Box>
    </Box>
  );
}
