import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import { API_URI, APU_URL_FLOWS } from "../Globals";
import { GooSpinner } from "react-spinners-kit";
import { InputAdornment } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import StoreIcon from "@mui/icons-material/Store";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PublicIcon from "@mui/icons-material/Public";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { CircularProgress } from "@mui/material";
import InputMask from "react-input-mask";
import { Tooltip } from "@mui/material";
import { red } from "@mui/material/colors";

export default function SignupForm() {
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    shopType: "", // Add this field to store the shop type
    shopName: "",
    shopEmail: "",
    shopPhone: "",
    shopContact: "",
    street: "",
    state: "",
    zipcode: "",
    billingStreet: "",
    billingState: "",
    billingZipcode: "",
    billingSameAsShop: true,
    totalShops: "", // Add this field for MSO
    signupShops: 1, // Add this field for MSO
  });
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponStatus, setCouponStatus] = useState(null);
  const [showExistingEmailModal, setShowExistingEmailModal] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const stateOptions = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];
  const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);

  const handleCouponSubmit = async (event) => {
    event.preventDefault();
    setIsApplyingCoupon(true);
    const searchParams = new URLSearchParams(window.location.search);
    const shopType = searchParams.get("shopType");
    const couponCodeParam = searchParams.get("couponCode");
    const emailParam = searchParams.get("email");
    try {
      const response = await axios.post(API_URI() + `/admin/validate-coupon`, {
        code: couponCodeParam,
      });
      if (response.status === 200) {
        setCouponStatus({ valid: true, message: "Code applied" });
        setCouponApplied(true);
      } else {
        setCouponStatus({
          valid: false,
          message: response.data.message || "Invalid Code",
        });
      }
    } catch (error) {
      setCouponStatus({
        valid: false,
        message: "Invalid Code",
      });
    }
    setIsApplyingCoupon(false);
  };

  useEffect(() => {
    async function fetchECommProducts() {
      const response = await axios.get(
        API_URI() + `/admin/fetch-ecommerce-products`
      );
      if (response.data && response.data.success) {
        console.log(response.data.data);
        setProducts(response.data.data);
      }
    }
    fetchECommProducts();
  }, []);

  useEffect(() => {
    if (products.length === 0) return;

    const searchParams = new URLSearchParams(window.location.search);
    const shopType = searchParams.get("shopType");
    const couponCodeParam = searchParams.get("couponCode");
    const emailParam = searchParams.get("email");

    setFormData((prevState) => ({
      ...prevState,
      shopType: shopType || "",
      signupShops: shopType === "mso" ? 2 : 1,
    }));

    if (emailParam) {
      fetchAccountInfo(emailParam);
    } else {
      setIsLoading(false);
    }
  }, [products]);

  useEffect(() => {
    if (products.length === 0) return;
    const searchParams = new URLSearchParams(window.location.search);
    const couponCodeParam = searchParams.get("couponCode");

    if (couponCodeParam) {
      setCouponCode(couponCodeParam);
      console.log("Fetching coupon:", couponCodeParam);
      setIsApplyingCoupon(true);
      setTimeout(() => {
        handleCouponSubmit({ preventDefault: () => {} });
      }, 3000);
    }
  }, [products]);

  const fetchAccountInfo = async (email) => {
    try {
      const response = await axios.get(
        `${APU_URL_FLOWS()}/salesforce/account-info/${email}`
      );
      const accountInfo = response.data;
      console.log("Account Info:", accountInfo);
      setFormData((prevState) => ({
        ...prevState,
        shopName: accountInfo.companyName || "",
        shopEmail: accountInfo.billingEmail || "",
        shopPhone: accountInfo.phone || "",
        street: accountInfo.billingAddress.street || "",
        city: accountInfo.billingAddress.city || "",
        state: accountInfo.billingAddress.state || "",
        zipcode: accountInfo.billingZip || "",
      }));

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching account info:", error);
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    let errors = {};

    // Remove all special characters from shopName
    formData.shopName = formData.shopName.replace(/[^a-zA-Z0-9 ]/g, "");

    // List of fields to validate
    const fieldsToValidate = [
      "shopName",
      "shopEmail",
      "shopPhone",
      "shopContact",
      "street",
      "state",
      "zipcode",
      "city",
    ];

    fieldsToValidate.forEach((field) => {
      if (!formData[field] || !formData[field].trim()) {
        errors[field] = "This field is required";
      }
    });

    // Validate zipcode length
    if (formData.zipcode.length !== 5) {
      errors.zipcode = "Zipcode must be exactly 5 characters";
    }

    // Additional fields when billing address is separate
    if (!formData.billingSameAsShop) {
      const billingFields = ["billingStreet", "billingState", "billingZipcode"];
      billingFields.forEach((field) => {
        if (!formData[field].trim()) {
          errors[field] = "This field is required";
        }
      });

      // Validate billing zipcode length
      if (formData.billingZipcode.length !== 5) {
        errors.billingZipcode = "Billing Zipcode must be exactly 5 digits";
      }
    }

    if (formData.shopType === "mso") {
      const msoFields = ["signupShops"];
      msoFields.forEach((field) => {
        console.log("Field:", formData[field]);
        if (typeof formData[field] !== "number" && !formData[field].trim()) {
          errors[field] = "This field is required";
        }
      });
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Prevent multiple submissions
    if (isSubmitting) {
      return;
    }
  
    // Validate the form
    if (validateForm()) {
      setIsSubmitting(true);
      setIsProcessing(true);
  
      try {
        // Check if the email already exists in the system
        const response = await axios.post(
          APU_URL_FLOWS() + `/stripe/check-existing-email`,
          {
            email: formData.shopEmail,
          }
        );
  
        if (response.data.exists) {
          // If the email exists, show the modal with the specified message
          setIsProcessing(false);
          setShowExistingEmailModal(true);
        } else {
          // If the email doesn't exist, proceed with building your object
          const checkoutData = {
            ...formData,
            selectedProducts: selectedProducts.map(
              ({ _id, ProductName, SetupFee, UnitPrice, PricebookEntryId }) => ({
                _id,
                ProductName,
                SetupFee,
                UnitPrice,
                PricebookEntryId,
              })
            ),
          };
  
          checkoutData.discountedTotal = discountedTotal;
          checkoutData.couponCode = couponCode;
  
          console.log("Checkout Data:", checkoutData);
          
          // Submit checkout
          const checkoutResponse = await axios.post(
            APU_URL_FLOWS() + `/stripe/create-account-and-checkout`,
            checkoutData
          );
  
          if (checkoutResponse.data.status === true) {
            document.location = checkoutResponse.data.url;
          } else {
            // Handle unsuccessful checkout
            console.error("Checkout failed:", checkoutResponse.data);
            // You might want to show an error message to the user here
          }
        }
      } catch (error) {
        console.error("Error during submission:", error);
        // Handle the error scenario
        // You might want to show an error message to the user here
      } finally {
        setIsSubmitting(false);
        setIsProcessing(false);
      }
    } else {
      console.log("Validation failed. Errors:", formErrors);
      // Optionally, you can handle visual feedback for validation errors here
    }
  };

  const handleProductSelect = (product) => {
    // Check if the product is already selected
    const isAlreadySelected = selectedProducts.find(
      (p) => p._id === product._id
    );
    if (!isAlreadySelected) {
      setSelectedProducts([...selectedProducts, product]);
    } else {
      // If already selected, unselect it
      setSelectedProducts(
        selectedProducts.filter((p) => p._id !== product._id)
      );
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={60} color="primary" />
        <Typography variant="h6" style={{ marginLeft: "16px" }}>
          Loading account information...
        </Typography>
      </div>
    );
  }
  const totalUpFront = selectedProducts.reduce(
    (acc, product) =>
      acc + product.SetupFee * (parseInt(formData.signupShops) || 1),
    0
  );

  const totalLossDiscount = selectedProducts.reduce((acc, product) => {
    if (product.ProductName === "TotalLoss__Client" && couponStatus?.valid) {
      return acc + product.SetupFee * (parseInt(formData.signupShops) || 1);
    } else {
      return acc;
    }
  }, 0);

  const discountedTotal = totalUpFront - totalLossDiscount;

  const ExistingEmailModal = () => (
    <Modal
      open={showExistingEmailModal}
      onClose={() => setShowExistingEmailModal(false)}
      aria-labelledby="existing-email-modal-title"
      aria-describedby="existing-email-modal-description"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Card sx={{ minWidth: 350, maxWidth: 600, m: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Email Already Exists
          </Typography>
          <Typography>
            This email already exists in our system which means it is associated
            to a CRASH account. Please contact our sales team to assist with
            this purchase: 949-922-0018
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowExistingEmailModal(false)}
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </CardContent>
      </Card>
    </Modal>
  );

  const ProductSelection = ({
    products,
    onProductSelect,
    selectedProducts,
  }) => {
    const isProductSelected = (productId) => {
      return selectedProducts.some((product) => product._id === productId);
    };

    return (
      <Paper sx={{ mt: 2, p: 2 }}>
        <Typography variant="h6">Select Products</Typography>
        <Grid container spacing={2}>
          {products.map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product._id}>
              <Card
                onClick={() => onProductSelect(product)}
                sx={{
                  cursor: "pointer",
                  border: isProductSelected(product._id)
                    ? "2px solid blue"
                    : "1px solid transparent",
                  transition: "border 0.3s ease-in-out",
                }}
              >
                <CardContent>
                  <div style={{ position: "relative" }}>
                    <img
                      src={product.ProductActiveImage}
                      alt={product.ProductName}
                      style={{
                        maxWidth: "100%",
                        display: "block",
                        marginBottom: "10px",
                      }}
                    />
                    {product.CustomNote && (
                      <Tooltip
                        title={product.CustomNote}
                        placement="top"
                        sx={{
                          "& .MuiTooltip-tooltip": {
                            fontSize: "14px",
                            backgroundColor: "rgba(0, 0, 0, 0.8)",
                            color: "white",
                            padding: "8px",
                            borderRadius: "4px",
                          },
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                            color: "white",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "12px",
                            cursor: "pointer",
                          }}
                        >
                          i
                        </div>
                      </Tooltip>
                    )}
                  </div>
                  <Typography variant="body1">{product.ProductName}</Typography>
                  <Typography variant="body2">
                    Setup Fee: $
                    {product.ProductName === "TotalLoss__Client" &&
                    couponStatus?.valid
                      ? "0.00"
                      : product.SetupFee}
                  </Typography>
                  <Typography variant="body2">
                    Transactional Cost: ${" "}
                    {product.ProductName === "TotalLoss__Client" &&
                    couponStatus?.valid
                      ? "99.00"
                      : product.UnitPrice}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    );
  };

  return (
    <>
      {isProcessing && (
        <Modal
          open={true}
          onClose={() => {}}
          aria-labelledby="processing-modal-title"
          aria-describedby="processing-modal-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
          }}
        >
          <Card
            sx={{
              minWidth: 350,
              maxWidth: 600,
              m: 4,
              textAlign: "center",
              p: 4,
              borderRadius: "16px", // Increased border radius for a softer look
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)", // Added box shadow for depth
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  color: "primary.main",
                  mb: 4,
                }}
              >
                Processing...
              </Typography>
              <div
                style={{
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={60} color="primary" />
              </div>
              <Typography
                variant="body1"
                sx={{
                  mt: 4,
                  color: "text.secondary",
                  fontWeight: "medium",
                  maxWidth: "80%",
                  mx: "auto",
                }}
              >
                We are processing your information. This may take a moment.
                Please wait...
              </Typography>
            </CardContent>
          </Card>
        </Modal>
      )}
      <Modal
        open={true}
        onClose={() => {}}
        aria-labelledby="signup-modal-title"
        aria-describedby="signup-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Card sx={{ minWidth: 350, maxWidth: 600, m: 4 }}>
          <CardContent>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", mb: 3, textAlign: "center" }}
            >
              MyCRASH Signup
            </Typography>
            <form onSubmit={handleSubmit}>
              <Typography
                variant="body2"
                style={{
                  color: red[500],
                  marginBottom: "20px",
                  marginLeft: "10px",
                }}
              >
                * = Required Field
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={
                      <span>
                        {formData.shopType === "mso"
                          ? "Parent Company Name"
                          : "Company Name"}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    name="shopName"
                    value={formData.shopName}
                    onChange={handleInputChange}
                    error={!!formErrors.shopName}
                    helperText={formErrors.shopName}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <StoreIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: { color: "inherit" },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={
                      <span>
                        {formData.shopType === "mso"
                          ? "Parent Company Email"
                          : "Company Email"}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    name="shopEmail"
                    value={formData.shopEmail}
                    error={!!formErrors.shopEmail}
                    helperText={formErrors.shopEmail}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: { color: "inherit" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    mask="(999) 999-9999"
                    value={formData.shopPhone}
                    onChange={handleInputChange}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        fullWidth
                        label={
                          <span>
                            {formData.shopType === "mso"
                              ? "Parent Company Phone Number"
                              : "Company Phone Number"}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        name="shopPhone"
                        error={!!formErrors.shopPhone}
                        helperText={formErrors.shopPhone}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneIcon />
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          style: { color: "inherit" },
                        }}
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={
                      <span>
                        {formData.shopType === "mso"
                          ? "Parent Company Contact Name"
                          : "Company Contact Name"}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    name="shopContact"
                    value={formData.shopContact}
                    error={!!formErrors.shopContact}
                    helperText={formErrors.shopContact}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: { color: "inherit" },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={
                      <span>
                        Street Address <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    name="street"
                    value={formData.street}
                    error={!!formErrors.street}
                    helperText={formErrors.street}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationOnIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: { color: "inherit" },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={
                      <span>
                        City <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    name="city"
                    value={formData.city}
                    error={!!formErrors.city}
                    helperText={formErrors.city}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "inherit" },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth error={!!formErrors.state}>
                    <InputLabel
                      id="state-label"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      State <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      labelId="state-label"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <PublicIcon />
                        </InputAdornment>
                      }
                    >
                      {stateOptions.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                    {formErrors.state && (
                      <FormHelperText>{formErrors.state}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label={
                      <span>
                        Zipcode <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    name="zipcode"
                    value={formData.zipcode}
                    error={!!formErrors.zipcode}
                    helperText={formErrors.zipcode}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineIcon />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      maxLength: 5,
                    }}
                    InputLabelProps={{
                      style: { color: "inherit" },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.billingSameAsShop}
                        onChange={handleInputChange}
                        name="billingSameAsShop"
                      />
                    }
                    label="Billing address is the same as shop address"
                  />
                </Grid>

                {!formData.billingSameAsShop && (
                  <>
                    <Grid item xs={6} sm={5}>
                      <TextField
                        fullWidth
                        label={
                          <span>
                            Billing Street Address{" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        name="billingStreet"
                        value={formData.billingStreet}
                        error={!!formErrors.billingStreet}
                        helperText={formErrors.billingStreet}
                        onChange={handleInputChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationOnIcon />
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          style: { color: "inherit" },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3.5}>
                      <TextField
                        fullWidth
                        label={
                          <span>
                            Billing State{" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        name="billingState"
                        value={formData.billingState}
                        error={!!formErrors.billingState}
                        helperText={formErrors.billingState}
                        onChange={handleInputChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PublicIcon />
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          style: { color: "inherit" },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3.5}>
                      <TextField
                        fullWidth
                        label={
                          <span>
                            Billing Zipcode{" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        name="billingZipcode"
                        value={formData.billingZipcode}
                        error={!!formErrors.billingZipcode}
                        helperText={formErrors.billingZipcode}
                        onChange={handleInputChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailOutlineIcon />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          maxLength: 5,
                        }}
                        InputLabelProps={{
                          style: { color: "inherit" },
                        }}
                      />
                    </Grid>
                  </>
                )}

                {formData.shopType === "mso" && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="How many shops do you have?"
                        name="totalShops"
                        value={formData.totalShops}
                        error={!!formErrors.totalShops}
                        helperText={formErrors.totalShops}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label={
                          <span>
                            How many shops are you signing up?{" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        name="signupShops"
                        value={formData.signupShops}
                        error={!!formErrors.signupShops}
                        helperText={formErrors.signupShops}
                        onChange={handleInputChange}
                        type="number"
                        inputProps={{ min: 2 }}
                        required
                        InputLabelProps={{
                          style: { color: "inherit" },
                        }}
                      />
                    </Grid>
                  </>
                )}

                {/* Product Selection Section */}
                <Grid item xs={12}>
                  <ProductSelection
                    products={products}
                    onProductSelect={handleProductSelect}
                    selectedProducts={selectedProducts}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        label="Coupon Code"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        disabled={couponApplied} // Disable the input when couponApplied is true
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleCouponSubmit}
                        disabled={couponApplied || isApplyingCoupon}
                        startIcon={
                          isApplyingCoupon ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : null
                        }
                      >
                        {isApplyingCoupon ? "Applying..." : "Apply"}
                      </Button>
                    </Grid>
                  </Grid>
                  {couponStatus && (
                    <Typography
                      variant="body2"
                      color={couponStatus.valid ? "success.main" : "error.main"}
                    >
                      {couponStatus.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography>Subtotal: ${totalUpFront.toFixed(2)}</Typography>
                  {couponStatus && couponStatus.valid && (
                    <Typography>
                      Coupon "{couponCode}": -${totalLossDiscount.toFixed(2)}
                    </Typography>
                  )}
                  <Typography>
                    Total Up Front: ${discountedTotal.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={selectedProducts.length === 0 || isSubmitting}
                  >
                    {isSubmitting ? "Processing..." : "Checkout"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Modal>
      <ExistingEmailModal />
    </>
  );
}