import React, { useState } from "react";
import { API_URI } from "../../../../Globals";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import axios from "axios";
import { GooSpinner } from "react-spinners-kit";

function CreateShopModal({ open, onClose, onNext }) {
  const [shop, setShop] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Company: "",
    "Street 1": "",
    "Street 2": "",
    City: "",
    "Postal Code": "",
    Country: "",
    "State/Province": "",
    Active: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShop((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleNext = async () => {
    setIsLoading(true);

    try {
      const createShopApiUrl = API_URI() + "/admin/create";
      const response = await axios.post(createShopApiUrl, shop);
      const createdShop = response.data.shopData;

      // Refresh the shops list and open the licenses modal
      onNext(createdShop);

      setIsLoading(false);
    } catch (error) {
      console.error("Error creating shop:", error);
      setIsLoading(false);
      // Handle the error, show an error message, or perform any other necessary actions
    }
  };

  const handleSwitchChange = (e) => {
    setShop((prevState) => ({
      ...prevState,
      Active: e.target.checked,
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Create New Shop</DialogTitle>
      {isLoading === false && (
        <>
          <DialogContent>
            <TextField
              fullWidth
              margin="normal"
              label="Name"
              name="Name"
              value={shop.Name}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              name="Email"
              value={shop.Email}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Phone"
              name="Phone"
              value={shop.Phone}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Company"
              name="Company"
              value={shop.Company}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Street 1"
              name="Street 1"
              value={shop["Street 1"]}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Street 2"
              name="Street 2"
              value={shop["Street 2"]}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="City"
              name="City"
              value={shop.City}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Postal Code"
              name="Postal Code"
              value={shop["Postal Code"]}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Country"
              name="Country"
              value={shop.Country}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="State/Province"
              name="State/Province"
              value={shop["State/Province"]}
              onChange={handleInputChange}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={shop.Active}
                  onChange={handleSwitchChange}
                  name="Active"
                  color="primary"
                />
              }
              label="Active"
            />
          </DialogContent>
          <DialogActions>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleNext} color="primary">
                Next
              </Button>
            </DialogActions>
          </DialogActions>
        </>
      )}
      {isLoading === true && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GooSpinner size={180} color="#686769" loading={true} />
        </div>
      )}
    </Dialog>
  );
}

export default CreateShopModal;
