import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import Banner from "../components/Banner";
import BtnStartDv from "../components/BtnStartDv";
import Footer from "../components/Footer";
import axios from "axios";
import { API_URI } from "../../../../Globals";

const Paragraph = () => {
  return (
    <p
      style={{
        color: "rgb(192, 44, 122)",
        fontSize: "18px",
        marginTop: "20px",
        textAlign: "center",
        marginLeft: "100px",
        marginRight: "100px",
        fontFamily: "Roboto, sans-serif",
        fontWeight: "bold", // Added font weight
      }}
    >
      Whether you're a driver looking to recover lost value or a repair shop
      assisting clients, we're here to simplify the process and ensure fair
      reimbursement.
    </p>
  );
};

const SmallParagraph = () => {
  return (
    <p
      style={{
        fontSize: "14px",
        marginTop: "10px",
        textAlign: "center",
        marginLeft: "150px",
        marginRight: "150px",
        fontFamily: "Roboto, sans-serif",
      }}
    >
      <span style={{ color: "rgb(192, 44, 122)", fontWeight: "bold" }}>
        Diminished Value Powered by KriTech:
      </span>{" "}
      Your key to understanding your vehicle's worth after an accident and
      getting the compensation you deserve from insurance. With our
      user-friendly online system, you can quickly assess post-accident
      depreciation and generate detailed reports, saving time and money.
    </p>
  );
};

const PriceText = () => {
  const price = JSON.parse(localStorage.getItem("dvprice"));

  return (
    <p
      style={{
        fontSize: "24px",
        fontWeight: "900", // Increased font weight
        marginTop: "40px",
        textAlign: "center",
        color: "black",
        fontFamily: "Orbitron, sans-serif",
      }}
    >
      <strong>ONLY ${price} PER REPORT</strong>
    </p>
  );
};

const ValueText = () => {
  return (
    <p
      style={{
        fontSize: "24px",
        fontWeight: "900", // Increased font weight
        marginTop: "40px",
        textAlign: "center",
        color: "black",
        fontFamily: "Orbitron, sans-serif",
        padding: "15px",
      }}
    >
      <strong>
        THE BENEFITS OF DIMINISHED VALUE
        <br />
        POWERED BY KRITECH
      </strong>
    </p>
  );
};

const GridItem = ({ title, description }) => {
  return (
    <div style={{ textAlign: "left", marginBottom: "20px" }}>
      <span
        style={{
          fontSize: "18px",
          color: "rgb(192, 44, 122)",
          fontWeight: "bold",
        }}
      >
        {title}:
      </span>{" "}
      <span style={{ fontSize: "14px", color: "black" }}>{description}</span>
    </div>
  );
};

const Grid = () => {
  const price = JSON.parse(localStorage.getItem("dvprice"));
  const description =
    "At just $" +
    price +
    " per report, our service is 80-90% more cost-effective.";
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "20px",
        marginTop: "40px",
        marginLeft: "100px",
        marginRight: "100px",
        fontFamily: "Roboto, sans-serif", // Added font family
      }}
    >
      <GridItem
        title="ACCURATE"
        description="Diminished Value powered by Kritech delivers precise reports using a proprietary algorithm for the most reliable assessment."
      />
      <GridItem title="AFFORDABLE" description={description} />
      <GridItem
        title="FAST"
        description="What once required days of manual effort and research can now be completed with a simple click, saving you time and hassle."
      />
      <GridItem
        title="RECOVERABLE"
        description="With small investment and high payout potential, choosing Diminished Value Powered By Kritech is an easy decision."
      />
    </div>
  );
};

const DVLanding = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let dvPrice = localStorage.getItem("dvprice");

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          API_URI() +
            "/admin/getShopActiveProducts/e0c3c273-63b7-4066-b8fd-7c216f67b9ee"
        );
        console.log("Products:");

        // Find the item with ProductName == "DiminishedValue"
        const diminishedValueItem = response.data.find(
          (item) => item.ProductName === "DiminishedValue"
        );

        if (diminishedValueItem) {
          console.log(diminishedValueItem.UnitPrice);
          console.log(localStorage.getItem("dvprice"));
          let reload = false;
          if (localStorage.getItem("dvprice") === null) {
            reload = true;
          }
          localStorage.setItem("dvprice", diminishedValueItem.UnitPrice);
          if (reload) {
            window.location.reload();
          }
        } else {
          console.log("DiminishedValue item not found");
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    let serial = localStorage.getItem("localSerial");
    let token = localStorage.getItem("token");
    if (serial) {
      navigate("/dashboard/diminishedvalue");
    }
  }, []);

  if (dvPrice === null) {
    return <div></div>;
  }

  return (
    <div style={{ maxWidth: "1500px", margin: "0 auto" }}>
      <Header />
      <Banner />
      <Paragraph />
      <SmallParagraph />
      <BtnStartDv isLarge={true} buttonLink="/instantdv/2_StartReport" />
      <PriceText />
      <img
        src="/img/diminishedvalue/diminshedValue_sellsheet.png"
        alt="Diminished Value Sellsheet"
        style={{ display: "block", margin: "40px auto" }}
      />
      <ValueText />
      <Grid />
      <Footer />
    </div>
  );
};

export default DVLanding;
