import React from "react";
import Select from "react-select";

const DamageSeverityDropdown = ({
  damageSeverity,
  setDamageSeverity,
  errors,
  customStyles,
  options,
}) => {
  const handleChange = (selectedOption) => {
    setDamageSeverity(selectedOption.modifier);
  };

  const defaultStyles = {
    ...customStyles,
    control: (provided) => ({
      ...provided,
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
      backgroundColor: "rgba(111, 111, 111, 0.039)",
    }),
    option: (provided) => ({
      ...provided,
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
    }),
  };

  return (
    <div style={{ marginTop: "20px", fontFamily: "Roboto, sans-serif" }}>
      <Select
        options={options}
        value={options.find((option) => option.modifier === damageSeverity)}
        onChange={handleChange}
        styles={customStyles || defaultStyles}
        placeholder="Select Damage Severity *"
      />
      {errors.damageSeverity && (
        <div
          style={{
            color: "red",
            fontSize: "12px",
            textAlign: "left",
            marginTop: "5px",
          }}
        >
          {errors.damageSeverity}
        </div>
      )}
    </div>
  );
};

export default DamageSeverityDropdown;
