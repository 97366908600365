import React, { useState, useEffect } from "react";
import { Button, Container, Box, Typography, Paper } from "@mui/material";
import Modal from "../../components/Modal.module";
import { useNavigate } from "react-router-dom";

const DashboardAdmin = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh", // Set the height to 100% of the viewport height
        width: "100vw", // Set the width to 100% of the viewport width
        overflow: "auto", // Add overflow auto to allow scrolling if the content is bigger than the viewport
      }}
    >
      <Container
        component="main"
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "2rem", // 2x font size for all textual content
        }}
      >
        <Paper
          elevation={3}
          sx={{ padding: 6, width: "100%", marginBottom: 6 }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h4">
              {" "}
              {/* Adjusted variant to keep hierarchy */}
              My CRASH Network Admin
            </Typography>
            <Box sx={{ mt: 6, width: "100%" }}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                sx={{ mb: 4, py: 2, fontSize: "1.5rem" }}
                onClick={() => handleNavigation("/dashboard/flows/")}
              >
                Flows
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                sx={{ mb: 4, py: 2, fontSize: "1.5rem" }}
                onClick={() => handleNavigation("/dashboard/usage/")}
              >
                Usage Reports
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                sx={{ mb: 4, py: 2, fontSize: "1.5rem" }}
                onClick={() => handleNavigation("/dashboard/kritechadmin/")}
              >
                Licensing
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                sx={{ mb: 4, py: 2, fontSize: "1.5rem" }}
                onClick={() => handleNavigation("/dashboard/admin/products/")}
              >
                Products
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                sx={{ mb: 4, py: 2, fontSize: "1.5rem" }}
                onClick={() => handleNavigation("/dashboard/")}
              >
                CRASH Dashboard
              </Button>
            </Box>
          </Box>
        </Paper>
        {showModal && <Modal setIsOpen={() => setShowModal(false)} />}
      </Container>
    </Box>
  );
};

export default DashboardAdmin;
