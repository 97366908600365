import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchProducts } from "../../redux/products/actions";
import Header from "../../components/layout/Header/Header";
import Footer from "../../components/layout/Footer/Footer";
import ProductGrid from "./components/ProductGrid/ProductGrid";
import "./LandingPage.css";

const LandingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeProducts = useSelector((state) => state.products.activeProducts);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  return (
    <div className="landing-page">
      <Header onLogin={handleLogin} onSignUp={handleSignUp} />
      <main className="main-content">
        <img className="main-logo" src="path_to_main_logo" alt="main logo" />
        <h2 className="active-solutions-title">
          EXPLORE OUR <span className="active">ACTIVE</span> SOLUTIONS
        </h2>
        <ProductGrid products={activeProducts} />
      </main>
      <Footer />
    </div>
  );
};

export default LandingPage;
