import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ImageSlideshow from "./ImageSlideshow";
import CustomInfoModal from "./totallossv2.custominfomodal";
import ReportModal from "./totallossv2.job.report.modal";
import { updateRequestMessage, updateJobStatus } from "./totallossv2.service";
import BaseComponentTotalLoss from "./totallossv2baseapp";
import axios from "axios";
import SeeMoreInfoModal from "./totallossv2.job.moreinfo.modal";
import "@fontsource/orbitron";
import { styled } from "@mui/system";
import "@fontsource/lato";
import {
  UploadPdf,
  SubmitJob,
  ValidatePDF,
} from "../../../services/autoestimaterekey.service";
import {
  Button,
  Box,
  TextField,
  Select,
  MenuItem,
  Typography,
  Modal,
  Container,
  Grid,
} from "@mui/material";
import { useAuth } from "../../../AuthContext";

const TotalLossRekey = () => {
  const { isAuthenticated, user } = useAuth();
  const [platform, setPlatform] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [claimRoNumber, setClaimRoNumber] = useState("");
  const [vin, setVin] = useState("");
  const [notes, setNotes] = useState("");
  const { state } = useLocation();
  const [openReport, setOpenReport] = useState(false);
  const [openSlideshow, setOpenSlideshow] = useState(false);
  const [appraiserMsg, setAppraiserMsg] = useState("");
  const [customerMsg, setCustomerMsg] = useState("");
  //Modal data
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [pdfPath, setPdfPath] = useState("");
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);

  const navigate = useNavigate();

  const handleSubmitMsgResponse = (requestInfoNotes, customerResponse) => {
    handleOpenModal("", "", true, false);
    updateRequestMessage(
      state.rowData.jobId,
      customerResponse,
      "",
      () => {
        navigate("/dashboard/totallossv2/customer/usage");
      },
      () => {
        handleOpenModal(
          "Error",
          "We encountered a error while handling your request",
          false,
          true
        );
      },
      "Issue"
    );
  };

  const handleSubmitMsgResponse2 = (message) => {
    handleOpenModal("", "", true, false);

    updateRequestMessage(
      state.rowData.jobId,
      message,
      "",
      () => {
        navigate("/dashboard/totallossv2/customer/usage");
      },
      () => {
        handleOpenModal(
          "Error",
          "We encountered a error while handling your request",
          false,
          true
        );
      },
      "Issue"
    );
  };

  const handleOpenModal = (modalTitle, modalBody, showSpinner, showClose) => {
    setModalBody(modalBody);
    setModalTitle(modalTitle);
    setShowSpinner(showSpinner);
    setShowClose(showClose);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handlePlatformChange = (event) => {
    setPlatform(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleVinChange = (event) => {
    setVin(event.target.value);
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleClaimChange = (event) => {
    setClaimRoNumber(event.target.value);
  };

  const handleImagesClick = () => {
    // Implement the logic to open all images
  };

  const handleReportClick = () => {
    // Implement the logic to download PDF
  };

  const handleSubmitClick = () => {};

  const handleOpenImages = () => {
    setOpenSlideshow(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenSlideshow(false);
  };

  const handleCloseReport = () => {
    setOpenReport(false);
  };

  const handleOpenReport = () => {
    setOpenReport(true);
  };

  const submitRekey = async () => {
    handleOpenModal("Validating Login Info", "", true, false);
    const options = {
      method: "POST",
      url: "https://crashfunctions20240821023624.azurewebsites.net/api/AuthFunction",
      data: JSON.stringify({ username: username, password: password }), // Ensure data is properly formatted as JSON string
      headers: { "Content-Type": "application/json" }, // It's important to set Content-Type for POST requests
    };

    try {
      const response = await axios.request(options);
      // Check both HTTP status code and data status
      if (response.status !== 200 || response.data.status !== "SUCCESS") {
        // Consider creating a specific message based on `response.status` to provide more context
        handleOpenModal("Invalid Credentials", "", false, true); // Generic error for other cases
        return;
      }
      console.log(response.data);

      // Continue with submitting the TL rekey
      const platforms = ["CCC One", "Mitchell Cloud"];
      const entryMethod = ["New Workfile", "Into Existing"];

      let _config = {};
      _config.Application = "TotalLoss";
      _config.Serial = user.Serial;
      _config.Args = {};
      _config.Args.Username = username;
      _config.Args.Password = password;
      _config.Args.Pdf = state.rowData.pdfPath;
      _config.Args.JobId = state.rowData.jobId;
      _config.Args.RO = claimRoNumber;

      SubmitJob(user.Serial, _config, () => {
        handleOpenModal("Your estimate is being processed", "", true, false);
        updateJobStatus(
          state.rowData.jobId,
          6,
          () => {
            navigate("/dashboard/totalloss__client");
          },
          () => {
            handleOpenModal(
              "We encountered an Error while updating the job data",
              "",
              false,
              true
            );
          }
        );
      });
    } catch (error) {
      // Handle axios errors (error.response is available for HTTP response status codes)
      console.error(error);
      if (error.response && error.response.status === 400) {
        handleOpenModal("Invalid Credentials", "", false, true); // Generic error for other cases
      } else {
        handleOpenModal("Invalid Credentials", "", false, true); // Generic error for other cases
      }
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    console.log(state.rowData);
    setMessages(state.rowData.issueLog);
    setVin(state.vin);
    setNotes(state.optional_notes);
    setPlatform(state.platform);
    setPdfPath(state.pdfPath);
  }, [state.rowData]);

  return (
    <BaseComponentTotalLoss imageUrl="../../../..\img\totalloss_header.png">
      <Container
        sx={{
          fontFamily: "Orbitron, sans-serif",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          minHeight: "70vh",
          color: "#000",
        }}
      >
        <CustomInfoModal
          open={openModal}
          handleClose={handleCloseModal}
          title={modalTitle}
          showSpinner={showSpinner}
          showCloseButton={showClose}
          bodyText={modalBody}
        />
        <ReportModal
          open={openReport}
          handleClose={handleCloseReport}
          onSubmit={handleSubmitMsgResponse}
          initialText1={appraiserMsg}
          initialText2={customerMsg}
          disableInput1={true}
          disableInput2={false}
        />

        <Box display="flex" justifyContent="space-between" mb={2}>
          {messages === undefined && (
            <Button
              onClick={handleOpenReport}
              color="error"
              variant="contained"
              style={{ minWidth: "10%", height: "4rem", fontSize: "1.1rem" }}
            >
              Report Issue with Total Loss Estimate
            </Button>
          )}

          {messages !== undefined && (
            <Button
              onClick={handleOpen}
              color="error"
              variant="contained"
              style={{ minWidth: "10%", height: "4rem", fontSize: "1.1rem" }}
            >
              SEE ISSUE COMMENTS
            </Button>
          )}
          <SeeMoreInfoModal
            open={open}
            handleClose={handleClose}
            onSubmit={handleSubmitMsgResponse2}
            messages={messages}
            isError={"customer"}
          />
        </Box>
        <Box
          sx={{
            fontFamily: "Orbitron, sans-serif",
            backgroundColor: "#fff",
            borderRadius: "2em",
            padding: "2em",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontFamily: "Orbitron, sans-serif",
              marginBottom: "1em",
              marginTop: "1em",
            }}
          >
            Total Loss Rekey
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Typography align="left" variant="h6">
                Platform Going Into
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Select
                fullWidth
                defaultValue={state.rowData.platform}
                onChange={handlePlatformChange}
              >
                <MenuItem value="CccOne">Ccc One</MenuItem>
                <MenuItem value="Mitchell">Mitchell</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography align="left" variant="h6">
                Enter Username And Password for Platform Going Into
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Username"
                value={username}
                onChange={handleUsernameChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography align="left" variant="h6">
                Enter Claim or RO #
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Claim/RO #"
                value={claimRoNumber}
                onChange={handleClaimChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                align="center"
                variant="h3"
                fontWeight="bold"
                sx={{
                  fontFamily: "Orbitron, sans-serif",
                  marginBottom: "1em",
                  marginTop: "1em",
                }}
              >
                Vehicle Information
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="left" variant="h6">
                VIN
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              {" "}
              <Box
                component="div"
                sx={{
                  fontFamily: "Orbitron, sans-serif",
                  backgroundColor: "white",
                  width: "50%",
                  height: "90%",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: 1,
                  padding: "16.5px 18.5px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {state.rowData.vin}
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="left" variant="h6">
                Images:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Button variant="contained" onClick={handleOpenImages}>
                Click to open all images
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="left" variant="h6">
                Notes:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box
                component="div"
                sx={{
                  fontFamily: "Orbitron, sans-serif",
                  backgroundColor: "white",
                  width: "100%",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: 1,
                  padding: "18.5px 14px",
                  overflow: "auto",
                  whiteSpace: "pre-wrap",
                  height: "auto",
                  minHeight: "80px", // Adjust to match the height of 4 rows
                  textAlign: "left", // Add this line to align the text to the left
                }}
              >
                {state.rowData.optional_notes}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" size="large">
                <a
                  href={state.rowData.pdfPath}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Download Estimate PDF
                </a>
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="success"
                size="large"
                onClick={submitRekey}
              >
                Submit Rekey
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </BaseComponentTotalLoss>
  );
};

export default TotalLossRekey;
