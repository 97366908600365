import React from "react";
import { useNavigate } from "react-router-dom";
import "./ProductButton.css";

const ProductButton = ({ product }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(`/product/${product.id}`);
  };

  return (
    <button className="product-button" onClick={handleButtonClick}>
      <img className="product-image" src={product.image} alt={product.name} />
      <div className="product-name">{product.name}</div>
    </button>
  );
};

export default ProductButton;
