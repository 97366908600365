import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import { API_URI } from "../../../../Globals";
import dayjs from "dayjs"; // Make sure to import dayjs
function EditShopModal({ open, onClose, shop: initialShop }) {
  const [shop, setShop] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Company: "",
    Street1: "",
    Street2: "",
    City: "",
    PostalCode: "",
    Country: "",
    StateProvince: "",
    Active: false,
  });

  useEffect(() => {
    if (initialShop && open) {
      setShop({
        ...initialShop,
        ValidUntil: initialShop.ValidUntil
          ? dayjs(initialShop.ValidUntil)
          : null,
      });
    }
  }, [initialShop, open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShop((prev) => ({ ...prev, [name]: value }));
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setShop((prev) => ({ ...prev, [name]: checked }));
  };

  const handleDateChange = (newValue) => {
    setShop((prev) => ({ ...prev, ValidUntil: newValue }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!initialShop?._id) return;
    axios
      .put(API_URI() + `/admin/shops/update/${initialShop._id}`, shop)
      .then((response) => {
        console.log("Shop updated successfully:", response.data);
        onClose();
      })
      .catch((error) =>
        console.error("There was an error updating the shop:", error)
      );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit Shop</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          label="Name"
          name="Name"
          value={shop.Name}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          name="Email"
          value={shop.Email}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Phone"
          name="Phone"
          value={shop.Phone}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Company"
          name="Company"
          value={shop.Company}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Street 1"
          name="Street1"
          value={shop.Street1}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Street 2"
          name="Street2"
          value={shop.Street2}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="City"
          name="City"
          value={shop.City}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Postal Code"
          name="PostalCode"
          value={shop.PostalCode}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Country"
          name="Country"
          value={shop.Country}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="State/Province"
          name="StateProvince"
          value={shop.StateProvince}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="SalesforceID"
          name="SalesforceId"
          value={shop.SalesforceId}
          onChange={handleInputChange}
        />

        <div
          style={{ margin: "16px 0", display: "flex", flexDirection: "column" }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Valid Until"
              value={shop.ValidUntil}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </div>
        <FormControlLabel
          control={
            <Switch
              checked={shop.Active}
              onChange={handleSwitchChange}
              name="Active"
            />
          }
          label="Active"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditShopModal;
