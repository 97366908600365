import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import GroupList from "./GroupList";
import styled from "styled-components";
import { fetchConfig } from "./apiService";
import GroupOptions from "./GroupOptions";

function SuperAdmin() {
  const [open, setOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupConfig, setGroupConfig] = useState(null);

  const Wrapper = styled.div`
    position: relative;
    background-color: transparent;
    background: white;
    width: 100%;
    height: 100%;
    font-family: "Orbitron";
  `;

  function handleGroupClick(group) {
    fetchConfig(`group/${group._id}/config`).then((fetchedConfig) => {
      setGroupConfig(fetchedConfig);
      setSelectedGroup(group);
      setOpen(false);
    });
  }

  const closeGroupOptions = () => {
    setSelectedGroup(null);
    setGroupConfig(null);
  };
  return (
    <Wrapper>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">Dashboard</Typography>
        </Toolbar>
      </AppBar>

      <Drawer open={open} onClose={() => setOpen(false)}>
        <List>
          <GroupList onGroupClick={handleGroupClick} />
        </List>
      </Drawer>
      {selectedGroup && groupConfig && (
        <GroupOptions group={selectedGroup} onClose={closeGroupOptions} />
      )}
    </Wrapper>
  );
}

export default SuperAdmin;
