import React from "react";
import styles from "./Modal.module.css";
import ClipLoader from "react-spinners/ClipLoader";
const Modal = ({ setIsOpen, currState, currMsg }) => {
  return (
    <>
      <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h5 className={styles.heading}>
              {currState === false && (
                <>Verifying all information. This may take a moment.</>
              )}
              {currState === true && <>{currMsg}</>}
            </h5>
          </div>

          <div className={styles.modalActions}>
            {currState === true && (
              <button
                className={styles.cancelBtn}
                onClick={() => document.location.reload()}
              >
                Continue
              </button>
            )}
            {currState === false && (
              <ClipLoader
                loading={true}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
