import React from "react";
import Button from "@mui/material/Button";
import WarningIcon from "@mui/icons-material/Warning";

function filterWhiteColors(data) {
  const buttonColors = JSON.parse(data.buttonColors);
  for (let key in buttonColors) {
    if (buttonColors[key] === "white") {
      delete buttonColors[key];
    }
  }
  data.buttonColors = JSON.stringify(buttonColors);
  return data;
}

const getTableConfigs = (handlePayloadClick, downloadPdf) => ({
  MitchellPartAPI: {
    dateKey: "DateReKeyed",
    sort: [{ id: "DateReKeyed", desc: true }],
    columns: [
      {
        id: "status",
        accessorKey: "Status",
        header: "Status",
        filterSelectOptions: [
          "Success",
          "Fail",
          "Success(PartErrors)",
          "Processing",
          "Timeout",
          "Unknown Status",
        ],
        filterVariant: "select",
        Cell: ({ cell }) => {
          const status = cell.row.original.Status;
          let statusLabel = "";
          let customStyle = {};

          switch (status) {
            case 0:
              statusLabel = "Success";
              customStyle = { backgroundColor: "green", color: "white" };
              break;
            case 1:
              statusLabel = "Fail";
              customStyle = { backgroundColor: "red", color: "white" };
              break;
            case 2:
              statusLabel = "Success(PartErrors)";
              customStyle = { backgroundColor: "yellow", color: "black" };
              break;
            case 3:
              statusLabel = "Processing";
              customStyle = { backgroundColor: "blue", color: "white" };
              break;
            case 4:
              statusLabel = "Timeout";
              customStyle = { backgroundColor: "orange", color: "white" };
              break;
            default:
              statusLabel = "Unknown Status";
              customStyle = { backgroundColor: "gray", color: "white" };
              break;
          }

          return (
            <div className="Aoo-spaceBetween">
              <Button
                variant="contained"
                style={{ borderRadius: "20px", ...customStyle }}
                size="small"
                onClick={() => {
                  // Logic for handling the status change can be implemented here
                }}
              >
                {statusLabel}
              </Button>
            </div>
          );
        },
      },
      {
        id: "serial",
        accessorKey: "serial",
        header: "Serial",
        Cell: ({ cell }) => {
          let serial = cell.row.original.Payload;
          try {
            if (serial !== null) {
              serial = JSON.parse(cell.row.original.Payload).Serial;
            }
          } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle the error or set a default value for serial
            serial = "Error parsing JSON"; // Or any other default/fallback value
          }

          return <>{serial}</>;
        },
      },
      {
        id: "vin",
        accessorKey: "vin",
        header: "VIN",
        Cell: ({ cell }) => {
          let vin = cell.row.original.Payload;
          try {
            if (vin !== null) {
              vin = JSON.parse(cell.row.original.Payload).VIN;
            }
          } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle the error or set a default value for vin
            vin = "Error parsing JSON"; // Or any other default/fallback value
          }

          return <>{vin}</>;
        },
      },
      {
        id: "vhdesc",
        accessorKey: "vhdesc",
        header: "Vehicle Description",
        Cell: ({ cell }) => {
          let vhDescription = cell.row.original.Payload;
          try {
            if (vhDescription !== null) {
              vhDescription = JSON.parse(cell.row.original.Payload).Description;
            }
          } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle the error or set a default value for vhDescription
            vhDescription = "Error parsing JSON"; // Or any other default/fallback value
          }

          return <>{vhDescription}</>;
        },
      },
      {
        id: "DateReKeyed",
        accessorKey: "DateReKeyed",
        header: "Date",
        Cell: ({ cell }) => {
          const dateString = cell.row.original.DateReKeyed;
          const dateObject = new Date(dateString);
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZoneName: "short",
          };
          const formattedDate =
            dateObject.toString() === "Invalid Date"
              ? "N.a"
              : new Intl.DateTimeFormat("en-US", options).format(dateObject);

          return <div>{formattedDate}</div>;
        },
      },

      {
        size: 40,
        id: "payload",
        accessorKey: "Payload",
        header: "Payload",
        Cell: ({ cell }) => {
          return (
            <>
              <Button
                variant="contained"
                style={{ borderRadius: "20px" }}
                size="small"
                onClick={() => {
                  handlePayloadClick(cell.row.original);
                }}
              >
                View Payload
              </Button>
            </>
          );
        },
      },
    ],
  },
  PDR: {
    dateKey: "DateReKeyed",
    sort: [{ id: "DateReKeyed", desc: true }],
    columns: [
      {
        id: "Status",
        accessorKey: "Status",
        header: "Status",
        filterSelectOptions: [
          "Success",
          "Fail",
          "Success(PartErrors)",
          "Processing",
          "Timeout",
          "Unknown Status",
        ],
        filterVariant: "select",
        Cell: ({ cell }) => {
          const status = cell.row.original.Status;
          let statusLabel = "";
          let customStyle = {};

          switch (status) {
            case 0:
              statusLabel = "Success";
              customStyle = { backgroundColor: "green", color: "white" };
              break;
            case 1:
              statusLabel = "Fail";
              customStyle = { backgroundColor: "red", color: "white" };
              break;
            case 2:
              statusLabel = "Success(PartErrors)";
              customStyle = { backgroundColor: "yellow", color: "black" };
              break;
            case 3:
              statusLabel = "Processing";
              customStyle = { backgroundColor: "blue", color: "white" };
              break;
            case 4:
              statusLabel = "Timeout";
              customStyle = { backgroundColor: "orange", color: "white" };
              break;
            default:
              statusLabel = "Unknown Status";
              customStyle = { backgroundColor: "gray", color: "white" };
              break;
          }

          return (
            <div className="Aoo-spaceBetween">
              <Button
                variant="contained"
                style={{ borderRadius: "20px", ...customStyle }}
                size="small"
                onClick={() => {
                  // Logic for handling the status change can be implemented here
                }}
              >
                {statusLabel}
              </Button>
            </div>
          );
        },
      },
      {
        id: "PlatformInto",
        accessorKey: "PlatformInto",
        header: "Platform Into",
      },
      {
        id: "DateReKeyed",
        accessorKey: "DateReKeyed",
        header: "Date",
        Cell: ({ cell }) => {
          const dateString = cell.row.original.DateReKeyed;
          const dateObject = new Date(dateString);
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZoneName: "short",
          };
          const formattedDate =
            dateObject.toString() === "Invalid Date"
              ? "N.a"
              : new Intl.DateTimeFormat("en-US", options).format(dateObject);

          return <div>{formattedDate}</div>;
        },
      },
      {
        id: "DateLastUpdate",
        accessorKey: "DateLastUpdate",
        header: "DateLastUpdate",
        Cell: ({ cell }) => {
          const dateString = cell.row.original.DateLastUpdate;
          const dateObject = new Date(dateString);
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZoneName: "short",
          };
          const formattedDate =
            dateObject.toString() === "Invalid Date"
              ? "N.a"
              : new Intl.DateTimeFormat("en-US", options).format(dateObject);

          return <div>{formattedDate}</div>;
        },
      },
      {
        size: 40,
        id: "payload",
        accessorKey: "Payload",
        header: "Payload",
        Cell: ({ cell }) => {
          return (
            <>
              <Button
                variant="contained"
                style={{ borderRadius: "20px" }}
                size="small"
                onClick={() => {
                  handlePayloadClick(cell.row.original);
                }}
              >
                View Payload
              </Button>
            </>
          );
        },
      },
    ],
  },
  ReKey: {
    dateKey: "DateReKeyed",
    sort: [
      {
        id: "DateReKeyed",
        desc: true,
      },
    ],
    columns: [
      {
        size: 50,
        id: "status2",
        accessorKey: "Status",
        header: "Status",
        filterSelectOptions: [
          "Success",
          "Fail",
          "Success(PartErrors)",
          "Processing",
          "Timeout",
          "Unknown Status",
        ],
        filterVariant: "select",
        Cell: ({ cell }) => {
          const status = cell.row.original.Status;
          let statusLabel = "";
          let customStyle = {};

          switch (status) {
            case 0:
              statusLabel = "Success";
              customStyle = { backgroundColor: "green", color: "white" };
              break;
            case 1:
              statusLabel = "Fail";
              customStyle = { backgroundColor: "red", color: "white" };
              break;
            case 2:
              statusLabel = "Success(PartErrors)";
              customStyle = { backgroundColor: "yellow", color: "black" };
              break;
            case 3:
              statusLabel = "Processing";
              customStyle = { backgroundColor: "blue", color: "white" };
              break;
            case 4:
              statusLabel = "Timeout";
              customStyle = { backgroundColor: "orange", color: "white" };
              break;
            default:
              statusLabel = "Unknown Status";
              customStyle = { backgroundColor: "gray", color: "white" };
              break;
          }

          return (
            <div className="Aoo-spaceBetween">
              <Button
                variant="contained"
                style={{ borderRadius: "20px", ...customStyle }}
                size="small"
                onClick={() => {
                  // Logic for handling the status change can be implemented here
                }}
              >
                {statusLabel}
              </Button>
            </div>
          );
        },
      },
      { id: "SerialId", accessorKey: "SerialId", header: "Serial", size: 100 },
      { id: "company", accessorKey: "Company", header: "Company", size: 100 },
      {
        id: "Params.TotalTime",
        accessorKey: "TotalTime",
        header: "Total Time",
        size: 100,
        Cell: ({ cell }) => {
          // Check if Params is not null and TotalTime is available
          if (
            cell.row.original.Params &&
            cell.row.original.Params.TotalTime != null
          ) {
            const time = cell.row.original.Params.TotalTime;

            const formatTime = (timeInSeconds) => {
              const minutes = Math.floor(timeInSeconds / 60);
              const seconds = timeInSeconds % 60;

              // If it takes under a minute, return only seconds
              if (minutes === 0) {
                return `${seconds}s`;
              }

              // Return formatted time with double digits for seconds
              return `${minutes}m:${seconds.toString().padStart(2, "0")}s`;
            };

            return <div>{formatTime(time)}</div>;
          }

          // Return a default or placeholder value if Params or TotalTime is not available
          return <div>Not available</div>;
        },
      },
      {
        id: "platformSource",
        accessorKey: "PlatformSource",
        header: "Platform Source",
        size: 100,
      },
      {
        id: "platformInto",
        accessorKey: "PlatformInto",
        header: "Platform Into",
        size: 100,
      },
      {
        id: "DateReKeyed",
        accessorKey: "DateReKeyed",
        header: "Date reKeyed",
        minSize: 100,
        maxSize: 500,
        Cell: ({ cell }) => {
          const dateString = cell.row.original.DateReKeyed;
          const dateObject = new Date(dateString);
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZoneName: "short",
          };
          const formattedDate =
            dateObject.toString() === "Invalid Date"
              ? "N.a"
              : new Intl.DateTimeFormat("en-US", options).format(dateObject);

          return <div>{formattedDate}</div>;
        },
      },

      {
        id: "payload2",
        accessorKey: "Payload",
        header: "Actions",
        size: 50,
        Cell: ({ cell }) => {
          return (
            <>
              <Button
                variant="contained"
                style={{ borderRadius: "20px", marginBottom: "10px" }}
                size="small"
                onClick={() => {
                  handlePayloadClick(cell.row.original);
                }}
              >
                View Payload
              </Button>
              <Button
                variant="contained"
                style={{ borderRadius: "20px" }}
                size="small"
                onClick={() => {
                  window.open(
                    "https://aerksa.blob.core.windows.net/pdf-jobs/" +
                      JSON.parse(cell.row.original.Payload).Pdf,
                    "_blank"
                  );
                }}
              >
                Download PDF
              </Button>
            </>
          );
        },
      },
    ],
  },
  TotalLoss: {
    dateKey: "DateReKeyed",
    sort: [{ id: "submit_date", desc: true }],
    columns: [
      {
        id: "jobStatus",
        accessorKey: "status",
        filterSelectOptions: [
          "New Job",
          "Claimed Job",
          "Info Request",
          "Pending Customer Review",
          "Issue Raised",
          "Final - Locked",
          "Canceled - Locked",
          "Unknown Status",
        ],
        filterVariant: "select",
        header: "Workflow Status",
        Cell: ({ cell }) => {
          const status = cell.row.original.status;
          let statusLabel = "";
          let customStyle = {};
          let icon = null;

          switch (status) {
            case "0":
              statusLabel = "New Job";
              customStyle = { backgroundColor: "blue", color: "white" };
              break;
            case "1":
              statusLabel = "Claimed Job";
              customStyle = { backgroundColor: "lightblue", color: "black" };
              break;
            case "2":
              statusLabel = "Info Request";
              customStyle = { backgroundColor: "purple", color: "white" };
              break;
            case "3":
              statusLabel = "Pending Customer Review";
              customStyle = { backgroundColor: "orange", color: "black" };

              break;
            case "4":
            case "5":
              statusLabel = "Issue Raised";
              customStyle = { backgroundColor: "yellow", color: "black" };
              icon = (
                <WarningIcon fontSize="small" style={{ marginRight: "5px" }} />
              );
              break;
            case "6":
              statusLabel = "Final - Locked";
              customStyle = { backgroundColor: "green", color: "white" };
              break;
            case "7":
              statusLabel = "Canceled - Locked";
              customStyle = { backgroundColor: "red", color: "white" };
              break;
            default:
              statusLabel = "Unknown Status";
              customStyle = { backgroundColor: "gray", color: "white" };
              break;
          }

          return (
            <Button
              variant="contained"
              style={{ borderRadius: "20px", ...customStyle }}
              startIcon={icon}
              size="small"
            >
              {statusLabel}
            </Button>
          );
        },
      },
      {
        id: "rekeystatus",
        accessorKey: "rekeystatus",
        header: "ReKey Status",
        Cell: ({ cell }) => {
          const status = cell.row.original.rekeystatus;
          let statusLabel = "";
          let customStyle = {};

          switch (status) {
            case 0:
              statusLabel = "Success";
              customStyle = { backgroundColor: "green", color: "white" };
              break;
            case 1:
              statusLabel = "Fail";
              customStyle = { backgroundColor: "red", color: "white" };
              break;
            case 2:
              statusLabel = "Success(PartErrors)";
              customStyle = { backgroundColor: "yellow", color: "black" };
              break;
            case 3:
              statusLabel = "Processing";
              customStyle = { backgroundColor: "blue", color: "white" };
              break;
            case 4:
              statusLabel = "Timeout";
              customStyle = { backgroundColor: "orange", color: "white" };
              break;
            default:
              statusLabel = "Not Submited";
              customStyle = { backgroundColor: "gray", color: "white" };
              break;
          }

          return (
            <div className="Aoo-spaceBetween">
              <Button
                variant="contained"
                style={{ borderRadius: "20px", ...customStyle }}
                size="small"
                onClick={() => {
                  // Logic for handling the status change can be implemented here
                }}
              >
                {statusLabel}
              </Button>
            </div>
          );
        },
      },
      {
        id: "vin",
        accessorKey: "vin",
        header: "VIN",
        Cell: ({ cell }) => {
          console.log(cell.row.original);
          return <div>{cell.row.original.vin}</div>;
        },
      },
      {
        id: "customer_name",
        accessorKey: "customer_name",
        header: "User Name",
      },
      {
        id: "customer_company",
        accessorKey: "customer_company",
        header: "Company Name",
      },
      {
        id: "appraiser_name",
        accessorKey: "appraiser_name",
        header: "Appraiser",
      },
      {
        id: "company_name",
        accessorKey: "company_name",
        header: " Appraiser Company Name",
      },

      {
        id: "submit_date",
        accessorKey: "submit_date",
        header: "Date Created",
        Cell: ({ cell }) => {
          const dateString = cell.row.original.submit_date;
          const dateObject = new Date(dateString);
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZoneName: "short",
          };
          const formattedDate =
            dateObject.toString() === "Invalid Date"
              ? "N.a"
              : new Intl.DateTimeFormat("en-US", options).format(dateObject);

          return <div>{formattedDate}</div>;
        },
      },
      {
        id: "startTime",
        accessorKey: "startTime",
        header: "Date Job Claimed",
        Cell: ({ cell }) => {
          const dateString = cell.row.original.startTime;
          const dateObject = new Date(dateString);
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZoneName: "short",
          };
          const formattedDate =
            dateObject.toString() === "Invalid Date"
              ? "N.a"
              : new Intl.DateTimeFormat("en-US", options).format(dateObject);

          return <div>{formattedDate}</div>;
        },
      },
      {
        id: "appSubmitDate",
        accessorKey: "appSubmitDate",
        header: "Date Job Completed",
        Cell: ({ cell }) => {
          const dateString = cell.row.original.appSubmitDate;
          const dateObject = new Date(dateString);
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZoneName: "short",
          };
          const formattedDate =
            dateObject.toString() === "Invalid Date"
              ? "N.a"
              : new Intl.DateTimeFormat("en-US", options).format(dateObject);

          return <div>{formattedDate}</div>;
        },
      },
      {
        id: "dateRekeyed",
        accessorKey: "dateRekeyed",
        header: "Date ReKeyed",
        Cell: ({ cell }) => {
          const dateString = cell.row.original.dateRekeyed;
          const dateObject = new Date(dateString);
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZoneName: "short",
          };
          const formattedDate =
            dateObject.toString() === "Invalid Date"
              ? "N.a"
              : new Intl.DateTimeFormat("en-US", options).format(dateObject);

          return <div>{formattedDate}</div>;
        },
      },
      {
        id: "payload",
        accessorKey: "payload",
        header: "Payload",
        Cell: ({ cell }) => {
          return (
            <>
              <Button
                variant="contained"
                style={{ borderRadius: "20px" }}
                size="small"
                onClick={() => {
                  console.log("Row Data Total Loss:", cell.row.original);
                  let original = cell.row.original;
                  if (original.Payload != null) {
                    let payload = JSON.parse(original.Payload);
                    original.Payload = null;
                  }

                  handlePayloadClick(original);
                }}
              >
                View Payload
              </Button>
            </>
          );
        },
      },
    ],
  },
  DiminishedValue: {
    dateKey: "DateReKeyed",
    sort: [{ id: "DateReKeyed", desc: true }],
    columns: [
      {
        id: "CustomerName",
        accessorKey: "CustomerName",
        header: "CustomerName",
      },
      {
        id: "vin",
        accessorKey: "vin",
        header: "VIN",
      },
      {
        id: "DateReKeyed",
        accessorKey: "DateReKeyed",
        header: "Date",
        Cell: ({ cell }) => {
          const dateString = cell.row.original.DateReKeyed;
          const dateObject = new Date(dateString);
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZoneName: "short",
          };
          const formattedDate =
            dateObject.toString() === "Invalid Date"
              ? "N.a"
              : new Intl.DateTimeFormat("en-US", options).format(dateObject);

          return <div>{formattedDate}</div>;
        },
      },
      {
        id: "paylLastMessageoad",
        accessorKey: "LastMessage",
        header: "Download PDF",
        Cell: ({ cell }) => {
          return (
            <>
              <Button
                variant="contained"
                style={{ borderRadius: "20px" }}
                size="small"
                onClick={() => {
                  let downloadUrl = cell.row.original.LastMessage;
                  if (!downloadUrl) {
                    console.error("Download URL is missing");
                    return;
                  }

                  // Create a temporary link element and trigger the download
                  const link = document.createElement("a");
                  link.href = downloadUrl;
                  link.setAttribute("download", "true"); // This can be modified to set a specific filename
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              >
                Download PDF
              </Button>
            </>
          );
        },
      },
      {
        id: "payload",
        accessorKey: "payload",
        header: "Payload",
        Cell: ({ cell }) => {
          return (
            <>
              <Button
                variant="contained"
                style={{ borderRadius: "20px" }}
                size="small"
                onClick={() => {
                  console.log("Original obj json:", cell.row.original);
                  handlePayloadClick(cell.row.original);
                }}
              >
                View Payload
              </Button>
            </>
          );
        },
      },
    ],
  },
});

export default getTableConfigs;
