// noinspection JSValidateTypes

import React, { Component } from "react";
import { checkLocalLicense } from "../../util/license.extension";
import {
  UploadPdf,
  SubmitJob,
  ValidatePDF,
} from "../../services/autoestimaterekey.service";

import "bootstrap/dist/css/bootstrap.min.css";
import "./autoestimaterekey.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Dropzone from "react-dropzone";
import ClipLoader from "react-spinners/ClipLoader";
import { DataGrid } from "@mui/x-data-grid";

export default class EAudit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      config: {},
      isSubmitingWork: false,
      isWorkValid: false,
      isPDFValid: false,
      pdfValidationMsg: "",
      isCheckingPdf: false,
      processedPdfs: true,
    };

    this.setModalState = this.setModalState.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleSubmitWork = this.handleSubmitWork.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateWork = this.validateWork.bind(this);
    this.onCompleteUpload = this.onCompleteUpload.bind(this);
  }

  componentDidMount() {
    checkLocalLicense();
    let initialConfig = {};
    initialConfig["platform"] = "Select Platform";
    initialConfig["entry"] = "Select Entry Method";
    initialConfig["username"] = "";
    initialConfig["password"] = "";
    initialConfig["RO"] = "NULL";
    initialConfig["pdf"] = null;
    initialConfig["totalloss"] = true;

    this.setState({ config: initialConfig });
  }

  setModalState(state) {
    this.setState({ showModal: state });
  }

  setSelectedItem(key, selection) {
    let _config = this.state.config;
    _config[key] = selection;
    this.setState({ config: _config });

    this.validateWork();
  }

  getSelectedItem(key) {
    let _config = this.state.config;
    return _config[key];
  }

  async handleSubmitWork() {
    let _config = this.state.config;
    this.setState({ isSubmitingWork: true });
    SubmitJob(
      localStorage.getItem("aerk_license"),
      _config,
      this.submitJobComplete
    );
  }

  submitJobComplete() {
    window.location.href = "/dashboard/totalloss/submited/";
  }

  onCompleteUpload(data) {
    let _config = this.state.config;
    _config["pdf"] = data.filename;
    this.setState({ config: _config });

    if (data.filename != null && data.filename.length > 5) {
      ValidatePDF(_config, (validationResponse) => {
        console.log(validationResponse.success);
        console.log(validationResponse.message);

        this.setState({ pdfValidationMsg: validationResponse.message });
        if (validationResponse.success === true) {
          this.setState({ isPDFValid: true });
          this.validateWork();
        } else this.setState({ isPDFValid: false });
      });
    }

    console.log(_config);
  }

  handleInputChange(field, value) {
    this.setSelectedItem(field, value);
  }

  onChangeFileHandler = (acceptedFiles) => {
    this.setState({ isCheckingPdf: true });
    UploadPdf(acceptedFiles[0], (data) => {
      this.setState({ isCheckingPdf: false });
      this.onCompleteUpload(data);
    });

    console.log(acceptedFiles[0]);
  };

  handleMatchingInput(value) {}

  getCellHTML = (params) => {
    return (
      <div>
        <Form.Control
          type="number"
          placeholder="#Line"
          onChange={(e) => {
            this.handleMatchingInput(e.target.value);
          }}
        />
      </div>
    );
  };

  validateWork() {
    let _config = this.state.config;

    if (_config["pdf"] == null) {
      this.setState({ isWorkValid: false });
      return;
    }

    if (
      _config["platform"] !== "Select Platform" &&
      _config["entry"] !== "Select Entry Method" &&
      _config["username"] !== "Username" &&
      _config["username"].length > 0 &&
      _config["password"] !== "Password" &&
      _config["password"].length > 0
    ) {
      if (_config["entry"] === "Into Existing") {
        if (_config["RO"] !== "NULL" && _config["RO"].length > 3) {
          this.setState({ isWorkValid: true });
          return;
        }
      } else {
        this.setState({ isWorkValid: true });
        return;
      }
    }

    this.setState({ isWorkValid: false });
  }

  render() {
    const columns = [
      {
        field: "matching",
        headerName: "Matching",
        width: 70,
        renderCell: (cellValues) => this.getCellHTML(cellValues),
      },
      { field: "id", headerName: "Line#", width: 70 },
      { field: "oper", headerName: "Oper", width: 70 },
      {
        field: "description",
        headerName: "Description",
        width: 230,
      },
    ];

    const rows = [
      { id: 1, headerName: "Snow", description: "Jon", oper: "aaa" },
      { id: 2, headerName: "Lannister", description: "Cersei", oper: "aaa" },
      { id: 3, headerName: "Lannister", description: "Jaime", oper: "aaa" },
      { id: 4, headerName: "Stark", description: "Arya", oper: "aaa" },
      { id: 5, headerName: "Targaryen", description: "Daenerys", oper: "aaa" },
      { id: 6, headerName: "Melisandre", description: null, oper: "aaa" },
      { id: 7, headerName: "Clifford", description: "Ferrara", oper: "aaa" },
      { id: 8, headerName: "Frances", description: "Rossini", oper: "aaa" },
      { id: 9, headerName: "Roxie", description: "Harvey", oper: "aaa" },
    ];

    let docTypeStyle = {
      color: "green",
    };

    if (this.state.isPDFValid === false)
      docTypeStyle = {
        color: "red",
      };

    return (
      <>
        <div className="header-eaudit">
          <h3>Your Workfile</h3>
          <h3>Insurance PDF</h3>
        </div>
        {this.state.processedPdfs === false && (
          <div className="auth-wrapper">
            <div className={"auth-inner-app-form"}>
              <h3>eAudit</h3>
              <div className="Aerk-form">
                <div className={"container-step"}>
                  <div className={"container-step-entry"}>
                    Upload the Insrance's PDF
                  </div>
                  <div className={"container-step-entry"}>
                    <Dropzone onDrop={this.onChangeFileHandler}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>
                              Drag 'n' drop some files here, or click to select
                              files
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  <div style={docTypeStyle} className={"container-step-entry"}>
                    <ClipLoader
                      loading={this.state.isCheckingPdf}
                      size={30}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                    {this.state.pdfValidationMsg}
                  </div>
                </div>
                <div className={"container-step"}>
                  <div className={"container-step-entry"}>
                    Select Your CCC exported PDF
                  </div>
                  <div className={"container-step-entry"}>
                    <Dropzone onDrop={this.onChangeFileHandler}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>
                              Drag 'n' drop some files here, or click to select
                              files
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  <div style={docTypeStyle} className={"container-step-entry"}>
                    <ClipLoader
                      loading={this.state.isCheckingPdf}
                      size={30}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                    {this.state.pdfValidationMsg}
                  </div>
                </div>
                <>
                  <div className={"container-step"}>
                    <div className={"container-step-entry"}>
                      <Button
                        disabled={
                          this.state.isPDFValid === false ||
                          this.state.isSubmitingWork ||
                          this.state.isWorkValid === false
                        }
                        onClick={() => {
                          this.handleSubmitWork().then(() => {});
                        }}
                        variant="success"
                      >
                        Next
                      </Button>{" "}
                      <Button
                        disabled={this.state.isSubmitingWork}
                        onClick={() => {
                          window.location.href = "/dashboard/";
                        }}
                        variant="danger"
                      >
                        Cancel
                      </Button>{" "}
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        )}

        <div className="Table-Container">
          <div className="Table">
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </div>
          <div className="Table">
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </div>
        </div>

        <div className="footer-eaudit">
          <div className="footer-eaudit-btn">Next Step</div>
        </div>
      </>
    );
  }
}
