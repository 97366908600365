import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Badge,
  IconButton,
  Box,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import {
  fetchLocalShop,
  setActiveShopSerial,
} from "../../kritechadminv2/redux/actions";

const Header = () => {
  const user = useSelector((state) => state.auth.user);
  const activeShopSerial = useSelector((state) => state.shops.activeShopSerial);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const exitAdminDashboard = () => {
    if (user.role === "superAdmin") {
      navigate("/dashboard/admin/");
    } else {
      navigate("/dashboard");
    }
  };

  const handleExitViewAsShop = async () => {
    await dispatch(setActiveShopSerial(null));
    await dispatch(fetchLocalShop());
    navigate("/dashboard/shopadmin");
  };

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ flexGrow: 1, textDecoration: "none", color: "inherit" }}
        >
          CRASH Admin Hub
        </Typography>
        {activeShopSerial && (
          <Box display="flex" alignItems="center">
            <Badge color="error" overlap="circular" sx={{ mr: 2 }}>
              <Button
                onClick={handleExitViewAsShop}
                variant="contained"
                color="primary"
                size="small"
              >
                Exit Child Shop View
              </Button>
            </Badge>
          </Box>
        )}
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => exitAdminDashboard()}
          startIcon={<ExitToAppIcon />}
        >
          Exit
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
