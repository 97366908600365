import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchShops,
  createShop,
  fetchShopLicenses,
  fetchChildShopInfo,
  deleteShop,
  inviteToCrash,
  setActiveShopSerial,
  fetchLocalShop,
} from "../../kritechadminv2/redux/actions";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  TextField,
  Dialog,
  useTheme,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditIcon from "@mui/icons-material/ExpandLess";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CreateShopModal from "./CreateShopModal";
import ShopLicensesModal from "./ShopLicensesModal";
import { mkConfig, generateCsv, download } from "export-to-csv";
import AddIcon from "@mui/icons-material/Add";
import MinusIcon from "@mui/icons-material/Remove";
import { useNavigate, useLocation } from "react-router-dom";

const DeleteShopModal = ({ open, onClose, onConfirm, shopName }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Shop</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete "{shopName}"?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const InviteShopModal = ({ open, onClose, onSubmit, loading }) => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const localShop = useSelector((state) => state.shops.localShop);
  const sendingInvite = useSelector((state) => state.shops.sendingInvite);

  const handleSubmit = async () => {
    await dispatch(inviteToCrash(localShop.Serial, email));
    setEmail("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Invite Shop To MSO</DialogTitle>
      <DialogContent>
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={sendingInvite}>
          {sendingInvite ? <CircularProgress size={24} /> : "Invite"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ShopTable = ({ onSelectShop }) => {
  const dispatch = useDispatch();
  const shops = useSelector((state) => state.shops.shops);
  const isLoading = useSelector((state) => state.shops.loading);
  const error = useSelector((state) => state.shops.error);
  const totalCount = useSelector((state) => state.shops.totalCount);
  const localShop = useSelector((state) => state.shops.localShop);
  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  const [columnVisibility, setColumnVisibility] = useState({});
  const [isCreateShopModalOpen, setCreateShopModalOpen] = useState(false);
  const [createdShop, setCreatedShop] = useState(null);
  const [selectedShop, setSelectedShop] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedShopIds, setExpandedShopIds] = useState([]);
  const selectedShopLicenses = useSelector(
    (state) => state.shops.selectedShopLicenses
  );
  const localUser = useSelector((state) => state.shops.localUser);
  const [selectedChildShop, setSelectedChildShop] = useState(null);
  const [childAnchorEl, setChildAnchorEl] = useState(null);
  const [isInviteShopModalOpen, setInviteShopModalOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteLoading, setInviteLoading] = useState(false);
  const [isShopLicensesModalOpen, setShopLicensesModalOpen] = useState(false);
  const [selectedShopForLicenses, setSelectedShopForLicenses] = useState(null);
  const [isDeleteShopModalOpen, setDeleteShopModalOpen] = useState(false);
  const [shopToDelete, setShopToDelete] = useState(null);

  const navigate = useNavigate();

  const handleChildMenuOpen = (event, childShop) => {
    setSelectedChildShop(childShop);
    setChildAnchorEl(event.currentTarget);
  };

  const handleDeleteShop = async () => {
    setShopToDelete(selectedShop);
    setDeleteShopModalOpen(true);
    handleMenuClose();
  };

  const handleConfirmDeleteShop = async () => {
    await dispatch(deleteShop(shopToDelete._id));
    dispatch(
      fetchShops({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        columnFilters,
        globalFilter,
        sorting,
      })
    );
    setDeleteShopModalOpen(false);
    setShopToDelete(null);
  };

  const handleChildMenuClose = () => {
    setSelectedChildShop(null);
    setChildAnchorEl(null);
  };

  const handleEditLicenses = async () => {
    await dispatch(fetchShopLicenses(selectedShop.Serial));
    setSelectedShopForLicenses(selectedShop);
    setShopLicensesModalOpen(true);
    handleMenuClose();
  };

  const inviteShopToMSO = async () => {};

  const handleDeleteChildShop = async () => {
    // Dispatch action to delete the child shop
    handleChildMenuClose();

    await dispatch(deleteShop(selectedChildShop._id));
    dispatch(
      fetchShops({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        columnFilters,
        globalFilter,
        sorting,
      })
    );
  };

  const handleViewAsChildShop = async () => {
    if (selectedChildShop) {
      await dispatch(setActiveShopSerial(selectedChildShop.Serial));
      await dispatch(fetchLocalShop());
      navigate(`/dashboard/shopadmin/view-as-shop/${selectedChildShop.Serial}`);
      handleChildMenuClose();
    }
  };

  useEffect(() => {
    // Load column visibility from local storage on component mount
    const savedColumnVisibility = JSON.parse(
      localStorage.getItem("shopTableColumnVisibility") || "{}"
    );
    setColumnVisibility(savedColumnVisibility);
  }, []);

  useEffect(() => {
    // Save column visibility to local storage whenever it changes
    localStorage.setItem(
      "shopTableColumnVisibility",
      JSON.stringify(columnVisibility)
    );
  }, [columnVisibility]);

  useEffect(() => {
    dispatch(
      fetchShops({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        columnFilters,
        globalFilter,
        sorting,
      })
    );
  }, [dispatch, pagination, columnFilters, globalFilter, sorting]);

  const handleInviteShopToMSO = () => {
    setInviteShopModalOpen(true);
  };

  const handleInviteShopSubmit = async () => {
    setInviteLoading(true);
    try {
      // Dispatch the action to invite the shop using the inviteEmail
      await dispatch(inviteShopToMSO(inviteEmail));
      setInviteShopModalOpen(false);
      setInviteEmail("");
    } catch (error) {
      console.error("Error inviting shop:", error);
    } finally {
      setInviteLoading(false);
    }
  };

  const getAvailableShopsCount = () => {
    const totalShops = localShop.ShopLimit;
    const childShopsCount = localShop.childShops
      ? localShop.childShops.length
      : 0;
    return totalShops - childShopsCount - 1;
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleAddNewShop = () => {
    setCreateShopModalOpen(true);
  };

  const handleCreateShopSuccess = (newShop) => {
    setCreatedShop(newShop);
    dispatch(fetchShopLicenses(newShop.Serial));
    setCreateShopModalOpen(false);
  };

  const handleMenuOpen = (event, shop) => {
    setSelectedShop(shop);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setSelectedShop(null);
    setAnchorEl(null);
  };

  const handleViewAsShop = async () => {
    if (selectedShop) {
      await dispatch(setActiveShopSerial(selectedShop.Serial));
      await dispatch(fetchLocalShop());
      navigate(`/dashboard/shopadmin/view-as-shop/${selectedShop.Serial}`);
      handleChildMenuClose();
    }
  };

  const handleExpandShop = (shopId) => {
    if (expandedShopIds.includes(shopId)) {
      setExpandedShopIds(expandedShopIds.filter((id) => id !== shopId));
    } else {
      setExpandedShopIds([...expandedShopIds, shopId]);
      dispatch(fetchChildShopInfo(shopId));
    }
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const theme = useTheme();

  const columns = useMemo(
    () => [
      {
        accessorKey: "Serial",
        header: "Serial",
      },
      {
        accessorKey: "Name",
        header: "Name",
      },
      {
        accessorKey: "Email",
        header: "Email",
      },
      {
        accessorKey: "Phone",
        header: "Phone",
      },
      {
        accessorKey: "Company",
        header: "Company",
      },
      {
        id: "actions",
        header: "Actions",
        size: 50,
        Cell: ({ row }) => (
          <Box>
            <IconButton
              onClick={(event) => handleMenuOpen(event, row.original)}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );
  const table = useMaterialReactTable({
    columns,
    data: shops,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: true,
      columnVisibility: columnVisibility,
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: error
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    rowCount: totalCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: error,
      showProgressBars: isLoading,
      sorting,
      columnVisibility,
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        {localUser.role === "superAdmin" && (
          <Button onClick={handleAddNewShop} startIcon={<AddCircleIcon />}>
            Add New Shop
          </Button>
        )}
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
        <Button
          onClick={handleInviteShopToMSO}
          startIcon={<AddIcon />}
          disabled={getAvailableShopsCount() > 0 === false}
        >
          Invite Shop To MSO ({getAvailableShopsCount()} Invites Avaialble)
        </Button>
      </Box>
    ),
    muiTableBodyRowProps: {
      sx: {
        ".Mui-TableBodyCell-DetailPanel": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.grey[900]
              : theme.palette.grey[100],
        },
      },
    },
    muiExpandButtonProps: ({ row }) => ({
      children: (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {row.getIsExpanded() ? <MinusIcon /> : <AddIcon />}
          {row.original.childShops && row.original.childShops.length > 0 && (
            <Typography variant="body2" sx={{ marginLeft: "8px" }}>
              {row.original.childShops.length}
            </Typography>
          )}
        </Box>
      ),
      disabled:
        !row.original.childShops || row.original.childShops.length === 0,
    }),
    renderDetailPanel: ({ row }) => (
      <Box sx={{ padding: "16px" }}>
        {row.original.childShops !== undefined &&
          row.original.childShops.map((childShop) => (
            <Box
              key={childShop.Serial}
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "16px",
                padding: "16px",
                backgroundColor: theme.palette.background.default,
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="h6" gutterBottom>
                {childShop.Name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ marginRight: "8px", fontWeight: "bold" }}
                >
                  Serial:
                </Typography>
                <Typography variant="body2">{childShop.Serial}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ marginRight: "8px", fontWeight: "bold" }}
                >
                  Email:
                </Typography>
                <Typography variant="body2">{childShop.Email}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ marginRight: "8px", fontWeight: "bold" }}
                >
                  Phone:
                </Typography>
                <Typography variant="body2">{childShop.Phone}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton
                  onClick={(event) => handleChildMenuOpen(event, childShop)}
                  size="small"
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
      </Box>
    ),
  });
  return (
    <Box
      sx={{
        marginTop: "50px",
      }}
    >
      <InviteShopModal
        open={isInviteShopModalOpen}
        onClose={() => setInviteShopModalOpen(false)}
        onSubmit={handleInviteShopSubmit}
        loading={inviteLoading}
      />
      <MaterialReactTable
        table={table}
        onRowClick={({ row }) => onSelectShop(row.original)}
        sx={{
          marginTop: "150px",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
        muiTableBodyProps={{
          sx: {
            flexGrow: 1,
            overflowY: "auto",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          },
        }}
        muiTableHeadProps={{
          sx: {
            position: "sticky",
            top: 0,
            backgroundColor: "background.paper",
            zIndex: 1,
          },
        }}
        muiTablePaperProps={{
          sx: {
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          },
        }}
      />
      <CreateShopModal
        open={isCreateShopModalOpen}
        onClose={() => setCreateShopModalOpen(false)}
        onSuccess={handleCreateShopSuccess}
      />
      <ShopLicensesModal
        open={isShopLicensesModalOpen}
        onClose={() => setShopLicensesModalOpen(false)}
        shop={selectedShopForLicenses}
        licenses={selectedShopLicenses}
        onSave={(shop, enabledLicenses, transactionalCounts) => {
          console.log("Shop:", shop);
          console.log("Enabled Licenses:", enabledLicenses);
          console.log("Transactional Counts:", transactionalCounts);
          setShopLicensesModalOpen(false);
          setSelectedShopForLicenses(null);
          dispatch(
            fetchShops({
              pageIndex: pagination.pageIndex,
              pageSize: pagination.pageSize,
              columnFilters,
              globalFilter,
              sorting,
            })
          );
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDeleteShop}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
        <MenuItem onClick={handleViewAsShop}>
          <ListItemIcon>
            <VisibilityIcon />
          </ListItemIcon>
          <ListItemText primary="View As Shop" />
        </MenuItem>
        <MenuItem onClick={handleEditLicenses}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit Licenses" />
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={childAnchorEl}
        open={Boolean(childAnchorEl)}
        onClose={handleChildMenuClose}
      >
        <MenuItem onClick={handleDeleteChildShop}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
        <MenuItem onClick={handleViewAsChildShop}>
          <ListItemIcon>
            <VisibilityIcon />
          </ListItemIcon>
          <ListItemText primary="View As Shop" />
        </MenuItem>
        <MenuItem onClick={handleEditLicenses}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit Licenses" />
        </MenuItem>
      </Menu>
      <DeleteShopModal
        open={isDeleteShopModalOpen}
        onClose={() => setDeleteShopModalOpen(false)}
        onConfirm={handleConfirmDeleteShop}
        shopName={shopToDelete ? shopToDelete.Name : ""}
      />
    </Box>
  );
};

export default ShopTable;
