// noinspection JSValidateTypes

import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./autoestimaterekey.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";

import ModalNewUser from "../../components/ModalNewUser.modal";
import {
  addshopuser,
  getshopusers,
  removeshopuser,
} from "../../services/userprofile.service";

export default class UserProfiles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      config: {},
      isSubmitingWork: false,
      isWorkValid: false,
      isPDFValid: false,
      pdfValidationMsg: "",
      isCheckingPdf: false,
      clientObject: {},
      selectedOption: "NONE",
      lockProfile: false,
      newuser: "",
      newemail: "",
      activeUser: "Select User",
      activeEmail: "",
      userlist: [],
      showNewUserModal: false,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    getshopusers(localStorage.getItem("aerk_customerid"), (respone) => {
      this.setState({ userlist: respone });
    });
  }

  handleInputChange(field, value) {
    console.log(field, value);
    if (field === "name") this.setState({ newuser: value });
    if (field === "email") this.setState({ newemail: value });
  }

  isObject(obj) {
    return Object.prototype.toString.call(obj) === "[object Object]";
  }

  setSelectedItem(key, selection) {}

  getSelectedItem(key) {
    let _config = this.state.config;
    return _config[key];
  }

  setModalState(state) {
    this.setState({ showNewUserModal: state });
  }

  validateWork() {}

  setUser(user) {
    console.log("setting user:", user);
    this.setState({ activeUser: user.name });
    this.setState({ activeEmail: user.email });
  }

  getDefaultValue(field) {
    var tmpClient = this.state.clientObject;
    try {
      if (field === "CCCName") {
        return tmpClient.Company.CCCName;
      } else if (field === "MitchellName") {
        return tmpClient.Company.MitchellName;
      } else if (field === "AudatexName") {
        return tmpClient.Company.AudatexName;
      }
    } catch {}
    return "";
  }

  setProfileState(state) {
    var tmpClient = this.state.clientObject;
    tmpClient.State = state;

    this.setState({ clientObject: tmpClient });
  }

  addUser() {
    console.log("Adding new user");
    addshopuser(
      localStorage.getItem("aerk_customerid"),
      this.state.newemail,
      this.state.newuser,
      (respone) => {
        this.setState({ userlist: respone });
        window.location.reload();
      }
    );
  }

  removeuser() {
    removeshopuser(
      localStorage.getItem("aerk_customerid"),
      this.state.activeEmail,
      (respone) => {
        this.setState({ userlist: respone });
        window.location.reload();
      }
    );
  }

  render() {
    var users = this.state.userlist;
    return (
      <>
        <div className="page-header2">
          <div></div>
          <div
            className="button-header-page"
            onClick={() => {
              window.location.href = "/dashboard/";
            }}
          >
            Hub Home
          </div>
        </div>

        <div className="auth-wrapper">
          <div className={"auth-inner-app-form"}>
            <div className="auth-wrapper-header">
              <div className="auth-wrapper-txt">My CRASH Network</div>
              <div className="auth-wrapper-txt">Manage User Profiles</div>
            </div>

            <div className="Aerk-form">
              <div className={"container-step"}>
                <div className={"container-step-entry"}>Curent Shop Users:</div>
                <div className={"container-step-entry"}>
                  <MDBDropdown>
                    <MDBDropdownToggle>
                      {this.state.activeUser}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      {users.map((o) => {
                        return (
                          <MDBDropdownItem onClick={() => this.setUser(o)} link>
                            {o.name}
                          </MDBDropdownItem>
                        );
                      })}
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </div>
              </div>
              <div className={"container-step"}>
                <div className={"container-step-entry"}></div>
                <div className={"container-step-entry"}>
                  <Button
                    disabled={this.state.activeUser === "Select User"}
                    onClick={() => {
                      this.setModalState(true);
                    }}
                    variant="success"
                  >
                    Edit User
                  </Button>{" "}
                  <Button
                    disabled={this.state.activeUser === "Select User"}
                    onClick={() => {
                      this.removeuser();
                    }}
                    variant="danger"
                  >
                    Remove User
                  </Button>{" "}
                </div>
              </div>
              <div className={"container-step"}>
                <div className={"container-step-entry"}>New User's Name:</div>
                <div className={"container-step-entry"}>
                  <Form.Control
                    defaultValue={this.state.newuser}
                    type="text"
                    placeholder="Name"
                    onChange={(e) => {
                      this.handleInputChange("name", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={"container-step"}>
                <div className={"container-step-entry"}>New User's Email:</div>
                <div className={"container-step-entry"}>
                  <Form.Control
                    defaultValue={this.state.newemail}
                    type="text"
                    placeholder="Email"
                    onChange={(e) => {
                      this.handleInputChange("email", e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={"container-step"}>
                <div className={"container-step-entry"}></div>
                <div className={"container-step-entry"}>
                  <Button
                    disabled={
                      this.state.newuser === "" || this.state.newemail === ""
                    }
                    onClick={() => {
                      this.addUser();
                    }}
                    variant="success"
                  >
                    Add User
                  </Button>{" "}
                </div>
              </div>

              <div className={"container-step-entry"}>...</div>
            </div>
          </div>
        </div>
        {this.state.showNewUserModal && (
          <ModalNewUser
            setIsOpen={() => {
              this.setModalState(false);
            }}
            usert={this.state.activeUser}
            emailt={this.state.activeEmail}
          ></ModalNewUser>
        )}
      </>
    );
  }
}
