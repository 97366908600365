import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  Paper,
  Typography,
  Checkbox,
  Button,
  FormControlLabel,
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
import {
  setFileValidationStatus,
  setDvLink,
  setRange
} from "../../../../redux/diminishedvalue/actions";

const stripePromise = loadStripe("pk_live_43CORcVH1TMkq2Zk2FSuPE9Y00oYjjelMf");
let serial = localStorage.getItem("localSerial");
let adminSerial = localStorage.getItem("serial");

serial = serial === null? adminSerial:serial;

const MainContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  let selectedProduct = JSON.parse(localStorage.getItem("selectedProduct"));

  const {
    totalProjectedDimValue,
    ownerName,
    vin,
    vehicleYear,
    vehicleMake,
    vehicleModel,
    estimateTotal,
    marketValue,
    range
  } = useSelector((state) => state.diminishedValue);

  const calculateRange = (actualDV) => {
    const minRandom = Math.random() * 500 + 100;
    const maxRandom = Math.random() * 500 + 500;

    const minValue = Math.max(100, actualDV - minRandom);
    const maxValue = actualDV + maxRandom;
   
    return `<strong style="font-size: 32px;">$${minValue.toLocaleString(
      "en-US",
      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    )} - $${maxValue.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}</strong>`;
  };

  useEffect(() => {
    if (totalProjectedDimValue&& range === null) {
      dispatch(setRange(calculateRange(totalProjectedDimValue)));
    }
  }, [totalProjectedDimValue]);

  const handleConfirmation = (paymentMethod) => {
    console.log("Payment Method Created:", paymentMethod);
    setShowModal(false);
  };

  function formatMoney(amount) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    outline: "none",
    border: "2px solid rgb(192, 44, 122)", // Added pink border
    textAlign: "center", // Centered text
    marginTop: "20px", // Added top margin
  };

  const price = JSON.parse(localStorage.getItem("dvprice"));

  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "40px",
        minHeight: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "1500px",
      }}
    >
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="purchase-modal"
        aria-describedby="confirm-purchase-action"
      >
        <Paper style={modalStyle}>
          <div>
            <h3
              style={{
                fontSize: "28px", // Larger header text
                fontFamily: "Orbitron, sans-serif",
                color: "rgb(192, 44, 122)", // Pink color
                marginTop: "20px", // Added top margin for header
                cursor: "default", // Use default cursor style
              }}
            >
              YOUR ESTIMATED DIMINISHED VALUE
            </h3>
          </div>

          <div style={{ padding: "20px" }}>
            {(!serial || selectedProduct?.AvailableTransactions <= 0) && (
              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                Confirming this action will charge your account ${price || selectedProduct.UnitPrice} USD for
                the Final Diminished Value Report.
              </Typography>
            )}
            <div style={{ marginBottom: "20px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    color="primary"
                  />
                }
                label={
                  <span>
                    I Understand and Agree to the{" "}
                    <a
                      href="https://aerksa.blob.core.windows.net/legal/KriTech_terms_and_conditions_(current).pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Conditions
                    </a>{" "}
                    for this Diminished Value Application and the Charge for the
                    Transaction.
                  </span>
                }
              />
            </div>
            <Elements stripe={stripePromise}>
              <PaymentForm
                onPaymentMethodCreated={handleConfirmation}
                termsAccepted={termsAccepted}
              />
            </Elements>
          </div>
        </Paper>
      </Modal>
      <div style={{ maxWidth: "400px", width: "100%" }}>
        <h2
          style={{
            color: "rgb(192, 44, 122)",
            fontSize: "28px", // Larger header text
            fontWeight: "bold",
            lineHeight: "1.5",
            cursor: "default",
          }}
        >
          YOUR
          <br />
          DIMINISHED VALUE
          <br />
          REPORT
        </h2>

        <div className="summary-container">
          <h3
            style={{
              fontSize: "28px", // Larger header text
              fontFamily: "Orbitron, sans-serif",
              color: "rgb(192, 44, 122)", // Pink color
              marginTop: "20px", // Added top margin for header
            }}
          >
            Summary
          </h3>
          <div style={{ textAlign: "center" }}>
            {" "}
            {/* Centered summary text */}
            <p style={{ fontSize: "18px", textAlign: "center" }}>
              <strong>Owner Name:</strong> {ownerName}
            </p>
            <p style={{ fontSize: "18px", textAlign: "center" }}>
              <strong>VIN:</strong> {vin}
            </p>
            <p style={{ fontSize: "18px", textAlign: "center" }}>
              <strong>Vehicle Year:</strong> {vehicleYear}
            </p>
            <p style={{ fontSize: "18px", textAlign: "center" }}>
              <strong>Vehicle Make:</strong> {vehicleMake}
            </p>
            <p style={{ fontSize: "18px", textAlign: "center" }}>
              <strong>Vehicle Model:</strong> {vehicleModel}
            </p>
            <p style={{ fontSize: "18px", textAlign: "center" }}>
              <strong>Estimate Total:</strong> {formatMoney(estimateTotal)}
            </p>
            <p style={{ fontSize: "18px", textAlign: "center" }}>
              <strong>Market Value:</strong> {formatMoney(marketValue)}
            </p>
          </div>
        </div>

        <div
          style={{
            border: "2px solid rgb(192, 44, 122)",
            borderRadius: "10px",
            padding: "10px",
            marginTop: "40px",
            alignContent: "center",
          }}
        >
  {totalProjectedDimValue === 0 ? (
    <p style={{ fontSize: "20px", color: "black", marginTop: "10px" , // Larger header text
      fontFamily: "Orbitron, sans-serif",}}>
      Vehicle is not eligible for diminished value assessment.
    </p>
  ) : (
    <p style={{ fontSize: "26px", color: "black", marginTop: "10px" }} dangerouslySetInnerHTML={{ __html: range }}></p>
  )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <button
            onClick={() => {
              dispatch(setDvLink(null));
              navigate("/instantdv/6_OwnerInformation");
            }}
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "rgb(192, 44, 122)",
              fontFamily: "Roboto, sans-serif",
              fontSize: "24px",
              fontWeight: "bold",
              cursor: "pointer",
              marginRight: "10px",
              marginTop: "40px",
            }}
          >
            &lt; BACK
          </button>{totalProjectedDimValue !== 0 && (
          <button
            style={{
              backgroundColor: "rgb(192, 44, 122)",
              color: "white",
              fontWeight: "bold",
              fontSize: "18px",
              border: "none",
              cursor: "pointer",
              marginTop: "40px",
              marginLeft: "10px",
              fontFamily: "Orbitron, sans-serif",
            }}
            onClick={() => setShowModal(true)}
          >
            GET REPORT &gt;
          </button>)}
        </div>
      </div>
    </div>
  );
};

export default MainContent;
