// VehicleConditionDropdown.js
import React from "react";
import Select from "react-select";

const VehicleConditionDropdown = ({
  vehicleCondition,
  setVehicleCondition,
  errors,
  customStyles,
  options,
}) => {
  const handleChange = (selectedOption) => {
    setVehicleCondition(selectedOption.modifier);
  };

  const updatedCustomStyles = {
    ...customStyles,
    control: (provided) => ({
      ...provided,
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
      backgroundColor: "rgba(111, 111, 111, 0.039)",
    }),
    option: (provided) => ({
      ...provided,
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
    }),
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Select
        options={options}
        value={options.find((option) => option.modifier === vehicleCondition)}
        onChange={handleChange}
        styles={updatedCustomStyles}
        placeholder="VEHICLE CONDITION PRIOR TO ACCIDENT"
      />
      {errors.vehicleCondition && (
        <div style={{ color: "red", fontSize: "12px", textAlign: "left" }}>
          {errors.vehicleCondition}
        </div>
      )}
    </div>
  );
};

export default VehicleConditionDropdown;
