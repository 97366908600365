// UsageReportPage.js
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUsageReportData, fetchProducts } from "../redux/actions";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PrintIcon from "@mui/icons-material/Print";
import {
  MRT_ToggleDensePaddingButton,
  MRT_ToggleRowActionMenuButton,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFiltersButton,
  MRT_ShowHideColumnsButton,
} from "material-react-table";
import {
  AppBar,
  Toolbar,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
  Button,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { useNavigate } from "react-router-dom";
import getTableConfigs from "./TableConfig";
import PayloadModal from "./PayloadModal"; // Adjust the import path as necessary
import { MaterialReactTable } from "material-react-table";

import FilterIcon from "@mui/icons-material/FilterList";
const queryClient = new QueryClient();

const UsageReportPage = (serial, user) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, paginationInfo, products, loading, error } = useSelector(
    (state) => state.usageReports
  );

  const [selectedProduct, setSelectedProduct] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sortModel, setSortModel] = useState([]);
  const [isPayloadModalOpen, setIsPayloadModalOpen] = useState(false);
  const [payloadData, setPayloadData] = useState("");
  const localUser = useSelector((state) => state.shops.localUser);
  const localShop = useSelector((state) => state.shops.localShop);
  const [ownedProductsNames, setOwnedProductsNames] = useState([]);
  const [includeAllShopUsage, setIncludeAllShopUsage] = useState(false);
  const handlePayloadClickTable = (rowData) => {
    try {
      // First, unescape the JSON string
      const unescapedJson = rowData.Payload.replace(/\\n/g, '\n').replace(/\\"/g, '"');
  
      // Remove the outer quotes if they exist
      const trimmedJson = unescapedJson.replace(/^"(.*)"$/, '$1');
  
      // Parse the unescaped JSON string
      let payloadObj;
      try {
        payloadObj = JSON.parse(trimmedJson);
      } catch (parseError) {
        console.error("JSON Parse Error:", parseError);
        console.log("Problematic JSON string:", trimmedJson);
        
        // Find the position where the error occurred
        const errorPosition = parseInt(parseError.message.match(/position (\d+)/)?.[1]);
        if (!isNaN(errorPosition)) {
          const errorContext = trimmedJson.substring(Math.max(0, errorPosition - 50), errorPosition + 50);
          console.log("Error context:", errorContext);
        }
        
        // Handle the error (e.g., show an error message to the user)
        setPayloadData({ ...rowData, Payload: "Error: Invalid JSON" });
        setIsPayloadModalOpen(true);
        return;
      }
  
      // Remove estimateData field
      if (payloadObj.estimateData) {
        delete payloadObj.estimateData;
      }
  
      // Remove document fields
      if (payloadObj.UnivEstimateDocument && payloadObj.UnivEstimateDocument.DocumentFields) {
        delete payloadObj.UnivEstimateDocument.DocumentFields;
      }
  
      // Convert the modified object back to a JSON string without formatting
      const cleanedPayload = JSON.stringify(payloadObj);
      console.log(cleanedPayload);
  
      // Update state with the cleaned payload
      setPayloadData({...rowData, Payload: cleanedPayload});
      setIsPayloadModalOpen(true);
    } catch (error) {
      console.error("Error in handlePayloadClickTable:", error);
      // Handle the error (e.g., show an error message to the user)
      setPayloadData({ ...rowData, Payload: "Error processing payload" });
      setIsPayloadModalOpen(true);
    }
  };

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const columnsConfig = useMemo(
    () => getTableConfigs(handlePayloadClickTable)[selectedProduct] || [],
    [selectedProduct]
  );

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    const ownedNamesSet = new Set();
    products.forEach((product) => {
      if (
        localShop.ActiveProducts[product._id] &&
        localShop.ActiveProducts[product._id].enabled
      ) {
        // Remove "__string" and any characters following it
        const cleanedName = product.ProductName.replace(/__.*$/, "");

        // Check if cleaned name is already in the set
        if (!ownedNamesSet.has(cleanedName)) {
          ownedNamesSet.add(cleanedName);
        }
      }
    });

    const ownedNames = Array.from(ownedNamesSet);
    setOwnedProductsNames(ownedNames);
  }, [products, localShop]);

  useEffect(() => {
    dispatch(
      fetchUsageReportData(
        paginationInfo.page,
        paginationInfo.pageSize,
        selectedProduct,
        startDate,
        endDate,
        columnFilters,
        globalFilter,
        sortModel,
        localUser,
        includeAllShopUsage
      )
    );
  }, [
    selectedProduct,
    startDate,
    endDate,
    columnFilters,
    globalFilter,
    sortModel,
    localUser.role,
    includeAllShopUsage,
  ]);

  useEffect(() => {
    console.log("pagination changed");
    dispatch(
      fetchUsageReportData(
        pagination.pageIndex + 1,
        pagination.pageSize,
        selectedProduct,
        startDate,
        endDate,
        columnFilters,
        globalFilter,
        sortModel,
        localUser,
        includeAllShopUsage
      )
    );
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    console.log("Updated columns");
    if (Array.isArray(columnsConfig.sort)) {
      console.log("Updated sortModel:", columnsConfig.sort);
      setSortModel(columnsConfig.sort);
    }
  }, [columnsConfig]);

  const handleChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  const handleFilter = () => {
    setStartDate(dateRange[0].format("YYYY-MM-DD"));
    setEndDate(dateRange[1].format("YYYY-MM-DD"));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <div style={{ marginTop: "50px", flexGrow: 1, overflow: "auto" }}>
        <MaterialReactTable
          data={data}
          columns={
            columnsConfig.columns !== undefined ? columnsConfig.columns : []
          }
          state={{
            pagination: pagination,
            globalFilter,
            sorting: sortModel,
            columnFilters,
          }}
          manualPagination
          manualFiltering={true}
          onColumnFiltersChange={setColumnFilters}
          manualSorting={true}
          onGlobalFilterChange={(newValue) => {
            setGlobalFilter(newValue || "");
          }}
          onPaginationChange={setPagination}
          onSortingChange={setSortModel}
          pageCount={paginationInfo.totalPages}
          rowCount={paginationInfo.totalDocs}
          pageSizeOptions={[20, 50, 100, 500]}
          initialState={{ showColumnFilters: true }}
          renderToolbarInternalActions={({ table }) => (
            <Box>
              <MRT_ToggleGlobalFilterButton table={table} />
              <IconButton
                onClick={() => {
                  window.print();
                }}
              >
                <PrintIcon />
              </IconButton>

              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
            </Box>
          )}
          renderTopToolbarCustomActions={({ table }) => (
            <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeAllShopUsage}
                    onChange={(event) =>
                      setIncludeAllShopUsage(event.target.checked)
                    }
                  />
                }
                label="Include all related shop usage"
              />
              <FormControl
                variant="outlined"
                sx={{ minWidth: 150, bgcolor: "#ffffff" }}
              >
                <InputLabel htmlFor="product-select">Product</InputLabel>
                <Select
                  label="Product"
                  id="product-select"
                  value={selectedProduct}
                  onChange={handleChange}
                >
                  {ownedProductsNames.map((product) => (
                    <MenuItem key={product} value={product}>
                      {product}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateRangePicker
                  startText="Start Date"
                  endText="End Date"
                  value={dateRange}
                  onChange={(newRange) => setDateRange(newRange)}
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField
                        {...startProps}
                        helperText=""
                        variant="standard"
                        margin="normal"
                      />
                      <TextField
                        {...endProps}
                        helperText=""
                        variant="standard"
                        margin="normal"
                      />
                    </>
                  )}
                />
              </LocalizationProvider>
              <Tooltip title="Apply filter by Date">
                <IconButton onClick={handleFilter}>
                  <FilterIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
      </div>
      <PayloadModal
        open={isPayloadModalOpen}
        handleClose={() => setIsPayloadModalOpen(false)}
        payload={payloadData}
      />
    </div>
  );
};

export default UsageReportPage;
