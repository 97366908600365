import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Button,
  Box,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SyncIcon from "@mui/icons-material/Sync";
import { API_URI, APU_URL_FLOWS } from "../../../../Globals";
import EditProductModal from "./EditProductModal";

const ProductTable = () => {
  const [products, setProducts] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [isResyncing, setIsResyncing] = useState(false);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(API_URI() + "/admin/getAllProducts");
      console.log(response.data);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const openEditModal = (product) => {
    setEditingProduct(product);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditingProduct(null);
    setEditModalOpen(false);
    fetchProducts();
  };

  const saveEdit = () => {
    closeEditModal();
    fetchProducts();
  };

  const deleteProduct = async (productId) => {
    try {
      await axios.delete(API_URI() + `/admin/deleteProduct/${productId}`);
      // After deletion, re-fetch products to update the table:
      const response = await axios.get(API_URI() + "/admin/getAllProducts");
      console.log(response.data);
      setProducts(response.data);
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const resyncPricebooks = async () => {
    setIsResyncing(true);
    try {
      await axios.get(APU_URL_FLOWS() + "/stripe/resync-pricebooks");
      // After resync, re-fetch products to update the table
      fetchProducts();
    } catch (error) {
      console.error("Error resyncing pricebooks:", error);
    }
    setIsResyncing(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Tooltip title="Resync pricebooks">
          <Button
            variant="contained"
            color="primary"
            onClick={resyncPricebooks}
            disabled={isResyncing}
            startIcon={
              isResyncing ? <CircularProgress size={20} /> : <SyncIcon />
            }
          >
            {isResyncing ? "Resyncing..." : "Resync"}
          </Button>
        </Tooltip>
      </Box>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="products table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">Active Image</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">Product Name</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">Description</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">Custom Note</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">SF Payments Object</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">Product Detail Url</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">On Create Field Set</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  Salesforce Pricebook ID
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  Salesforce Product Code
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">Unit Price</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">Setup Fee</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">Is Enabled</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">eCommerce</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">Under Maintenance</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  Display If Not Owned
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  Transactional Product
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product._id} hover role="checkbox" tabIndex={-1}>
                <TableCell>
                  <img
                    src={
                      product.IsEnabled === "true"
                        ? product.ProductActiveImage
                        : product.ProductInactiveImage
                    }
                    alt="Product Active"
                    width="50"
                    height="50"
                  />
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{product.ProductName}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {product.ProductDescription}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{product.CustomNote}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {product.customSfPaymentObject}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {product.ProdDetailUrl}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {product.OnCreateFieldSet}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {product.SalesforceProductId}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {product.SalesforceProductCode}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {product.UnitPrice !== undefined
                      ? "$" + product.UnitPrice + " USD"
                      : "N/A"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {product.SetupFee !== undefined
                      ? "$" + product.SetupFee + " USD"
                      : "N/A"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{product.IsEnabled}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {product.eCommerce === true ? "Enabled" : "Disabled"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {product.UnderMaintenance === true ? "Enabled" : "Disabled"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {product.DisplayIfNotOwned === true ? "Yes" : "No"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {product.Transactional === true ? "Yes" : "No"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Tooltip title="Edit product">
                    <IconButton
                      color="primary"
                      aria-label="Edit product"
                      onClick={() => openEditModal(product)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete product">
                    <IconButton
                      color="secondary"
                      aria-label="Delete product"
                      onClick={() => deleteProduct(product._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isEditModalOpen && (
        <EditProductModal
          product={editingProduct}
          open={isEditModalOpen}
          onClose={closeEditModal}
          onSave={saveEdit}
        />
      )}
    </Box>
  );
};

export default ProductTable;
