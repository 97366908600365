import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Switch from "@mui/material/Switch";
import axios from "axios";
import { API_URI } from "../../../../Globals";
import { Box, Typography, Button, Container } from "@mui/material";
const LicensesModal = ({ open, onClose, user, products, onSave, shop }) => {
  const [enabledProducts, setEnabledProducts] = useState([]); // object to hold which products are enabled

  useEffect(() => {
    console.log("ShopData:", shop);
    localStorage.setItem("Company", shop.Company);
    if (open && user) {
      if (user.ActiveProducts) {
        setEnabledProducts(user.ActiveProducts); // Set the enabledProducts state directly from user.ActiveProducts
      } else {
        const initialProductsState = {};
        products.forEach((product) => {
          initialProductsState[product._id] = false;
        });
        setEnabledProducts(initialProductsState); // If ActiveProducts field doesn't exist, set all products to false
      }
    }
  }, [open, user, products]);

  const handleToggleProduct = (productId) => {
    setEnabledProducts((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };

  const saveUserProducts = async () => {
    const apiUrl = API_URI() + "/adminShop/updateActiveProducts"; // replace with your API endpoint
    try {
      const response = await axios.patch(apiUrl, {
        Serial: user.Serial,
        ActiveProducts: enabledProducts,
      });
      console.log("Update successful:", response.data);
      return Promise.resolve(); // Resolve the promise
    } catch (error) {
      console.error("Error updating products:", error);
      return Promise.reject(error); // Reject the promise
    }
  };

  const handleSave = async () => {
    try {
      await saveUserProducts(); // await the saveUserProducts function to complete
      onClose(); // then close the modal
    } catch (error) {
      console.error("Save failed:", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: "400px",
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Manage Licenses for {user?.Name}
        </Typography>
        {products
          .filter(
            (product) => shop.ActiveProducts && shop.ActiveProducts[product._id]
          )
          .map((product) => (
            <Box
              key={product._id}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                my: 1,
              }}
            >
              <label>{product.ProductName}</label>
              <Switch
                checked={enabledProducts[product._id] || false}
                onChange={() => handleToggleProduct(product._id)}
              />
            </Box>
          ))}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ mt: 2 }}
        >
          Save
        </Button>
      </Container>
    </Modal>
  );
};

export default LicensesModal;
