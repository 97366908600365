// src/redux/products/reducer.js
const initialState = {
  products: [],
  activeProducts: [],
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PRODUCTS":
      return {
        ...state,
        products: action.payload,
        activeProducts: action.payload.filter((product) => product.IsPublic),
      };
    default:
      return state;
  }
};

export default productsReducer;
