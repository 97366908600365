// ShopSelector.jsx
import { InputLabel, Select, MenuItem, FormControl } from "@mui/material";

function ShopSelector({ shops, selectedShop, onSelect }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <InputLabel
        htmlFor="shop-dropdown"
        style={{
          color: "white",
          marginRight: "10px",
          fontSize: "1.2rem",
          lineHeight: "1.5", // Added to ensure it's centered
          marginTop: "auto", // Centers it vertically
          marginBottom: "auto",
        }}
      >
        Select Shop:
      </InputLabel>
      <FormControl
        variant="outlined"
        size="small"
        style={{ backgroundColor: "white", marginRight: "10px" }}
      >
        <Select
          value={selectedShop}
          onChange={onSelect}
          id="shop-dropdown"
          style={{ minWidth: "200px" }}
        >
          <MenuItem value="All">All</MenuItem>
          {shops.map((shop) => (
            <MenuItem key={shop.Name} value={shop.Name}>
              {shop.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default ShopSelector;
