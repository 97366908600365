import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import StepHeader from "../components/StepHeader";
import MainContent from "./7_GetReport/MainContent";

const _7_GetReport = () => {
  return (
    <div style={{ maxWidth: "1500px", margin: "0 auto" }}>
      <Header
        isGrey={true}
        buttonText="DRIVE AWAY WITH A FAIR COMPENSATION"
        style={{ marginLeft: 0 }}
      />
      <div
        style={{ position: "relative", overflow: "hidden", minHeight: "400px" }}
      >
        <img
          src="/img/diminishedvalue/dv_car_r.png"
          alt="DV Car"
          style={{
            position: "absolute",
            left: "0",
            top: "50%",
            transform: "translateY(-50%)",
            width: "25%",
            height: "auto",
            zIndex: -1,
          }}
        />
        <img
          src="/img/diminishedvalue/dv_car_l.png"
          alt="DV Car"
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translateY(-50%) ",
            width: "25%",
            height: "auto",
            zIndex: -1,
          }}
        />
        <div style={{ position: "relative", zIndex: 1 }}>
          <StepHeader tstep={"Get Report"} />
          <MainContent />
        </div>
      </div>
      <Footer isGrey={true} />
    </div>
  );
};

export default _7_GetReport;
