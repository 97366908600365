import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Modal, Box, Typography, Select, MenuItem } from "@mui/material";
import { states } from "./states";
import { resetDvState } from "../../../redux/diminishedvalue/actions";
import { useDispatch } from "react-redux";
const Header = ({
  buttonText,
  buttonLink,
  isGrey,
  style,
  showAdditionalInfo = true,
}) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const dispatch = useDispatch();
  const handleClick = () => {
    if (buttonLink) {
      navigate(buttonLink);
    } else {
      setModalOpen(true);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleStateChange = (event) => {
    const state = event.target.value;
    setSelectedState(state);
    window.open(
      `https://mycrash.net/instantdv/AdditionalInfo?state=${encodeURIComponent(
        state
      )}`,
      "_blank"
    );
    handleClose();
  };

  const serialKey = localStorage.getItem("serial");
  const showReturnButton = serialKey && serialKey.length > 1;

  return (
    <header
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 0",
        position: "relative",
        backgroundColor: isGrey ? "#f0f0f0" : "transparent",
        ...style,
        fontFamily: "Roboto, sans-serif",
      }}
    >
      <img
        src="/img/diminishedvalue/diminishedvalue_logo.png"
        alt="Diminished Value Logo"
        style={{ width: "200px", marginLeft: "40px" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {buttonText && (
          <div
            style={{
              backgroundColor: "rgb(192, 44, 122)",
              color: "white",
              fontWeight: "bold",
              fontSize: "18px",
              padding: "5px 20px",
              border: "none",
              fontFamily: "Orbitron, sans-serif",
              cursor: "default",
            }}
            onClick={handleClick}
          >
            {buttonText}
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
        >
          {showReturnButton && (
            <button
              style={{
                backgroundColor: "transparent",
                fontSize: "14px",
                border: "none",
                color: "black",
                fontFamily: "Orbitron, sans-serif",
                fontWeight: "bold",
                whiteSpace: "nowrap",
                marginBottom: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(resetDvState());
                navigate("/dashboard/diminishedvalue");
              }}
            >
              &lt; Return to DV Dashboard
            </button>
          )}
          {showAdditionalInfo && (
            <button
              style={{
                backgroundColor: "transparent",
                fontSize: "14px",
                border: "none",
                color: "black",
                fontFamily: "Orbitron, sans-serif",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
              onClick={handleClick}
            >
              ADDITIONAL RESOURCES &gt;
            </button>
          )}
        </div>
      </div>
      <Modal open={modalOpen} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            align="center"
            fontFamily="Orbitron, sans-serif"
          >
            SELECT A STATE
          </Typography>
          <Select
            value={selectedState}
            onChange={handleStateChange}
            fullWidth
            displayEmpty
            renderValue={(value) => (value ? value : "Select a state")}
            sx={{
              fontFamily: "Orbitron, sans-serif",
              fontSize: "18px",
              "& .MuiSelect-icon": {
                fontSize: "28px",
              },
            }}
          >
            {states.map((state) => (
              <MenuItem
                key={state}
                value={state}
                sx={{ fontFamily: "Orbitron, sans-serif", fontSize: "18px" }}
              >
                {state}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Modal>
    </header>
  );
};

export default Header;
