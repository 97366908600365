import axios from "axios";
import { API_URI } from "../../../Globals";

const API_BASE_URL = API_URI(); // adjust this to your API base URL

export function fetchGroups() {
  return axios
    .get(`${API_BASE_URL}/superadmin/groups`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Failed to fetch groups", error);
      throw error;
    });
}

export async function createGroup(groupName, parentId = null) {
  console.log("Creating New Group:", groupName, ", ParnetId=", parentId);
  const response = await axios.post(
    `${API_BASE_URL}/superadmin/groups/${
      parentId !== null ? parentId + "/" : ""
    }${groupName}`
  );
  const group = response.data;
  return group;
}

export function saveGroupOptions(groupId, options) {
  return axios
    .patch(
      `${API_BASE_URL}/superadmin/config/group/${groupId}/options`,
      options
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error("Failed to save group options", error);
      throw error;
    });
}

export function getGroupOptions(groupId) {
  return axios
    .get(`${API_BASE_URL}/superadmin/config/group/${groupId}/options`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Failed to get group options", error);
      throw error;
    });
}

export function updateGroup(group) {
  return axios
    .put(`${API_BASE_URL}/superadmin/groups/${group._id}`, { group })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Failed to update group", error);
      throw error;
    });
}

export function deleteGroup(id) {
  return axios
    .delete(`${API_BASE_URL}/superadmin/groups/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Failed to delete group", error);
      throw error;
    });
}

export function fetchConfig(path) {
  return axios
    .get(`${API_BASE_URL}/superadmin/config/${path}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Failed to fetch config for path: ${path}`, error);
      throw error;
    });
}

export function updateConfig(path, config) {
  return axios
    .patch(`${API_BASE_URL}/superadmin/config/${path}`, config)
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Failed to update config for path: ${path}`, error);
      throw error;
    });
}
