import React, { useEffect, useState } from "react";
import { Box, Paper, Button, Typography, Grid } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DiminishedValue from "../diminishedvalue.page";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import { API_URI } from "../../../Globals";
import DiminishedValueMaterialTable from "./DiminishedValueMaterialTable";

const DiminishedValueUsage = () => {
  const navigate = useNavigate();
  // Define your columns configuration
  const columnsConfig = [
    { id: "owner", label: "Owner", minWidth: 170 },
    { id: "vin", label: "VIN", minWidth: 170 },
    { id: "make", label: "Make", minWidth: 100 },
    { id: "model", label: "Model", minWidth: 100 },
    { id: "year", label: "Year", minWidth: 70 },
    { id: "totalDV", label: "Total DV", minWidth: 100, align: "right" },
    {
      id: "download",
      label: "Action",
      minWidth: 170,
      align: "center",
      format: (value, row) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            console.log("Download URL:", row.lastMessage);
            handleDownload(row.lastMessage);
          }}
        >
          Download PDF
        </Button>
      ),
    },
  ];

  const [modalNewDv, setModalNewDv] = React.useState(false);
  const [apiEndpoint, setApiEndpoint] = React.useState("");
  const { user } = useAuth();
  const [tableData, setTableData] = React.useState([]);
  const [dvInstances, setDvInstances] = useState([]);

  const handleDownload = (downloadUrl) => {
    if (!downloadUrl) {
      console.error("Download URL is missing");
      return;
    }

    // Create a temporary link element and trigger the download
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "true"); // This can be modified to set a specific filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Debugging: Log the API endpoint and columns configuration
  console.log("API Endpoint:", apiEndpoint);
  console.log("Columns Config:", columnsConfig);

  const handleReturnToDashboard = () => {
    navigate("/");
  };

  const handleCreateNew = () => {
    navigate("/instantdv/2_StartReport");
    // setModalNewDv(true);
    // setDvInstances([...dvInstances, { id: Date.now() }]);
  };

  const handleCancleCreateDv = () => {
    setModalNewDv(false);
    setDvInstances([]);
  };

  return (
    <>
      {modalNewDv === true && (
        <>
          <Box sx={{ position: "absolute", top: 0, left: 0, m: 2 }}>
            <Button
              startIcon={<DashboardIcon />}
              variant="contained"
              color="secondary"
              onClick={handleCancleCreateDv}
            >
              Return to Dashboard
            </Button>
          </Box>
        </>
      )}

      {modalNewDv === false && (
        <>
          <Box sx={{ margin: "auto", maxWidth: "1200px", padding: "20px" }}>
            <Box my={4} display="flex" justifyContent="center">
              <img
                src="../../../img/diminishedvalue_logo.png"
                alt="Logo"
                style={{ width: "auto", height: "120px" }}
              />
            </Box>

            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Grid item>
                <Button
                  startIcon={<DashboardIcon />}
                  variant="contained"
                  color="primary"
                  onClick={handleReturnToDashboard}
                >
                  Dashboard Home
                </Button>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  variant="contained"
                  color="secondary"
                  onClick={handleCreateNew}
                >
                  Create New
                </Button>
              </Grid>
            </Grid>
          </Box>
          <DiminishedValueMaterialTable />
        </>
      )}
    </>
  );
};

export default DiminishedValueUsage;
