import React, { useState } from "react";
import { API_URI } from "../../../../Globals";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import axios from "axios";

function CreateProductModal({ open, onClose, onSave }) {
  const [product, setProduct] = useState({
    ProductName: "",
    ProductDescription: "",
    ProductActiveImage: null,
    ProductInactiveImage: null,
    SalesforceProductId: "",
    EnableSalesforceIntegration: false,
    IsEnabled: false,
    UnderMaintenance: false,
  });

  const [previewImage, setPreviewImage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setProduct((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      setProduct((prevState) => ({
        ...prevState,
        [name]: file,
      }));
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(product).forEach((key) => {
      formData.append(key, product[key]);
    });

    axios
      .post(API_URI() + "/admin/createProduct", formData)
      .then((response) => {
        console.log("Success:", response.data);

        document.location.reload();
      })
      .catch((error) =>
        console.error("There was an error creating the product:", error)
      );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Create New Product</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          label="Product Name"
          name="ProductName"
          value={product.ProductName}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Product Description"
          name="ProductDescription"
          value={product.ProductDescription}
          onChange={handleInputChange}
        />
        <Typography variant="body1" gutterBottom>
          Product Enabled Image
        </Typography>
        <input
          type="file"
          accept="image/*"
          name="ProductActiveImage"
          onChange={handleImageChange}
        />
        {product.ProductActiveImage && (
          <img
            src={URL.createObjectURL(product.ProductActiveImage)}
            alt="Product Enabled Preview"
            style={{ width: "150px", height: "150px" }}
          />
        )}

        <Typography variant="body1" gutterBottom>
          Product Disabled Image
        </Typography>
        <input
          type="file"
          accept="image/*"
          name="ProductInactiveImage"
          onChange={handleImageChange}
        />
        {product.ProductInactiveImage && (
          <img
            src={URL.createObjectURL(product.ProductInactiveImage)}
            alt="Product Disabled Preview"
            style={{ width: "150px", height: "150px" }}
          />
        )}
        <TextField
          fullWidth
          margin="normal"
          label="Salesforce Product ID"
          name="SalesforceProductId"
          value={product.SalesforceProductId}
          onChange={handleInputChange}
        />
        <FormControlLabel
          control={
            <Switch
              checked={product.EnableSalesforceIntegration}
              onChange={handleSwitchChange}
              name="EnableSalesforceIntegration"
              color="primary"
            />
          }
          label="Enable Salesforce Integration"
        />
        <FormControlLabel
          control={
            <Switch
              checked={product.IsEnabled}
              onChange={handleSwitchChange}
              name="IsEnabled"
              color="primary"
            />
          }
          label="Is Enabled"
        />
        <FormControlLabel
          control={
            <Switch
              checked={product.UnderMaintenance}
              onChange={handleSwitchChange}
              name="UnderMaintenance"
              color="primary"
            />
          }
          label="Under Maintenance"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateProductModal;
