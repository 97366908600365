import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ProductTable from "./ProductTable"; // import your ProductTable component
import { useNavigate, useLocation } from "react-router-dom";
import CreateProductModal from "./CreateProductModal"; // import your CreateProductModal component

const AdminProductPage = () => {
  const navigate = useNavigate();
  const [isProductModalOpen, setProductModalOpen] = useState(false);

  const createProduct = () => {
    setProductModalOpen(true);
  };

  const closeProductModal = () => {
    setProductModalOpen(false);
  };

  const exitProductDashboard = () => {
    navigate("/dashboard/admin/");
  };

  const handleSaveProduct = (product) => {
    // Handle saving the product here, e.g. update state, re-fetch products, etc.
    closeProductModal();
  };

  return (
    <div>
      <AppBar position="static" style={{ backgroundColor: "#3f51b5" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
          ></IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Product Management
          </Typography>

          <Button
            color="inherit"
            onClick={createProduct}
            startIcon={<AddIcon />}
          >
            Create Product
          </Button>

          <Button
            color="inherit"
            onClick={exitProductDashboard}
            startIcon={<ExitToAppIcon />}
          >
            Exit
          </Button>
        </Toolbar>
      </AppBar>

      <ProductTable />

      {isProductModalOpen && (
        <CreateProductModal
          open={isProductModalOpen}
          onClose={closeProductModal}
          onSave={handleSaveProduct}
        />
      )}
    </div>
  );
};

export default AdminProductPage;
