import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  Typography,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ReportModal = ({
  open,
  handleClose,
  onSubmit,
  initialText1,
  initialText2,
  disableInput1,
  disableInput2,
  baseText1,
}) => {
  const [requestInfoNotes, setRequestInfoNotes] = useState("");

  useEffect(() => {
    setRequestInfoNotes(initialText1);
  }, [initialText1]);

  const handleSubmit = () => {
    onSubmit(requestInfoNotes, requestInfoNotes);
    handleClose();
  };

  const handleReportClose = () => {
    console.log("Triggered: JobReport Close Modal");
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent
        sx={{
          borderRadius: "16px",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleReportClose}
          aria-label="close"
          sx={{ alignSelf: "flex-end", margin: "-8px -8px 0 0" }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h6"
            align="center"
            sx={{ fontWeight: "bold", fontSize: "2.2em", marginBottom: "30px" }}
          >
            Report Issue with Total Loss Estimate
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "black" }}>
            Please describe in details what is wrong with your Total Loss
            Estimate
          </Typography>
          <TextField
            multiline
            rows={10}
            variant="outlined"
            fullWidth
            defaultValue={initialText1}
            value={requestInfoNotes}
            onChange={(e) => setRequestInfoNotes(e.target.value)}
            InputProps={{
              style: {
                fontSize: "2rem",
              },
            }}
            sx={{ height: "100%" }}
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit Report
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ReportModal;
