import React, { useState } from "react";

const InputField = ({
  label,
  value,
  onChange,
  placeholder,
  error,
  disabled = false,
  type = "text",
  style = {},
  highlight = false,
  children,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (e) => {
    if (type === "text" && e.target.name === "zip") {
      const val = e.target.value;
      if (/^\d{0,5}$/.test(val)) {
        onChange(e);
      }
    } else {
      onChange(e);
    }
  };

  const handleKeyPress = (e) => {
    if (type === "text" && e.target.name === "zip" && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const isLabelVisible = isFocused || value;

  const formattedLabel = label.replace(/\*/g, "").trim();
  const isRequired = label.includes("*");

  return (
    <div style={{ marginTop: "20px", position: "relative", ...style }}>
      {isLabelVisible && (
        <label
          style={{
            position: "absolute",
            top: "0px",
            left: "10px",
            fontSize: "12px",
            fontWeight: "bold",
            fontFamily: "Roboto, sans-serif",
            backgroundColor: "#fff",
            padding: "0 5px",
            color: "#000",
            transition: "0.2s ease-in-out",
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
        >
          {placeholder}
          {isRequired && <span style={{ color: "red" }}> *</span>}
        </label>
      )}
      <div style={{ position: "relative" }}>
        {type === "select" ? (
          <select
            value={value}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            style={{
              backgroundColor: "#f9f9f9",
              border: highlight ? "2px solid magenta" : "1px solid #ccc",
              borderRadius: "4px",
              padding: "10px",
              width: "100%",
              fontSize: "14px",
              fontFamily: "Roboto, sans-serif",
              outline: "none",
              boxShadow: error ? "0 0 5px rgba(255, 0, 0, 0.5)" : "none",
              transition: "border 0.2s",
            }}
          >
            {children}
          </select>
        ) : (
          <input
            type={type}
            value={value}
            name={label.toLowerCase().includes("zip") ? "zip" : undefined}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            placeholder={isLabelVisible || isRequired ? "" : placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            style={{
              backgroundColor: "#f9f9f9",
              border: highlight ? "2px solid magenta" : "1px solid #ccc",
              borderRadius: "4px",
              padding: "10px",
              width: "100%",
              fontSize: "14px",
              fontFamily: "Roboto, sans-serif",
              outline: "none",
              boxShadow: error ? "0 0 5px rgba(255, 0, 0, 0.5)" : "none",
              transition: "border 0.2s",
            }}
          />
        )}
        {!isLabelVisible && isRequired && (
          <div
            style={{
              color: "rgba(111,111, 111, 0.9)",
              position: "absolute",
              top: "50%",
              left: "10px",
              transform: "translateY(-50%)",
              pointerEvents: "none",
              fontSize: "14px",
              fontFamily: "Roboto, sans-serif",
              display: "flex",
              alignItems: "center",
            }}
          >
            {placeholder}
            {type !== "select" && (
              <span style={{ color: "red", marginLeft: "2px" }}>*</span>
            )}
          </div>
        )}
      </div>
      {error && (
        <div
          style={{
            color: "red",
            fontSize: "12px",
            textAlign: "left",
            marginTop: "5px",
          }}
        >
          {error}
        </div>
      )}
    </div>
  );
};

export default InputField;
