import React, { useEffect, useState, useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "./PaymentForm.css";
import { useSelector } from "react-redux";
import { APU_URL_FLOWS } from "../../../../../Globals";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe("pk_live_43CORcVH1TMkq2Zk2FSuPE9Y00oYjjelMf");

const CheckoutForm = ({ paymentPayload, onPaymentSuccess, termsAccepted }) => {
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);
  
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  
  const dvLink = useSelector((state) => state.diminishedValue.dvLink);
  const jobId = useSelector((state) => state.diminishedValue.jobId);
  const email = useSelector((state) => state.diminishedValue.ownerEmail);
  const name = useSelector((state) => state.diminishedValue.ownerName);

  const selectedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
  const serial = localStorage.getItem("localSerial") || localStorage.getItem("serial");

  useEffect(() => {
    console.log("Prod:", selectedProduct);
    console.log("Serial:", serial);
    console.log("JobId:", jobId);
  }, [selectedProduct, serial, jobId]);

  const handleCardChange = (event) => {
    setCardComplete(event.complete);
    setError(event.error ? event.error.message : null);
  };

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    if (processing) return;

    setProcessing(true);
    setError(null);

    if (!stripe || !elements) {
      setProcessing(false);
      return;
    }

    let paymentMethodId = null;

    if (!serial || !jobId || !selectedProduct?._id) {
      if (!cardComplete) {
        setError("Please complete your card details.");
        setProcessing(false);
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (error) {
        setError(error.message);
        setProcessing(false);
        return;
      }

      paymentMethodId = paymentMethod.id;
    }

    try {
      const response = await fetch(`${APU_URL_FLOWS()}/stripe/create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          paymentMethodId,
          shopSerial: serial,
          productId: selectedProduct?._id,
          jobId,
          email,
          ownerEmail: email,
          ownerName: name,
        }),
      });

      const { clientSecret, error: backendError } = await response.json();

      if (backendError) {
        setError(backendError.message);
      } else {
        if (serial && jobId && selectedProduct?._id) {
          navigate(`/instantdv/8_DownloadReport`);
          setSuccess(true);
        } else {
          const { error: confirmError } = await stripe.confirmCardPayment(clientSecret);
          if (confirmError) {
            setError(confirmError.message);
          } else {
            navigate(`/instantdv/8_DownloadReport`);
            setSuccess(true);
          }
        }
      }
    } catch (error) {
      setError("An unexpected error occurred. Please try again.");
    }

    setProcessing(false);
  }, [stripe, elements, cardComplete, serial, jobId, selectedProduct, email, name, navigate, processing]);

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <h2 className="form-title">Payment Details</h2>
      {(!serial || !jobId || !selectedProduct?._id) && (
        <div className="form-group">
          <label htmlFor="card-element" className="form-label">
            Credit or Debit Card
          </label>
          <div className="card-element-wrapper">
            <CardElement
              id="card-element"
              onChange={handleCardChange}
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
          </div>
        </div>
      )}

      {selectedProduct?.AvailableTransactions > 0 && (
        <div className="available-transactions-info">
          You will be using one of your Available Uses to purchase this report. 
          You will have {selectedProduct.AvailableTransactions - 1} Available Uses left after this purchase.
        </div>
      )}

      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">Payment successful. Thank you!</div>}
      
      <button
        type="submit"
        disabled={processing || success || !termsAccepted || (!cardComplete && !serial && !jobId && !selectedProduct?._id)}
        className="submit-button"
      >
        {processing ? "Processing..." : "SUBMIT AND DOWNLOAD"}
      </button>
    </form>
  );
};

const PaymentForm = (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);

export default PaymentForm;