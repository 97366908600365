import React, { useState } from "react";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import FolderIcon from "@mui/icons-material/Folder";
import DescriptionIcon from "@mui/icons-material/Description";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";
import Switch from "@mui/material/Switch";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const FlowsSidebar = ({ treeData, setTreeData, onSelectFlow }) => {
  const [editingId, setEditingId] = useState(null);
  const [tempName, setTempName] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const initialFlowData = {
    id: "",
    endpointUrl: "",
    folderName: "",
    flowName: "",
    flowType: "",
    dataSource: "",
    dataSourceObject: "",
    whereCondition: "",
    selectedFields: "",
    steps: [
      {
        operationName: "",
        dataRecipient: "",
        dataRecipientObject: "",
        dataSourceUniqueId: "",
        dataRecipientUniqueId: "",
        callbackField: "",
        mappings: [
          {
            dataRecipientFieldName: "",
            dataSourceFieldName: "",
            lookup: "",
          },
        ],
      },
    ],
  };

  const handleStartEditing = (id, name) => {
    setEditingId(id);
    setTempName(name);
  };

  const handleRename = (id) => {
    const renameRecursive = (items, idToRename) => {
      return items.map((item) => {
        if (item.id === idToRename) {
          item.name = tempName;
        } else if (item.children) {
          item.children = renameRecursive(item.children, idToRename);
        }
        return item;
      });
    };

    setTreeData((prev) => renameRecursive(prev, id));
    setEditingId(null);
  };

  const handleAddFolder = () => {
    const newId = Date.now().toString();
    const newFolder = {
      id: newId,
      name: `Folder ${newId}`,
      type: "folder",
      children: [],
    };

    if (selectedId) {
      const addToSelected = (items, targetId) => {
        for (const item of items) {
          if (item.id === targetId && item.type === "folder") {
            item.children.push(newFolder);
            return true;
          }
          if (item.children && addToSelected(item.children, targetId)) {
            return true;
          }
        }
        return false;
      };

      setTreeData((prev) => {
        const updated = [...prev];
        if (!addToSelected(updated, selectedId)) {
          updated.push(newFolder);
        }
        return updated;
      });
    } else {
      setTreeData((prev) => [...prev, newFolder]);
    }
  };

  const handleAddFlow = () => {
    const newId = Date.now().toString();
    const newFlowData = {
      ...initialFlowData,
      flowName: `Flow ${newId}`,
    };
    const newFlowTreeItem = {
      id: newId,
      name: `Flow ${newId}`,
      type: "flow",
      data: newFlowData,
    };

    const addToSelected = (items, targetId) => {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.id === targetId && item.type === "folder") {
          item.children = [...(item.children || []), newFlowTreeItem];
          return true; // Ensure to return true to stop the recursive call once the item is added
        }
        if (item.children && addToSelected(item.children, targetId)) {
          return true; // Ensure to return true to stop the recursive call once the item is added
        }
      }
      return false;
    };

    setTreeData((prev) => {
      const updated = [...prev];
      if (!addToSelected(updated, selectedId)) {
        updated.push(newFlowTreeItem);
      }
      return updated;
    });

    console.log("New flow added:", newFlowTreeItem);
    // Notify the main page about the new flow
    onSelectFlow(newFlowTreeItem);
  };

  const handleDelete = (id) => {
    const deleteRecursive = (arr, idToDelete) => {
      return arr.filter((item) => {
        if (item.id === idToDelete) return false;
        if (item.children)
          item.children = deleteRecursive(item.children, idToDelete);
        return true;
      });
    };

    setTreeData((prev) => deleteRecursive(prev, id));
  };

  const renderTree = (items) => {
    return items.map((item) => (
      <TreeItem
        key={item.id}
        nodeId={item.id}
        label={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>{item.name}</span>
            <div>
              <IconButton
                size="small"
                onClick={() => handleStartEditing(item.id, item.name)}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={() => handleDelete(item.id)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
              {item.type === "flow" && (
                <Switch
                  size="small"
                  checked={item.data.enabled}
                  onChange={() => {
                    toggleFlowEnabled(treeData, item.id);
                    setTreeData([...treeData]); // force re-render
                  }}
                />
              )}
            </div>
          </div>
        }
        icon={
          item.type === "folder" ? (
            <FolderIcon />
          ) : item.data.enabled ? (
            <FiberManualRecordIcon color="success" />
          ) : (
            <FiberManualRecordIcon color="error" />
          )
        }
      >
        {item.children && renderTree(item.children)}
      </TreeItem>
    ));
  };

  const findItemRecursive = (items, nodeId) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.id === nodeId) {
        return item;
      }
      if (item.children) {
        const foundItem = findItemRecursive(item.children, nodeId);
        if (foundItem) {
          return foundItem;
        }
      }
    }
    return null;
  };

  const onNodeSelect = (event, nodeId) => {
    if (nodeId === selectedId) {
      setSelectedId(null); // Deselect the current selection if it's clicked again
    } else {
      const selectedItem = findItemRecursive(treeData, nodeId);
      if (selectedItem) {
        setSelectedId(nodeId);
        if (selectedItem.type === "flow") {
          console.log(selectedItem);
          onSelectFlow(selectedItem);
        }
      }
    }
  };

  const toggleFlowEnabled = (items, id) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.id === id && item.type === "flow") {
        item.data.enabled = !item.data.enabled;
        return;
      }
      if (item.children) {
        toggleFlowEnabled(item.children, id);
      }
    }
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ flexGrow: 1 }}>
        <IconButton onClick={handleAddFolder}>
          <AddIcon /> Add Folder
        </IconButton>
        <IconButton onClick={handleAddFlow}>
          <AddIcon /> Add Flow
        </IconButton>
        <Divider />
        <TreeView
          selected={selectedId}
          onNodeSelect={onNodeSelect}
          defaultCollapseIcon={<FolderIcon />}
          defaultExpandIcon={<DescriptionIcon />}
        >
          {renderTree(treeData)}
        </TreeView>
      </div>
    </div>
  );
};

export default FlowsSidebar;
