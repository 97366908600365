import React, { useState } from "react";
import {
  TextField,
  Grid,
  IconButton,
  Paper,
  Button,
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Switch,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { styled } from "@mui/system";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  overflow: "visible",
}));

const type = "row";

const DraggableTableRow = ({ row, index, moveRow }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: type,
    hover(item) {
      if (item.index === index) return;
      moveRow(item.index, index);
      item.index = index;
    },
  }));

  return (
    <TableRow
      ref={(node) => drag(drop(node))}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {row}
    </TableRow>
  );
};

function GroupConfigEditor({ initialConfig, onSave, onClose }) {
  const prepareInitialConfig = (config) => {
    let maxId = 0;
    const preparedConfig = config.map((item) => {
      if (!item.id) {
        maxId++;
        return { ...item, id: maxId };
      } else {
        if (item.id > maxId) {
          maxId = item.id;
        }
        return item;
      }
    });
    return { config: preparedConfig, nextId: maxId + 1 };
  };

  const initialData = prepareInitialConfig(initialConfig.arr || []);
  const [config, setConfig] = useState(initialData.config);
  const [nextId, setNextId] = useState(initialData.nextId);

  const updateItem = (id, newValues) => {
    setConfig((prevConfig) =>
      prevConfig.map((item) =>
        item.id === id ? { ...item, ...newValues } : item
      )
    );
  };

  const removeItem = (id) => {
    setConfig((prevConfig) => prevConfig.filter((item) => item.id !== id));
  };

  const addItem = () => {
    setConfig([
      ...config,
      {
        id: nextId,
        type: "text",
        label: "",
        options: [],
      },
    ]);
    setNextId(nextId + 1);
  };

  const saveConfig = () => {
    onSave(config);
  };

  const handleDragEnd = (params) => {
    const newIndex = params.overRowIndex !== -1 ? params.overRowIndex + 1 : 0;
    console.log(newIndex);
    const configCopy = [...config];
    const movedItem = configCopy.splice(params.rowIndex, 1)[0];
    configCopy.splice(newIndex, 0, movedItem);
    setConfig(configCopy);
  };

  const columns = [
    {
      field: "type",
      headerName: "Type",
      width: 150,
      renderCell: renderTypeCell,
    },
    {
      field: "label",
      headerName: "Label",
      flex: 1,
      renderCell: renderLabelCell,
    },
    {
      field: "options",
      headerName: "Options",
      flex: 1,
      renderCell: renderOptionsCell,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: renderActionsCell,
    },
  ];

  function renderTypeCell(cellProps) {
    const { id, type, value, onChange, ...otherProps } = cellProps;

    return (
      <CustomTableCell {...otherProps}>
        <TextField
          select
          fullWidth
          value={type}
          onChange={(e) =>
            updateItem(id, { ...cellProps.row, type: e.target.value })
          }
          SelectProps={{
            native: true,
          }}
          inputProps={{
            ...value,
          }}
        >
          <option value="text">Text</option>
          <option value="number">Number</option>
          <option value="dropdown">Dropdown</option>
          <option value="multidropdown">Multi-choice Dropdown</option>
          <option value="toggle">Toggle</option>
          <option value="header">Header</option>
          <option value="group">Group</option>
          <option value="color">Color</option>
          <option value="cssStyle">CSS Style</option>
        </TextField>
      </CustomTableCell>
    );
  }

  function renderLabelCell(cellProps) {
    const { id, label, ...otherProps } = cellProps;

    return (
      <CustomTableCell {...otherProps}>
        <TextField
          fullWidth
          value={label}
          onChange={(e) =>
            updateItem(id, { ...cellProps.row, label: e.target.value })
          }
          placeholder="Label"
        />
      </CustomTableCell>
    );
  }

  function renderOptionsCell(cellProps) {
    const { id, type, options, ...otherProps } = cellProps;
    let optionInput;

    if (type === "dropdown" || type === "multidropdown") {
      optionInput = (
        <TextField
          fullWidth
          value={options.join(",")}
          onChange={(e) =>
            updateItem(id, {
              ...cellProps.row,
              options: e.target.value.split(","),
            })
          }
          placeholder="Options separated by comma"
        />
      );
    } else if (type === "color") {
      optionInput = (
        <TextField
          type="color"
          fullWidth
          value={options?.color}
          onChange={(e) =>
            updateItem(id, {
              ...cellProps.row,
              options: { color: e.target.value },
            })
          }
        />
      );
    } else if (type === "cssStyle") {
      optionInput = (
        <TextField
          fullWidth
          value={options?.style}
          onChange={(e) =>
            updateItem(id, {
              ...cellProps.row,
              options: { style: e.target.value },
            })
          }
          placeholder="CSS Style"
        />
      );
    } else if (type === "toggle") {
      optionInput = (
        <Switch
          checked={options?.checked || false}
          onChange={(e) =>
            updateItem(id, {
              ...cellProps.row,
              options: { checked: e.target.checked },
            })
          }
          color="primary"
        />
      );
    }

    return <CustomTableCell {...otherProps}>{optionInput}</CustomTableCell>;
  }

  function renderActionsCell(cellProps) {
    const { id, ...otherProps } = cellProps;

    return (
      <CustomTableCell {...otherProps}>
        <IconButton onClick={() => removeItem(id)}>
          <RemoveCircleIcon />
        </IconButton>
      </CustomTableCell>
    );
  }
  const moveRow = (fromIndex, toIndex) => {
    const newRowOrder = Array.from(config);
    const movedRow = newRowOrder[fromIndex];
    newRowOrder.splice(fromIndex, 1);
    newRowOrder.splice(toIndex, 0, movedRow);
    setConfig(newRowOrder);
  };

  const handleChange = (field, id, value) => {
    setConfig((prevConfig) =>
      prevConfig.map((item) => {
        if (item.id === id) {
          return { ...item, [field]: value };
        }
        return item;
      })
    );
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.field}>{column.headerName}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {config.map((row, index) => (
                <DraggableTableRow
                  key={row.id}
                  row={
                    <>
                      <CustomTableCell>
                        {renderTypeCell({ ...row, id: row.id, type: row.type })}
                      </CustomTableCell>
                      <CustomTableCell>
                        {renderLabelCell({
                          ...row,
                          id: row.id,
                          label: row.label,
                        })}
                      </CustomTableCell>
                      <CustomTableCell>
                        {renderOptionsCell({
                          ...row,
                          id: row.id,
                          type: row.type,
                          options: row.options,
                        })}
                      </CustomTableCell>
                      <CustomTableCell>
                        {renderActionsCell({ ...row, id: row.id })}
                      </CustomTableCell>
                    </>
                  }
                  index={index}
                  moveRow={moveRow}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DndProvider>
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <IconButton color="primary" onClick={addItem}>
            <AddCircleIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" onClick={saveConfig}>
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default GroupConfigEditor;
