import React, { useMemo, useState, useCallback } from "react";
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFullScreenButton,
} from "material-react-table";
import { Box, IconButton, Tooltip } from "@mui/material";
import Papa from "papaparse";
import DownloadIcon from "@mui/icons-material/Download";

const PDRTable = ({ data: initialData }) => {
  const [tableData, setTableData] = useState(initialData ?? []);
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
      },
      {
        accessorKey: "date",
        header: "Date",
        Cell: ({ cell }) => new Date(cell.getValue()).toLocaleString(),
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => {
          let color;
          switch (cell.getValue()) {
            case "Success":
              color = "green";
              break;
            case "Fail":
              color = "red";
              break;
            default:
              color = "gray";
          }
          return (
            <Box sx={{ color, fontWeight: "bold" }}>{cell.getValue()}</Box>
          );
        },
      },
      {
        accessorKey: "details",
        header: "Details",
        Cell: ({ cell }) => JSON.stringify(cell.getValue(), null, 2),
      },
      {
        accessorKey: "price",
        header: "Price",
        Cell: ({ cell }) => `$${cell.getValue().toFixed(2)}`,
      },
    ],
    []
  );

  const handleExportSelectedRows = useCallback(
    (rows) => {
      const csv = Papa.unparse(rows);
      const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const csvURL = window.URL.createObjectURL(csvData);
      const tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "export.csv");
      tempLink.click();
    },
    [selectedRows, tableData]
  );

  return (
    <Box sx={{ marginTop: 3 }}>
      <MaterialReactTable
        columns={columns}
        data={tableData}
        enableRowSelection
        state={{ showColumnFilters: true }}
        renderToolbarInternalActions={({ table }) => (
          <>
            {/* add your own custom print button or something */}
            <DownloadIcon
              onClick={() =>
                handleExportSelectedRows(table.getSelectedRowModel().rows)
              }
            ></DownloadIcon>
            {/* built-in buttons (must pass in table prop for them to work!) */}
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_ToggleFullScreenButton table={table} />
          </>
        )}
      />
    </Box>
  );
};

export default PDRTable;
