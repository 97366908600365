import React, { useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";

import { IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "../../App.css";

const Example = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery(
    [
      "table-data",
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    async () => {
      const url = new URL(
        "/clientdata",
        "https://aerkbackendv2.azurewebsites.net"
      );
      url.searchParams.set(
        "start",
        `${pagination.pageIndex * pagination.pageSize}`
      );
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("globalFilter", globalFilter ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      url.searchParams.set("clientid", localStorage.getItem("aerk_customerid"));
      url.searchParams.set("tab", window.document.setVal);

      const response = await fetch(url.href);
      const json = await response.json();
      return json;
    },
    { keepPreviousData: false }
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => (
          <div className="Aoo-spaceBetween">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={cell.row.original.status !== 0 ? 3 : 0}
              label="Status"
              onChange={(event, child) => {
                console.log(event.target.value);
                console.log(child);
              }}
            >
              <MenuItem value={0}>Success</MenuItem>
              <MenuItem value={3}>Processing</MenuItem>
            </Select>
          </div>
        ),
      },
      {
        accessorKey: "vin",
        header: "VIN",
      },
      {
        accessorKey: "appType",
        header: "Product",
      },
      {
        accessorKey: "dateTimeProcessed",
        header: "Start Datetime",
      },
      {
        accessorKey: "estimateName",
        header: "PDF Name",
      },
      {
        accessorKey: "platform",
        header: "Into Platform",
      },
      {
        accessorKey: "totalTime",
        header: "Time",
      },
    ],
    []
  );

  const [value, setValue] = React.useState("1");

  function TabPanel(props) {}

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    window.document.setVal = newValue;
    setValue(newValue);
    console.log("handle:", newValue);
    refetch();
  };

  return (
    <>
      <div className="AppNoBg"></div>
      <div className="page-header2">
        <div></div>
        <div
          className="button-header-page"
          onClick={() => {
            window.location.href = "/dashboard/";
          }}
        >
          Home
        </div>
      </div>
      <div className="ClientDashHeader">
        <div>CRASH Network Admin</div>
        <div>Shop Dashboard</div>
      </div>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Processing Estimates" {...a11yProps(0)} />
            <Tab label="Completed Estimates" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          Item One
        </TabPanel>
        <TabPanel value={value} index={1}>
          Item Two
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
      </Box>

      <MaterialReactTable
        columns={columns}
        data={data?.data ?? []} //data is undefined on first render
        initialState={{ showColumnFilters: true }}
        manualFiltering
        manualPagination
        manualSorting
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderTopToolbarCustomActions={() => (
          <Tooltip arrow title="Refresh Data">
            <IconButton onClick={() => refetch()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        )}
        rowCount={data?.meta?.totalRowCount ?? 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
    </>
  );
};

const queryClient = new QueryClient();

const UsageReport = () => (
  <QueryClientProvider client={queryClient}>
    <Example />
  </QueryClientProvider>
);

export default UsageReport;
