// noinspection JSValidateTypes

import React, { Component } from "react";
import { checkLocalLicense } from "../../util/license.extension";
import "bootstrap/dist/css/bootstrap.min.css";
import "./autoestimaterekey.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import {
  GetClient,
  UpdateProfile,
  //LockProfile,
} from "../../services/license.service";
import { Buffer } from "buffer";

export default class ShopProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      config: {},
      isSubmitingWork: false,
      isWorkValid: false,
      isPDFValid: false,
      pdfValidationMsg: "",
      isCheckingPdf: false,
      clientObject: {},
      selectedOption: "NONE",
      lockProfile: false,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.updateProfileInfo = this.updateProfileInfo.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  componentDidMount() {
    var tmpObj = {};
    tmpObj.Company = {};
    tmpObj.Company.MitchellName = "";
    tmpObj.Company.AudatexName = "";
    tmpObj.Company.CCCName = "";
    this.setState({ clientObject: tmpObj });

    console.log();
    if (localStorage.getItem("hasEAuditLock") === "true")
      this.setState({ lockProfile: true });

    console.log("ClientData");
    GetClient((data) => {
      console.log(data);
      if (data === null) return;

      var tmpClient = data.rsp.Customer;
      if (this.isObject(tmpClient.Street2)) tmpClient.Street2 = "+";

      tmpClient.Email = decodeURIComponent(tmpClient.Email).replaceAll("+", "");
      tmpClient.Phone = decodeURIComponent(tmpClient.Phone).replaceAll("+", "");
      tmpClient.Street1 = decodeURIComponent(tmpClient.Street1).replaceAll(
        "+",
        ""
      );
      tmpClient.Street2 = decodeURIComponent(tmpClient.Street2).replaceAll(
        "+",
        ""
      );
      tmpClient.City = decodeURIComponent(tmpClient.City).replaceAll("+", "");

      tmpClient.PostalCode = decodeURIComponent(
        tmpClient.PostalCode
      ).replaceAll("+", "");

      tmpClient.Country = decodeURIComponent(tmpClient.Country).replaceAll(
        "+",
        ""
      );

      tmpClient.State = decodeURIComponent(tmpClient.State).replaceAll("+", "");

      tmpClient.Company.CCCName =
        tmpClient.Company.CCCName === undefined
          ? ""
          : decodeURIComponent(tmpClient.Company.CCCName);

      tmpClient.Company.MitchellName =
        decodeURIComponent(tmpClient.Company.MitchellName) === undefined
          ? ""
          : decodeURIComponent(tmpClient.Company.MitchellName).replaceAll(
              "+",
              ""
            );

      tmpClient.Company.AudatexName =
        decodeURIComponent(tmpClient.Company.AudatexName).replaceAll(
          "+",
          ""
        ) === undefined
          ? ""
          : decodeURIComponent(tmpClient.Company.AudatexName);

      tmpClient.Name =
        decodeURIComponent(tmpClient.Name).replaceAll("+", "") === undefined
          ? ""
          : decodeURIComponent(tmpClient.Name).replaceAll("+", " ");

      if (tmpClient.Company.AudatexName === "undefined")
        tmpClient.Company.AudatexName = "";

      if (tmpClient.Company.MitchellName === "undefined")
        tmpClient.Company.MitchellName = "";

      if (tmpClient.Company.CCCName === "undefined")
        tmpClient.Company.CCCName = "";

      tmpClient.Street1 = tmpClient.Street1.slice(0, -1);

      this.setState({ clientObject: data.rsp.Customer });
      console.log("CustomerData", data.rsp.Customer);
      console.log("DecodedData", data.rsp.Customer);
      this.setState({ selectedOption: data.rsp.Customer.Country });
    });
  }

  updateProfileInfo() {
    var tmpClient = this.state.clientObject;
    var companyField = {};
    companyField.MitchellName = tmpClient.Company.MitchellName;
    companyField.AudatexName = tmpClient.Company.AudatexName;
    companyField.CCCName = tmpClient.Company.CCCName;
    var jsonStr = JSON.stringify(companyField);
    var b64 = Buffer.from(jsonStr).toString("base64");
    tmpClient.Company = b64;
    this.setState({ clientObject: tmpClient });

    if (this.isObject(tmpClient.Email)) tmpClient.Email = "";
    else tmpClient.Email = encodeURIComponent(tmpClient.Email);

    if (this.isObject(tmpClient.Phone)) tmpClient.Phone = "";
    else tmpClient.Phone = encodeURIComponent(tmpClient.Phone);

    if (this.isObject(tmpClient.Street1)) tmpClient.Street1 = "";
    else tmpClient.Street1 = encodeURIComponent(tmpClient.Street1);

    if (this.isObject(tmpClient.Street2)) tmpClient.Street2 = "";
    else tmpClient.Street2 = encodeURIComponent(tmpClient.Street2);

    if (this.isObject(tmpClient.Name)) tmpClient.Name = "";
    else tmpClient.Name = encodeURIComponent(tmpClient.Name);

    if (this.isObject(tmpClient.City)) tmpClient.City = "";
    else tmpClient.City = encodeURIComponent(tmpClient.City);

    if (this.isObject(tmpClient.Country)) tmpClient.Country = "";
    else tmpClient.Country = encodeURIComponent(tmpClient.Country);

    if (this.isObject(tmpClient.PostalCode)) tmpClient.PostalCode = "";
    else tmpClient.PostalCode = encodeURIComponent(tmpClient.PostalCode);

    if (this.isObject(tmpClient.State)) tmpClient.State = "";
    else tmpClient.State = encodeURIComponent(tmpClient.State);

    UpdateProfile(
      localStorage.getItem("customerId"),
      tmpClient.Email,
      tmpClient.Phone,
      tmpClient.Street1,
      tmpClient.Street2,
      tmpClient.Name,
      tmpClient.Company,
      tmpClient.City,
      tmpClient.Country,
      tmpClient.PostalCode,
      tmpClient.State,
      () => {
        window.location.href = "/dashboard";
        /*LockProfile(() => {
          window.location.href = "/dashboard";
        });*/
      }
    );
  }

  isObject(obj) {
    return Object.prototype.toString.call(obj) === "[object Object]";
  }

  handleInputChange(field, value) {
    console.log("Field:", field, "=", value);
    var tmpClient = this.state.clientObject;
    if (field === "Email") tmpClient.Email = value;
    if (field === "Company") tmpClient.Company = value;
    if (field === "Phone") tmpClient.Phone = value;
    if (field === "Street1") tmpClient.Street1 = value;
    if (field === "Street2") tmpClient.Street2 = value;
    if (field === "City") tmpClient.City = value;
    if (field === "PostalCode") tmpClient.PostalCode = value;
    if (field === "Country") tmpClient.Country = value;
    if (field === "State") tmpClient.State = value;
    if (field === "CCCName") tmpClient.Company.CCCName = value;
    if (field === "MitchellName") tmpClient.Company.MitchellName = value;
    if (field === "AudatexName") tmpClient.Company.AudatexName = value;
  }

  setSelectedItem(key, selection) {}

  getSelectedItem(key) {
    let _config = this.state.config;
    return _config[key];
  }

  onCompleteUpload(data) {}

  handleOptionChange = (changeEvent) => {
    this.setState({
      selectedOption: changeEvent.target.value,
    });
    this.handleInputChange("Country", changeEvent.target.value);
  };

  onChangeFileHandler = (acceptedFiles) => {};

  validateWork() {}

  getDefaultValue(field) {
    var tmpClient = this.state.clientObject;
    try {
      if (field === "CCCName") {
        return tmpClient.Company.CCCName;
      } else if (field === "MitchellName") {
        return tmpClient.Company.MitchellName;
      } else if (field === "AudatexName") {
        return tmpClient.Company.AudatexName;
      }
    } catch {}
    return "";
  }

  setProfileState(state) {
    var tmpClient = this.state.clientObject;
    tmpClient.State = state;

    this.setState({ clientObject: tmpClient });
  }

  render() {
    var options = [
      "AL",
      "AK",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "FL",
      "GA",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "OH",
      "OK",
      "OR",
      "PA",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
    ];
    var optionsCA = [
      "AB",
      "BC",
      "MB",
      "NB",
      "NL",
      "NT",
      "NS",
      "NU",
      "ON",
      "PE",
      "QC",
      "SK",
      "YT",
    ];

    return (
      <>
        <div className="page-header2">
          <div
            className="button-header-page"
            onClick={() => {
              window.location.href = "/dashboard/profile/users/";
            }}
          >
            Manage User Profiles
          </div>
          <div
            className="button-header-page"
            onClick={() => {
              window.location.href = "/dashboard/";
            }}
          >
            Hub Home
          </div>
        </div>

        <div className="auth-wrapper">
          <div className={"auth-inner-app-form"}>
            <div className="auth-wrapper-header">
              <div className="auth-wrapper-txt">My CRASH Network</div>
              <div className="auth-wrapper-txt">Shop Profile</div>
            </div>
            <div className="auth-wrapper-txt-p">
              (Shop Names must match Shop Name on PDF or eAudit will not work)
            </div>
            <div className="Aerk-form">
              <div className={"container-step"}>
                <div className={"container-step-entry"}>CCC PDF Shop Name:</div>
                <div className={"container-step-entry"}>
                  <Form.Control
                    disabled={this.state.lockProfile}
                    type="text"
                    defaultValue={this.getDefaultValue("CCCName")}
                    placeholder="CCC PDF Shop Name"
                    onChange={(e) => {
                      this.handleInputChange("CCCName", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={"container-step"}>
                <div className={"container-step-entry"}>
                  Mitchell PDF Shop Name:
                </div>
                <div className={"container-step-entry"}>
                  <Form.Control
                    disabled={this.state.lockProfile}
                    defaultValue={this.getDefaultValue("MitchellName")}
                    type="text"
                    placeholder="Mitchell PDF Shop Name"
                    onChange={(e) => {
                      this.handleInputChange("MitchellName", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={"container-step"}>
                <div className={"container-step-entry"}>
                  Audatex PDF Shop Name:
                </div>
                <div className={"container-step-entry"}>
                  <Form.Control
                    disabled={this.state.lockProfile}
                    defaultValue={this.getDefaultValue("AudatexName")}
                    type="text"
                    placeholder="Audatex PDF Shop Name"
                    onChange={(e) => {
                      this.handleInputChange("AudatexName", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={"container-step"}>
                <div className={"container-step-entry"}>Street Address:</div>
                <div className={"container-step-entry"}>
                  <Form.Control
                    defaultValue={
                      this.isObject(this.state.clientObject.Street1)
                        ? ""
                        : this.state.clientObject.Street1
                    }
                    type="text"
                    placeholder="Street Address"
                    onChange={(e) => {
                      this.handleInputChange("Street1", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={"container-step"}>
                <div className={"container-step-entry"}>Street Address 2:</div>
                <div className={"container-step-entry"}>
                  <Form.Control
                    defaultValue={
                      this.isObject(this.state.clientObject.Street2)
                        ? ""
                        : this.state.clientObject.Street2
                    }
                    type="text"
                    placeholder="Street Address 2"
                    onChange={(e) => {
                      this.handleInputChange("Street2", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={"container-step"}>
                <div className={"container-step-entry"}>City:</div>
                <div className={"container-step-entry"}>
                  <Form.Control
                    defaultValue={
                      this.isObject(this.state.clientObject.City)
                        ? ""
                        : this.state.clientObject.City
                    }
                    type="text"
                    placeholder="City"
                    onChange={(e) => {
                      this.handleInputChange("City", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={"container-step"}>
                <div className={"container-step-entry"}>State:</div>
                <div className={"container-step-entry"}>
                  <Form.Select
                    value={this.state.clientObject.State}
                    onChange={(e) => this.setProfileState(e.target.value)}
                  >
                    {(this.state.selectedOption === "US"
                      ? options
                      : optionsCA
                    ).map((o) => {
                      return <option value={o}>{o}</option>;
                    })}
                  </Form.Select>
                </div>
              </div>
              <div className={"container-step"}>
                <div className={"container-step-entry"}>Country:</div>
                <div className={"container-step-entry"}>
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="US"
                        checked={this.state.selectedOption === "US"}
                        onChange={this.handleOptionChange}
                      />
                      USA
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="CA"
                        checked={this.state.selectedOption === "CA"}
                        onChange={this.handleOptionChange}
                      />
                      Canada
                    </label>
                  </div>
                </div>
              </div>
              <div className={"container-step"}>
                <div className={"container-step-entry"}>Phone Number:</div>
                <div className={"container-step-entry"}>
                  <Form.Control
                    defaultValue={this.state.clientObject.Phone}
                    type="text"
                    placeholder="Phone Number"
                    onChange={(e) => {
                      this.handleInputChange("Phone", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={"container-step"}>
                <div className={"container-step-entry"}>Shop Email:</div>
                <div className={"container-step-entry"}>
                  <Form.Control
                    defaultValue={this.state.clientObject.Email}
                    type="text"
                    placeholder="Shop Email"
                    onChange={(e) => {
                      this.handleInputChange("Email", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={"container-step"}>
                <div className={"container-step-entry"}>
                  <Button
                    onClick={() => {
                      this.updateProfileInfo();
                    }}
                    variant="success"
                  >
                    Save Shop Profile
                  </Button>{" "}
                </div>
              </div>
              <div className={"container-step-entry"} hidden>
                ssss
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
