// reducer.js

import {
  SET_SELECTED_FILE,
  SET_FILE_VALIDATION_STATUS,
  START_FILE_UPLOAD,
  END_FILE_UPLOAD,
  SET_LOADING,
  SET_PDF_VALIDATION_MESSAGE,
  SET_ESTIMATE_DATA,
  SET_VEHICLE_DATA,
  SET_ESTIMATE_TOTAL,
  SET_MILEAGE,
  SET_DATE_OF_LOSS,
  SET_CLAIM_NO,
  SET_LICENSE_PLATE,
  SET_OWNER_NAME,
  SET_VIN,
  SET_VEHICLE_MAKE,
  SET_VEHICLE_MODEL,
  SET_VEHICLE_TYPE,
  SET_RAW_VEHICLE_YEAR,
  SET_VEHICLE_YEAR,
  SET_TRIMS,
  SET_HAS_TRIMS,
  SET_MANUAL_TRIMS,
  SET_INSURANCE_COMPANY,
  SET_DAMAGE_SEVERITY,
  SET_OWNER_EMAIL,
  SET_OWNER_ADDRESS,
  SET_OWNER_CITY,
  SET_OWNER_STATE,
  SET_OWNER_ZIP,
  SET_MARKET_VALUE,
  SET_VEHICLE_CONDITION,
  SET_DV_LINK,
  SET_DV_VALUE,
  SET_VEHICLE_SUBMODEL,
  SET_CURRENT_STEP,
  ADD_COMPLETED_STEP,
  RESET_STEPS,
  SET_VIN_ENTERED,
  SET_VEHICLE_DAMAGE,
  SET_JOB_ID,
  SET_RANGE
} from "./actions";

const initialState = {
  range:null,
  selectedFile: null,
  fileValid: false,
  uploading: false,
  loading: false,
  pdfValidationMessage: "",
  estimateData: {},
  vehicleData: {},
  estimateTotal: "",
  mileage: "",
  dateOfLoss: null,
  claimNo: "",
  licensePlate: "",
  ownerName: "",
  vin: "",
  vehicleMake: "",
  vehicleModel: "",
  vehicleType: "",
  vehicleSubmodel: "SUBMODEL/TRIM *",
  rawVehicleYear: "",
  vehicleYear: null,
  trims: [],
  hasTrims: false,
  manualTrims: false,
  insuranceCompany: "",
  config: {},
  damageSeverity: "",
  ownerEmail: "",
  ownerAddress: "",
  ownerCity: "",
  ownerState: "",
  ownerZip: "",
  marketValue: "",
  vehicleCondition: "",
  dvLink: null,
  totalProjectedDimValue: "",
  currentStep: "",
  completedSteps: [],
  vinEntered: false,
  vehicleDamage: {
    14: "white",
    16: "white",
    19: "white",
    21: "white",
    12: "white",
    1: "white",
    3: "white",
    4: "white",
    5: "white",
    6: "white",
    7: "white",
    8: "white",
    9: "white",
    10: "white",
    11: "white",
    13: "white",
  },
  jobId: null,
};

const diminishedValueReducer = (state = initialState, action) => {
  switch (action.type) {
    case "diminishedValue/resetState":
      return initialState;
    case SET_JOB_ID:
      return {
        ...state,
        jobId: action.payload,
      };
      case SET_RANGE:
        return {
          ...state,
          range: action.payload,
        };
    case SET_VIN_ENTERED:
      return {
        ...state,
        vinEntered: action.payload,
      };
    case SET_VEHICLE_DAMAGE:
      return {
        ...state,
        vehicleDamage: action.payload,
      };
    case RESET_STEPS:
      return {
        ...state,
        completedSteps: [],
      };
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case SET_SELECTED_FILE:
      return {
        ...state,
        selectedFile: action.payload,
      };
    case SET_FILE_VALIDATION_STATUS:
      return {
        ...state,
        fileValid: action.payload,
      };
    case ADD_COMPLETED_STEP:
      return {
        ...state,
        completedSteps: [...state.completedSteps, action.payload],
      };
    case SET_OWNER_EMAIL:
      return { ...state, ownerEmail: action.payload };
    case SET_OWNER_ADDRESS:
      return { ...state, ownerAddress: action.payload };
    case SET_OWNER_CITY:
      return { ...state, ownerCity: action.payload };
    case SET_OWNER_STATE:
      return { ...state, ownerState: action.payload };
    case SET_VEHICLE_CONDITION:
      return { ...state, vehicleCondition: action.payload };
    case SET_MARKET_VALUE:
      return { ...state, marketValue: action.payload };
    case SET_DV_LINK:
      console.log("SET_DV_LINK:", action.payload);
      return { ...state, dvLink: action.payload };
    case SET_VEHICLE_SUBMODEL:
      return { ...state, vehicleSubmodel: action.payload };
    case SET_OWNER_ZIP:
      return { ...state, ownerZip: action.payload };
    case SET_DAMAGE_SEVERITY:
      return {
        ...state,
        damageSeverity: action.payload,
      };
    case START_FILE_UPLOAD:
      return {
        ...state,
        uploading: true,
      };
    case END_FILE_UPLOAD:
      return {
        ...state,
        uploading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_PDF_VALIDATION_MESSAGE:
      return {
        ...state,
        pdfValidationMessage: action.payload,
      };
    case SET_ESTIMATE_DATA:
      return {
        ...state,
        estimateData: action.payload,
      };
    case SET_VEHICLE_DATA:
      return {
        ...state,
        vehicleData: action.payload,
      };
    case SET_ESTIMATE_TOTAL:
      return {
        ...state,
        estimateTotal: action.payload,
      };
    case SET_MILEAGE:
      return {
        ...state,
        mileage: action.payload,
      };
    case SET_DATE_OF_LOSS:
      return {
        ...state,
        dateOfLoss: action.payload,
      };
    case SET_CLAIM_NO:
      return {
        ...state,
        claimNo: action.payload,
      };
    case SET_LICENSE_PLATE:
      return {
        ...state,
        licensePlate: action.payload,
      };
    case SET_OWNER_NAME:
      return {
        ...state,
        ownerName: action.payload,
      };
    case SET_VIN:
      return {
        ...state,
        vin: action.payload,
      };
    case SET_VEHICLE_MAKE:
      return {
        ...state,
        vehicleMake: action.payload,
      };
    case SET_VEHICLE_MODEL:
      return {
        ...state,
        vehicleModel: action.payload,
      };
    case SET_VEHICLE_TYPE:
      return {
        ...state,
        vehicleType: action.payload,
      };
    case SET_RAW_VEHICLE_YEAR:
      return {
        ...state,
        rawVehicleYear: action.payload,
      };
    case SET_VEHICLE_YEAR:
      return {
        ...state,
        vehicleYear: action.payload,
      };
    case SET_TRIMS:
      return {
        ...state,
        trims: action.payload,
      };
    case SET_HAS_TRIMS:
      return {
        ...state,
        hasTrims: action.payload,
      };
    case SET_MANUAL_TRIMS:
      return {
        ...state,
        manualTrims: action.payload,
      };
    case SET_INSURANCE_COMPANY:
      return {
        ...state,
        insuranceCompany: action.payload,
      };
    case SET_DV_VALUE:
      return {
        ...state,
        totalProjectedDimValue: action.payload,
      };
    default:
      return state;
  }
};

export default diminishedValueReducer;
