import axios from "axios";

const development = false;
const live = false;

export const API_URI = () => {
  if (development) {
    return "http://localhost:3001";
  } else {
    if (live) return "https://aerkbackendv2.azurewebsites.net";
    else return "https://aerkbackend.azurewebsites.net";
  }
};

export const APU_URL_FLOWS = () => {
  if (development) {
    return "http://localhost:3002";
  } else {
    return "https://aerkflows.azurewebsites.net";
  }
};

export const API_ValidatePDF = () => {
  if (development) {
    return "https://crashfunctions20240821023624.azurewebsites.net/api/ValidatePDF";
  } else {
    return "https://crashfunctions20240821023624.azurewebsites.net/api/ValidatePDF";
  }
};

export const fetchUnitPrice = async (shopSerial, productId) => {
  const options = {
    method: "POST",
    url: APU_URL_FLOWS() + "/stripe/fetch-unit-price",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      shopSerial: shopSerial,
      productId: productId,
    },
  };

  try {
    const response = await axios.request(options);

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const checkProductPayment = (shopSerial, productId, jobId) => {
  return new Promise((resolve, reject) => {
    // <-- Enclosing the request in a Promise
    const options = {
      method: "POST",
      url: APU_URL_FLOWS() + "/stripe/check-product-payment",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        shopSerial: shopSerial,
        productId: productId,
        jobId: jobId,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        resolve(response.data); // <-- Resolving the promise with response data
      })
      .catch(function (error) {
        console.error(error);
        reject(error); // <-- Rejecting the promise with error
      });
  });
};
