import React from "react";

const Banner = ({ state }) => {
  const baseHeight = 300;

  return (
    <section
      style={{
        backgroundImage: "url(/img/diminishedvalue/banner_car_damaged.jpg)",
        backgroundColor: state ? "#f5f5f5" : "transparent",
        backgroundSize: "cover",
        height: `${baseHeight}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        fontFamily: "Roboto, sans-serif",
      }}
    >
      {state ? (
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "20px",
            textAlign: "center",
            position: "absolute",
            left: 0,
            right: 0,
          }}
        >
          <h1 style={{ margin: 0 }}>
            <strong
              style={{
                fontWeight: 900,
                fontSize: "36px",
                color: "black",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              DIMINISHED VALUE CLAIMS
            </strong>
          </h1>
          <h2
            style={{
              color: "rgb(192, 44, 122)",
              margin: 0,
              fontWeight: 900,
              fontSize: "36px",
              fontFamily: "Orbitron, sans-serif",
            }}
          >
            {state.toUpperCase()}
          </h2>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "20px",
            textAlign: "center",
            position: "absolute",
            left: 0,
            right: 0,
          }}
        >
          <h1 style={{ fontSize: "36px", fontWeight: "bold" }}>
            <span style={{ color: "black" }}>A </span>
            <span
              style={{
                color: "rgb(192, 44, 122)",
                fontWeight: "bold",
              }}
            >
              MINOR ACCIDENT
            </span>{" "}
            <span style={{ color: "black" }}>CAN HAVE A </span>
            <span
              style={{
                color: "rgb(192, 44, 122)",
                fontWeight: "bold",
              }}
            >
              MAJOR IMPACT
            </span>{" "}
            <span style={{ color: "black" }}>
              ON YOUR VEHICLE'S RESALE VALUE
            </span>
            .
          </h1>
        </div>
      )}
    </section>
  );
};

export default Banner;
