import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import Header from "../components/Header";
import Footer from "../components/Footer";
import StepHeader from "../components/StepHeader";
import { API_URI } from "../../../../Globals";
import axios from "axios";
import { CircularProgress, Modal, Paper, Typography } from "@mui/material";

import {
  addCompletedStep,
  uploadFile,
} from "../../../redux/diminishedvalue/actions";

const MainContent = () => {
  const [selectedBox, setSelectedBox] = useState(null);
  const [showUploadContent, setShowUploadContent] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { uploading, fileValid, selectedFile, loading, pdfValidationMessage } =
    useSelector((state) => state.diminishedValue);

  useEffect(() => {
    const fetchProducts = async () => {
      let serial = localStorage.getItem("localSerial");

      if (serial) {
        setIsLoading(true);
        try {
          const response = await axios.get(
            API_URI() + "/admin/getShopActiveProducts/" + serial
          );
          console.log("Products:", response.data[4]);
          localStorage.setItem(
            "selectedProduct",
            JSON.stringify(response.data[4])
          );
          setIsLoading(false); // Set isLoading to false after data is fetched
        } catch (error) {
          console.error("Error fetching products:", error);
          setIsLoading(false); // Set isLoading to false even if there's an error
        }
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    if (fileValid) {
      navigate("/instantdv/3m_VehicleDetails");
    }
  }, [fileValid]);

  const handleBoxClick = (box) => {
    handleNextClick(box);
  };

  const handleNextClick = (box) => {
    if (box === "upload") {
      setShowUploadContent(true);
    } else {
      navigate("/instantdv/3m_VehicleDetails");
    }
  };

  const handleNextClickValid = () => {
    navigate("/instantdv/3m_VehicleDetails");
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        dispatch(uploadFile(acceptedFiles[0]));
      }
    },
    [dispatch]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const LoadingModal = () => (
    <Paper
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: "20px",
        borderRadius: "10px",
        textAlign: "center",
        outline: "none",
        boxShadow: 24,
        p: 4,
      }}
    >
      <CircularProgress />
      <Typography variant="h6" sx={{ marginTop: "10px" }}>
        Loading User Data...
      </Typography>
    </Paper>
  );

  return (
    <>
      {showUploadContent && <StepHeader tstep={"Vehicle Information"} />}
      {!showUploadContent && <StepHeader tstep={"Start Reporting"} />}
      <Modal
        open={isLoading}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingModal />
      </Modal>
      <div
        style={{
          textAlign: "center",
          marginTop: "40px",
          minHeight: "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {!showUploadContent ? (
          <>
            <div>
              <h2
                style={{
                  color: "rgb(192, 44, 122)",
                  fontSize: "24px",
                  fontFamily: "Orbitron, sans-serif",
                  fontWeight: "bold",
                }}
              >
                LET'S START YOUR DV REPORT
              </h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "40px",
                }}
              >
                <div
                  style={{
                    border: "2px solid rgb(192, 44, 122)",
                    borderRadius: "10px",
                    padding: "20px",
                    marginRight: "20px",
                    cursor: "pointer",
                    backgroundColor:
                      selectedBox === "upload"
                        ? "rgba(192, 44, 122, 0.1)"
                        : "transparent",
                  }}
                  onClick={() => handleBoxClick("upload")}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      marginBottom: "5px",
                      fontFamily: "Orbitron, sans-serif",
                    }}
                  >
                    UPLOAD
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      marginBottom: "5px",
                      fontFamily: "Orbitron, sans-serif",
                    }}
                  >
                    REPAIR
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      fontFamily: "Orbitron, sans-serif",
                    }}
                  >
                    ESTIMATE
                  </p>
                </div>
                <div
                  style={{
                    backgroundColor: "black",
                    color: "rgb(192, 44, 122)",
                    borderRadius: "50%",
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "24px",
                    fontWeight: "bold",
                    fontFamily: "Orbitron, sans-serif",
                  }}
                >
                  OR
                </div>
                <div
                  style={{
                    border: "2px solid rgb(192, 44, 122)",
                    borderRadius: "10px",
                    padding: "20px",
                    marginLeft: "20px",
                    cursor: "pointer",
                    backgroundColor:
                      selectedBox === "vin"
                        ? "rgba(192, 44, 122, 0.1)"
                        : "transparent",
                  }}
                  onClick={() => handleBoxClick("vin")}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      marginBottom: "5px",
                      fontFamily: "Orbitron, sans-serif",
                    }}
                  >
                    ENTER
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      marginBottom: "5px",
                      fontFamily: "Orbitron, sans-serif",
                    }}
                  >
                    VIN
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      fontFamily: "Orbitron, sans-serif",
                    }}
                  >
                    NUMBER
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <div
                {...getRootProps()}
                style={{
                  backgroundColor: "#f0f0f0",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                  textAlign: "center",
                  maxWidth: "400px",
                  margin: "40px auto",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <input {...getInputProps()} />
                {uploading ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress></CircularProgress>
                    <p
                      style={{
                        color: "rgb(192, 44, 122)",
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginTop: "10px",
                        fontFamily: "Orbitron, sans-serif",
                      }}
                    >
                      Validating PDF...
                    </p>
                  </div>
                ) : (
                  <>
                    <p
                      style={{
                        color: "rgb(192, 44, 122)",
                        fontWeight: "bold",
                        fontSize: "18px",
                        border: "none",
                        fontFamily: "Orbitron, sans-serif",
                      }}
                    >
                      {isDragActive ? (
                        <>
                          Drop the file here
                          <br />
                          to upload
                        </>
                      ) : selectedFile ? (
                        <>
                          {selectedFile.name} <br />
                          <span
                            style={{
                              color: "green",
                              fontSize: "14px",
                              fontFamily: "Orbitron, sans-serif",
                            }}
                          >
                            {fileValid ? "Valid PDF" : "Invalid PDF"}
                          </span>
                        </>
                      ) : (
                        <>
                          Drag and Drop
                          <br />
                          Repair Estimate
                        </>
                      )}
                    </p>
                    {!selectedFile && (
                      <>
                        <img
                          src="/img/diminishedvalue/Drag-N-Drop_Icon.png"
                          alt="Drag and Drop"
                          style={{ width: "200px" }}
                        />
                        <p
                          style={{
                            fontSize: "24px",
                            fontFamily: "Orbitron, sans-serif",
                          }}
                        >
                          or
                        </p>
                        <button
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontSize: "16px",
                            fontFamily: "Orbitron, sans-serif",
                          }}
                        >
                          Choose File
                        </button>
                        <p
                          style={{
                            fontSize: "12px",
                            fontFamily: "Orbitron, sans-serif",
                          }}
                        >
                          Accepted file format: PDF
                        </p>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const _2_StartReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div style={{ maxWidth: "1500px", margin: "0 auto" }}>
      <Header
        isGrey={true}
        buttonText="DRIVE AWAY WITH A FAIR COMPENSATION"
        style={{ marginLeft: 0 }}
      />
      <div
        style={{ position: "relative", overflow: "hidden", minHeight: "400px" }}
      >
        <img
          src="/img/diminishedvalue/dv_car_r.png"
          alt="DV Car"
          style={{
            position: "absolute",
            left: "0",
            top: "50%",
            transform: "translateY(-50%)",
            width: "25%",
            height: "auto",
            zIndex: -1,
          }}
        />
        <img
          src="/img/diminishedvalue/dv_car_l.png"
          alt="DV Car"
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translateY(-50%) ",
            width: "25%",
            height: "auto",
            zIndex: -1,
          }}
        />
        <div style={{ position: "relative", zIndex: 1 }}>
          <MainContent />
        </div>
      </div>
      <Footer isGrey={true} />
    </div>
  );
};

export default _2_StartReport;
