import React from "react";
import styled from "styled-components";
import "@fontsource/orbitron";
import { Box, Button, Typography } from "@mui/material";
import { Home as HomeIcon } from "@mui/icons-material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import "@fontsource/orbitron";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  position: relative;
  background-color: transparent;
  background: white;
  width: 100%;
  height: 100%;
  font-family: "Orbitron";
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 24%;
  object-fit: fill;
`;

const Content = styled.div`
  position: relative;
  backgroundcolor: transparent;
  z-index: 0;
`;

const Logo = styled.img`
  width: 450px; /* Adjust this value to change the size of the logo */
  position: relative;
  margin-top: 10%;
  height: 120;
`;

const BaseComponentTotalLoss = ({ imageUrl, children }) => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Image src={imageUrl} />
      <Typography
        position="absolute"
        top="12%"
        left="10%"
        variant="h6"
        align="center"
        gutterBottom
        fontSize={"2em"}
        fontWeight="900"
        fontFamily="Orbitron"
        style={{ color: "black", marginBottom: "1.5rem" }}
      >
        {localStorage.getItem("activeUser")}
      </Typography>
      {window.location.pathname === "/dashboard/totalloss__appraiser" && (
        <Box
          position="absolute"
          top={"3%"}
          right={"1%"}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Button
            onClick={() => {
              navigate("/dashboard/");
            }}
            variant="contained"
            style={{
              minWidth: "10%",
              height: "3rem", // 3 times bigger height
              fontSize: "3rem", // 3 times bigger font size
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <HomeIcon sx={{ marginRight: "2rem", transform: "scale(2)" }} />
            <Typography
              variant="body1"
              style={{
                color: "white",
                fontFamily: "Orbitron, sans-serif",
                fontWeight: "bold",
                fontSize: "2rem",
              }}
            >
              Dashboard Home
            </Typography>
          </Button>
        </Box>
      )}

      {window.location.pathname === "/dashboard/totallossv2/new/" && (
        <Box
          position="absolute"
          top={"3%"}
          right={"1%"}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Button
            onClick={() => {
              navigate("/dashboard/totalloss__client");
            }}
            variant="contained"
            style={{
              minWidth: "10%",
              height: "3rem", // 3 times bigger height
              fontSize: "3rem", // 3 times bigger font size
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <HomeIcon sx={{ marginRight: "2rem", transform: "scale(2)" }} />
            <Typography
              variant="body1"
              style={{
                color: "white",
                fontFamily: "Orbitron, sans-serif",
                fontWeight: "bold",
                fontSize: "2rem",
              }}
            >
              Dashboard Home
            </Typography>
          </Button>
        </Box>
      )}

      {window.location.pathname ===
        "/dashboard/totallossv2/customer/job/rekey" && (
        <Box
          position="absolute"
          top={"3%"}
          right={"1%"}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Button
            onClick={() => {
              navigate("/dashboard/totalloss__client");
            }}
            variant="contained"
            style={{
              minWidth: "10%",
              height: "3rem", // 3 times bigger height
              fontSize: "3rem", // 3 times bigger font size
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <HomeIcon sx={{ marginRight: "2rem", transform: "scale(2)" }} />
            <Typography
              variant="body1"
              style={{
                color: "white",
                fontFamily: "Orbitron, sans-serif",
                fontWeight: "bold",
                fontSize: "2rem",
              }}
            >
              Dashboard Home
            </Typography>
          </Button>
        </Box>
      )}

      {window.location.pathname ===
        "/dashboard/totallossv2/appraiser/job/details" && (
        <Box
          position="absolute"
          top={"3%"}
          right={"1%"}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Button
            onClick={() => {
              navigate("/dashboard/totalloss__appraiser");
            }}
            variant="contained"
            style={{
              minWidth: "10%",
              height: "3rem", // 3 times bigger height
              fontSize: "3rem", // 3 times bigger font size
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <HomeIcon sx={{ marginRight: "2rem", transform: "scale(2)" }} />
            <Typography
              variant="body1"
              style={{
                color: "white",
                fontFamily: "Orbitron, sans-serif",
                fontWeight: "bold",
                fontSize: "2rem",
              }}
            >
              Dashboard Home
            </Typography>
          </Button>
        </Box>
      )}

      {window.location.pathname ===
        "/dashboard/totallossv2/customer/job/update" && (
        <Box
          position="absolute"
          top={"3%"}
          right={"1%"}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Button
            onClick={() => {
              navigate("/dashboard/totalloss__client");
            }}
            variant="contained"
            style={{
              minWidth: "10%",
              height: "3rem", // 3 times bigger height
              fontSize: "3rem", // 3 times bigger font size
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <HomeIcon sx={{ marginRight: "2rem", transform: "scale(2)" }} />
            <Typography
              variant="body1"
              style={{
                color: "white",
                fontFamily: "Orbitron, sans-serif",
                fontWeight: "bold",
                fontSize: "2rem",
              }}
            >
              Dashboard Home
            </Typography>
          </Button>
        </Box>
      )}

      {window.location.pathname === "/dashboard/totalloss__client" && (
        <Box
          position="absolute"
          top={"3%"}
          left={"1%"}
          width={"98%"}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box>
            <Button
              onClick={() => {
                navigate("/dashboard/totallossv2/new/");
              }}
              variant="contained"
              style={{
                minWidth: "10%",
                height: "3rem", // same height
                fontSize: "3rem", // same font size
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <AddBoxIcon sx={{ marginRight: "2rem", transform: "scale(2)" }} />
              <Typography
                variant="body1"
                style={{
                  color: "white",
                  fontFamily: "Orbitron, sans-serif",
                  fontWeight: "bold",
                  fontSize: "2rem",
                }}
              >
                Create New Total Loss Job
              </Typography>
            </Button>
          </Box>

          <Box>
            <Button
              onClick={() => {
                navigate("/dashboard/");
              }}
              variant="contained"
              style={{
                minWidth: "10%",
                height: "3rem",
                fontSize: "3rem",
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <HomeIcon sx={{ marginRight: "2rem", transform: "scale(2)" }} />
              <Typography
                variant="body1"
                style={{
                  color: "white",
                  fontFamily: "Orbitron, sans-serif",
                  fontWeight: "bold",
                  fontSize: "2rem",
                }}
              >
                Dashboard Home
              </Typography>
            </Button>
          </Box>
        </Box>
      )}

      <Logo src={"../../../../img/totalloss_logo.png"} alt="Logo" />
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default BaseComponentTotalLoss;
