import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { createShop } from "../../kritechadminv2/redux/actions";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";

function CreateShopModal({ open, onClose, onSuccess }) {
  const initialShopState = {
    Name: "",
    Email: "",
    Phone: "",
    Company: "",
    "Street 1": "",
    "Street 2": "",
    City: "",
    "Postal Code": "",
    Country: "",
    "State/Province": "",
    Active: true,
  };

  const dispatch = useDispatch();
  const [shop, setShop] = useState(initialShopState);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);

  useEffect(() => {
    if (open) {
      setShop(initialShopState);
      setErrors({});
    }
  }, [open]);

  useEffect(() => {
    validateForm();
  }, [shop]);

  const validateForm = () => {
    const newErrors = {};

    if (!shop.Name.trim()) {
      newErrors.Name = "Name is required";
    }

    if (!shop.Email.trim()) {
      newErrors.Email = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(shop.Email)) {
      newErrors.Email = "Invalid email address";
    }

    if (!shop.Phone.trim()) {
      newErrors.Phone = "Phone is required";
    } else if (!/^\d{10,15}$/.test(shop.Phone)) {
      newErrors.Phone = "Invalid phone number";
    }

    if (!shop.Company.trim()) {
      newErrors.Company = "Company is required";
    }

    if (!shop["Street 1"].trim()) {
      newErrors["Street 1"] = "Street 1 is required";
    }

    if (!shop.City.trim()) {
      newErrors.City = "City is required";
    }

    if (!shop["Postal Code"].trim()) {
      newErrors["Postal Code"] = "Postal Code is required";
    }

    if (!shop.Country.trim()) {
      newErrors.Country = "Country is required";
    }

    if (!shop["State/Province"].trim()) {
      newErrors["State/Province"] = "State/Province is required";
    }

    setErrors(newErrors);
    setIsNextButtonEnabled(Object.keys(newErrors).length === 0);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShop((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleNext = async () => {
    if (!isNextButtonEnabled) return;

    setIsLoading(true);
    try {
      const createdShop = await dispatch(createShop(shop));
      onSuccess(createdShop);
      setIsLoading(false);
      onClose(); // Close the modal upon successful creation
    } catch (error) {
      console.error("Error creating shop:", error);
      setIsLoading(false);
    }
  };

  const renderTextField = (name, label, type = "text") => (
    <TextField
      fullWidth
      margin="normal"
      label={label}
      name={name}
      type={type}
      value={shop[name]}
      onChange={handleInputChange}
      error={!!errors[name]}
      helperText={errors[name]}
    />
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Create New Shop</DialogTitle>
      {isLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", height: "80px" }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <DialogContent>
            {renderTextField("Name", "Name")}
            {renderTextField("Email", "Email", "email")}
            {renderTextField("Phone", "Phone")}
            {renderTextField("Company", "Company")}
            {renderTextField("Street 1", "Street 1")}
            {renderTextField("Street 2", "Street 2")}
            {renderTextField("City", "City")}
            {renderTextField("Postal Code", "Postal Code")}
            {renderTextField("Country", "Country")}
            {renderTextField("State/Province", "State/Province")}
            <FormControl fullWidth margin="normal">
              <InputLabel id="shop-type-label">Shop Type</InputLabel>
              <Select
                labelId="shop-type-label"
                name="shopType"
                value={shop.shopType}
                label="Shop Type"
                onChange={handleInputChange}
                disabled={isLoading}
              >
                <MenuItem value="Individual">Individual</MenuItem>
                <MenuItem value="MSO">MSO</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleNext}
              color="primary"
              disabled={!isNextButtonEnabled}
            >
              Next
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default CreateShopModal;
