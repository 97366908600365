// EnterErrorModal.js
import React from "react";
import { Modal, Box, Typography } from "@mui/material";

const EnterErrorModal = ({ enterError, setEnterError }) => (
  <Modal
    open={enterError}
    onClose={() => setEnterError(false)}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
      }}
    >
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Please enter VIN & Year to continue
      </Typography>
    </Box>
  </Modal>
);

export default EnterErrorModal;
