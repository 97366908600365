import React, { useMemo, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";

import { IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  DateRangePicker,
  DateRange,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import { Box, Tabs, Grid, Tab, Button } from "@mui/material";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import {
  ReSubmitJob,
  DeleteJob,
  OpenLogs,
  EstimatePDF,
  ChangeJobStatus,
} from "../../../services/autoestimaterekey.service";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/system";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import "./LoadingBar.css";

const LoadingBar = ({ currentStage, totalStages, currentItem, totalItems }) => {
  const stageProgress = (currentStage / totalStages) * 100;
  const itemProgress = (currentItem / totalItems) * 100;

  return (
    <div className="loadingBarContainer">
      <div className="stage">
        <div className="progress" style={{ width: `${stageProgress}%` }}>
          <span className="progressText">
            Stage: {currentStage}/{totalStages}
          </span>
        </div>
      </div>
      <div className="item">
        <div className="progress" style={{ width: `${itemProgress}%` }}>
          <span className="progressText">
            Item: {currentItem}/{totalItems}
          </span>
        </div>
      </div>
      <div className="label">
        Overall: Stage {currentStage} of {totalStages} | Item {currentItem} of{" "}
        {totalItems}
      </div>
    </div>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  backgroundColor: "#fff", // set background to white
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: "#fff", // set background to white
}));

const Example = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);

    // Clear current data and filters
    setColumnFilters([]);
    setGlobalFilter("");
    setSorting([]);
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });

    // Refetch data when tab changes
    queryClient.refetchQueries(["table-data", newValue]); // Pass the newValue as a parameter to your query
  };

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [stage, setStage] = useState(1);
  const [item, setItem] = useState(1);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async (fileName) => {
    console.log("Clicked Export");
    const jsonData = await fetchAllData();
    console.log("ReceivedExportData:", jsonData);
    const ws = XLSX.utils.json_to_sheet(jsonData.data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const fetchAllData = async () => {
    const url = new URL(
      "/usagereports",
      "https://aerkbackendv2.azurewebsites.net/"
      //"http://localhost:3001/"
    );
    url.searchParams.set("size", "9999");
    url.searchParams.set("start", `0`);
    url.searchParams.set("filters", JSON.stringify([]));
    url.searchParams.set("globalFilter", "");
    url.searchParams.set("sorting", JSON.stringify([]));

    if (activeTab === 0) {
      url.searchParams.set("appType", "AERK");
    } else if (activeTab === 1) {
      url.searchParams.set("appType", "Total Loss");
    } else if (activeTab === 2) {
      url.searchParams.set("appType", "Axiom PDR");
    }

    const response = await fetch(url.href);
    const json = await response.json();
    console.log("Response Json:", json);

    return json;
  };

  const { data, isError, isFetching, isLoading, refetch } = useQuery(
    [
      "table-data",
      activeTab,
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      dateRange, // Include the date filter in the query key
    ],
    async () => {
      const url = new URL(
        "/usagereports",
        "https://aerkbackendv2.azurewebsites.net/"
        //"http://localhost:3001/"
      );
      url.searchParams.set(
        "start",
        `${pagination.pageIndex * pagination.pageSize}`
      );
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("globalFilter", globalFilter ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      if (activeTab === 0) {
        url.searchParams.set("appType", "AERK");
      } else if (activeTab === 1) {
        url.searchParams.set("appType", "Total Loss");
      } else if (activeTab === 2) {
        url.searchParams.set("appType", "Axiom PDR");
      }

      url.searchParams.set("dateFilter", dateRange ?? "");

      const response = await fetch(url.href);
      const json = await response.json();

      console.log("Response Json:", json);

      return json;
    },
    { keepPreviousData: true }
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "index",
        header: "Index",
        Cell: ({ cell }) => cell.row.index,
      },
      {
        accessorKey: "totalTime",
        header: "Time",
      },
      {
        accessorKey: "appType",
        header: "Application",
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => (
          <div className="Aoo-spaceBetween">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={cell.row.original.status}
              label="Status"
              onChange={(event, child) => {
                console.log(event.target.value);
                console.log(child);
                if (localStorage.getItem("isOwner") === "true") {
                  cell.row.original.status = event.target.value;
                  ChangeJobStatus(
                    cell.row.original._id,
                    event.target.value,
                    () => {
                      document.location.reload();
                    }
                  );
                } else {
                  console.log("Viewers and sales and update state");
                }
              }}
            >
              <MenuItem value={0}>Success</MenuItem>
              <MenuItem value={1}>Fail</MenuItem>
              <MenuItem value={2}>Success(PartErrors)</MenuItem>
              <MenuItem value={3}>Processing</MenuItem>
              <MenuItem value={4}>Timeout</MenuItem>
            </Select>
          </div>
        ),
      },
      {
        accessorKey: "estimateName",
        header: "Estimate Name",
      },
      {
        accessorKey: "sourceDocumentType",
        header: "Src Doc Type",
      },
      {
        accessorKey: "platform",
        header: "Platform",
      },
      {
        accessorKey: "entryType",
        header: "Entry Type",
      },
      {
        accessorKey: "customerId",
        header: "Customer ID",
      },
      {
        accessorKey: "serialNo",
        header: "Serial No",
      },
      {
        accessorKey: "dateTimeProcessed",
        header: "Date Processed",
      },
      {
        accessorKey: "company",
        header: "Company",
      },
      {
        accessorKey: "actions",
        header: "Actions",
        Cell: ({ cell }) => (
          <div>
            {(cell.row.original.status !== 0 ||
              cell.row.original.status !== "0") &&
              localStorage.getItem("isOwner") === "true" && (
                <div>
                  {cell.row.original.status !== 3 && (
                    <>
                      <button
                        OpenLogs
                        onClick={() => {
                          ReSubmitJob(
                            cell.row.original.tempJobObjectId,
                            cell.row.original._id,
                            (result) => {
                              console.log(result);
                              window.location.reload();
                            }
                          );
                          console.log(cell);
                        }}
                      >
                        Re-run Job
                      </button>
                      <button
                        onClick={() => {
                          OpenLogs(cell.row.original._id);
                          console.log(cell);
                        }}
                      >
                        Logs
                      </button>
                      <button
                        onClick={() => {
                          EstimatePDF(
                            cell.row.original.pdfUrl.split("\\").pop()
                          );
                          console.log(cell);
                        }}
                      >
                        Estimate PDF
                      </button>
                      <button
                        onClick={() => {
                          DeleteJob(
                            cell.row.original.tempJobObjectId,
                            cell.row.original._id,
                            (result) => {
                              console.log(result);
                              window.location.reload();
                            }
                          );
                          console.log(cell);
                        }}
                      >
                        Delete
                      </button>
                    </>
                  )}
                  {cell.row.original.status === 3 && (
                    <button
                      onClick={() => {
                        var url = "http://20.221.82.152:4444/ui#/sessions";
                        window.open(url, "_blank");
                      }}
                    >
                      Live View
                    </button>
                  )}
                </div>
              )}
          </div>
        ),
      },
    ],
    []
  );

  const columnsPdr = useMemo(
    () => [
      {
        accessorKey: "index",
        header: "Index",
        Cell: ({ cell }) => cell.row.index,
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => (
          <div className="Aoo-spaceBetween">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={cell.row.original.status}
              label="Status"
              onChange={(event, child) => {
                console.log(event.target.value);
                console.log(child);
                if (localStorage.getItem("isOwner") === "true") {
                  cell.row.original.status = event.target.value;
                  ChangeJobStatus(
                    cell.row.original._id,
                    event.target.value,
                    () => {
                      document.location.reload();
                    }
                  );
                } else {
                  console.log("Viewers and sales and update state");
                }
              }}
            >
              <MenuItem value={0}>Success</MenuItem>
              <MenuItem value={1}>Fail</MenuItem>
              <MenuItem value={2}>Success(PartErrors)</MenuItem>
              <MenuItem value={3}>Processing</MenuItem>
              <MenuItem value={4}>Timeout</MenuItem>
            </Select>
          </div>
        ),
      },
      {
        accessorKey: "customerId",
        header: "Repairer Id",
      },
      {
        accessorKey: "company",
        header: "Account Id",
      },
      {
        accessorKey: "pdfUrl",
        header: "Scope Id",
      },
      {
        accessorKey: "vin",
        header: "VIN",
      },
      {
        accessorKey: "estimateName",
        header: "#RO Number",
      },

      {
        accessorKey: "dateTimeProcessed",
        header: "Date Processed",
      },
    ],
    []
  );

  const columnsTotalLoss = useMemo(
    () => [
      {
        accessorKey: "index",
        header: "Index",
        Cell: ({ cell }) => cell.row.index,
      },
      {
        accessorKey: "status",
        header: "Workflow Stage",
        Cell: ({ cell }) => (
          <div className="Aoo-spaceBetween">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={cell.row.original.status}
              label="Status"
              onChange={(event, child) => {
                console.log(event.target.value);
                console.log(child);
                if (localStorage.getItem("isOwner") === "true") {
                  cell.row.original.status = event.target.value;
                  ChangeJobStatus(
                    cell.row.original._id,
                    event.target.value,
                    () => {
                      document.location.reload();
                    }
                  );
                } else {
                  console.log("Viewers and sales and update state");
                }
              }}
            >
              <MenuItem value={0}>New Job</MenuItem>
              <MenuItem value={1}>Claimed Job</MenuItem>
              <MenuItem value={2}>Awaiting Customer</MenuItem>
              <MenuItem value={3}>Issue Raised</MenuItem>
              <MenuItem value={4}>Issue Escalated</MenuItem>
              <MenuItem value={5}>Issue Escalated</MenuItem>
              <MenuItem value={6}>Final - Locked</MenuItem>
              <MenuItem value={7}>Canceled - Locked</MenuItem>
            </Select>
          </div>
        ),
      },
      {
        accessorKey: "status",
        header: "reKey Status",
        Cell: ({ cell }) => (
          <LoadingBar
            currentStage={stage}
            totalStages={3}
            currentItem={item}
            totalItems={45}
          />
        ),
      },
      {
        accessorKey: "platform",
        header: "Platform",
      },
      {
        accessorKey: "timezone",
        header: "Timezone",
      },
      {
        accessorKey: "vin",
        header: "VIN",
      },
      {
        accessorKey: "customer_id",
        header: "Customer Id",
      },

      {
        accessorKey: "submit_date",
        header: "Date Processed",
      },
    ],
    []
  );

  const columnsForActiveTab = useMemo(() => {
    switch (activeTab) {
      case 0:
        return columns;
      case 1:
        return columnsTotalLoss;
      case 2:
        return columnsPdr;
      default:
        return columns;
    }
  }, [activeTab, columns, columnsPdr]);

  return (
    <StyledBox>
      <StyledTabs
        value={activeTab}
        onChange={handleChange}
        variant="fullWidth"
        centered
      >
        <Tab label="reKey" />
        <Tab label="Total Loss" />
        <Tab label="Axiom PDR" />
      </StyledTabs>
      <Box>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MaterialReactTable
            columns={columnsForActiveTab}
            data={data?.data ?? []}
            initialState={{ showColumnFilters: true }}
            manualFiltering
            manualPagination
            manualSorting
            muiToolbarAlertBannerProps={
              isError
                ? {
                    color: "error",
                    children: "Error loading data",
                  }
                : undefined
            }
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            renderTopToolbarCustomActions={() => (
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Tooltip arrow title="Refresh Data">
                    <IconButton onClick={() => refetch()}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center" justifyContent="flex-end">
                    <Grid item>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateRangePicker
                          startText="Start date"
                          endText="End date"
                          value={dateRange}
                          onChange={(newValue) => {
                            setDateRange(newValue);
                            refetch();
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="warning"
                        onClick={(e) => exportToCSV("filename")}
                      >
                        Export
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            rowCount={data?.meta?.totalRowCount ?? 0}
            state={{
              columnFilters,
              globalFilter,
              isLoading,
              pagination,
              showAlertBanner: isError,
              showProgressBars: isFetching,
              sorting,
            }}
          />
        </LocalizationProvider>
      </Box>
    </StyledBox>
  );
};

const queryClient = new QueryClient();

const UsageReport = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Example />
    </QueryClientProvider>
  );
};

export default UsageReport;
