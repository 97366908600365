// LoadingModal.js
import React from "react";
import { Modal, CircularProgress } from "@mui/material";

const LoadingModal = ({ loading }) => (
  <Modal
    open={loading}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <div
      style={{
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "4px",
        textAlign: "center",
      }}
    >
      <CircularProgress />
      <p>
        Please wait while we gather your vehicle details. This may take up to a
        couple minutes.
      </p>
    </div>
  </Modal>
);

export default LoadingModal;
