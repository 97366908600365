import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert"; // If using MUI v5. If using v4, refer to MUI docs for the appropriate import

const Notification = ({ open, onClose, message, severity = "info" }) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
