import React, { useEffect, useState } from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ListItemButton,
  List,
  Box,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { v4 as uuidv4 } from "uuid";
import {
  fetchGroups,
  createGroup,
  deleteGroup,
  fetchConfig,
  updateConfig,
} from "./apiService";
import GroupConfigEditor from "./GroupConfigEditor";

function GroupList({ onGroupClick }) {
  const [groups, setGroups] = useState([]);
  const [newGroupName, setNewGroupName] = useState("");
  const [newSubgroupNames, setNewSubgroupNames] = useState({});
  const [editingGroupId, setEditingGroupId] = useState(null);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    fetchGroups().then((fetchedGroups) => {
      const groupsWithSubgroups = fetchedGroups.reduce((acc, group) => {
        if (!group.parentId) {
          acc.push({ ...group, subgroups: [] });
        } else {
          const parentGroup = acc.find((g) => g._id === group.parentId);
          if (parentGroup) {
            parentGroup.subgroups.push(group);
          }
        }
        return acc;
      }, []);
      setGroups(groupsWithSubgroups);
    });
  }, []);

  function handleGroupCreate(groupName, parentId = null) {
    createGroup(groupName, parentId).then((newGroup) => {
      if (!parentId) {
        setGroups([...groups, newGroup]);
      } else {
        setGroups((prevGroups) =>
          prevGroups.map((group) => {
            if (group._id === parentId) {
              return {
                ...group,
                subgroups: group.subgroups
                  ? [...group.subgroups, newGroup]
                  : [newGroup],
              };
            }
            return group;
          })
        );
      }
    });
  }
  function handleSubgroupCreate(parentId) {
    const newSubgroupName = newSubgroupNames[parentId];
    handleGroupCreate(newSubgroupName, parentId);
    setNewSubgroupNames({ ...newSubgroupNames, [parentId]: "" });
  }
  function handleGroupDelete(group) {
    deleteGroup(group._id).then(() => {
      setGroups(groups.filter((g) => g._id !== group._id));
    });
  }

  function handleGroupConfigOpen(group) {
    fetchConfig(`group/${group._id}/config`).then((fetchedConfig) => {
      setConfig(fetchedConfig);
      setEditingGroupId(group._id);
    });
  }

  function handleConfigSave() {
    updateConfig(`group/${editingGroupId}/config`, config).then(() => {
      setEditingGroupId(null);
    });
  }

  function handleGroupClick(group) {
    if (group.subgroups) {
      onGroupClick(group);
    } else {
      const updatedGroup = { ...group };
      delete updatedGroup.subgroups;
      onGroupClick(updatedGroup);
    }
  }

  return (
    <>
      {/* Input field and Add button for a new Group */}
      <ListItem>
        <TextField
          fullWidth
          label="New Group Name"
          value={newGroupName}
          onChange={(e) => setNewGroupName(e.target.value)}
        />
        <IconButton onClick={() => handleGroupCreate(newGroupName, null)}>
          <AddIcon />
        </IconButton>
      </ListItem>

      {/* List of Groups and Subgroups */}
      <List>
        {groups.map((group) => (
          <React.Fragment key={group._id}>
            {/* Group */}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleGroupClick(group)}
                sx={{ flexGrow: 1 }}
              >
                <ListItemText primary={group.name} />
              </ListItemButton>
              <ListItemIcon>
                <IconButton onClick={() => handleGroupConfigOpen(group)}>
                  <SettingsIcon />
                </IconButton>
              </ListItemIcon>
              <ListItemIcon>
                <IconButton onClick={() => handleGroupDelete(group)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemIcon>
            </ListItem>

            {/* Nested List for Subgroups */}
            <Box pl={4}>
              {group.subgroups?.map((subgroup) => (
                <ListItem key={subgroup._id} disablePadding>
                  {/* Subgroup */}
                  <ListItemButton
                    onClick={() => handleGroupClick(subgroup)}
                    sx={{ flexGrow: 1 }}
                  >
                    <ListItemText primary={subgroup.name} />
                  </ListItemButton>
                  <ListItemIcon>
                    <IconButton onClick={() => handleGroupConfigOpen(subgroup)}>
                      <SettingsIcon />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemIcon>
                    <IconButton onClick={() => handleGroupDelete(subgroup)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemIcon>
                </ListItem>
              ))}
              {/* Input field and Add button for a new Subgroup */}
              <ListItem>
                <TextField
                  fullWidth
                  label={`New Subgroup for ${group.name}`}
                  value={newSubgroupNames[group._id] || ""}
                  onChange={(e) =>
                    setNewSubgroupNames({
                      ...newSubgroupNames,
                      [group._id]: e.target.value,
                    })
                  }
                />
                <IconButton onClick={() => handleSubgroupCreate(group._id)}>
                  <AddIcon />
                </IconButton>
              </ListItem>
            </Box>
          </React.Fragment>
        ))}
      </List>

      {/* Dialog for Group and Subgroup Configuration */}
      {editingGroupId && (
        <Dialog open={editingGroupId !== null}>
          <DialogTitle>Edit Configuration</DialogTitle>
          <DialogContent>
            <GroupConfigEditor
              initialConfig={config}
              onSave={(newConfig) => {
                console.log("NewConfig:", newConfig);
                var tempConfig = config;
                tempConfig.arr = newConfig;
                setConfig(tempConfig);
                handleConfigSave();

                setEditingGroupId(null);
              }}
              onClose={() => setEditingGroupId(null)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditingGroupId(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
export default GroupList;
