import React, { Component } from "react";
import Modal from "../../../components/Modal.module";
import { checkLocalLicense } from "../../../util/license.extension";
import Form from "react-bootstrap/Form";
import {
  GetClientLicenses,
  SetProprety,
} from "../../../services/license.service";

export default class AdjustActivations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      customerId: "",
      activations: 0,
    };

    this.setModalState = this.setModalState.bind(this);
  }

  componentDidMount() {
    checkLocalLicense();
  }

  setModalState(state) {
    this.setState({ showModal: state });
  }

  async addActivations(customerId, amount) {
    await GetClientLicenses(customerId, async (dataType, data) => {
      let minHubActivations = 9999;
      for (let i = 0; i < data.children[0].children.length; i++) {
        // let licenseTemplateId = "";
        for (let j = 0; j < data.children[0].children[i].children.length; j++) {
          if (
            data.children[0].children[i].children[j].name ===
            "LicenseTemplateId"
          ) {
            //licenseTemplateId = data.children[0].children[i].children[j].value;
          }
        }
      }

      for (let i = 0; i < data.children[0].children.length; i++) {
        let serialNo = "";
        let hasHub = false;
        for (let j = 0; j < data.children[0].children[i].children.length; j++) {
          if (data.children[0].children[i].children[j].name === "Properties") {
            for (
              let k = 0;
              k < data.children[0].children[i].children[j].children.length;
              k++
            ) {
              if (
                data.children[0].children[i].children[j].children[k].name ===
                "HUBActivations"
              ) {
                hasHub = true;
              }
            }
          }

          if (
            data.children[0].children[i].children[j].name === "SerialNumber"
          ) {
            serialNo = data.children[0].children[i].children[j].value;
          }
        }

        if (hasHub) {
          await SetProprety(
            serialNo,
            parseInt(minHubActivations, 10) + amount,
            (dataType, data) => {}
          );
        }
      }

      window.location.href = "/";
    });
  }

  handleInputChange(field, value) {
    if (field === "customerId") {
      this.setState({ customerId: value });
    } else if (field === "activations") {
      this.setState({ activations: parseInt(value, 10) });
    }
  }

  render() {
    return (
      <div className="auth-wrapper">
        <div className={"auth-inner-app"}>
          <div className={"parent"}>
            <h3>My CRASH Network Admin</h3>
            <p>Adjust Activations for Customer</p>

            <div className={"container-step"}>
              <div className={"container-step-entry"}>
                Customer ID or Serial Number
              </div>
              <Form.Control
                type="text"
                placeholder="#Customer ID"
                onChange={(e) => {
                  this.handleInputChange("customerId", e.target.value);
                }}
              />
              <div className={"container-step-entry"}></div>
            </div>
            <div className={"divider"}>.</div>
            <div className={"container-step"}>
              <div className={"container-step-entry"}>
                Number of Activations to Add or Remove
              </div>
              <Form.Control
                type="text"
                placeholder="#Activations Count"
                onChange={(e) => {
                  this.handleInputChange("activations", e.target.value);
                }}
              />
              <div className={"container-step-entry"}></div>
            </div>

            <div className={"divider"}>.</div>
            <div className={"divider"}>.</div>

            <div className={"childSides"}>
              <button
                className={"buttonAuth"}
                onClick={async () => {
                  await this.addActivations(
                    this.state.customerId,
                    this.state.activations
                  );
                }}
              >
                Add Activations
              </button>
              <button
                className={"buttonDeAuth"}
                onClick={async () => {
                  await this.addActivations(
                    this.state.customerId,
                    -this.state.activations
                  );
                }}
              >
                Remove Activations
              </button>
            </div>
          </div>

          {this.state.showModal && (
            <Modal
              setIsOpen={async () => {
                await this.addActivations(
                  this.state.customerId,
                  -this.state.activations
                );
              }}
            />
          )}
        </div>
      </div>
    );
  }
}
