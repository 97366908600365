import React, { Component } from "react";
import { checkLocalLicense } from "../../util/license.extension";

import "bootstrap/dist/css/bootstrap.min.css";
import "./autoestimaterekey.css";

// noinspection JSUnusedLocalSymbols
export default class TotalLossSubmited extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    checkLocalLicense();
  }

  render() {
    return (
      <div className="auth-wrapper">
        <div className={"auth-inner-app-form"}>
          <h3>Total Loss</h3>

          <p>
            Your estimate is being processed, You will receie an email when your
            estimate is done processing . Please make sure to review and verify
            all parts data is correct on your estimate after it has been
            rekeyed. you will also need to select and insurance company and
            enter the customer contact information if you selected into New
            Workfile.
          </p>

          <p>
            If there are any issues with your estimate the Kri-Tech Support team
            will be notified and will contact you with further
            information/instructions.
          </p>

          <div className={"footer-buttons"}>
            <button
              className={"buttonBack"}
              onClick={() => {
                window.location.href = "/dashboard/totalloss/";
              }}
            >
              Total Loss Home Page
            </button>

            <button
              className={"buttonBack"}
              onClick={() => {
                window.location.href = "/dashboard/";
              }}
            >
              Hub Home Page
            </button>
          </div>
        </div>
      </div>
    );
  }
}
