import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import { createJobRequest } from "./totallossv2.service";
import { createTheme } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import {
  Modal,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { checkProductPayment } from "../../../Globals";
import CustomInfoModal from "./totallossv2.custominfomodal";
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Select,
  TextField,
  ButtonGroup,
  Dialog,
  DialogActions,
} from "@mui/material";
import "@fontsource/orbitron";
import { styled } from "@mui/system";
import "@fontsource/lato";
import BaseComponentTotalLoss from "./totallossv2baseapp";
import PieMenu, { Slice } from "react-pie-menu";
import { ThemeProvider, css } from "styled-components";
import { useAuth } from "../../../AuthContext";
import { fetchUnitPrice } from "../../../Globals";

const NewTotalLossJob = () => {
  const { isAuthenticated, user } = useAuth();
  const [platform, setPlatform] = useState("");
  const [vin, setVin] = useState("");
  const [optionalNotes, setOptionalNotes] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [images, setImages] = useState({});
  const [additionalImages, setAdditionalImages] = useState(
    new Array(5).fill(null)
  );
  //Modal data
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showClose, setShowClose] = useState(false);

  //Step 5
  const [body, setBody] = useState("");
  const [bdyS, setBdyS] = useState("");
  const [refinish, setRefinish] = useState("");
  const [glass, setGlass] = useState("");
  const [frame, setFrame] = useState("");
  const [mechanical, setMechanical] = useState("");
  const [paintMaterialsRate, setPaintMaterialsRate] = useState("");
  const [taxRateForParts, setTaxRateForParts] = useState("");
  const [taxRateForLabor, setTaxRateForLabor] = useState("");
  const [useAlternativeParts, setUseAlternativeParts] = useState(false);
  // State for each button
  const [open, setOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);

  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalSeverity, setModalSeverity] = useState("success");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [refundAccepted, setRefundAccepted] = useState(false);
  const [availableTransactions, setAvailableTransactions] = useState(0);
  const [pricePerUnit, setPricePerUnit] = useState(0);

  const [condition, setCondition] = useState("");
  const [buttonColors, setButtonColors] = useState({
    14: "white",
    16: "white",
    19: "white",
    21: "white",
    12: "white",
    1: "white",
    3: "white",
    4: "white",
    5: "white",
    6: "white",
    7: "white",
    8: "white",
    9: "white",
    10: "white",
    11: "white",
    13: "white",
  });

  const handleButtonClick = (id) => {
    setSelectedButton(id);
    setOpen(true);
  };

  const handleDialogOption = (color) => {
    setButtonColors((prevColors) => ({
      ...prevColors,
      [selectedButton]: color,
    }));
    // Close the Dialog
    setOpen(false);
  };

  useEffect(() => {
    fetchTLPrice();
  });

  const handleDialogClose = (color) => {
    // Here, you can update the color of the button using the selectedButton state
    // For demonstration, I'm just closing the dialog
    setOpen(false);
  };

  const handleBulkImageUpload = (event) => {
    const files = Array.from(event.target.files);
    setAdditionalImages((currentImages) => {
      const newImages = [...currentImages];
      let fileIndex = 0;
      
      // Fill in null slots first
      for (let i = 0; i < 5 && fileIndex < files.length; i++) {
        if (newImages[i] === null) {
          newImages[i] = files[fileIndex];
          fileIndex++;
        }
      }
      
      // Add remaining files to the end
      while (fileIndex < files.length) {
        newImages.push(files[fileIndex]);
        fileIndex++;
      }
      
      return newImages;
    });
  };

  const handleAdditionalImageChange = (event, index) => {
    const newFiles = Array.from(event.target.files);
    setAdditionalImages((currentImages) => [
      ...currentImages.slice(0, index),
      ...newFiles,
      ...currentImages.slice(index + 1),
    ]);
  };

  const handleDropForAdditionalImages = (event, index) => {
    event.preventDefault(); // Prevent the default behavior
    const filesDropped = Array.from(event.dataTransfer.files); // Convert FileList to Array

    // Assuming setAdditionalImages and additionalImages work as before
    setAdditionalImages((currentImages) => {
      const newImages = [...currentImages];
      filesDropped.forEach((file, fileIndex) => {
        // Assuming the logic for incorporating dropped files should replace the image at the drop position
        // Modify this logic as needed based on how you want to handle the specific file replacements or additions
        newImages[index + fileIndex] = file;
      });
      return newImages;
    });
  };

  const validateFields = () => {
    if (vin == null || vin.length < 4) return "VIN is required field";

    if (platform == null || platform.length < 4)
      return "You have not selected platform";

    return "";
  };

  const addAdditionalDamageImage = () => {
    if (additionalImages.length < 25) {
      setAdditionalImages([...additionalImages, null]);
    }
  };

  const handleOpenModal = (modalTitle, modalBody, showSpinner, showClose) => {
    setModalBody(modalBody);
    setModalTitle(modalTitle);
    setShowSpinner(showSpinner);
    setShowClose(showClose);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  async function fetchTLPrice() {
    let selectedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
    console.log("User:", user);
    let serial = user.Serial;
    let fetchPriceResponse = await fetchUnitPrice(serial, selectedProduct._id);

    if (fetchPriceResponse.success === true) {
      if (fetchPriceResponse.state === "No payment required") {
        // Handle the case when no payment is required
      } else if (fetchPriceResponse.state === "OK") {
        console.log("UnitPrice:", fetchPriceResponse);
        setPricePerUnit(fetchPriceResponse.unitPrice);
        setAvailableTransactions(selectedProduct.AvailableTransactions);
      }
    }
    console.log("Fetch Price Response:", fetchPriceResponse);
  }

  const handleImageChange = (event, position) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const imageData = reader.result;
      const encodedImageName = encodeImageName(position, imageData);

      // Create a new File object with the encoded image name
      const fileWithEncodedName = new File([file], encodedImageName, {
        type: file.type,
      });

      // Store the new File object in the state with the position as the key
      setImages({ ...images, [position]: fileWithEncodedName });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event, position) => {
    event.preventDefault(); // Prevent the default handling of the files (e.g., opening them)
    const files = event.dataTransfer.files; // Getting the files that were dropped

    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const imageData = reader.result;
        const encodedImageName = encodeImageName(position, imageData);

        const fileWithEncodedName = new File([file], encodedImageName, {
          type: file.type,
        });
        setImages({ ...images, [position]: fileWithEncodedName });
      };

      reader.readAsDataURL(file);
    }
  };

  const handlePurchaseConfirm = async () => {
    setPurchaseLoading(true);
    let selectedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
    try {
      const response = await checkProductPayment(
        user.Serial,
        selectedProduct._id
      );

      if (response.success) {
        if (availableTransactions > 0) {
          // Update the available transactions count in local storage
          selectedProduct.AvailableTransactions -= 1;
          localStorage.setItem(
            "selectedProduct",
            JSON.stringify(selectedProduct)
          );
          setAvailableTransactions(selectedProduct.AvailableTransactions);
        }
        setShowPurchaseModal(false);
        setModalMessage("");
        setModalSeverity("success");

        // Call createJobRequest function to submit the job
        createJobRequest(
          user.Serial,
          vin,
          platform,
          getFilesFromImages(),
          getFilesFromAditionalImages(),
          optionalNotes,
          specialInstructions,
          buttonColors,
          condition,
          body,
          bdyS,
          refinish,
          glass,
          frame,
          mechanical,
          paintMaterialsRate,
          taxRateForParts,
          taxRateForLabor,
          useAlternativeParts,
          () => {
            window.location.href = "/dashboard/totalloss__client";
          },
          () => {
            handleOpenModal(
              "Submit Job Error",
              "We encountered an error submitting your job. Please try again later.",
              false,
              true
            );
          }
        );
      } else {
        // Purchase failed, show error message in modal
        setModalMessage("Purchase failed. Please try again.");
        setModalSeverity("error");
      }
    } catch (error) {
      // Handle API error, show error message in modal
      console.error("Error processing purchase:", error);
      setModalMessage("An error occurred while processing the purchase.");
      setModalSeverity("error");
    }
    setPurchaseLoading(false);
  };

  const createJob = () => {
    let validateResponse = validateFields();

    if (validateResponse !== "") {
      handleOpenModal(
        "Please fill all required fields",
        validateResponse,
        false,
        true
      );
      return;
    }

    setShowPurchaseModal(true);
  };

  // Generate a unique hash for the image
  const generateImageHash = (imageData) => {
    const hash = CryptoJS.SHA256(imageData).toString(CryptoJS.enc.Hex);
    return hash;
  };

  // Encode the image position and unique hash in the image name
  const encodeImageName = (position, imageData) => {
    const hash = generateImageHash(imageData);
    position = position.replaceAll("'", "TTT");
    position = position.replaceAll("/", "XXX");
    position = position.replaceAll(")", "WWW");
    position = position.replaceAll("(", "ZZZ");
    position = position.replaceAll(",", "QQQ");

    return `${position}_${hash}.jpg`;
  };

  // Decode the image name to get the position and unique hash
  const decodeImageName = (imageName) => {
    const [position, hashWithExt] = imageName.split("_");
    const hash = hashWithExt.replace(".jpg", "");
    return { position, hash };
  };

  const getFilesFromAditionalImages = () => {
    console.log("Total Additional Images:", additionalImages.length);
    return additionalImages;
  };

  const getFilesFromImages = () => {
    const files = Object.keys(images).map((position) => images[position]);
    return files;
  };
  const OFFSET_ANGLE = "15.2deg"; // You can adjust this value to move the conic-gradient around.

  const StyledSlice = styled(Slice)`
    background: ${(props) =>
      buttonColors[props.kind] && buttonColors[props.kind] !== "white"
        ? buttonColors[props.kind]
        : `conic-gradient(
             from ${OFFSET_ANGLE},
             grey 0% 0.1%, 
             white 0.1% 99.9%, 
             grey 99.9% 100%
           )`};
  `;

  const theme = {
    pieMenu: {
      container: css`
        border: 4px solid white;
        background: radial-gradient(
          transparent
            ${({ centerRadius }) =>
              `${centerRadius}, rgba(255, 255, 255, 0.85) ${centerRadius}`}
        );
        overflow: visible;
      `,
    },
    slice: {
      container: css`
        cursor: pointer;
        border: 1px solid lightgrey;

        /* Dynamically adjust background based on slice kind */
        background: ${(props) => buttonColors[props.kind] || "white"};

        // ... other slice styles ...
      `,
      content: css`
        padding-left: 60px;
        padding-right: 60px;
      `,
    },
  };

  return (
    <BaseComponentTotalLoss imageUrl="../../../..\img\totalloss_header.png">
      <Modal
        open={showPurchaseModal}
        onClose={() => {
          setShowPurchaseModal(false);
          setModalMessage("");
          setModalSeverity("success");
        }}
      >
        {/* Modal content */}
        <Paper
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
            borderRadius: 8,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h6"
            style={{
              marginBottom: "20px",
              marginTop: "20px",
              fontWeight: "bold",
            }}
          >
            Confirm Purchase
          </Typography>
          {!purchaseLoading && !modalMessage && (
            <div style={{ padding: "20px" }}>
              <Typography
                variant="body1"
                style={{ marginBottom: "20px", fontWeight: "bold" }}
              >
                {availableTransactions > 0 ? (
                  <>
                    You will be using one of your Available Uses to purchase
                    this report. You will have {availableTransactions - 1}{" "}
                    Available Uses left after this purchase.
                  </>
                ) : (
                  <>
                    Confirming this action will charge your shop's Kri-Tech
                    account ${pricePerUnit}.00 USD for Creating a Virtual Total
                    Loss Job.
                  </>
                )}
              </Typography>
              <div style={{ marginBottom: "20px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={
                    <span>
                      I Understand and Agree to the{" "}
                      <a
                        href="https://aerksa.blob.core.windows.net/legal/KriTech_terms_and_conditions_(current).pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms and Conditions
                      </a>{" "}
                      for this Virtual Total Loss Application and the Charge for
                      the Transaction.
                    </span>
                  }
                />
              </div>
              <div style={{ marginBottom: "20px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={refundAccepted}
                      onChange={(e) => setRefundAccepted(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={
                    <span>
                      I Understand and Agree that if my vehicle does NOT exceed
                      the vehicle value threshold and is NOT a total loss, I
                      will be refunded for my transaction fee less a 20%
                      processing charge.*
                    </span>
                  }
                />
              </div>
              <Typography variant="caption" style={{ marginBottom: "20px" }}>
                *Any and all transaction fee refunds are subject to a 20%
                processing fee.
              </Typography>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePurchaseConfirm}
                  style={{ marginRight: "10px" }}
                  disabled={!termsAccepted || !refundAccepted}
                >
                  Confirm
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setShowPurchaseModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
          {purchaseLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <CircularProgress />
            </div>
          )}
          {modalMessage && (
            <>
              <Alert severity={modalSeverity} style={{ marginBottom: "20px" }}>
                {modalMessage}
              </Alert>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowPurchaseModal(false)}
                >
                  Close
                </Button>
              </div>
            </>
          )}
        </Paper>
      </Modal>
      <Container>
        <CustomInfoModal
          open={openModal}
          handleClose={handleCloseModal}
          title={modalTitle}
          showSpinner={showSpinner}
          showCloseButton={showClose}
          bodyText={modalBody}
        />

        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "2em",
            padding: "2em",
            fontFamily: "Orbitron, sans-serif",
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              paddingTop: 0,
              paddingBottom: 4,
              color: "black",
              fontFamily: "Orbitron, sans-serif",
            }}
          >
            New Total Loss Job
          </Typography>

          <Box marginLeft={"10%"} sx={{ marginTop: 0 }}>
            <Typography
              variant="h5"
              gutterBottom
              align="left"
              sx={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              Step 1:
            </Typography>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={6} sm={1.8}>
                <Typography
                  align="left"
                  variant="subtitle1"
                  sx={{ color: "black", fontFamily: "Orbitron, sans-serif" }}
                >
                  Select Platform Going Into
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Select
                  value={platform}
                  onChange={(e) => setPlatform(e.target.value)}
                  sx={{
                    backgroundColor: "white",
                    width: "50%",
                    height: "80%",
                    zIndex: 4,
                    fontFamily: "Orbitron, sans-serif",
                  }}
                >
                  <MenuItem value="CccOne">CccOne</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>

          <Box marginLeft={"10%"} sx={{ marginTop: 4 }}>
            <Typography
              variant="h5"
              gutterBottom
              align="left"
              sx={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              Step 2:
            </Typography>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={6} sm={1.8}>
                <Typography
                  align="left"
                  variant="subtitle1"
                  sx={{ color: "black", fontFamily: "Orbitron, sans-serif" }}
                >
                  Enter VIN
                </Typography>
              </Grid>
              <Grid sx={{ marginRight: 0 }} item xs={12} sm={8}>
                <TextField
                  value={vin}
                  onChange={(e) => setVin(e.target.value)}
                  fullWidth
                  sx={{
                    backgroundColor: "white",
                    width: "50%",
                    height: "80%",
                    zIndex: 4,
                    fontFamily: "Orbitron, sans-serif",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box marginLeft="10%" sx={{ marginTop: 2, marginBottom: 2 }}>
            <Typography
              variant="h5"
              gutterBottom
              align="left"
              sx={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              Step 3:
            </Typography>

            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                <Typography
                  align="left"
                  variant="subtitle1"
                  sx={{ color: "black", fontFamily: "Orbitron, sans-serif" }}
                >
                  Select Impact Locations
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  zIndex: 4,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={() => handleButtonClick(14)}
                  variant="contained"
                  style={{
                    backgroundColor: buttonColors[14],
                    color: "black",
                    fontSize: "larger",
                    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
                  }}
                >
                  Unknown
                </Button>

                <Button
                  onClick={() => handleButtonClick(16)}
                  variant="contained"
                  style={{
                    backgroundColor: buttonColors[16],
                    color: "black",
                    fontSize: "larger",
                    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
                  }}
                >
                  Non-Collision
                </Button>

                <Button
                  onClick={() => handleButtonClick(19)}
                  variant="contained"
                  style={{
                    backgroundColor: buttonColors[19],
                    color: "black",
                    fontSize: "larger",
                    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
                  }}
                >
                  All-Over
                </Button>

                <Button
                  onClick={() => handleButtonClick(21)}
                  variant="contained"
                  style={{
                    backgroundColor: buttonColors[21],
                    color: "black",
                    fontSize: "larger",
                    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
                  }}
                >
                  Undercarriage
                </Button>
              </Grid>

              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <div
                  style={{
                    position: "relative",
                    width: "700px",
                    height: "700px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <PieMenu
                      radius="300px"
                      centerRadius="120px"
                      centerX="350px"
                      centerY="350px"
                      style={{
                        zIndex: -1,
                      }}
                    >
                      <StyledSlice
                        kind="12"
                        onSelect={() => handleButtonClick(12)}
                      >
                        Front
                      </StyledSlice>
                      <StyledSlice
                        kind="1"
                        onSelect={() => handleButtonClick(1)}
                      >
                        R Front Corner
                      </StyledSlice>
                      <StyledSlice
                        kind="2"
                        onSelect={() => handleButtonClick(2)}
                      >
                        R Front Side
                      </StyledSlice>
                      <StyledSlice
                        kind="3"
                        onSelect={() => handleButtonClick(3)}
                      >
                        R Side
                      </StyledSlice>
                      <StyledSlice
                        kind="4"
                        onSelect={() => handleButtonClick(4)}
                      >
                        R Rear Side
                      </StyledSlice>
                      <StyledSlice
                        kind="5"
                        onSelect={() => handleButtonClick(5)}
                      >
                        R Rear Corner
                      </StyledSlice>
                      <StyledSlice
                        kind="6"
                        onSelect={() => handleButtonClick(6)}
                      >
                        Rear
                      </StyledSlice>
                      <StyledSlice
                        kind="7"
                        onSelect={() => handleButtonClick(7)}
                      >
                        L Rear Corner
                      </StyledSlice>
                      <StyledSlice
                        kind="8"
                        onSelect={() => handleButtonClick(8)}
                      >
                        L Rear Side
                      </StyledSlice>
                      <StyledSlice
                        kind="9"
                        onSelect={() => handleButtonClick(9)}
                      >
                        L Side
                      </StyledSlice>
                      <StyledSlice
                        kind="10"
                        onSelect={() => handleButtonClick(10)}
                      >
                        L Front Side
                      </StyledSlice>
                      <StyledSlice
                        kind="11"
                        onSelect={() => handleButtonClick(11)}
                      >
                        L Front Corner
                      </StyledSlice>
                    </PieMenu>
                  </ThemeProvider>

                  {/* Image in the center */}
                  <img
                    src="../../../..\img\vehicle-outline.png"
                    alt="Vehicle Outline"
                    style={{
                      position: "absolute",
                      width: "160px",
                      height: "290px",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 1,
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={() => handleButtonClick(13)} // Assuming 13 is the ID for the Rollover button
                    style={{
                      position: "absolute",
                      width: "60px", // Adjust as needed for desired button size
                      height: "60px",
                      borderRadius: "60px", // Half of width/height to make it circular
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -20%)",
                      zIndex: 2, // Ensure the button is on top
                      backgroundColor: buttonColors[13] || "white",
                      color: "black",
                    }}
                  >
                    Rollover
                  </Button>
                </div>
              </Grid>

              <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogActions>
                  <Button
                    onClick={() => handleDialogOption("yellow")}
                    style={{
                      backgroundColor: "yellow",
                      fontSize: "1.5em",
                      padding: "12px 24px",
                    }}
                  >
                    Minor
                  </Button>
                  <Button
                    onClick={() => handleDialogOption("red")}
                    style={{
                      backgroundColor: "red",
                      fontSize: "1.5em",
                      padding: "12px 24px",
                    }}
                  >
                    Major
                  </Button>
                  <Button
                    onClick={() => handleDialogOption("white")}
                    style={{
                      backgroundColor: "white",
                      fontSize: "1.5em",
                      padding: "12px 24px",
                    }}
                  >
                    Clear
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Box>

          <Box marginLeft={"10%"} sx={{ marginTop: 4 }}>
            <Typography
              variant="h5"
              gutterBottom
              align="left"
              sx={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              Step 4:
            </Typography>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={6} sm={1.8}>
                <Typography
                  align="left"
                  variant="subtitle1"
                  sx={{ color: "black", fontFamily: "Orbitron, sans-serif" }}
                >
                  Chose Overall Condition
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Select
                  value={condition}
                  onChange={(e) => setCondition(e.target.value)}
                  sx={{
                    backgroundColor: "white",
                    width: "50%",
                    height: "80%",
                    fontFamily: "Orbitron, sans-serif",
                  }}
                >
                  <MenuItem value="Excellent">Excellent</MenuItem>
                  <MenuItem value="Very Good">Very Good</MenuItem>
                  <MenuItem value="Good">Good</MenuItem>
                  <MenuItem value="Fair">Fair</MenuItem>
                  <MenuItem value="Unknown">Unknown</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>

          <Box marginLeft="10%" sx={{ marginTop: 4 }}>
            <Typography
              variant="h5"
              gutterBottom
              align="left"
              sx={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              Step 5 (Optional):
            </Typography>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                <Grid container alignItems="flex-start" justify="flex-start">
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={useAlternativeParts}
                          onChange={(e) =>
                            setUseAlternativeParts(e.target.checked)
                          }
                          name="useAlternativeParts"
                        />
                      }
                      label="Use Alternative Parts"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  align="left"
                  variant="subtitle1"
                  sx={{ color: "black", fontFamily: "Orbitron, sans-serif" }}
                >
                  Enter Labor Rates for Cost Accuracy
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Body"
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Bdy-S"
                  value={bdyS}
                  onChange={(e) => setBdyS(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Refinish"
                  value={refinish}
                  onChange={(e) => setRefinish(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Glass"
                  value={glass}
                  onChange={(e) => setGlass(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Frame"
                  value={frame}
                  onChange={(e) => setFrame(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Mechanical"
                  value={mechanical}
                  onChange={(e) => setMechanical(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Paint Materials Rate"
                  value={paintMaterialsRate}
                  onChange={(e) => setPaintMaterialsRate(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Tax Rate for Parts"
                  value={taxRateForParts}
                  onChange={(e) => setTaxRateForParts(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Tax Rate for Labor"
                  value={taxRateForLabor}
                  onChange={(e) => setTaxRateForLabor(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginLeft={"10%"} sx={{ marginTop: 4 }}>
            <Typography
              variant="h5"
              gutterBottom
              align="left"
              sx={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              Step 6:   |
              
              <input
    accept="image/*"
    style={{ display: 'none' }}
    id="bulk-image-upload"
    type="file"
    multiple
    onChange={handleBulkImageUpload}
  />
  <label htmlFor="bulk-image-upload">
    <Button
      variant="contained"
      component="span"
      sx={{
        backgroundColor: "#00457c",
        color: "white",
        fontFamily: "Orbitron, sans-serif",
      }}
    >
      Upload Bulk
    </Button>
  </label>
            </Typography>
          </Box>
          <Box>
            <Typography
              marginLeft={"10%"}
              align="left"
              variant="subtitle1"
              sx={{
                color: "black",
                marginBottom: "20px",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              Upload Exterior Images
            </Typography>
            {[
              ["Front Left Corner", "Front Right Corner"],
              ["Back Left Corner", "Back Right Corner"],
            ].map((row, rowIndex) => (
              <Grid
                marginLeft={"25%"}
                sx={{
                  marginLeft: "30%",
                  width: "40%",
                  fontFamily: "Orbitron, sans-serif",
                }}
                container
                spacing={1}
                justifyContent="center"
                key={rowIndex}
              >
                {row.map((fieldName, fieldIndex) => (
                  <Grid item xs={12} sm={6} md={4} key={fieldName}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "Orbitron, sans-serif",
                      }}
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => handleDrop(e, fieldName)}
                    >
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id={fieldName}
                        type="file"
                        onChange={(e) => handleImageChange(e, fieldName)}
                      />
                      <label htmlFor={fieldName}>
                        {images[fieldName] && (
                          <Typography
                            variant="subtitle1"
                            align="center"
                            sx={{
                              color: "black",
                              fontFamily: "Orbitron, sans-serif",
                            }}
                          >
                            {fieldName.replace(/\s*\([^)]*\)/g, "")}
                          </Typography>
                        )}

                        <Box
                          color={"white"}
                          sx={{
                            width: 115,
                            height: 90,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: images[fieldName]
                              ? "transparent"
                              : "#00457c",
                            marginBottom: 1,
                            fontFamily: "Orbitron, sans-serif",
                          }}
                        >
                          {images[fieldName] ? (
                            <img
                              src={URL.createObjectURL(images[fieldName])}
                              alt={fieldName}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            fieldName
                          )}
                        </Box>
                      </label>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Box>

          <Box marginLeft={"10%"} sx={{ marginTop: 4 }}>
            <Typography
              variant="h5"
              gutterBottom
              align="left"
              sx={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              Step 7:
            </Typography>
          </Box>

          <Box>
            <Typography
              marginLeft={"10%"}
              align="left"
              variant="subtitle1"
              sx={{
                color: "black",
                marginBottom: "20px",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              Upload Informational Images
            </Typography>
            {[
              [
                "Interior (Open Driver's Door, Step Back, Take Picture)",
                "VIN Sticker",
                "Milage/Odometer",
              ],
            ].map((row, rowIndex) => (
              <Grid
                marginLeft={"25%"}
                sx={{ marginLeft: "30%", width: "40%" }}
                container
                spacing={1}
                justifyContent="center"
                key={rowIndex}
              >
                {row.map((fieldName, fieldIndex) => (
                  <Grid item xs={12} sm={6} md={4} key={fieldName}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "Orbitron, sans-serif",
                      }}
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => handleDrop(e, fieldName)}
                    >
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id={fieldName}
                        type="file"
                        onChange={(e) => handleImageChange(e, fieldName)}
                      />
                      <label htmlFor={fieldName}>
                        {images[fieldName] && (
                          <Typography
                            variant="subtitle1"
                            align="center"
                            sx={{
                              color: "black",
                              fontFamily: "Orbitron, sans-serif",
                            }}
                          >
                            {fieldName.replace(/\s*\([^)]*\)/g, "")}
                          </Typography>
                        )}
                        <Box
                          color={"white"}
                          sx={{
                            width: 115,
                            height: 90,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: images[fieldName]
                              ? "transparent"
                              : "#00457c",
                            marginBottom: 1,
                            fontFamily: "Orbitron, sans-serif",
                          }}
                        >
                          {images[fieldName] ? (
                            <img
                              src={URL.createObjectURL(images[fieldName])}
                              alt={fieldName}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            fieldName
                          )}
                        </Box>
                      </label>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ))}

            <Typography
              marginLeft={"10%"}
              variant="h5"
              gutterBottom
              align="left"
              sx={{
                color: "black",
                fontWeight: "bold",
                marginTop: "20px",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              Step 8:
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: "10%", marginRight: "10%" }}>
  <Typography
    align="left"
    variant="subtitle1"
    sx={{
      color: "black",
      marginBottom: "10px",
      fontFamily: "Orbitron, sans-serif",
    }}
  >
    Upload Damage Images
  </Typography>
 
</Box>
            {Array.from(
              { length: Math.ceil(additionalImages.length / 5) },
              (_, i) => i
            ).map((rowIndex) => (
              <Grid
                container
                spacing={4}
                justifyContent="center"
                key={rowIndex}
              >
                {Array.from({ length: 5 }, (_, i) => i)
                  .map((i) => rowIndex * 5 + i)
                  .filter((i) => i < additionalImages.length)
                  .map((i) => (
                    <Grid item key={i}>
                      <Box
                        onDragOver={(e) => e.preventDefault()} // Allow drop
                        onDrop={(e) => handleDropForAdditionalImages(e, i)} // Handle drop
                      >
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id={`additional-${i}`}
                          type="file"
                          multiple
                          onChange={(e) => handleAdditionalImageChange(e, i)}
                        />
                        <label htmlFor={`additional-${i}`}>
                          {additionalImages[i] && (
                            <Typography
                              variant="subtitle1"
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Orbitron, sans-serif",
                              }}
                            >
                              Damage {i + 1}
                            </Typography>
                          )}
                          <Box
                            color={"white"}
                            sx={{
                              width: 115,
                              height: 90,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontFamily: "Orbitron, sans-serif",
                              backgroundColor: additionalImages[i]
                                ? "transparent"
                                : "#00457c",
                            }}
                          >
                            {additionalImages[i] ? (
                              <img
                                src={URL.createObjectURL(additionalImages[i])}
                                alt={`Damage ${i + 1}`}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              "Damage " + (i + 1)
                            )}
                          </Box>
                        </label>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            ))}
            <Box marginLeft={"10%"} align="left" mt={2}>
              <button onClick={addAdditionalDamageImage}>
                + Add Additional Damage Image
              </button>
            </Box>

            <Typography
              variant="h5"
              gutterBottom
              align="left"
              sx={{
                color: "black",
                fontWeight: "bold",
                marginLeft: "10%",
                marginTop: "30px",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              Damage Notes
            </Typography>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Damage Notes"
                  multiline
                  rows={4}
                  onChange={(e) => setOptionalNotes(e.target.value)}
                  fullWidth
                  sx={{
                    backgroundColor: "white",
                    marginLeft: "20%",
                    fontFamily: "Orbitron, sans-serif",
                  }}
                />
              </Grid>
            </Grid>

            <Typography
              variant="h5"
              gutterBottom
              align="left"
              sx={{
                color: "black",
                fontWeight: "bold",
                marginLeft: "10%",
                marginTop: "30px",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              Special Instructions
            </Typography>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Special Instructions"
                  multiline
                  rows={4}
                  onChange={(e) => setSpecialInstructions(e.target.value)}
                  fullWidth
                  sx={{
                    backgroundColor: "white",
                    marginLeft: "20%",
                    fontFamily: "Orbitron, sans-serif",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box marginLeft={"10%"} sx={{ marginTop: 4 }}>
            <Typography
              variant="h5"
              gutterBottom
              align="left"
              sx={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              Step 9:
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={createJob}
              sx={{
                alignSelf: "flex-start",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              Submit Job
            </Button>
          </Box>
        </Box>
      </Container>
    </BaseComponentTotalLoss>
  );
};

export default NewTotalLossJob;
