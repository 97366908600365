// CustomInfoModal.jsx
import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomInfoModal = ({
  open,
  handleClose,
  title,
  showSpinner,
  showCloseButton,
  bodyText,
}) => {
  return (
    <Dialog
      open={open}
      onClose={showSpinner ? null : handleClose} // Disable closing the modal when the spinner is active
      maxWidth="md"
      fullWidth={false}
    >
      <DialogContent
        sx={{
          borderRadius: "16px",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {showCloseButton && (
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ alignSelf: "flex-end", margin: "-8px -8px 0 0" }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Typography align="center" variant="h6">
          {title}
        </Typography>
        {showSpinner && <CircularProgress sx={{ alignSelf: "center" }} />}
        <Typography variant="body1">{bodyText}</Typography>
      </DialogContent>
    </Dialog>
  );
};

export default CustomInfoModal;
