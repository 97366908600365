import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Dashboard from "./pages/dashboard.page";
import SignupPage from "./pages/signup.page";
import AutoEstimateRekey from "./pages/apps/autoestimaterekey.page";
import RekeyClassic from "./pages/apps/autoestimaterekey.v2.page";
import RekeyAPIV2 from "./pages/apps/autoestimaterekey.v2.api.page";
import AutoEstimateRekeySubmited from "./pages/apps/autoestimaterekey.submited.page";
import TotallossPage from "./pages/apps/totalloss.page";
import DashboardAdmin from "./pages/apps/hubadmin.page";
import ClientAdmin from "./pages/apps/clientadmin.page";
import AdjustActivations from "./pages/apps/hubAdmin/adjustactivations.page";
import UsageReport from "./pages/apps/hubAdmin/usagereport.page";
import Licenseing from "./pages/apps/hubAdmin/licenseing.page";
import TotalLossSubmited from "./pages/apps/totalloss.submited.page";
import ShopProfile from "./pages/apps/shopprofile.page";
import EAudit from "./pages/apps/eaudit.page";
import UserProfiles from "./pages/apps/manageuserprofile.page";
import NewTotalLossJob from "./pages/apps/totallossv2/totallossv2.create.page";
import TotalLossDashboard from "./pages/apps/totallossv2/totallossv2.dashboard.page";
import TotalLossCustomerUsage from "./pages/apps/totallossv2/totallossv2.customer.usage.page";
import AppraiserUsage from "./pages/apps/totallossv2/totallossv2.appraiser.usage.page";
import CustomerEditJob from "./pages/apps/totallossv2/totallossv2.customer.editjob.page";
import TotalLossJobDetailsAppraiser from "./pages/apps/totallossv2/totallossv2.appraiser.editjob.page.js";
import TotalLossRekey from "./pages/apps/totallossv2/totallossv2.customer.rekey.page";
import TotalLossJobDetails from "./pages/apps/totallossv2/_deprecated_totaljossv2.updatejob.customer";
import "@fontsource/orbitron";
import SuperAdmin from "./pages/apps/superadmin/SuperAdmin";
import DashboardV2 from "./pages/dashboardv2.page";
import KritechAdminDashboard from "./pages/apps/adminv2/kritechadmin/dashboard.page";
import { AuthProvider } from "./AuthContext";
import PinSetPage from "./pages/PinSetPage";
import AdminProductPage from "./pages/apps/adminv2/products/AdminProductPage";
import UsageReportPage from "./pages/apps/adminv2/usage/usage.page";
import FlowsPage from "./pages/apps/adminv2/flows/flows.page";
import DiminishedValue from "./pages/apps/diminishedvalue.page.js";
import DiminishedValueUsage from "./pages/apps/diminishedvalue/DiminishedValueUsage.js";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Checkout from "./checkout.js";
import PDRDashboard from "./pages/apps/adminv2/pdr/PDRDashboard.js";
import SignupForm from "./pages/signup.form.page.js";
import ShopDashboardReduxWrapper from "./pages/apps/adminv2/kritechadminv2/ShopDashboardReduxWrapper.js";
import SignupFormMSO from "./pages/signup.form.mso.js";
import NCSCodeGen from "./pages/ncscoupon.page.js";
import LandingPage from "./crash/pages/LandingPage/LandingPage.jsx";
import DVLanding from "./crash/pages/DiminishedValue/pages/1_DVLanding.jsx";
import { Provider } from "react-redux";
import store from "./crash/redux/store";
import _2_StartReport from "./crash/pages/DiminishedValue/pages/2_StartReport.jsx";
import _3m_VehicleDetails from "./crash/pages/DiminishedValue/pages/3m_VehicleDetails.jsx";
import _4_VehicleInfo from "./crash/pages/DiminishedValue/pages/4_VehicleInfo.jsx";
import _4m_VehicleDamage from "./crash/pages/DiminishedValue/pages/4m_VehicleDamage.jsx";
import _5_ClaimInformation from "./crash/pages/DiminishedValue/pages/5_ClaimInformation.jsx";
import _6_OwnerInformation from "./crash/pages/DiminishedValue/pages/6_OwnerInformation.jsx";
import _7_GetReport from "./crash/pages/DiminishedValue/pages/7_GetReport.jsx";
import _8_DownloadReport from "./crash/pages/DiminishedValue/pages/8_DownloadReport.jsx";
import { Add } from "@mui/icons-material";
import Additional from "./crash/pages/DiminishedValue/pages/AdditionalInfo.jsx";
const theme = createTheme({ fontFamily: "Orbitron, sans-serif" });

function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          {" "}
          <Router>
            <div className="App">
              <AuthProvider>
                <Routes>
                  <Route exact path="/instantdv" element={<DVLanding />} />
                  <Route
                    exact
                    path="/instantdv/2_StartReport"
                    element={<_2_StartReport />}
                  />
                  <Route
                    exact
                    path="/instantdv/AdditionalInfo"
                    element={<Additional />}
                  />
                  <Route
                    exact
                    path="/instantdv/3m_VehicleDetails"
                    element={<_3m_VehicleDetails />}
                  />
                  <Route
                    exact
                    path="/instantdv/4_VehicleInfo"
                    element={<_4_VehicleInfo />}
                  />
                  <Route
                    exact
                    path="/instantdv/4m_VehicleDamage"
                    element={<_4m_VehicleDamage />}
                  />
                  <Route
                    exact
                    path="/instantdv/5_ClaimInformation"
                    element={<_5_ClaimInformation />}
                  />
                  <Route
                    exact
                    path="/instantdv/6_OwnerInformation"
                    element={<_6_OwnerInformation />}
                  />
                  <Route
                    exact
                    path="/instantdv/7_GetReport"
                    element={<_7_GetReport />}
                  />
                  <Route
                    exact
                    path="/instantdv/8_DownloadReport"
                    element={<_8_DownloadReport />}
                  />

                  <Route exact path="/newlanding" element={<LandingPage />} />
                  <Route
                    path="/dashboard/shopadmin/*"
                    element={<ShopDashboardReduxWrapper />}
                  />
                  <Route exact path="/NCScodegen" element={<NCSCodeGen />} />
                  <Route exact path="/msosignup" element={<SignupFormMSO />} />
                  <Route exact path="/signupform" element={<SignupForm />} />
                  <Route
                    exact
                    path="/dashboard/pdr"
                    element={<PDRDashboard />}
                  />
                  <Route
                    exact
                    path="/dvusage"
                    element={<DiminishedValueUsage />}
                  />
                  <Route exact path="/" element={<SignupPage />} />
                  <Route exact path="/checkout" element={<Checkout />} />
                  <Route
                    exact
                    path="/dashboard/diminishedvalue"
                    element={<DiminishedValueUsage />}
                  />
                  <Route
                    path="/dashboard/shopadmin/flows/"
                    element={<FlowsPage />}
                  />
                  <Route
                    exact
                    path="/dashboard/usage/"
                    element={<UsageReportPage />}
                  />
                  <Route exact path="/dashboard/" element={<DashboardV2 />} />
                  <Route
                    path="/dashboard/shopadmin/products/"
                    element={<AdminProductPage />}
                  />
                  <Route exact path="/dashboardv2/" element={<DashboardV2 />} />
                  <Route
                    exact
                    path="/dashboard/kritechadmin/"
                    element={<KritechAdminDashboard />}
                  />
                  <Route
                    exact
                    path="/dashboard/autorekey/"
                    element={<RekeyClassic />}
                  />
                  <Route
                    exact
                    path="/dashboard/rekey/"
                    element={<RekeyAPIV2 />}
                  />
                  <Route
                    exact
                    path="/dashboard/totalloss/"
                    element={<TotallossPage />}
                  />
                  <Route
                    exact
                    path="/dashboard/autorekey/submited/"
                    element={<AutoEstimateRekeySubmited />}
                  />
                  <Route
                    exact
                    path="/dashboard/totalloss/submited/"
                    element={<TotalLossSubmited />}
                  />
                  <Route
                    exact
                    path="/dashboard/totallossv2/new/"
                    element={<NewTotalLossJob />}
                  />
                  <Route
                    exact
                    path="/dashboard/totallossv2/dashboard/"
                    element={<TotalLossDashboard />}
                  />
                  <Route
                    exact
                    path="/dashboard/totallossv2/customer/usage"
                    element={<TotalLossCustomerUsage />}
                  />
                  <Route
                    exact
                    path="/dashboard/totalloss__client"
                    element={<TotalLossCustomerUsage />}
                  />
                  <Route
                    exact
                    path="/dashboard/totallossv2/appraiser/job/details"
                    element={<TotalLossJobDetailsAppraiser />}
                  />
                  <Route
                    exact
                    path="/dashboard/totallossv2/customer/job/update"
                    element={<CustomerEditJob />}
                  />
                  <Route
                    exact
                    path="/dashboard/totallossv2/customer/job/updateold"
                    element={<TotalLossJobDetails />}
                  />
                  <Route
                    exact
                    path="/dashboard/totallossv2/customer/job/rekey"
                    element={<TotalLossRekey />}
                  />

                  <Route
                    exact
                    path="/dashboard/totallossv2/appraiser/usage"
                    element={<AppraiserUsage />}
                  />

                  <Route
                    exact
                    path="/dashboard/totalloss__appraiser"
                    element={<AppraiserUsage />}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/"
                    element={<DashboardAdmin />}
                  />
                  <Route
                    exact
                    path="/dashboard/client/"
                    element={<ClientAdmin />}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/adjustactivations/"
                    element={<AdjustActivations />}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/usagereports/"
                    element={<UsageReport />}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/licenseing/"
                    element={<Licenseing />}
                  />
                  <Route
                    exact
                    path="/dashboard/profile/users/"
                    element={<UserProfiles />}
                  />
                  <Route
                    exact
                    path="/dashboard/profile/"
                    element={<ShopProfile />}
                  />
                  <Route
                    exact
                    path="/dashboard/resetpin/"
                    element={<PinSetPage />}
                  />
                  <Route exact path="/superadmin/" element={<SuperAdmin />} />
                  <Route exact path="/dashboard/eaudit/" element={<EAudit />} />
                </Routes>
              </AuthProvider>
            </div>
          </Router>
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
