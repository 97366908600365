import axios from "axios";
import { API_URI, API_ValidatePDF } from "../Globals";

const apiEndpoint = API_URI();
const azFunc_ValidatePDF = API_ValidatePDF();

export const UploadPdf = (file, onComplete) => {
  const data = new FormData();

  data.append("file", file);

  axios
    .post(apiEndpoint + "/uploadBlob/", data, {
      // receive two    parameter endpoint url ,form data
    })
    .then((res) => {
      // then print response status
      localStorage.setItem("uploadedPdf", file.name);
      console.log("UploadedPdf:", file.name);
      onComplete(res.data);
      console.log(res.data);
    });
};

export const ReSubmitJob = (jobObjectId, jobId, onComplete) => {
  axios
    .get(apiEndpoint + "/user/resubmitjob/" + jobObjectId + "?jobid=" + jobId, {
      // receive two    parameter endpoint url ,form data
    })
    .then((res) => {
      // then print response status
      onComplete(res.data);
      console.log(res.data);
    });
};

export const isViwer = () => {
  var val = localStorage.getItem("isViwer");
  if (val !== null && val === true) return true;
  else return false;
};

export const isSales = () => {
  var val = localStorage.getItem("isSales");
  if (val !== null && val === true) return true;
  else return false;
};

export const ChangeJobStatus = (jobObjectId, newstatus, onComplete) => {
  axios
    .get(
      apiEndpoint +
        "/user/updatestatus/" +
        jobObjectId +
        "?newstatus=" +
        newstatus,
      {
        // receive two    parameter endpoint url ,form data
      }
    )
    .then((res) => {
      // then print response status
      onComplete(res.data);
      console.log(res.data);
    });
};

export const DeleteJob = (jobObjectId, jobId, onComplete) => {
  axios
    .get(apiEndpoint + "/user/deletejob/" + jobObjectId + "?jobid=" + jobId, {
      // receive two    parameter endpoint url ,form data
    })
    .then((res) => {
      // then print response status
      onComplete(res.data);
      console.log(res.data);
    });
};

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const SubmitJob = (serial, formData, onComplete) => {
  formData["aerk_name"] = localStorage.getItem("aerk_name");
  formData["aerk_company"] = "";

  if (validateEmail(localStorage.getItem("activeEmail")))
    formData["aerk_email"] = localStorage.getItem("activeEmail");
  else formData["aerk_email"] = localStorage.getItem("aerk_email");
  formData["aerk_contact"] = "FIX ME"; //localStorage.getItem("aerk_contact");
  formData["aerk_serial"] = localStorage.getItem("aerk_license");
  formData["aerk_customerid"] = localStorage.getItem("aerk_customerid");
  formData["pdfName"] = localStorage.getItem("uploadedPdf");

  console.log("UploadedPdf:", formData["pdf"]);

  axios
    .post(apiEndpoint + "/user/submitjob/" + serial, JSON.stringify(formData), {
      // receive two    parameter endpoint url ,form data
    })
    .then((res) => {
      // then print response status
      onComplete(res.data);
      console.log("Submit Job Response:", res.data);
    });
};

export const OpenLogs = (job) => {
  window.open(apiEndpoint + "/user/getlogs/" + job, "_blank");
};

export const EstimatePDF = (pdfUrl) => {
  window.open(
    "https://aerksa.blob.core.windows.net/pdf-jobs/" + pdfUrl,
    "_blank"
  );
};

export const ValidatePDF = (job, onComplete) => {
  const options = {
    method: "POST",
    url: azFunc_ValidatePDF,
    headers: { "Content-Type": "application/json" },
    data: job,
  };

  axios
    .request(options)
    .then(function (response) {
      onComplete(response.data);
      console.log(response);
    })
    .catch(function (error) {
      console.error(error);
    });
};
