import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import Banner from "../components/Banner";
import BtnStartDv from "../components/BtnStartDv";
import Footer from "../components/Footer";
import { stateInfoJson } from "./AdditionalInfo_JSON";

const Additional = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [stateInfo, setStateInfo] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const state = searchParams.get("state");
    if (state) {
      const stateData = stateInfoJson().find((info) => info.State === state);
      setStateInfo(stateData);
    }
  }, [location.search]);

  const renderParagraphs = (text) => {
    return text.split("<n/>").map((paragraph, index) => (
      <p
        key={index}
        style={{
          fontSize: "18px",
          fontFamily: "Roboto, sans-serif",
          textAlign: "left",
          marginBottom: "20px",
        }}
      >
        {paragraph.split("<strong>").map((segment, i) => {
          if (i % 2 === 1) {
            return (
              <React.Fragment key={i}>
                <strong>{segment.split("</strong>")[0]}</strong>
                <br />
                {segment.split("</strong>")[1]}
              </React.Fragment>
            );
          }
          return segment;
        })}
      </p>
    ));
  };

  return (
    <div style={{ maxWidth: "1500px", margin: "0 auto" }}>
      <Header showAdditionalInfo={false} />
      <Banner state={stateInfo?.State} />

      {stateInfo && (
        <div style={{ marginTop: "40px" }}>
          <div
            style={{
              fontSize: "24px",
              fontFamily: "Roboto, sans-serif",
              marginBottom: "40px",
            }}
            dangerouslySetInnerHTML={{
              __html: stateInfo.StateIntro.replace(
                /<strong>(.*?)<\/strong>/g,
                '<strong style="color: rgb(192, 44, 122);">$1</strong>'
              ),
            }}
          ></div>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, marginRight: "40px" }}>
              <div
                style={{
                  backgroundColor: "#555",
                  padding: "20px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                <h3
                  style={{
                    margin: 0,
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "rgb(192, 44, 122)",
                  }}
                >
                  SUMMARY
                </h3>
                <h3
                  style={{
                    margin: 0,
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {stateInfo.State} DIMINISHED VALUE CLAIMS
                </h3>
              </div>
              <div
                style={{
                  backgroundColor: "#fce4ec",
                  padding: "20px",
                  marginBottom: "20px",
                  fontSize: "20px",
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                <p style={{ textAlign: "left" }}>
                  <strong style={{ color: "rgb(192, 44, 122)" }}>
                    Statute of Limitation:
                  </strong>{" "}
                  {stateInfo.StatuteofLimitation}
                </p>
                <p style={{ textAlign: "left" }}>
                  <strong style={{ color: "rgb(192, 44, 122)" }}>
                    Third Party:
                  </strong>{" "}
                  {stateInfo.ThirdParty}
                </p>
                <p style={{ textAlign: "left" }}>
                  <strong style={{ color: "rgb(192, 44, 122)" }}>
                    First Party:
                  </strong>{" "}
                  {stateInfo.FirstParty}
                </p>
                <p style={{ textAlign: "left" }}>
                  <strong style={{ color: "rgb(192, 44, 122)" }}>
                    Property Damage:
                  </strong>{" "}
                  {stateInfo.PropertyDamage}
                </p>
                <p style={{ textAlign: "left" }}>
                  <strong style={{ color: "rgb(192, 44, 122)" }}>
                    Uninsured Motorist:
                  </strong>{" "}
                  {stateInfo.UninsuredMotorist}
                </p>
                <p style={{ textAlign: "left" }}>
                  <strong style={{ color: "rgb(192, 44, 122)" }}>
                    Underinsured Motorist:
                  </strong>{" "}
                  {stateInfo.UnderinsuredMotorist}
                </p>
                <p style={{ textAlign: "left" }}>
                  <strong style={{ color: "rgb(192, 44, 122)" }}>
                    Small Claims Limit:
                  </strong>{" "}
                  {stateInfo.SmallClaimsLimit}
                </p>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ marginBottom: "40px" }}>
                <strong
                  style={{ fontSize: "30px", color: "rgb(192, 44, 122)" }}
                >
                  Law:
                </strong>
                {renderParagraphs(stateInfo.Law)}
              </div>
              <div>
                <strong
                  style={{ fontSize: "30px", color: "rgb(192, 44, 122)" }}
                >
                  How to File:
                </strong>
                {renderParagraphs(stateInfo.HowtoFile)}
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Additional;
