import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";

const TotalLossDashboard = () => {
  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        bgcolor="transparent"
        p={4}
      >
        <Typography
          variant="h2"
          component="h1"
          textAlign="center"
          mb={4}
          color="white"
        >
          Total Loss Dashboard
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          bgcolor="white"
          borderRadius="16px"
          p={4}
        >
          <Box mb={2}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => {
                window.location.href = "/dashboard/totallossv2/new/";
              }}
            >
              Create New Total Loss Job
            </Button>
          </Box>

          <Box mb={2}>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => {
                window.location.href = "/dashboard/totallossv2/customer/usage";
              }}
            >
              Total Loss Usage Reports
            </Button>
          </Box>

          <Box>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => {
                window.location.href = "/dashboard/totallossv2/appraiser/usage";
              }}
            >
              Total Loss Usage Reports Appraiser
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default TotalLossDashboard;
