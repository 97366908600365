import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Grid,
  Box,
  TextField,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  TableContainer,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_URI } from "../../../../Globals";
import axios from "axios";
import { JSONTree } from "react-json-tree";

const FlowEditor = ({ flow, onSave }) => {
  const [localFlowData, setLocalFlowData] = useState(flow.data);
  const [tabValue, setTabValue] = useState(0); // New state for active tab
  const [collectionData, setCollectionData] = useState([]); // State to store collection data
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to zero when rows per page changes
  };

  const handleTabChange = (event, newValue) => {
    console.log("MongoDataSource:", localFlowData.mongoDataSource);
    fetchCollectionData(localFlowData.mongoDataSource);
    setTabValue(newValue);
  };

  const fetchCollectionData = async (collectionName) => {
    console.log("Fetching collection data for:", collectionName);
    try {
      const response = await axios.get(
        `${API_URI()}/admin/fetchFlowCollection/${collectionName}`
      );
      console.log("Collection Data:", response.data);
      setCollectionData(response.data); // Set the fetched data
    } catch (error) {
      console.error("Error fetching collection data:", error);
    }
  };

  useEffect(() => {
    setLocalFlowData(flow.data);
  }, [flow]);

  const handleInputChange = (field, value) => {
    setLocalFlowData((prev) => ({ ...prev, [field]: value }));
  };

  const handleStepInputChange = (index, field, value) => {
    const updatedSteps = [...localFlowData.steps];
    updatedSteps[index][field] = value;
    setLocalFlowData((prev) => ({ ...prev, steps: updatedSteps }));
  };

  const handleMappingInputChange = (stepIndex, mappingIndex, field, value) => {
    const updatedSteps = [...localFlowData.steps];
    updatedSteps[stepIndex].mappings[mappingIndex][field] = value;
    setLocalFlowData((prev) => ({ ...prev, steps: updatedSteps }));
  };

  const addMapping = (stepIndex) => {
    const updatedSteps = [...localFlowData.steps];
    updatedSteps[stepIndex].mappings.push({
      dataRecipientFieldName: "",
      dataSourceFieldName: "",
      callbackField: "",
    });
    setLocalFlowData((prev) => ({ ...prev, steps: updatedSteps }));
  };

  const removeMapping = (stepIndex, mappingIndex) => {
    const updatedSteps = [...localFlowData.steps];
    updatedSteps[stepIndex].mappings.splice(mappingIndex, 1);
    setLocalFlowData((prev) => ({ ...prev, steps: updatedSteps }));
  };

  const addStep = () => {
    setLocalFlowData((prev) => ({
      ...prev,
      steps: [
        ...prev.steps,
        {
          operationName: "",
          dataRecipient: "",
          dataRecipientObject: "",
          dataSourceUniqueIdentifier: "",
          dataRecipientUniqueIdentifier: "",
          callbackField: "",
          mappings: [],
        },
      ],
    }));
  };

  const removeStep = (index) => {
    const updatedSteps = [...localFlowData.steps];
    updatedSteps.splice(index, 1);
    setLocalFlowData((prev) => ({ ...prev, steps: updatedSteps }));
  };

  const handleSave = () => {
    onSave({ id: flow.id, data: localFlowData });
  };

  return (
    <Box p={3}>
      <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="setup and logs tabs"
        >
          <Tab label="Setup" />
          <Tab label="Logs" />
        </Tabs>

        {tabValue === 0 && (
          <Box p={3}>
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="MongoDataSource"
                    value={localFlowData.mongoDataSource} // ensure this field is managed in your component's state
                    onChange={(e) =>
                      handleInputChange("mongoDataSource", e.target.value)
                    }
                  />
                </Grid>
                {/* Using Grid to organize the fields */}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="External end-point URL"
                    value={localFlowData.endpointUrl}
                    onChange={(e) =>
                      handleInputChange("endpointUrl", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Folder Name"
                    value={localFlowData.folderName}
                    onChange={(e) =>
                      handleInputChange("folderName", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Flow Name"
                    value={localFlowData.flowName}
                    onChange={(e) =>
                      handleInputChange("flowName", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Flow Type"
                    value={localFlowData.flowType}
                    onChange={(e) =>
                      handleInputChange("flowType", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Data Source"
                    value={localFlowData.dataSource}
                    onChange={(e) =>
                      handleInputChange("dataSource", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Data Source Object"
                    value={localFlowData.dataSourceObject}
                    onChange={(e) =>
                      handleInputChange("dataSourceObject", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Where Condition (On Data Source)"
                    value={localFlowData.whereCondition}
                    onChange={(e) =>
                      handleInputChange("whereCondition", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Data Source Object Selected Field"
                    value={localFlowData.dataSourceObjectSelectedField}
                    onChange={(e) =>
                      handleInputChange(
                        "dataSourceObjectSelectedField",
                        e.target.value
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Paper>

            {localFlowData.steps &&
              localFlowData.steps.map((step, index) => (
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginTop: "20px" }}
                  key={index}
                >
                  <Typography variant="h6">Step {index + 1}</Typography>
                  <IconButton onClick={() => removeStep(index)}>
                    <DeleteIcon />
                  </IconButton>

                  <Grid container spacing={3}>
                    {/* Using Grid to organize the step mandatory fields */}
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Operation Name"
                        value={step.operationName}
                        onChange={(e) =>
                          handleStepInputChange(
                            index,
                            "operationName",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Data Recipient"
                        value={step.dataRecipient}
                        onChange={(e) =>
                          handleStepInputChange(
                            index,
                            "dataRecipient",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Data Recipient Object"
                        value={step.dataRecipientObject}
                        onChange={(e) =>
                          handleStepInputChange(
                            index,
                            "dataRecipientObject",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Data Source Unique Identifier"
                        value={step.dataSourceUniqueIdentifier}
                        onChange={(e) =>
                          handleStepInputChange(
                            index,
                            "dataSourceUniqueIdentifier",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Data Recipient Unique Identifier"
                        value={step.dataRecipientUniqueIdentifier}
                        onChange={(e) =>
                          handleStepInputChange(
                            index,
                            "dataRecipientUniqueIdentifier",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Callback Field"
                        value={step.callbackField}
                        onChange={(e) =>
                          handleStepInputChange(
                            index,
                            "callbackField",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                  </Grid>

                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Data Recipient Field Name</TableCell>
                        <TableCell>Data Source Field Name</TableCell>
                        <TableCell>Callback Field</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {step.mappings &&
                        step.mappings.map((mapping, mapIndex) => (
                          <TableRow key={mapIndex}>
                            <TableCell>
                              <TextField
                                fullWidth
                                value={mapping.dataRecipientFieldName}
                                onChange={(e) =>
                                  handleMappingInputChange(
                                    index,
                                    mapIndex,
                                    "dataRecipientFieldName",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                fullWidth
                                value={mapping.dataSourceFieldName}
                                onChange={(e) =>
                                  handleMappingInputChange(
                                    index,
                                    mapIndex,
                                    "dataSourceFieldName",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                fullWidth
                                value={mapping.callbackField}
                                onChange={(e) =>
                                  handleMappingInputChange(
                                    index,
                                    mapIndex,
                                    "callbackField",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => removeMapping(index, mapIndex)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => addMapping(index)}
                  >
                    Add Mapping
                  </Button>
                </Paper>
              ))}

            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={addStep}
              >
                Add Step
              </Button>
            </Box>
            <Box mt={3}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSave}
              >
                Save Flow
              </Button>
            </Box>
          </Box>
        )}

        {tabValue === 1 && (
          <TableContainer component={Paper}>
            <Table>
              {/* ... TableHead and other parts of your table ... */}
              <TableBody>
                {collectionData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((entry, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <JSONTree data={entry} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={collectionData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </Paper>
    </Box>
  );
};

export default FlowEditor;
