import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  Typography,
  IconButton,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";

const SeeMoreInfoModal = ({
  open,
  handleClose,
  onSubmit,
  messages,
  isError,
}) => {
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    onSubmit(message);
    setMessage("");
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent
        sx={{
          borderRadius: "16px",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          minHeight: "50vh",
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{
            alignSelf: "flex-end",
            margin: "-15px -15px 0 0", // tripled the negative margin
            padding: "24px", // added padding
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h6"
            align="center"
            sx={{ fontWeight: "bold", fontSize: "2em", marginBottom: "8px" }}
          >
            {isError === "customer" && (
              <>Requested Information Notes from Appraiser</>
            )}
            {isError === "appraiser" && (
              <>Request Customer for More Information</>
            )}
            {isError !== "customer" && isError !== "appraiser" && (
              <>Reported Issue with Total Loss Estimate</>
            )}
          </Typography>
          <List>
            {messages !== undefined &&
              messages.map((msg, index) => {
                // For appraiser messages
                if (msg.msg_appraiser) {
                  return (
                    <ListItem key={`received-${index}`}>
                      <Paper
                        sx={{
                          padding: "8px 16px",
                          borderRadius: "16px",
                          backgroundColor: "#f0f0f0",
                        }}
                      >
                        <ListItemText
                          primary={msg.msg_appraiser}
                          secondary={`Appraiser • ${new Date(
                            msg.timestamp
                          ).toLocaleString()}`}
                        />
                      </Paper>
                    </ListItem>
                  );
                }
                // For customer messages
                else if (msg.msg_customer) {
                  return (
                    <ListItem
                      key={`sent-${index}`}
                      sx={{ justifyContent: "flex-end" }}
                    >
                      <Paper
                        sx={{
                          padding: "8px 16px",
                          borderRadius: "16px",
                          backgroundColor: "#4caf50",
                          color: "#ffffff",
                        }}
                      >
                        <ListItemText
                          primary={msg.msg_customer}
                          secondary={`Customer • ${new Date(
                            msg.timestamp
                          ).toLocaleString()}`}
                        />
                      </Paper>
                    </ListItem>
                  );
                }
                return null;
              })}
          </List>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            label="Type your message"
            InputProps={{
              style: {
                fontSize: "1.2rem",
              },
            }}
          />
          <IconButton onClick={handleSubmit}>
            <SendIcon />
          </IconButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SeeMoreInfoModal;
