import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { API_URI } from "../../../../Globals";
import StepHeader from "../components/StepHeader";

import axios from "axios";
import { resetDvState } from "../../../redux/diminishedvalue/actions"; // Adjust the import path as necessary
import {
  Modal,
  CircularProgress,
  Box,
  Typography,
  Button,
  TextField,
  Snackbar,
} from "@mui/material";

const MainContent = () => {
  const [email, setEmail] = useState("");
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const dvValue = useSelector(
    (state) => state.diminishedValue.totalProjectedDimValue
  );
  const dvLink = useSelector((state) => state.diminishedValue.dvLink);
  const vin = useSelector((state) => state.diminishedValue.vin)
  const dispatch = useDispatch();
  const ownerEmail = useSelector((state) => state.diminishedValue.ownerEmail);
  const [successNotification, setSuccessNotification] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    sendEmailToShop(ownerEmail);
  }, []);

  const handleEmailClick = () => {
    setShowEmailInput(true);
  };

  const handleDownloadClick = () => {
    if (dvLink) {
      window.location.href = dvLink;
    } else {
      console.error("No download link available");
    }
  };

  const handleEmailSubmit = () => {
    sendEmailToShop(email);
    console.log("Email report to:", email);
  };

  function formatMoney(amount) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }

  const sendEmailToShop = (sendTo = null) => {
    console.log("sending email to shop");
    setLoading(true);
    setModalOpen(true);
    const data = {
      email: sendTo !== null ? sendTo : ownerEmail,
      link: dvLink,
      subject: `Your Diminished Value Report is Ready - VIN: ${vin}`,
      body: `Your Diminished Value Report is ready. Please download it from the link below. ${dvLink}`,
      jobId: "dv",
    };
 
    console.log("Sending email to shop:", data);

    axios
      .post(API_URI() + "/admin/sendDvEmailToSho2", data)
      .then((response) => {
        console.log("Email sent successfully:", response.data);
        setLoading(false);
        setModalOpen(false);
        setEmail(""); // Clear the email textbox
        setSuccessNotification(true); // Set success notification to true
      })
      .catch((error) => {
        console.error(
          "Error sending email to shop:",
          error.response ? error.response.data : error
        );
        setLoading(false);
        setModalOpen(false);
      });
  };

  const handleNewDvClick = () => {
    dispatch(resetDvState());
    navigate("/instantdv/2_StartReport");
  };

  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "40px",
        minHeight: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <h2
        style={{
          color: "black",
          fontSize: "24px",
          fontFamily: "Orbitron, sans-serif",
          fontWeight: "bold",
        }}
      >
        Looks like you are owed{" "}
        <span style={{ color: "rgb(192, 44, 122)" }}>
          {formatMoney(dvValue)}
        </span>{" "}
        in diminished value.
      </h2>
      <p
        style={{
          fontSize: "24px",
          fontFamily: "Orbitron, sans-serif",
          width: "50%",
          fontWeight: "bold",
        }}
      >
        Please download or email your final report to submit to the insurance
        company.
      </p>
      <div>
        <button
          onClick={handleDownloadClick}
          style={{
            backgroundColor: "rgb(192, 44, 122)",
            color: "white",
            fontWeight: "bold",
            fontSize: "18px",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            margin: "10px",
            cursor: "pointer",
          }}
        >
          Download Report
        </button>
        <button
          onClick={handleEmailClick}
          style={{
            backgroundColor: "rgb(192, 44, 122)",
            color: "white",
            fontWeight: "bold",
            fontSize: "18px",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            margin: "10px",
            cursor: "pointer",
          }}
        >
          Email Report
        </button>
        <button
          onClick={handleNewDvClick}
          style={{
            backgroundColor: "rgb(192, 44, 122)",
            color: "white",
            fontWeight: "bold",
            fontSize: "18px",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            margin: "10px",
            cursor: "pointer",
          }}
        >
          Start New DV
        </button>
      </div>
      {showEmailInput && (
        <div style={{ marginTop: "20px" }}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            style={{
              padding: "10px",
              fontSize: "16px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              marginRight: "10px",
            }}
          />
          <button
            onClick={handleEmailSubmit}
            style={{
              backgroundColor: "rgb(192, 44, 122)",
              color: "white",
              fontWeight: "bold",
              fontSize: "18px",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Send
          </button>
        </div>
      )}

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          <CircularProgress />
          <Typography
            id="loading-modal-description"
            variant="h6"
            style={{ marginTop: "20px" }}
          >
            Sending Email...
          </Typography>
        </Box>
      </Modal>
      <Snackbar
        open={successNotification}
        autoHideDuration={3000}
        onClose={() => setSuccessNotification(false)}
        message="Email sent successfully"
      />
    </div>
  );
};

const _8_DownloadReport = () => {
  return (
    <div style={{ maxWidth: "1500px", margin: "0 auto" }}>
      <Header
        isGrey={true}
        buttonText="DRIVE AWAY WITH A FAIR COMPENSATION"
        style={{ marginLeft: 0 }}
      />
      <div
        style={{ position: "relative", overflow: "hidden", minHeight: "400px" }}
      >
        <img
          src="/img/diminishedvalue/dv_car_r.png"
          alt="DV Car"
          style={{
            position: "absolute",
            left: "0",
            top: "50%",
            transform: "translateY(-50%)",
            width: "25%",
            height: "auto",
            zIndex: -1,
          }}
        />
        <img
          src="/img/diminishedvalue/dv_car_l.png"
          alt="DV Car"
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translateY(-50%) ",
            width: "25%",
            height: "auto",
            zIndex: -1,
          }}
        />
        <div style={{ position: "relative", zIndex: 1 }}>
          <MainContent />
        </div>
      </div>
      <Footer isGrey={true} />
    </div>
  );
};

export default _8_DownloadReport;
