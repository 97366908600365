import axios from "axios";
import { API_URI } from "../Globals";

const apiEndpoint = API_URI();

export const addshopuser = (clientid, email, name, onComplete) => {
  const options = {
    method: "GET",
    url: apiEndpoint + "/user/addshopuser/" + clientid,
    params: { email: email, name: name },
  };

  axios
    .request(options)
    .then(function (response) {
      var object = response.data;

      if (object.length === 1) {
        localStorage.setItem("activeUser", name);
        localStorage.setItem("activeEmail", email);
      }
      console.log(response.data);
      onComplete(response.data);
    })
    .catch(function (error) {
      console.error(error);
    });
};

export const getshopusers = (clientid, oncomplete) => {
  const options = {
    method: "GET",
    url: apiEndpoint + "/user/shopusers/" + clientid,
  };

  axios
    .request(options)
    .then(function (response) {
      console.log(response.data);
      oncomplete(response.data);
    })
    .catch(function (error) {
      console.error(error);
    });
};

export const removeshopuser = (clientid, email, oncomplete) => {
  const options = {
    method: "GET",
    url: apiEndpoint + "/user/removeshopuser/" + clientid,
    params: { email: email },
  };

  axios
    .request(options)
    .then(function (response) {
      console.log(response.data);
      oncomplete(response.data);
    })
    .catch(function (error) {
      console.error(error);
    });
};

export const updatepin = (clientid, emailold, pin, oncomplete) => {
  const options = {
    method: "GET",
    url: apiEndpoint + "/user/updatepin/" + clientid,
    params: {
      email: emailold,
      pin,
    },
  };

  axios
    .request(options)
    .then(function (response) {
      console.log(response.data);
      oncomplete(response.data);
    })
    .catch(function (error) {
      console.error(error);
    });
};

export const updateshopuser = (
  clientid,
  emailold,
  name,
  emailnew,
  oncomplete
) => {
  const options = {
    method: "GET",
    url: apiEndpoint + "/user/updateshopuser/" + clientid,
    params: {
      email: emailold,
      newemail: emailnew,
      newname: name,
    },
  };

  axios
    .request(options)
    .then(function (response) {
      console.log(response.data);
      oncomplete(response.data);
    })
    .catch(function (error) {
      console.error(error);
    });
};
