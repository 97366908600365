import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import {
  useTheme,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import { API_URI } from "../../../../Globals";
import EditShopModal from "./EditShopModal";
import ShopLicensesModal from "./ShopLicensesModal";

function ShopsTable({ shops, onSelectShop, onDelete, onRefresh }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    console.log(event);
    setPageSize(parseInt(event, 10));
    setPage(0);
  };

  // Map the provided data to match DataGrid's expectations
  const mappedData = shops.map((shop) => ({
    id: shop._id, // Use a unique identifier from the shop object
    ...shop,
  }));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedShop, setSelectedShop] = useState(null);
  const [shopCount, setShopCount] = useState(0);
  const [isLicensesModalOpen, setLicensesModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [shopLicenses, setShopLicenses] = useState([]);

  const fetchShopLicenses = async (shopSerial) => {
    try {
      const response = await axios.get(
        API_URI() + `/admin/getShopActiveProducts/${shopSerial}`
      );
      setShopLicenses(response.data);
    } catch (error) {
      console.error("Error fetching shop licenses:", error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get(API_URI() + "/admin/getAllProducts");
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (selectedShop) {
      fetchShopLicenses(selectedShop.Serial);
    }
  }, [selectedShop]);

  useEffect(() => {
    if (shops.length > 0 && shopCount === 0) {
      setShopCount(shops.length);
    } else if (shops.length !== shopCount) {
      setShopCount(shops.length);
      const newShop = shops[shops.length - 1];
      setSelectedShop(newShop);
      setLicensesModalOpen(true);
    }
  }, [shops, selectedShop]);

  const handleSaveLicenses = async (
    shop,
    enabledLicenses,
    transactionalCounts
  ) => {
    try {
      // Update the shop's ActiveProducts and ActiveProductsMeta
      const apiUrl = API_URI() + "/admin/updateShopActiveProducts";
      await axios.patch(apiUrl, {
        Serial: shop.Serial,
        ActiveProducts: enabledLicenses,
        ActiveProductsMeta: {
          AvailableTransactions: transactionalCounts,
        },
      });

      // Close the licenses modal
      setLicensesModalOpen(false);

      // Refresh the shops list
      onRefresh();

      // Show a success message or perform any other necessary actions
      console.log("Shop licenses saved successfully");
    } catch (error) {
      console.error("Error saving shop licenses:", error);
      // Handle the error, show an error message, or perform any other necessary actions
    }
  };

  const onEditLicenses = (shop) => {
    setSelectedShop(shop); // set selected shop
    setLicensesModalOpen(true); // open the edit licenses modal
  };

  const onEdit = (shop) => {
    setSelectedShop(shop); // set selected shop
    setEditModalOpen(true); // open the edit modal
  };

  const handleClick = (event, params) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(params.row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleDeleteClick = (row) => {
    onDelete(row); // Call the onDelete prop with the shop ID
    handleClose(); // Close the menu
  };

  const baseColumns = [
    { field: "Name", headerName: "Name", width: 150, flex: 1 },
    { field: "Serial", headerName: "Serial;", width: 200, fullWidth: true },
    { field: "ValidUntil", headerName: "ValidUntil;", width: 150, flex: 1 },
    { field: "Email", headerName: "Email", width: 150, flex: 1 },
    { field: "Phone", headerName: "Phone", width: 130, flex: 1 },
    { field: "Company", headerName: "Company", width: 180, flex: 1 },
    { field: "Street1", headerName: "Street 1", width: 200, flex: 1 },
    { field: "Street2", headerName: "Street 2", width: 200, flex: 1 },
    { field: "City", headerName: "City", width: 120, flex: 1 },
    { field: "PostalCode", headerName: "Postal Code", width: 130, flex: 1 },
    { field: "Country", headerName: "Country", width: 120, flex: 1 },
    {
      field: "State/Province",
      headerName: "State/Province",
      width: 150,
      flex: 1,
    },
    {
      field: "Active",
      headerName: "Active",
      width: 120,
      flex: 1,
      valueGetter: (params) => (params.value ? "Yes" : "No"),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 50,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(event) => {
              event.stopPropagation(); // Stop the event from propagating to onRowClick
              handleClick(event, params);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={
              Boolean(anchorEl) && selectedRow && selectedRow.id === params.id
            }
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                onEdit(selectedRow);
                handleClose();
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDeleteClick(selectedRow);
                handleClose();
              }}
            >
              Delete
            </MenuItem>
            <MenuItem
              onClick={() => {
                onEditLicenses(selectedRow);
                handleClose();
              }}
            >
              Edit Licenses
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  const columnsForSmallScreen = baseColumns.filter((column) =>
    ["Name", "Phone", "City", "Active", "actions"].includes(column.field)
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "94vh" }}>
      <EditShopModal
        open={isEditModalOpen}
        onClose={() => setEditModalOpen(false)}
        shop={selectedShop}
      />
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          onRowClick={(params) => {
            if (onSelectShop) onSelectShop(params.row);
          }}
          rows={mappedData}
          columns={isSmallScreen ? columnsForSmallScreen : baseColumns}
          page={page}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          checkboxSelection
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </div>
      <ShopLicensesModal
        open={isLicensesModalOpen}
        onClose={() => {
          setLicensesModalOpen(false);
          setSelectedShop(null);
        }}
        shop={selectedShop}
        licenses={shopLicenses}
        onSave={handleSaveLicenses}
      />
    </div>
  );
}
export default ShopsTable;
