import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import {
  fetchUsers,
  fetchLicenses,
  fetchUsageReports,
  fetchLocalShop,
  fetchShopUsers,
  storeLocalUser,
  setActiveShopSerial,
} from "./redux/actions";
import {
  Container,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import Header from "./common/Header";
import Sidebar from "./common/Sidebar";
import ShopTable from "./ShopManagement/ShopTable";
import ShopDetails from "./ShopManagement/ShopDetails";
import UserList from "./UserManagement/UserList";
import UsageReportList from "./UsageReports/UsageReportList";
import Notification from "./common/Notification";
import ConfirmationDialog from "./common/ConfirmationDialog";
import { Provider } from "react-redux";
import store from "./redux/store";
import { useAuth } from "../../../../AuthContext"; // Import the useAuth hook

const ShopDashboardAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const usageReports = useSelector((state) => state.usageReports);
  const localShop = useSelector((state) => state.shops.localShop);
  const loading = useSelector((state) => state.shops.loading);
  const loadingLocalShop = useSelector((state) => state.shops.loadingLocalShop);
  const loadingUsers = useSelector((state) => state.shops.loadingUsers);
  const [selectedShop, setSelectedShop] = useState(null);
  const [notification, setNotification] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { user, isAuthenticated, isLoading } = useAuth(); // Get the user, isAuthenticated, and isLoading from the AuthContext
  const activeShopSerial = useSelector((state) => state.shops.activeShopSerial);

  useEffect(() => {
    async function fetchData() {
      if (!isAuthenticated && !loadingLocalShop) {
        navigate("/"); // Redirect to the login page if not authenticated and not loading
      } else {
        if (user) {
          console.log("User:", user);
          localStorage.setItem("serial_admin", user.Serial);
          if (localShop === null || localShop.Serial === undefined) {
            console.log("Fetching local shop");
            await dispatch(fetchLocalShop());
          }
          console.log("LocalShop:", localShop);
          localStorage.setItem("shopProducts",JSON.stringify(localShop.ActiveProducts));
          dispatch(storeLocalUser(user));
          dispatch(fetchShopUsers(user.Serial));
        }
        console.log("XUSer", user);
      }
    }
    fetchData();
  }, [isAuthenticated, isLoading, navigate, dispatch]);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const viewAsShopIndex = pathParts.indexOf("view-as-shop");
    console.log("ActiveShopSerial", activeShopSerial);

    if (viewAsShopIndex !== -1 && pathParts.length > viewAsShopIndex + 1) {
      const shopSerial = pathParts[viewAsShopIndex + 1];
      if (activeShopSerial !== shopSerial) {
        dispatch(setActiveShopSerial(shopSerial));
        dispatch(fetchLocalShop());
      }
    } else {
      if (activeShopSerial !== null) {
        dispatch(setActiveShopSerial(null));
        dispatch(fetchLocalShop());
      }
    }
  }, [dispatch, location.pathname, activeShopSerial]);

  const handleShopSelect = (shop) => {
    setSelectedShop(shop);
  };

  const handleUsageReportSelect = (usageReport) => {
    navigate(`/dashboard/shopadmin/reports/${usageReport.id}`);
  };

  const handleNotification = (message, type) => {
    setNotification({ message, type });
  };

  const handleConfirmationDialog = (message, onConfirm) => {
    setConfirmationDialog({ message, onConfirm });
  };

  const renderContent = () => {
    const pathParts = location.pathname.split("/");
    const viewAsShopSerial = pathParts.includes("view-as-shop")
      ? pathParts[pathParts.length - 1]
      : null;

    let path = location.pathname.replace("/dashboard/shopadmin", "");

    if (viewAsShopSerial) {
      path = path.replace(`/view-as-shop/${viewAsShopSerial}`, "");
    }

    switch (path) {
      case "/shops":
        return (
          <>
            <ShopTable onSelectShop={handleShopSelect} />
          </>
        );
      case "/users":
        return <UserList serial={user.Serial} />;

      case "/reports":
        return (
          <UsageReportList
            usageReports={usageReports}
            onSelect={handleUsageReportSelect}
          />
        );
      case "/profile":
        return <ShopDetails shop={localShop} />;
      default:
        return (
          <Grid container spacing={2} sx={{ marginTop: "50px" }}>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    System Updates
                  </Typography>
                  {/* Add system updates content */}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    News
                  </Typography>
                  {/* Add news content */}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        );
    }
  };

  const calculateMaxWidth = () => {
    const sidebarWidth = isSidebarOpen ? 300 : 110; // Adjust 240 to your actual sidebar width
    return `calc(100vw - ${sidebarWidth}px)`;
  };

  return (
    <Provider store={store}>
      {isAuthenticated && !isLoading ? (
        loadingLocalShop ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Header />
            <Box sx={{ display: "flex" }}>
              <Sidebar
                isOpen={isSidebarOpen}
                onToggle={() => setIsSidebarOpen(!isSidebarOpen)}
              />
              <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Container
                  maxWidth={false}
                  sx={{ maxWidth: calculateMaxWidth() }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {renderContent()}
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Box>
            <Notification
              open={notification !== null}
              message={notification?.message}
              type={notification?.type}
              onClose={() => setNotification(null)}
              onConfirm={() => {}}
            />
            <ConfirmationDialog
              open={confirmationDialog !== null}
              title="Confirmation"
              message={confirmationDialog?.message}
              onConfirm={confirmationDialog?.onConfirm}
              onCancel={() => setConfirmationDialog(null)}
            />
          </>
        )
      ) : null}
    </Provider>
  );
};

export default ShopDashboardAdmin;
