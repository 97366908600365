import React, { useState, createRef } from "react";
import {
  Box,
  Card,
  CardContent,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { API_URI } from "../Globals";
import { GooSpinner } from "react-spinners-kit";
import { useAuth } from "../AuthContext";

const PinSetPage = () => {
  const pinLength = 4;
  const [pin, setPin] = useState(Array(pinLength).fill(""));
  const inputRefs = Array.from({ length: pinLength }).map(() => createRef());
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();
  const [error, setError] = useState("");
  const mainLogoPath = "../../img/dashboard/CrashLogo_Final.png";
  const handleChange = (value, index) => {
    if (/[^0-9]/.test(value)) return; // Prevent non-digit input
    pin[index] = value;
    setPin([...pin]);

    if (index < pinLength - 1 && value !== "")
      inputRefs[index + 1].current.focus(); // Move focus to the next input
  };

  const handleSubmit = async () => {
    if (pin.join("").length < pinLength) return;
    setIsLoading(true);
    const params = new URLSearchParams(location.search);
    const user = params.get("user"); // This will give you the value of the 'user' parameter
    var Pin = pin.join("");
    var Serial = user;

    try {
      const response = await axios.patch(API_URI() + "/adminShop/updatepin", {
        Serial,
        Pin,
      });

      console.log("Pin updated successfully", response.data);
      localStorage.setItem("selectedOption", response.data.username);
      try {
        const data = await login(Serial); // assuming login returns the axios response
        console.log("Handle Verify", data);

        if (data && data.token) {
          localStorage.setItem("token", data.token);
        }
      } catch (error) {
        console.error("Verification failed", error);
        setError("Verification failed. Please try again.");
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error("Error Response", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error Request", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        setIsLoading(false);
        console.error("Error", error.message);
      }
    }

    window.location.href = window.location.origin;
  };
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <GooSpinner size={180} color="#686769" loading={true} />
      </div>
    );
  }
  return (
    <div className="dashboard-v2">
      <header className="header"></header>
      <main className="main-content">
        <img className="main-logo" src={mainLogoPath} alt="main logo" />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight="50vh"
        >
          <Card sx={{ p: 3, boxShadow: 3 }}>
            <CardContent>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                textAlign="center"
                fontWeight="bold"
                mb={3}
              >
                Set CRASH User PIN
              </Typography>
              <Box display="flex" justifyContent="center" gap={2} mb={3}>
                {Array.from({ length: pinLength }).map((_, index) => (
                  <TextField
                    key={index}
                    variant="outlined"
                    type="text"
                    inputProps={{
                      style: {
                        textAlign: "center",
                        fontSize: "2rem",
                        width: "5rem",
                        height: "5rem",
                      },
                      maxLength: 1,
                    }}
                    value={pin[index]}
                    onChange={(e) => handleChange(e.target.value, index)}
                    inputRef={inputRefs[index]}
                    sx={{ "& input": { textAlign: "center" } }}
                  />
                ))}
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Save
              </Button>
              {error && <Typography color="error">{error}</Typography>}
            </CardContent>
          </Card>
        </Box>
      </main>
    </div>
  );
};

export default PinSetPage;
