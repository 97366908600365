import { Card, CardContent, Grid, Typography, Paper } from "@mui/material";

function ShopDetails({ shop }) {
  const fieldsToDisplay = [
    "Name",
    "Serial",
    "Email",
    "Phone",
    "Company",
    "City",
  ];

  return (
    <Card elevation={3} style={{}}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          {" "}
          {/* Reduced spacing */}
          {fieldsToDisplay.map((field) => (
            <Grid item xs={12} sm={4} md={3} lg={2} key={field}>
              {" "}
              {/* Adjusted breakpoints */}
              <Paper
                elevation={1}
                style={{
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    fontWeight: "600",
                    marginBottom: "2px",
                  }}
                >
                  {" "}
                  {/* Reduced font size */}
                  {field.replace("_", " ")}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: "400",
                    color: "#555",
                  }}
                >
                  {" "}
                  {/* Reduced font size */}
                  {shop[field]}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ShopDetails;
