// usersReducer.js
const initialState = {
  users: [],
  loading: false,
  resetingPin: false,
  isDeleting: false,
  error: null,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DELETE_USER_REQUEST":
      return {
        ...state,
        isDeleting: true,
        error: null,
      };
    case "DELETE_USER_SUCCESS":
      return {
        ...state,
        isDeleting: false,
        error: null,
      };
    case "DELETE_USER_FAILURE":
      return {
        ...state,
        isDeleting: false,
        error: action.payload,
      };
    case "FETCH_USERS_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "FETCH_USERS_SUCCESS":
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: null,
      };
    case "FETCH_USERS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "RESET_USER_PIN_REQUEST":
      return {
        ...state,
        resetingPin: true,
        error: action.payload,
      };
    case "RESET_USER_PIN_SUCCESS":
      return {
        ...state,
        resetingPin: false,
        error: null,
      };
    case "RESET_USER_PIN_FAILURE":
      return {
        ...state,
        resetingPin: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default usersReducer;
