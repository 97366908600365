import React from "react";
import styles from "./Modal.module.css";
import { RiCloseLine } from "react-icons/ri";
import Form from "react-bootstrap/Form";
import { updateshopuser } from "../services/userprofile.service";
const ModalNewUser = ({ setIsOpen, usert, emailt }) => {
  var updatedUsr = usert;
  var updatedEmail = emailt;

  return (
    <>
      <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modalEditUser}>
          <div className={styles.modalHeader}>
            <h5 className={styles.heading2}>Edit user</h5>
          </div>
          <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className={"container-step"}>
            <div className={"container-step-entry"}>
              <div className={styles.field2}>Edit Name</div>
            </div>
            <div className={"container-step-entry"}>
              <Form.Control
                defaultValue={usert}
                type="text"
                placeholder="Name"
                onChange={(e) => {
                  updatedUsr = e.target.value;
                }}
              />
            </div>
          </div>
          <div className={"container-step"}>
            <div className={"container-step-entry"}>
              <div className={styles.field2}>Edit Email</div>
            </div>
            <div className={"container-step-entry"}>
              <Form.Control
                defaultValue={emailt}
                type="text"
                placeholder="Email"
                onChange={(e) => {
                  updatedEmail = e.target.value;
                }}
              />
            </div>
          </div>
          <div className={styles.modalActions}>
            <div className={styles.actionsContainer}>
              <button
                className={styles.deleteBtn}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className={styles.cancelBtn}
                onClick={() => {
                  updateshopuser(
                    localStorage.getItem("aerk_customerid"),
                    usert,
                    updatedUsr,
                    updatedEmail,
                    () => {
                      window.location.reload();
                    }
                  );
                  setIsOpen(false);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalNewUser;
